import { useCallback, useState } from 'react';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import AnnotationEditor, { whenToUseChangeHandler } from '../../EntityListDetail/AnnotationEditor/AnnotationEditor';
import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { stringJoin } from '../FormDetail/_utility';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { IEntityFormDetailProps, IFormHeaderProps } from '../entityDetailPopup.interfaces';
import { FormDetail } from '../FormDetail/_FormDetail';
import { InfoGroup } from '../../InfoBlocks';
import { utcDateToLocaleDateString } from '../../../utilities/utils';
import { FormHeaderBase } from '../FormDetail/Headers/_FormHeaderBase';
import { QuickAppointmentAnnotation } from '../../../models/BusinessObjects_Model/QuickAppointmentAnnotation';
import FormDetailStyles from '../FormDetail/formDetail.module.scss';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../store/hooks';
import useQuickAnnotationViewModel from '../../../viewmodels/QuickAnnotationViewModel';
import authProvider from '../../../utilities/authProvider';

interface IQuickAnnotionFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<QuickAppointmentAnnotation>;
}

export function QuickAnnotationForm(props: IQuickAnnotionFormDetailProps) {
	const organizationSettings = useAppSelector((state) => state.globals.organizationSettings);
	const showAppointmentSettings = organizationSettings.quickAnnotation_ShowAppointmentSettings;

	const [formattedDate] = useState(utcDateToLocaleDateString(Date.now()));
	const viewModel = useQuickAnnotationViewModel(authProvider.getUserId()!, props.id);

	const propertyNames = viewModel.getProperties();
	const errors = (propertyName: string) => {
		const propertyErrors = viewModel.getPropertyErrors(propertyName);
		if (propertyErrors !== undefined && propertyErrors.length > 0) return stringJoin(propertyErrors);
		else return undefined;
	};

	return (
		<FormDetail
			vm={viewModel}
			headerText={viewModel.getProperty(propertyNames.appointmentSubject)}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="note-sticky"
			header={
				<QuickAnnotationHeader
					vm={viewModel}
					headerText={viewModel.getProperty(propertyNames.appointmentSubject)}
					editing={props.isEditing}
					setEditing={props.setIsEditing}
					closeDetailPopup={props.closeDetailPopup}
					iconName="note-sticky"
				/>
			}
		>
			<AnnotationEditor
				defaultContent={viewModel.getProperty(propertyNames.annotationText)}
				labels={{ left: formattedDate, middle: t('annotation') }}
				functions={{
					changeHandler: (value) => viewModel.setProperty(propertyNames.annotationText, value),
				}}
				whenToUseChangeHandler={whenToUseChangeHandler.change}
				errorMessage={errors(propertyNames.annotationText)}
				editing
			/>
			{showAppointmentSettings && (
				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('appointmentAnnotationAttributes.appointmentInfoHeaderText')}
				>
					<BoundInputRows.TextRow
						label={t('subject')}
						vm={viewModel}
						model={viewModel.model}
						modelValuePropertyName={propertyNames.appointmentSubject}
						errorMessage={errors(propertyNames.appointmentSubject)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('appointmentAttributes.appointmentType')}
						vm={viewModel}
						model={viewModel.model}
						modelValuePropertyName={propertyNames.appointmentTypeId}
						errorMessage={errors(propertyNames.appointmentTypeId)}
						DDEntityType={DropdownEntityType.appointmentTypes}
					/>
					<BoundInputRows.BooleanRow
						label={t('appointmentAttributes.isRecurring')}
						vm={viewModel}
						model={viewModel.model}
						modelValuePropertyName={propertyNames.isFollowUp}
						errorMessage={errors(propertyNames.isFollowUp)}
						noDeleteButton
					/>
				</InfoGroup>
			)}
		</FormDetail>
	);
}

export function QuickAnnotationHeader(props: IFormHeaderProps) {
	const vm = props.vm;
	const closeDetailPopup = props.closeDetailPopup;
	const [saveDisabled, disableSave] = useState(false);

	const updateItem = useCallback(async () => {
		if (!vm.isValid || !vm.isDirty || saveDisabled) return;
		disableSave(true);

		const updateWorked = await vm.update();

		if (updateWorked) closeDetailPopup(true);
	}, [vm, closeDetailPopup, saveDisabled]);

	const saveButtonClassName = `${FormDetailStyles.formButton} ${(!vm.isValid || saveDisabled) && FormDetailStyles.disabled}`;

	return (
		<FormHeaderBase
			{...props}
			headerText={t('appointmentAnnotationAttributes.initialHeaderText')}
		>
			<FontAwesomeIcon
				className={saveButtonClassName}
				icon={faSave}
				onClick={async () => await updateItem()}
				size="xl"
			/>
		</FormHeaderBase>
	);
}
