import { api } from '../../api/_Executor';
import { ActivityQuery } from '../../business/Query/ActivityQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { RangeFilter } from '../../business/Query/_filters';
import { AgendaTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { AgendaItemView } from '../../models/BusinessObjects_View/AgendaItemView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

export interface IAgendaItemViewCollectionViewModel<T = AgendaItemView, Q extends BaseQuery = ActivityQuery> extends ICollectionViewModel<T, Q> {
	fetch(from: Date, to: Date, representativeId?: string): Promise<boolean>;
}

/** USE IN AGENDA ONLY! :: USE FETCH FUNCTION INSTEAD OF READ! */
export function AgendaItemViewCollectionViewModel<T = AgendaItemView, Q extends BaseQuery = ActivityQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean
): IAgendaItemViewCollectionViewModel<T, Q> {
	const apiReader = api.activity.viewAgendaItemsAsync;
	const query = new ActivityQuery() as Q;
	const table = AgendaTableDefinition;
	const canSearch: boolean = false;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<T, Q>(instanceName, apiReader, ignoreRepresentative_id, query, undefined, 'entity_Id', 'company_Id', false);

	async function fetch(from: Date, to: Date, representativeId?: string): Promise<boolean> {
		const filter = new RangeFilter('ActivityQuery.RangeFilter', from, to);
		const q = new ActivityQuery();

		if (representativeId === undefined && !ignoreRepresentative_id) {
			representativeId = cvm.getRepresentativeId();
		}

		q.addOrReplaceFilter(filter);
		q.representative_Id = representativeId;

		return await cvm.doQuery(q as Q);
	}

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,

		fetch,
	};
}
