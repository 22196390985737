import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getTokenFromCookie, isTokenValid } from '../utilities/authProvider';

export const AuthenticatedRoutes = () => {
	const location = useLocation();
	// const valid = isTokenValid();
	const token = getTokenFromCookie();

	if (token !== undefined) {
		return <Outlet />;
	} else {
		return (
			<Navigate
				to='/login'
				replace
				state={{ from: location }}
			/>
		);
	}
};
