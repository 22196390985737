import styles from './loginPanel.module.scss';
import * as React from 'react';
import Logo from '../../images/mydesk_blue.svg';


export default class Container extends React.Component<{ children?: React.ReactNode; }> {

	render() {
		return (
			/*TODO: lessen divception if possible?*/
			<div className={styles.container}>
				<div className={styles.box}>
					<div className={styles.boxColumn}>
						<div className={styles.boxHeader}>
							<img
								src={Logo}
								alt="MyDesk Logo"
								className={styles.salesFlowLogo}
							/>
						</div>
						<div className={styles.centerBox}>
							<div className={styles.centerBoxContent}>
								{this.props.children}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}