import { IconCustomType } from '../components/IconCustom/IconCustom.index';
import { AddressDto } from '../model/EntityDtos/AddressDto';
import { CompanyDto } from '../model/EntityDtos/CompanyDto';
import { ContactDto } from '../model/EntityDtos/ContactDto';
import { RepresentativeDto } from '../model/EntityDtos/RepresentativeDto';
import { PrioritizedCompanyView } from '../model/Views/PioritizedCompanyView';
import { Contact } from '../models/Model/Contact';
import { isNothing, isNotNothing, nullToEmpty, pCase } from './utils';

function append(s: string, add: string | null): string {
	if (isNothing(add)) return s;
	let result = `${s}${add}`;
	return result;
}

function appendWithPreSeperator(s: string, seperator: string, add: string | null | undefined): string {
	if (isNothing(add)) return s;
	if (isNotNothing(s)) return `${s}${seperator}${add}`;
	return add!;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function appendWithSeperators(s: string, sepStart: string, add: string | null, sepEnd: string): string {
	if (isNothing(add)) return s;
	if (isNotNothing(s)) return `${s}${sepStart}${add}${sepEnd}`;
	return add!;
}

//IN USE
export const getContactFullName = (entity?: Contact | null): string => {
	if (!entity) return '';

	let s = '';

	s = appendWithPreSeperator(s, ' ', pCase(entity.firstName));
	s = appendWithPreSeperator(s, ' ', pCase(entity.surname));

	return s;
};

export const getDashIconForSalutation = (salution: string | null | undefined): IconCustomType => {
	//TODO: Other languages and stuff
	if (!salution) return IconCustomType.DASH_CONTACT;
	const salute = salution.toLowerCase();

	if (salute === 'de heer') return IconCustomType.DASH_CONTACT_MAN;
	if (salute === 'mevrouw') return IconCustomType.DASH_CONTACT_WOMAN;

	return IconCustomType.DASH_CONTACT;
};

export const getAddressStringFromPrioritizedCompanyView = (dto?: PrioritizedCompanyView | null, useVisitingAddress: boolean = true): string | undefined => {
	if (!dto) return undefined;

	let s = '';

	s = append(s, dto.entity_PrimaryVisitingAddress_City);
	s = appendWithPreSeperator(s, ', ', dto.entity_PrimaryVisitingAddress_Street);

	if (isNothing(s)) return undefined;

	return s;
};

export const getFullNameFromPrioritizedCompanyView = (dto?: PrioritizedCompanyView | null): string | undefined => {
	if (!dto) return undefined;

	let s = '';

	s = appendWithPreSeperator(s, ' ', pCase(dto.entity_PrimaryContact_FirstName));
	s = appendWithPreSeperator(s, ' ', pCase(dto.entity_PrimaryContact_SurnamePrefixes));
	s = appendWithPreSeperator(s, ' ', pCase(dto.entity_PrimaryContact_Surname));

	return s;
};

export const getRepresentativeNameFromPrioritizedCompanyView = (dto: PrioritizedCompanyView | null): string | undefined => {
	if (!dto) return undefined;

	let s = '';

	if (isNotNothing(dto.entity_PrimaryRepresentative_Name)) return nullToEmpty(dto.entity_PrimaryRepresentative_Name);
	if (isNotNothing(dto.entity_PrimaryRepresentative_Code)) return nullToEmpty(dto.entity_PrimaryRepresentative_Code);

	return s;
};

//Probably deprecated / not in use (switched to view)
export function getDtoRepresentativeString(dto: RepresentativeDto | null, defaultValue: string = ''): string {
	if (isNothing(dto)) return defaultValue;

	if (isNotNothing(dto.fullName)) return nullToEmpty(dto.fullName);
	if (isNotNothing(dto.code)) return nullToEmpty(dto.code);

	return defaultValue;
}

//Probably deprecated / not in use (switched to view)
export function getDtoCompanyRepresentativeString(dto: CompanyDto, defaultValue: string = ''): string {
	return getDtoRepresentativeString(dto.primaryRepresentative, '');
}

//Probably deprecated / not in use (switched to view)
export const getAddressStringFromAddressDto = (dto?: AddressDto | null): string | undefined => {
	if (!dto) return undefined;

	let s = '';

	s = append(s, dto.city);
	s = appendWithPreSeperator(s, ', ', dto.street);

	if (isNothing(s)) return undefined;

	return s;
};

//Probably deprecated / not in use (switched to view)
export const getAddressStringFromCompanyDto = (dto?: CompanyDto | null, useVisitingAddress: boolean = true): string | undefined => {
	if (!dto) return undefined;
	let dtoAddress: AddressDto | null;

	if (useVisitingAddress) {
		dtoAddress = dto.primaryVisitingAddress;
	} else {
		dtoAddress = dto.primaryShippingAddress;
	}

	return getAddressStringFromAddressDto(dtoAddress);
};
