import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import styles from '../settingsPage.module.scss';

import { FieldDefinitionTypeListProps } from './types';

export class TypeList extends Component<FieldDefinitionTypeListProps> {
	render() {
		return (
			<Fragment>
				{this.props.types.map((type) => {
					return (
						<div className={styles.componentWrapper}>
							<div
								draggable
								className={`${styles.component} ${styles.listItem}`}
								onDragStart={this.props.onDragStart}
								onDragEnd={this.props.onDragEnd}
								key={type.name}
								id={type.name}
							>
								<div className={styles.icon}>
									{type.icon && (
										<FontAwesomeIcon icon={type.icon} />
									)}
								</div>
								<div className={styles.text}>
									<Trans
										i18nKey={type.name.toLocaleLowerCase()}
									/>
								</div>
								<div className={styles.grip}>
									<FontAwesomeIcon icon='grip-vertical' />
								</div>
							</div>
						</div>
					);
				})}
			</Fragment>
		);
	}
}
