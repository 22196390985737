import styles from './grid.module.scss';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import React from 'react';
import { Component, Fragment, ReactNode } from 'react';
import { Link, PathMatch } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconCustom, IconCustomType } from '../IconCustom/IconCustom.index';
import { InputFieldComponentWrapper } from '../InputFields_OLD/InputFieldComponent';
import { SearchBar } from '../SearchBar/SearchBar';

export class Grid extends Component<{ children?: React.ReactNode }> {
	render() {
		return <div className={styles.grid}>{this.props.children}</div>;
	}
}

export class Column extends Component<{
	children?: React.ReactNode;
	size: 'fourth' | 'half' | 'threeFourths' | 'whole';
}> {
	render() {
		return <div className={`${styles.column} ${styles[this.props.size]}`}>{this.props.children}</div>;
	}
}

export class Header extends Component<{
	iconLeft?: IconCustomType;
	content: ReactNode;
	iconRight?: IconCustomType;
	onRightClick?: () => Promise<any | void>;
}> {
	handle_onRightClick = async () => {
		if (!this.props.onRightClick) return;
		await this.props.onRightClick();
	};

	render() {
		return (
			<div className={`${styles.rowHeader} text-uppercase`}>
				{this.props.iconLeft !== undefined && (
					<div className={styles.icon}>
						<IconCustom kind={this.props.iconLeft} />
					</div>
				)}
				<div className={styles.text}>{this.props.content}</div>
				{this.props.iconRight && (
					<div className={`${styles.icon} ${styles.right}`}>
						<IconCustom
							kind={this.props.iconRight}
							onClick={this.handle_onRightClick}
						/>
					</div>
				)}
			</div>
		);
	}
}

export class HeaderEntity extends Component<{
	iconLeft?: IconCustomType;
	content: ReactNode;
	iconRight?: ReactNode;
}> {
	render() {
		return (
			<div className={`${styles.rowHeader} text-uppercase`}>
				{this.props.iconLeft && (
					<div className={styles.icon}>
						<IconCustom kind={this.props.iconLeft} />
					</div>
				)}
				<div className={styles.text}>{this.props.content}</div>
				{this.props.iconRight && <div className={`${styles.icon} ${styles.right}`}>{this.props.iconRight}</div>}
			</div>
		);
	}
}

export class HeaderEntityTable extends Component<{
	title: string | undefined | null;
	content: ReactNode;
	tabProps?: {
		tabs: { text: string; query: object }[];
		tabClickHandler: (tabQuery: object) => () => void;
	};
	iconLeft: IconCustomType;
	iconRight?: ReactNode;
	searchCallBack: (searchString: string) => void;
}> {
	render() {
		return (
			<Fragment>
				<div className={styles.rowHeader}>
					{/* TODO: maybe later */}
					{this.props.tabProps !== undefined &&
						this.props.tabProps!.tabs.map((tab, key) => (
							<div
								key={key}
								className={styles.tab}
								onClick={this.props.tabProps!.tabClickHandler(tab.query)}
							>
								{t(tab.text)}
							</div>
						))}
					<div className={styles.text}>&nbsp;</div>
					{this.props.iconRight && <div className={`${styles.icon} ${styles.right}`}>{this.props.iconRight}</div>}
				</div>
				<GridComponents.Row.SubHeader>
					<div className={styles.listIcon}>
						<IconCustom kind={this.props.iconLeft} />
					</div>
					<div className={styles.listTitle}>{this.props.title}</div>
					<div className={styles.listSearchBar}>
						<SearchBar
							searchCallback={this.props.searchCallBack}
							border
						/>
					</div>
				</GridComponents.Row.SubHeader>
			</Fragment>
		);
	}
}

export interface ITabGroup {
	title: string;
	tabs: {
		title: string;
		icon?: IconProp;
		element: ReactNode;
	}[];
}

export class VerticalTabs extends Component<{ tabGroups: ITabGroup[] }, { activeTab: [number, number] }> {
	constructor(props) {
		super(props);

		this.state = { activeTab: [0, 0] };
	}

	showTab = (int1: number, int2: number) => {
		this.setState({
			activeTab: [int1, int2],
		});
	};

	render() {
		return (
			<div className={styles.tabs}>
				<div className={styles.container}>
					<div className={styles.side}>
						<div className={styles.tabTops}>
							{this.props.tabGroups.map((group, i1) => (
								<div className={styles.group}>
									<div className={styles.groupTitle}>{group.title}</div>
									{group.tabs.map((tab, i2) => (
										<div
											className={styles.tabLink}
											onClick={() => this.showTab(i1, i2)}
										>
											<FontAwesomeIcon
												icon={tab.icon ? tab.icon : 'diamond'}
												size='lg'
												className={styles.tabIcon}
											/>
											{tab.title}
										</div>
									))}
								</div>
							))}
						</div>
					</div>

					{this.props.tabGroups.map((group, i1) => {
						return group.tabs.map((tab, i2) => {
							const activeTab = this.state.activeTab;

							return (
								<div
									style={{
										display: i1 !== activeTab[0] || i2 !== activeTab[1] ? 'none' : '',
									}}
									className={styles.tab}
								>
									{tab.element}
								</div>
							);
						});
					})}
				</div>
			</div>
		);
	}
}

export class HorizontalTabs extends Component<{
	tabs: {
		title: string;
		element: ReactNode;
	}[];
	activeTab: number;
	rightElement?: ReactNode;
	tabClickHandler?: (index: number) => void;
}> {
	render() {
		return (
			<div className={styles.tabs}>
				<div className={styles.container}>
					<div className={styles.top}>
						<div className={styles.tabTops}>
							{this.props.tabs.map((tab, index) => (
								<div
									className={`${styles.tabTop} 
									${index === this.props.activeTab && styles.active} 
									${index !== this.props.activeTab && index !== this.props.activeTab - 1 && styles.line}
									`}
									key={index}
									onClick={() => {
										if (this.props.tabClickHandler) this.props.tabClickHandler(index);
									}}
								>
									{tab.title}
								</div>
							))}
						</div>

						<div className={styles.rightElement}>{this.props.rightElement}</div>
					</div>

					{this.props.tabs.map((tab, i) => (
						<div
							style={{
								display: this.props.activeTab !== i ? 'none' : '',
							}}
						>
							{tab.element}
						</div>
					))}
				</div>
			</div>
		);
	}
}

export class TableRow extends Component<{
	header?: string;
	children?: React.ReactNode;
}> {
	render(): React.ReactNode {
		return this.props.children && this.props.header ? (
			<InputFieldComponentWrapper label={this.props.header}>{this.props.children}</InputFieldComponentWrapper>
		) : null;
	}
}

export class SubHeader extends Component<{ children?: React.ReactNode }> {
	render() {
		return <div className={styles.rowSubHeader}>{this.props.children}</div>;
	}
}

export class Search extends Component<{
	withBorder?: Boolean;
}> {
	getStyleName = () => {
		if (this.props.withBorder) {
			return styles.searchBorder;
		}
		return styles.search;
	};

	render() {
		return (
			<div className={this.getStyleName()}>
				<div className={styles.question}>
					<FontAwesomeIcon icon={['far', 'search']} />
				</div>
				<div className={styles.box}>
					{/* TODO: Translate */}
					<input
						type='text'
						title='Search'
					/>
				</div>
				<div className={styles.filter}>
					<FontAwesomeIcon icon={['far', 'filter']} />
				</div>
			</div>
		);
	}
}

export class SubHeaderDetail extends Component<{
	icon?: IconProp;
	name: string;
	buttons?: ReactNode[];
}> {
	render() {
		return (
			<div className={`${styles.detail} text-capitalize`}>
				{this.props.icon && (
					<div className={styles.icon}>
						<FontAwesomeIcon icon={this.props.icon} />
					</div>
				)}
				<div className={`${styles.name} ${styles.big}`}>{this.props.name}</div>
				<div className={styles.buttons}>
					{/* <a href="#"><i className="fas fa-trash-alt"></i></a>
						<a href="#"><i className="fas fa-edit"></i></a>
						<a href="#"><i className="fas fa-save"></i></a> */}

					{this.props.buttons}
				</div>
			</div>
		);
	}
}

export class Scroll extends Component<{ children?: React.ReactNode; onScrollCallback?: any }> {
	scrollDiv!: React.RefObject<HTMLDivElement>;
	canHandleScroll: boolean = true;

	constructor(props) {
		super(props);
		this.scrollDiv = React.createRef<HTMLImageElement>();
	}

	public resetScrollPosition() {
		if (!this.scrollDiv.current) return;
		if (this.scrollDiv.current.scrollTop === 0) return;

		this.canHandleScroll = false;
		this.scrollDiv.current.scrollTop = 0;
		setTimeout(() => (this.canHandleScroll = true), 0);
	}

	handleScroll = (e) => {
		if (!this.props.onScrollCallback) return;
		if (!this.canHandleScroll) return;

		this.props.onScrollCallback(e);
	};

	render() {
		return (
			<div
				ref={this.scrollDiv}
				onScroll={this.handleScroll}
				className={styles.rowScroll}
			>
				{this.props.children}
			</div>
		);
	}
}

export class Breadcrumb extends Component<{
	match: PathMatch<string> | null;
}> {
	generateLinks = (): ReactNode[] => {
		const urlParts = window.location.pathname.split('/');
		const links: ReactNode[] = [];
		let url = '/';

		for (let i = 0; i < urlParts.length; i++) {
			const urlPart = urlParts[i];
			url += urlPart;
			links.push(
				i < urlParts.length - 1 ? (
					<Fragment key={i}>
						<Link to={url}>
							<Trans i18nKey={urlPart} />
						</Link>{' '}
						/{' '}
					</Fragment>
				) : (
					<Trans i18nKey={urlPart} />
				)
			);
		}

		return links;
	};

	render() {
		return <div className={styles.breadcrumb}>{this.generateLinks()}</div>;
	}
}

const GridComponents = {
	Grid: Grid,
	Column: Column,
	Row: {
		VerticalTabs: VerticalTabs,
		HorizontalTabs: HorizontalTabs,
		Header: Header,
		HeaderEntity: HeaderEntity,
		HeaderEntityTable: HeaderEntityTable,
		Breadcrumb: Breadcrumb,
		SubHeader: SubHeader,
		SubHeaderDetail: SubHeaderDetail,
		Scroll: Scroll,
	},
	TableRow: TableRow,
	Search: Search,
};

export default GridComponents;
