import React, { Component, Fragment } from 'react';

import {
	InputProperties,
	FieldDefinitionPageProps,
	FieldDefinitionPageState,
	FieldDefinitionAnyOnDetailPanel,
	FieldDefinitionBaseOnDetailPanel,
	FieldDefinitionEntityType,
	FieldDefinitionGroup,
} from './types';
import enums from '../enums';
import DetailInputs from '../DetailInputs.json';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import GridComponents from '../../../components/Grid';
import { FieldDefinitionDetails, GroupDetails } from './DetailsPanel';
import FieldDefinitionEditor from './FieldDefinitionEditor';
import { TypeList } from './TypeList';
import fieldDefinitionAPI from '../../../api/FieldDefinitionAPI';
import { fieldDefinitionPositionOnGroupLayer, groupPositionOnGroupLayer } from './FieldDefinitionEditor/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExtensionType, FieldType } from '../../../model/_Enums';
import { SearchBar } from '../../../components/SearchBar/SearchBar';
import { withRouter } from '../../../compat/RouterCompat';
import { fireLoadBegin, fireLoadEnd } from '../../../utilities/EventService';
import { openAlert, openConfirm } from '../../../components/Dialog/dialogSlice';
import { connect } from 'react-redux';

class FieldDefinitionPageComponent extends Component<FieldDefinitionPageProps, FieldDefinitionPageState> {
	constructor(props) {
		super(props);

		this.state = {
			extensionTypes: [],
			isSaving: false,
		};
	}

	/**
	 * Sends a read call to the API and, after sorting it, saves the result to the state.
	 */
	private refreshTable = () => {
		fireLoadBegin();
		this.setState(
			{
				detailIndex: undefined,
			},
			() => {
				fieldDefinitionAPI.Read().then((res) => {
					if (res.ok)
						res.json().then((json) => {
							this.setState({
								extensionTypes: this.sortFieldDefinitions(json),
							});
						});
				});

				fireLoadEnd();
			}
		);
	};

	private findExtensionType = (extensionTypeIndex: string): FieldDefinitionEntityType | undefined => {
		return this.state.extensionTypes.find((extensionType) => {
			return extensionType.key === extensionTypeIndex;
		});
	};

	/**
	 * Sorts an array of FieldDefinitions by their entityType_Name, and then by their viewIndex using the `Array.prototype.sort()` function.
	 * @param fieldDefinitions list of FieldDefinitions (gotten from the API)
	 * @returns the same list of fieldDefinitions now sorted by their entityType_Name and viewIndex.
	 */
	private sortFieldDefinitions = (fieldDefinitions: FieldDefinitionAnyOnDetailPanel[]): FieldDefinitionEntityType[] => {
		const entityTypes: FieldDefinitionEntityType[] = Object.keys(ExtensionType)
			.filter((key) => !isNaN(Number(ExtensionType[key])))
			.map((extensionType, key): FieldDefinitionEntityType => {
				return { key: extensionType, groups: [] };
			});

		fieldDefinitions.forEach((fieldDefinition, key) => {
			let entityType_Name = fieldDefinition.entityType_Name;
			if (entityType_Name === 'Account' || entityType_Name === 'Lead') entityType_Name = 'Company';

			const sortedET = entityTypes.find((ET) => {
				return ET.key === entityType_Name;
			});
			if (sortedET) {
				if (sortedET.groups[fieldDefinition.groupIndex]) {
					const sortedGroup = sortedET.groups[fieldDefinition.groupIndex];
					sortedGroup.fieldDefinitions.push(fieldDefinition);
				} else
					sortedET.groups[fieldDefinition.groupIndex] = {
						label: fieldDefinition.groupName,
						fieldDefinitions: [fieldDefinition],
					};

				if (sortedET.groups[fieldDefinition.groupIndex])
					sortedET.groups[fieldDefinition.groupIndex].fieldDefinitions.sort((FD1, FD2) => {
						return FD1.viewIndex - FD2.viewIndex;
					});
			}
		});
		return entityTypes;
	};

	/**
	 * Sets the FieldType being dragged to the state.
	 * @param e DragStart html event object.
	 */
	onTypeListDragStart = (e) => {
		const dragging = e.target.id;
		this.setState({ draggingType: dragging });
	};

	/**
	 * Sets `state.draggingType` to `undefined`.
	 */
	onDragEnd = () => {
		this.setState({
			draggingType: undefined,
		});
	};

	/**
	 * Takes the ExtensionTypeIndex from the parameters and returns another function.
	 * @param ETIndex The ExtensionType of the location where the drop event occurred.
	 * @returns A function that'll take care of the next step(s).
	 * This function puts the new FieldDefinition in the state after running some checks.
	 * Uses `this.generateEmptyFieldDefinition()` and the passed parameters to create the new FieldDefinition.
	 */
	onDrop =
		(ExtensionTypeName: string) =>
		/**
		 * This function is used on the middle layer of the editor, where groups are listed.
		 * @param groupIndex A number representing the position of the group the new FD will be in.
		 * @param groupName The name of the group the new FD will be in.
		 * @returns
		 */
		(groupIndex: number, groupName: string | null) =>
		/**
		 * This function puts the new FieldDefinition in the state after running some checks.
		 * Uses `this.generateEmptyFieldDefinition()` and the passed parameters to create the new FieldDefinition.
		 * @param viewIndex Number representing where the FD will be positioned; obtained based on position where drop occurred.
		 */
		(viewIndex: number) => {
			const extensionType = this.findExtensionType(ExtensionTypeName);
			if (this.state.draggingType !== undefined && extensionType !== undefined) {
				const list = extensionType.groups[groupIndex].fieldDefinitions;

				if (!list) return;

				const newItem = this.generateEmptyFieldDefinition(
					this.state.draggingType,
					ExtensionTypeName,
					viewIndex,
					groupIndex,
					groupName ? groupName : null
				);

				if (viewIndex !== undefined) {
					list!.splice(viewIndex!, 0, newItem);
					for (let i = viewIndex + 1; i < list.length; i++) {
						const listItem = list[i];

						listItem.viewIndex = i;

						listItem.method = listItem.method === 'post' || listItem.method === 'delete' ? listItem.method : 'put';
					}
				} else {
					viewIndex = list!.length;
					list!.push(newItem);
				}

				this.setState({
					extensionTypes: this.state.extensionTypes,
					detailIndex: {
						ET: ExtensionTypeName,
						group: groupIndex,
						FD: viewIndex,
					},
					draggingType: undefined,
				});
			}
		};

	changeGroupAttribute = (group: FieldDefinitionGroup, attribute: 'index' | 'label', value: number | string): void => {
		switch (attribute) {
			case 'index':
				if (typeof value === 'number') {
					group.fieldDefinitions.forEach((fieldDefinition) => {
						fieldDefinition.groupIndex = value;
						if (fieldDefinition.method !== 'post') fieldDefinition.method = 'put';
					});
				}
				break;

			case 'label':
				if (typeof value === 'string') {
					group.label = value;
					group.fieldDefinitions.forEach((fieldDefinition) => {
						fieldDefinition.groupName = value;
						if (fieldDefinition.method !== 'post') fieldDefinition.method = 'put';
					});
				}
				break;
		}
	};

	moveGroup = (ExtensionTypeIndex: string) => (prevGroupPosition: groupPositionOnGroupLayer, newGroupPosition: groupPositionOnGroupLayer) => {
		const groups = this.findExtensionType(ExtensionTypeIndex)?.groups;
		if (!groups) return;
		const groupToMove = groups[prevGroupPosition.group];
		let positionDifference = newGroupPosition.group - prevGroupPosition.group;
		const positionDifferenceIsPositive = positionDifference > 0;

		if (positionDifferenceIsPositive) positionDifference--;

		if (positionDifference !== 0) {
			if (positionDifferenceIsPositive)
				for (let i = 1; i < positionDifference; i++) {
					const group = groups[prevGroupPosition.group + i];

					this.changeGroupAttribute(group, 'index', prevGroupPosition.group + i - 1);
				}
			else
				for (let i = -1; i >= positionDifference; i--) {
					const group = groups[prevGroupPosition.group + i];

					this.changeGroupAttribute(group, 'index', prevGroupPosition.group + i + 1);
				}

			this.changeGroupAttribute(groupToMove, 'index', newGroupPosition.group);

			groups.splice(prevGroupPosition.group, 1);
			groups.splice(newGroupPosition.group - (positionDifferenceIsPositive ? 1 : 0), 0, groupToMove);

			this.setState({
				extensionTypes: this.state.extensionTypes,
			});
		}
	};

	/**
	 * This function manipulates the viewIndex of multiple specified FieldDefinitions.
	 * @param FSIndex The index of the extensionType, used for getting the correct FieldDefinition.
	 * @returns a function that will handle moving a specific fieldDefinition from here.
	 */
	moveFieldDefinition =
		(FSIndex: string) =>
		(groupIndex: number) =>
		/**
		 * This is the third layer of the same function.
		 * @param FDIndex  The index of the FieldDefinition, used for getting the correct FieldDefinition.
		 * @param moveDifference The amount that the FieldDefinition's viewIndex will need to be manipulated. Can be negative.
		 */
		(FDIndex: number, moveDifference: number) => {
			const FDs = this.findExtensionType(FSIndex)?.groups[groupIndex].fieldDefinitions;

			if (moveDifference !== 0 && FDs) {
				const FDToMove = FDs[FDIndex];
				if (FDToMove) {
					FDToMove.viewIndex = FDIndex + moveDifference;
					FDToMove.method = FDToMove.method === 'post' ? 'post' : 'put';

					if (moveDifference < 0)
						for (let i = -1; i >= moveDifference; i--) {
							const currentFDIndex = i + FDIndex;
							FDs[currentFDIndex].viewIndex++;
							this.changeFieldDefinitionMethod(FDs[currentFDIndex]);
						}
					else
						for (let i = 1; i <= moveDifference; i++) {
							const currentFDIndex = i + FDIndex;
							FDs[currentFDIndex].viewIndex--;
							this.changeFieldDefinitionMethod(FDs[currentFDIndex]);
						}
					FDs.splice(FDIndex, 1);
					FDs.splice(FDIndex + moveDifference, 0, FDToMove);
					this.setState({
						extensionTypes: this.state.extensionTypes,
					});
				}
			}
		};

	moveFieldDefinitionToNewGroup = (ExtensionTypeIndex: string) => (prevFDPosition: fieldDefinitionPositionOnGroupLayer) => {
		const currentET = this.state.extensionTypes[ExtensionTypeIndex];
		const currentGroup = currentET.groups[prevFDPosition.group];
		const currentFD = currentGroup.fieldDefinitions[prevFDPosition.FD];

		this.moveFieldDefinition(ExtensionTypeIndex)(prevFDPosition.group)(prevFDPosition.FD, currentGroup.fieldDefinitions.length - (prevFDPosition.FD + 1));

		currentFD.groupIndex = currentET.groups.length;
		currentFD.viewIndex = 0;

		currentGroup.fieldDefinitions.pop();
		currentET.groups.push({
			label: '',
			fieldDefinitions: [currentFD],
		});
		currentFD.groupName = '';
		this.changeFieldDefinitionMethod(currentFD);
		this.setState({ extensionTypes: this.state.extensionTypes });
	};

	moveFieldDefinitionToExistingGroup =
		(ExtensionTypeIndex: string) => (prevGroupIndex: number, nextGroupIndex: number) => (prevFDIndex: number, nextFDIndex: number) => {
			const currentET = this.state.extensionTypes[ExtensionTypeIndex];
			const currentGroup = currentET.groups[prevGroupIndex];
			const nextGroup = currentET.groups[nextGroupIndex];
			const currentFD = currentGroup.fieldDefinitions[prevFDIndex];

			this.moveFieldDefinition(ExtensionTypeIndex)(prevGroupIndex)(prevFDIndex, currentGroup.fieldDefinitions.length - (prevFDIndex + 1));

			currentFD.groupIndex = nextGroupIndex;
			currentFD.viewIndex = nextGroup.fieldDefinitions.length;

			currentGroup.fieldDefinitions.pop();
			nextGroup.fieldDefinitions.push(currentFD);
			this.moveFieldDefinition(ExtensionTypeIndex)(nextGroupIndex)(
				nextGroup.fieldDefinitions.length - 1,
				nextFDIndex - (nextGroup.fieldDefinitions.length - 1)
			);
			this.setState({
				extensionTypes: this.state.extensionTypes,
			});
		};

	moveNewFDToNewGroup = (ExtensionTypeName: string) => (FDType: string) => {
		const groups = this.findExtensionType(ExtensionTypeName)?.groups;
		if (!groups) return;

		const newFD = this.generateEmptyFieldDefinition(FDType, ExtensionTypeName, 0, groups.length, null);

		groups.push({ label: null, fieldDefinitions: [newFD] });

		this.setState({ extensionTypes: this.state.extensionTypes });
	};

	changeFieldDefinitionMethod = (FD: FieldDefinitionBaseOnDetailPanel) => {
		if (FD.method !== 'delete' && FD.method !== 'post') {
			FD.method = 'put';
		}
	};

	onItemClick = (ETName: string) => (groupIndex: number) => (FDIndex?: number) => () => {
		this.setState({
			detailIndex: {
				ET: ETName,
				group: groupIndex,
				FD: FDIndex,
			},
		});
	};

	onFormChange = (index?: number) => (e) => {
		const detailIndex = this.state.detailIndex!;
		const group = this.findExtensionType(detailIndex.ET)?.groups[detailIndex.group];
		if (detailIndex.FD !== undefined) {
			const FDs = group?.fieldDefinitions;
			if (!FDs) return;

			if (index !== undefined) FDs![detailIndex.FD!][e.target.name][index] = e.target.value;
			else FDs![detailIndex.FD!][e.target.name] = e.target.value;

			if (FDs![detailIndex!.FD!].method !== 'post') FDs![detailIndex!.FD!].method = 'put';

			this.setState({ extensionTypes: this.state.extensionTypes });
		} else {
			if (!group) return;

			this.changeGroupAttribute(group, 'label', e.target.value);

			this.setState({ extensionTypes: this.state.extensionTypes });
		}
	};

	onFormBooleanChange = (index?: number) => (e) => {
		const value = e.target.value === 'true' ? true : false;
		const detailIndex = this.state.detailIndex!;
		let FDs = this.findExtensionType(detailIndex.ET)?.groups[detailIndex.group].fieldDefinitions;
		if (!FDs) return;

		if (index !== undefined) FDs![this.state.detailIndex!.FD!][e.target.name][index] = value;
		else FDs![this.state.detailIndex!.FD!][e.target.name] = value;

		if (FDs![this.state.detailIndex!.FD!].method !== 'post') FDs![this.state.detailIndex!.FD!].method = 'put';
		this.setState({ extensionTypes: this.state.extensionTypes });
	};

	onDeleteButtonClick = (ETIndex: string) => (groupIndex: number) => (FDIndex: number) => () => {
		this.displayConfirmDialog({
			title: 'areYouSureDeleteFieldDefinitionTitle',
			action: () => this.prepareFieldDefinitionForDeletion(ETIndex, groupIndex, FDIndex),
			text: 'areYouSureDeleteFieldDefinitionText',
			icon: 'warning',
		});
	};

	prepareFieldDefinitionForDeletion = (ETIndex: string, groupIndex: number, FDIndex: number) => {
		const fieldDefinitions = this.findExtensionType(ETIndex)?.groups[groupIndex].fieldDefinitions;
		if (!fieldDefinitions) return;
		if (fieldDefinitions[FDIndex].method === 'post') fieldDefinitions.splice(FDIndex, 1);
		else fieldDefinitions[FDIndex].method = 'delete';

		for (let i = FDIndex; i < fieldDefinitions.length; i++) {
			const FD = fieldDefinitions[i];
			FD.viewIndex = i;
			FD.method = FD.method === 'post' || FD.method === 'delete' ? FD.method : 'put';
		}

		this.setState({
			extensionTypes: this.state.extensionTypes,
			detailIndex: FDIndex === this.state.detailIndex?.FD ? undefined : this.state.detailIndex,
		});
	};

	moveListItem = (index: number, name: string, direction: 'up' | 'down') => (e: React.MouseEvent) => {
		e.preventDefault();

		const list =
			this.state.extensionTypes[this.state.detailIndex!.ET].groups[this.state.detailIndex?.group!].fieldDefinitions[this.state.detailIndex!.FD!][name];

		const itemToMove = list[index];
		let newIndex = index;

		list.splice(index, 1);

		switch (direction) {
			case 'up':
				newIndex--;
				break;
			case 'down':
				newIndex++;
				break;
		}

		list.splice(newIndex, 0, itemToMove);

		this.setState({
			extensionTypes: this.state.extensionTypes,
		});
	};

	deleteFieldDefinitionArrayItem = (index: number, name: string) => (e: React.MouseEvent) => {
		e.preventDefault();

		this.state.extensionTypes[this.state.detailIndex!.ET].groups[this.state.detailIndex?.group!].fieldDefinitions[this.state.detailIndex!.FD!][name].splice(
			index,
			1
		);

		this.setState({
			extensionTypes: this.state.extensionTypes,
		});
	};

	onSaveClick = () => {
		const fieldDefinitions: FieldDefinitionAnyOnDetailPanel[] = [];
		this.state.extensionTypes.forEach((extensionType) => {
			extensionType.groups.forEach((group) => {
				fieldDefinitions.push(...group.fieldDefinitions);
			});
		});

		let inputValid = false;

		//check if there are any FDs that need to be changed
		fieldDefinitions.forEach((fd) => {
			if (fd.method) inputValid = true;
		});

		//check if all the required properties are filled in.
		fieldDefinitions.forEach((fd, i) => {
			if (fd.method === 'delete' || fd.method === undefined) return;

			const inputFields: InputProperties[] = [...DetailInputs.base, ...DetailInputs[fd.$type]];
			let invalid = false;
			inputFields.forEach((inputField, iter) => {
				if (inputField.required)
					if (
						fd[inputField.name] === null ||
						fd[inputField.name] === undefined ||
						fd[inputField.name] === '' ||
						(inputField.type === 'list' && fd[inputField.name].length === 0)
					) {
						if (fd.method === 'put' && !inputField.editable) return;
						invalid = true;
						return;
					}
			});

			fieldDefinitions[i].invalid = invalid;
			if (invalid) inputValid = false;
		});

		this.setState({
			extensionTypes: this.sortFieldDefinitions(fieldDefinitions),
		});

		if (inputValid) {
			this.displayConfirmDialog({
				title: 'areYouSureSave',
				action: () => {
					this.saveFieldDefinitons(fieldDefinitions);
				},
			});
		}
	};

	/**
	 * Generates a mostly blank fieldDefinition, this functions parameters are FieldDefinition properties that are gotten based on the new FieldDefinition's position in the editor.
	 * @param type FieldDefinition type. It's a number because FieldTypes use an enumerator.
	 * @param EntityType_Name EntityType_Name. It's a number because ExtensionTypes use an enumerator.
	 * @param viewIndex This number indicates this FD's position on the list within a group.
	 * @param groupIndex This number indicates this FD's group's position within an ExtensionType.
	 * @param groupName This string indicates the name of this FD's group.
	 * @returns FieldDefinition Javascript object with most of its properties left blank.
	 */
	private generateEmptyFieldDefinition = (
		type: string,
		EntityType_Name: string,
		viewIndex: number,
		groupIndex: number,
		groupName: string | null
	): FieldDefinitionAnyOnDetailPanel => {
		let FD: FieldDefinitionAnyOnDetailPanel = {
			label: '',
			isReadOnly: false,
			isRequired: false,
			type: 0,
			$type: type, //FIXME
			defaultValue: null,
			entityType_Name: EntityType_Name,
			viewIndex: viewIndex,
			method: 'post',
			groupIndex: groupIndex,
			groupName: groupName,

			companyTypeValue: 0,
			valueTrue: '',
			valueFalse: '',
			dateTimeType: 'datetime',
			options: [],
			decimals: 0,
			autoExpand: false,
			length: null,
		};
		return FD;
	};

	saveFieldDefinitons = (fieldDefinitions: FieldDefinitionBaseOnDetailPanel[]) => {
		this.setState({ isSaving: true }, async () => {
			let apiMessage: string = '';
			for (let i = 0; i < fieldDefinitions.length; i++) {
				const FD = fieldDefinitions[i];
				switch (FD.method) {
					case 'post':
						const postRes = await this.handleAPIFunction(fieldDefinitionAPI.Create(FD), 'successfullyAdded');

						if (apiMessage !== 'successfullyAdded') apiMessage = postRes;

						break;
					case 'put':
						const putRes = await this.handleAPIFunction(fieldDefinitionAPI.Update(FD.id!, FD), 'updateSuccessful');

						if (apiMessage !== 'successfullyAdded' && apiMessage !== 'updateSuccessful') apiMessage = putRes;

						break;
					case 'delete':
						const deleteRes = await this.handleAPIFunction(fieldDefinitionAPI.Delete(FD.id!), 'deletedSuccessfully');

						if (apiMessage !== 'successfullyAdded' && apiMessage !== 'updateSuccessful' && apiMessage !== 'deletedSuccessfully')
							apiMessage = deleteRes;

						break;
				}

				FD.method = undefined;
			}
			if (apiMessage)
				this.displayAlertDialog({
					title: apiMessage,
				});
			this.setState({
				isSaving: false,
			});
			this.refreshTable();
		});
	};

	private displayConfirmDialog = (paramsObj: { title: string; action: () => void; text?: string; icon?: string }) => {
		this.props.dispatch(
			openConfirm({
				title: paramsObj.title,
				confirmButtons: [
					{
						onClick: paramsObj.action,
					},
				],
				text: paramsObj.text,
				icon: paramsObj.icon,
			})
		);
	};

	private displayAlertDialog = (paramsObj: { title: string; text?: string }) => {
		this.props.dispatch(
			openAlert({
				title: paramsObj.title,
				text: paramsObj.text,
			})
		);
	};

	private handleAPIFunction = async (apiFunction: Promise<Response>, successMessage: string): Promise<string> => {
		return apiFunction
			.then((res) => {
				if (res.ok) {
					return successMessage;
				} else {
					return 'anErrorOccurred';
				}
			})
			.catch((err) => {
				console.error(err);
				return 'anErrorOccurred';
			});
	};

	onDetailCloseClick = () => {
		this.setState({
			detailIndex: undefined,
		});
	};

	componentDidMount() {
		this.refreshTable();
	}

	types: { name: string; icon: IconProp }[] = [
		{ name: 'fieldDefinitionText', icon: 'i-cursor' },
		{ name: 'fieldDefinitionBoolean', icon: 'toggle-off' },
		{ name: 'fieldDefinitionDateTime', icon: ['far', 'calendar'] },
		{ name: 'fieldDefinitionNumeric', icon: 'hashtag' },
		{ name: 'fieldDefinitionSingleOption', icon: 'bars' },
		{ name: 'fieldDefinitionMultipleOption', icon: 'tasks' },
	];

	render() {
		const detailExtensionType = this.state.detailIndex !== undefined ? this.findExtensionType(this.state.detailIndex.ET) : undefined;

		const detailGroup = detailExtensionType !== undefined && this.state.detailIndex ? detailExtensionType.groups[this.state.detailIndex.group] : undefined;

		const detailFieldDefinition =
			detailGroup !== undefined && this.state.detailIndex!.FD !== undefined ? detailGroup.fieldDefinitions[this.state.detailIndex!.FD] : undefined;

		const detailFieldType = detailFieldDefinition !== undefined ? detailFieldDefinition['$type'] : undefined;

		return (
			<Fragment>
				<GridComponents.Column size="half">
					<GridComponents.Row.Header content={<GridComponents.Row.Breadcrumb match={this.props.match} />} />

					{/* <div className="row_header_icons">
                            <a href="#" title="e-mail"><i className="fal fa-envelope"></i></a>
                            <a href="#" title="call"><i className="fal fa-phone"></i></a>
                            <a href="#" title="view on map"><i className="fal fa-map-marker-alt"></i></a>
                            <a href="#" title="print"><i className="fal fa-file-export"></i></a>
                            <a href="#" title="export"><i className="fal fa-print"></i></a>
                        </div> */}
					<GridComponents.Row.SubHeader>
						<GridComponents.Row.SubHeaderDetail
							icon="city"
							name={t('fieldDefinitions')}
							buttons={[
								<FontAwesomeIcon
									onClick={this.refreshTable}
									icon="repeat"
								/>,

								<FontAwesomeIcon
									onClick={this.onSaveClick}
									icon="save"
								/>,
							]}
						/>
					</GridComponents.Row.SubHeader>
					<GridComponents.Row.Scroll>
						{this.state.extensionTypes && (
							<FieldDefinitionEditor
								extensionTypes={this.state.extensionTypes}
								types={this.types}
								dragging={this.state.draggingType}
								moveFieldDefinitionFunction={this.moveFieldDefinition}
								moveGroupFunction={this.moveGroup}
								moveFDToGroup={this.moveFieldDefinitionToExistingGroup}
								moveFDToNewGroup={this.moveFieldDefinitionToNewGroup}
								moveNewFDToNewGroup={this.moveNewFDToNewGroup}
								onItemClick={this.onItemClick}
								onFDDrop={this.onDrop}
								onDeleteButtonClick={this.onDeleteButtonClick}
								{...this.props}
								enums={enums}
							/>
						)}
					</GridComponents.Row.Scroll>
				</GridComponents.Column>

				<GridComponents.Column size="fourth">
					<GridComponents.Row.Header
						content={
							this.state.detailIndex !== undefined ? (
								<Fragment>
									<Trans i18nKey="components" /> \ {FieldType[this.state.detailIndex.ET]}
								</Fragment>
							) : (
								<Fragment>
									<Trans i18nKey="components" />
								</Fragment>
							)
						}
					></GridComponents.Row.Header>
					<Fragment>
						{this.state.detailIndex === undefined && (
							<GridComponents.Row.SubHeader>
								<SearchBar
									searchCallback={() => {
										console.log('no search yet');
									}}
								/>
							</GridComponents.Row.SubHeader>
						)}

						{this.state.detailIndex !== undefined && (
							<GridComponents.Row.SubHeader>
								<GridComponents.Row.SubHeaderDetail
									icon={detailFieldType !== undefined ? this.types.find((type) => type.name === detailFieldType)?.icon : 'folder'}
									name={t(detailFieldType !== undefined ? detailFieldType.toLowerCase() : 'group')}
									buttons={[
										<FontAwesomeIcon
											onClick={this.onDetailCloseClick}
											icon="door-closed"
										/>,
									]}
								/>
							</GridComponents.Row.SubHeader>
						)}
					</Fragment>

					<GridComponents.Row.Scroll>
						{detailExtensionType && detailGroup ? (
							detailFieldDefinition ? (
								<FieldDefinitionDetails
									navigate={this.props.navigate}
									location={this.props.location}
									match={this.props.match}
									fieldDefinition={detailFieldDefinition}
									onChange={this.onFormChange}
									onBooleanChange={this.onFormBooleanChange}
									onListItemDeleteButtonClick={this.deleteFieldDefinitionArrayItem}
									onListItemMoveClick={this.moveListItem}
								/>
							) : (
								<GroupDetails
									navigate={this.props.navigate}
									location={this.props.location}
									match={this.props.match}
									group={detailGroup}
									onChange={this.onFormChange}
									onBooleanChange={this.onFormBooleanChange}
									onListItemDeleteButtonClick={this.deleteFieldDefinitionArrayItem}
									onListItemMoveClick={this.moveListItem}
								/>
							)
						) : (
							<Fragment>
								<TypeList
									types={this.types}
									onDragStart={this.onTypeListDragStart}
									onDragEnd={this.onDragEnd}
								/>
							</Fragment>
						)}
					</GridComponents.Row.Scroll>
				</GridComponents.Column>
			</Fragment>
		);
	}
}

export const FieldDefinitionPage = withRouter(connect()(FieldDefinitionPageComponent));
