export interface IQuickAppointmentAnnotation {
	representativeId: string | null; //Guid
	companyId: string | null; //Guid
	annotationText: string | null;
	startTime: Date | null;
	endTime: Date | null;
	appointmentTypeId: string | null; //Guid
	appointmentSubject: string | null;
	isFollowUp: boolean;
}

export class QuickAppointmentAnnotation implements IQuickAppointmentAnnotation {
	public representativeId: string | null = null; //Guid
	public companyId: string | null = null; //Guid
	public annotationText: string | null = null;
	public startTime: Date | null = null;
	public endTime: Date | null = null;
	public appointmentTypeId: string | null = null; //Guid
	public appointmentSubject: string | null = null;
	public isFollowUp: boolean = false;

	// get representativeId(): string | null {
	// 	return this._representativeId;
	// }
	// set representativeId(value: string | null) {
	// 	this.representativeId = value;
	// }

	// get companyId(): string | null {
	// 	return this._companyId;
	// }
	// set companyId(value: string | null) {
	// 	this._companyId = value;
	// }

	// get annotationText(): string | null {
	// 	return this._annotationText;
	// }
	// set annotationText(value: string | null) {
	// 	this._annotationText = value;
	// }

	// get startTime(): Date | null {
	// 	return this._startTime;
	// }
	// set startTime(value: Date | null) {
	// 	this._startTime = value;
	// }

	// get endTime(): Date | null {
	// 	return this._endTime;
	// }
	// set endTime(value: Date | null) {
	// 	this._endTime = value;
	// }

	// get appointmentTypeId(): string | null {
	// 	return this._appointmentTypeId;
	// }
	// set appointmentTypeId(value: string | null) {
	// 	this._appointmentTypeId = value;
	// }

	// get appointmentSubject(): string | null {
	// 	return this._appointmentSubject;
	// }
	// set appointmentSubject(value: string | null) {
	// 	this._appointmentSubject = value;
	// }

	// get isFollowUp(): boolean {
	// 	return this._isFollowUp;
	// }
	// set isFollowUp(value: boolean) {
	// 	this._isFollowUp = value;
	// }
}
