import { api } from '../../api/_Executor';
import { AddressQuery } from '../../business/Query/AddressQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { AddressTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { IAddressView } from '../../models/BusinessObjects_View/AddressView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

//TODO! <extends> (interface)!
export function AddressViewCollectionViewModel<T extends IAddressView, Q extends BaseQuery = AddressQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean,
	addressQuery?: AddressQuery
): ICollectionViewModel<T, Q> {
	const apiReader = api.address.viewAsync;
	const query = new AddressQuery() as Q;
	const table = AddressTableDefinition;
	const canSearch: boolean = false;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<T, Q>(instanceName, apiReader, ignoreRepresentative_id, query);

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,
	};
}

// import { api } from "../../api/_Executor";
// import { AddressQuery } from "../../business/Query/AddressQuery";
// import { ContactQuery } from "../../business/Query/ContactQuery";
// import { PrioritizedOpportunityQuery } from "../../business/Query/PrioritizedOpportunityQuery";
// import { PrioritizedTaskQuery } from "../../business/Query/PrioritizedTaskQuery";
// import { TaskQuery } from "../../business/Query/TaskQuery";
// import { AddressView } from "../../models/BusinessObjects_View/AddressView";
// import { ContactView } from "../../models/BusinessObjects_View/ContactView";
// import { PrioritizedOpportunityView } from "../../models/BusinessObjects_View/PrioritizedOpportunityView";
// import { PrioritizedTaskView } from "../../models/BusinessObjects_View/PrioritizedTaskView";
// import { UserManager } from "../../utilities/UserManager";
// import { BaseViewCollectionViewModel } from "./_BaseViewCollectionViewModel";

// export function AddressViewCollectionViewModel(instanceName:string) {
// 	const switchModeFunc = undefined;
// 	const apiReader = api.address.viewAsync;
// 	const query = new AddressQuery();

// 	const cvm = BaseViewCollectionViewModel<AddressView, AddressQuery>(
// 		instanceName,
// 		apiReader,
// 		switchModeFunc,
// 		query,
// 	);

// 	return {
// 		switchMode: cm.doSwitchMode,
// 		read: cm.doRead,
// 		pageNext: cm.doPageNext,
// 		search: cm.doSearch,
// 		refresh: cm.doRefresh,

// 		items: cm.items,
// 		mode: cm.mode,
// 		searchTerm: cm.searchTerm,
// 		query: cm.query,

// 		instanceName
// 	}
// }
