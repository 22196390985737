import React, { useEffect, useState } from 'react';
import { withRouter } from '../../compat/RouterCompat';
import styles from '../test.module.scss';
import EventBus from '../../utilities/EventBus';
import { PanelMain } from '../../components';
import { IReactRouterProps } from '../../globals/types';
import { connect } from 'react-redux';
import { TestDropDownControl } from '../../components/Test/TestDropdownControl';
import { PrioritizedCompanyViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedCompanyViewCollectionViewModel';
import { BoundListView } from '../../components/EntityListDetail/BoundListView/BoundListView';
import { PrioritizedTaskViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedTaskViewCollectionViewModel';
import { PrioritizedOpportunityViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedOpportunityViewCollectionViewModel';
import { CompanyAnnotationViewCollectionViewModel } from '../../viewmodels/Collections/CompanyAnnotationViewCollectionViewModel';
import { AppointmentViewCollectionViewModel } from '../../viewmodels/Collections/AppointmentViewCollectionViewModel';
import { ContactViewCollectionViewModel } from '../../viewmodels/Collections/ContactViewCollectionViewModel';
import { AddressViewCollectionViewModel } from '../../viewmodels/Collections/AddressViewCollectionViewModel';
import { AgendaItemViewCollectionViewModel, IAgendaItemViewCollectionViewModel } from '../../viewmodels/Collections/AgendaItemViewCollectionViewModel';
import { CalendarItemView } from '../../models/BusinessObjects_View/CalendarItemView';
import { ActivityQuery } from '../../business/Query/ActivityQuery';
import { EntityType } from '../../components/EntityDetailPopup/entityDetailPopup.enums';

export const TestPage3Component: React.FC<IReactRouterProps> = (props) => {
	const cms = [
		AddressViewCollectionViewModel('address'),
		CompanyAnnotationViewCollectionViewModel('annotation'),
		ContactViewCollectionViewModel('contactview'),
		PrioritizedOpportunityViewCollectionViewModel('opportunity'),
		PrioritizedTaskViewCollectionViewModel('tasks'),
		AppointmentViewCollectionViewModel('appointments'),
		AgendaItemViewCollectionViewModel('agenda'),
	];

	const mainCompanies = PrioritizedCompanyViewCollectionViewModel('companyChooser');
	const [defIndex, setDefindex] = useState<number>(0);
	const [companyId, setCompanyId] = useState<string>('');

	async function handle_dropdown_onChange(e) {
		var name = e.target.value;
		var index = cms.findIndex((x) => x.instanceName === name);
		setDefindex(index);
	}

	async function handle_BoundListViewMain_onRowClick(tableItem: any) {
		setCompanyId(tableItem.entity_Id);
	}

	function handle_BoundListView_onRowClick(tableItem: any) {
		console.log('clicked' + tableItem.$type);
	}

	function handle_viewModel_event(args) {
		console.log('Rows fetched: ' + args.detail.message);
	}

	useEffect(() => {
		mainCompanies.refresh().catch((e) => console.log(e));

		cms.forEach((def) => {
			var name = def.instanceName;
			EventBus.instance().on(name, handle_viewModel_event);
		});
	}, []);

	useEffect(() => {
		// if (defIndex === 6) {
		// 	//This shouldn't be here, query not based on company. Oh well...
		// 	const idBlisss = '0B5671E2-6775-ED11-A816-002248816633';
		// 	const idNoa = '184F8EB7-6975-ED11-A816-002248816633';
		// 	const cvm = cms[6] as IAgendaItemViewCollectionViewModel<CalendarItemView, ActivityQuery>;
		// 	const dateFrom = new Date();
		// 	const dateTo = new Date();
		// 	dateFrom.setFullYear(dateFrom.getFullYear() - 1);
		// 	cvm.fetch(dateFrom, dateTo, idNoa);
		// 	return;
		// }
		// cms[defIndex].read(companyId);
	}, [defIndex, companyId]);

	async function test() {}
	async function refresh() {}

	return (
		<PanelMain>
			<div className={styles.container}>
				<div className={styles.formBox}>
					<h3>Collection ViewModels test</h3>
				</div>
				<div style={{ height: 300, overflowY: 'hidden', marginBottom: 20 }}>
					{/* <BoundListView
						cvm={mainCompanies}
						entityType={EntityType.Company}
						// idPropName='entity_Id'
						VMInstanceName='test3Company'
						headerText='bogos'
					/> */}
				</div>
				<div className={styles.formBox}>
					CollectionType:
					<TestDropDownControl
						options={cms}
						property={'cms'}
						optionText={'instanceName'}
						optionValue={'instanceName'}
						onChange={handle_dropdown_onChange}
					/>
					<div className={styles.floatR}>{companyId}</div>
				</div>

				<div style={{ height: 300, overflowY: 'hidden' }}></div>

				<div>&nbsp;</div>
				<button onClick={async () => await refresh()}>Refresh</button>
				<button onClick={async () => await test()}>Test</button>
			</div>
		</PanelMain>
	);
};

export const TestPage3 = connect(() => {})(withRouter(TestPage3Component));
