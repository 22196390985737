/* eslint-disable no-cond-assign */
export class StringBuilder {
	strings: any = [];

	contains = (value):boolean => {
		if (this.strings.length > 0) {
			return this.strings.contains(value);
		}
		return false;
	}

	hasData = (): boolean => {
		return this.strings.length;
	}

	appendUnique = (value) => {
		if (!this.contains(value)) {
			this.append(value);
		}
	}

	append = (value) => {
		if (!value) return;
		this.strings.push(value);
	}

	appendLine = (value) => {
		if (!value)  {
			this.strings.push('\n');
			return;
		}

		this.strings.push(`${value}\n`);
	}

	clear = () => {
		this.strings.length = 0;
	}

	toString = (seperator: string = '') => {
		return this.strings.join(seperator);
	}

	toArray = () => {
		return this.strings;
	}
}

export class CssBuilder {
	builder = new StringBuilder();

	hasData = (): boolean => {
		return this.builder.hasData();
	}

	append = (value) => {
		this.builder.append(value);
	}

	clear = () => {
		this.builder.clear();
	}

	toCssProperties = () => {
		const output = this.builder.toString('; ');
		return convertStringToCssProperties(output);
	}
}

export const convertStringToCssProperties = (cssText: string) => {
	const regex = /([\w-]*)\s*:\s*([^;]*)/g;
	let match, properties = {};

	while (match = regex.exec(cssText)) {
		properties[match[1]] = match[2].trim();
	}

	return properties;
}