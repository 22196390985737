import { api } from '../../api/_Executor';
import { ContactQuery } from '../../business/Query/ContactQuery';
import { RepresentativeQuery } from '../../business/Query/RepresentativeQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { GeneralTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { ContactView } from '../../models/BusinessObjects_View/ContactView';
import { RepresentativeView } from '../../models/BusinessObjects_View/RepresentativeView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

export enum RepresentitiveCollectionMode {
	Active,
	Inactive,
}

//GEDEELTELIJK GETEST
//Could have filters (active, inactive, et certera)
export function RepresentativeViewCollectionViewModel<T = RepresentativeView, Q extends BaseQuery = RepresentativeQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean,
	defaultMode = 0
): ICollectionViewModel<T, Q> {
	function switchMode(modus: number, query: RepresentativeQuery) {
		switch (modus) {
			case RepresentitiveCollectionMode.Active: {
				//Open
				query.isActive = true;
				break;
			}
			case RepresentitiveCollectionMode.Inactive: {
				query.isActive = false;
				break;
			}
		}
	}

	const apiReader = api.representative.viewAsync;
	const query = new RepresentativeQuery() as Q;
	const table = GeneralTableDefinition;
	const canSearch: boolean = true;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<T, Q>(
		instanceName,
		apiReader,
		ignoreRepresentative_id,
		query,
		switchMode,
		undefined,
		undefined,
		false,
		undefined,
		defaultMode
	);

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,
	};
}

// import { api } from "../../api/_Executor";
// import { ContactQuery } from "../../business/Query/ContactQuery";
// import { PrioritizedOpportunityQuery } from "../../business/Query/PrioritizedOpportunityQuery";
// import { PrioritizedTaskQuery } from "../../business/Query/PrioritizedTaskQuery";
// import { TaskQuery } from "../../business/Query/TaskQuery";
// import { ContactView } from "../../models/BusinessObjects_View/ContactView";
// import { PrioritizedOpportunityView } from "../../models/BusinessObjects_View/PrioritizedOpportunityView";
// import { PrioritizedTaskView } from "../../models/BusinessObjects_View/PrioritizedTaskView";
// import { UserManager } from "../../utilities/UserManager";
// import { BaseViewCollectionViewModel } from "./_BaseViewCollectionViewModel";

// export function ContactViewCollectionViewModel(instanceName:string) {
// 	const switchModeFunc = undefined;
// 	const apiReader = api.contact.viewAsync;
// 	const query = new ContactQuery();

// 	const cvm = BaseViewCollectionViewModel<ContactView, ContactQuery>(
// 		instanceName,
// 		apiReader,
// 		switchModeFunc,
// 		query,
// 	);

// 	return {
// 		switchMode: cm.doSwitchMode,
// 		read: cm.doRead,
// 		pageNext: cm.doPageNext,
// 		search: cm.doSearch,
// 		refresh: cm.doRefresh,

// 		items: cm.items,
// 		mode: cm.mode,
// 		searchTerm: cm.searchTerm,
// 		query: cm.query,

// 		instanceName
// 	}
// }
