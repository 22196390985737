import { AuthorizedFetchRequest } from './apiBase';

const baseUrl = `api/Representative`;

export function GetRepresentatives(queries?: object): Promise<Response> {
	let url = { base: baseUrl, path: '/list' };

	return AuthorizedFetchRequest(url, 'POST', { ...queries });
}

export function GetRepresentative(id, queries?: object): Promise<Response> {
	let url = { base: baseUrl + `/${id}`, queries: queries };

	return AuthorizedFetchRequest(url, 'GET');
}
