import styles from '../dynamicTable.module.scss';
import { IColumnConverter } from './IColumnConverter';
import { ITableAttribute } from '../dynamicTable.index';
import { IconCustom, IconCustomType } from '../../IconCustom/IconCustom.index';
import { CompanyType, CompanyTypeName } from '../../../model/_Enums';
import { PrioritizedCompanyView } from '../../../model/Views/PioritizedCompanyView';
import { getFullNameFromPrioritizedCompanyView } from '../../../utilities/entityHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

const getNameValue = (row: any, attr: ITableAttribute): string | undefined => {
	if (!row || !attr.property?.name) return undefined;
	if (Array.isArray(attr.property.name)) {
		return attr.property.name.map((propName) => row[propName]).join(' ');
	} else {
		return row[attr.property.name];
	}
};

//TEMP: Untill we implement icons:
const httpsRegex: RegExp = /^(https?:\/\/)/;
const regWebsiteLink: RegExp = /^((https?:\/\/)?(www.)?)/;

const getLink = (protocol: string, href: string, text: string = '', target: string | undefined = undefined) => {
	let value = text !== '' ? text : href;
	value = value.replace(regWebsiteLink, '');

	if (!httpsRegex.test(href) && !protocol) {
		protocol = 'https://';
	}

	//TEMP: We want so save some space, remove http(s) for now
	return (
		<a
			className={styles.link}
			href={`${protocol}${href}`}
			target={target}
			onClick={(event) => event.stopPropagation()}
		>
			{value}
		</a>
	);
};

export const TelephoneConverter: IColumnConverter = class TelephoneConvert {
	static convert = (row: any, attr: ITableAttribute): JSX.Element | string | undefined => {
		const value = getNameValue(row, attr);
		if (!value) return undefined;
		return getLink('tel:', value);
	};
};

export const BooleanToIconConverter: IColumnConverter = class BooleanIconConvert {
	static convert = (row: PrioritizedCompanyView, attr: ITableAttribute): JSX.Element | string | undefined => {
		const value = getNameValue(row, attr);
		if (!value) return <FontAwesomeIcon icon={faXmark} />;
		else return <FontAwesomeIcon icon={faCheck} />;
	};
};

export const CompanyContactConverter: IColumnConverter = class CompanyContactConvert {
	static convert = (row: PrioritizedCompanyView, attr: ITableAttribute): JSX.Element | string | undefined => {
		return getFullNameFromPrioritizedCompanyView(row);
	};
};

export const EmailConverter: IColumnConverter = class EmailConvert {
	static convert = (row: any, attr: ITableAttribute): JSX.Element | string | undefined => {
		const value = getNameValue(row, attr);
		if (!value) return undefined;
		return getLink('mailto:', value.toLowerCase());
	};
};

export const WebsiteConverter: IColumnConverter = class WebsiteConvert {
	static convert = (row: any, attr: ITableAttribute): JSX.Element | string | undefined => {
		const value = getNameValue(row, attr);
		if (!value) return undefined;
		return getLink('', value, '', '_blank');
	};
};

export const MainConverter: IColumnConverter = class MainConvert {
	static convert = (row: any, attr: ITableAttribute): JSX.Element | string | undefined => {
		const value = getNameValue(row, attr);
		if (!value) return undefined;

		return <div className={styles.main}>{value.toUpperCase()}</div>;
	};
};

export const CompanyToIconConverter: IColumnConverter = class CompanyToIconConvert {
	static convert = (row: any, attr: ITableAttribute): JSX.Element | string | undefined => {
		const value = getNameValue(row, attr);
		if (!value) return undefined;

		if (value.toLowerCase() === CompanyTypeName.Lead) {
			return (
				<FontAwesomeIcon
					icon='building'
					color='#1E3768'
					size='xl'
				/>
			);
		}

		return (
			<FontAwesomeIcon
				icon='buildings'
				color='#1E3768'
				size='xl'
			/>
		);
	};
};
