import { delay } from "./utils";

export default class EventBus {
	eventTargets: string[] = [];

	//#region singleton
	private static _instance:EventBus;
	private constructor() {	}

	public static instance = ():EventBus => {
		if (!EventBus._instance) {
			EventBus._instance = new EventBus();
		}

		return EventBus._instance;
	}
	//#endregion singleton

	//subscriber
	public on = (eventName:string, listener:any) => {
		//Uncomment for debugging:
		//const hasListener = (this.eventTargets.indexOf(eventName) >= 0);
		//console.log("on [" + eventName + "] registered = " + hasListener + " :: " + this.eventTargets);

		this.remove (eventName, listener);
		// if (this.eventTargets.indexOf(eventName) >= 0) { 
		// 	// this.remove(eventName, listener);
		// 	return false; 
		// }
		
		this.eventTargets.push(eventName);
		document.addEventListener(eventName, listener);
		
		return true;
	}

	//publisher
	public dispatch = (eventName:string, data:any) => {
		const event = new CustomEvent(eventName, { detail: data });
		//we'll delay on purpose, should give UI time to do stuff
		delay(1).then(() => document.dispatchEvent(event));
	}

	public remove = (eventName:string, listener:any) => {
		document.removeEventListener(eventName, listener);

		const index = this.eventTargets.indexOf(eventName);
		if (index >= 0) {
		  this.eventTargets.splice(index, 1);
		}
	}
}