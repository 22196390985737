import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ErrorPageLayout } from '../components/ErrorPageLayout/ErrorPageLayout';

//TODO: Proper styling and content (language switch, more text, perhaps a report option?)
export const NotFoundPage = () => {
	return (
		<ErrorPageLayout>
			<p>
				<h1>
					<Trans i18nKey='notFound.pageNotFound' />
				</h1>
			</p>
			<p>
				<Link
					to='/'
					replace={true}
				>
					<Trans i18nKey='notFound.returnToHome' />
				</Link>
			</p>
		</ErrorPageLayout>
	);
};
