import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

/**
 * Adds a HOC mimicing the old react-router V5 and lower withRouter functionality
 * @deprecated
 * */

export const withRouter = (Component) => (props) => {
	let location = useLocation();
	let navigate = useNavigate();
	let params = useParams();

	return (
		<React.Fragment>
			<Component
				{...props}
				location={location}
				match={{ params: params }}
				navigate={navigate}
			/>
		</React.Fragment>
	);
    
}