import React, { Component } from 'react';

import { t } from 'i18next';
// import Enums from '../../enums';

import { GroupLayer } from './GroupLayer';
import { ExtensionTypeLayerProps } from './types';

import { InfoGroupAccordion } from '../../../../components/InfoBlocks';
import { withRouter } from '../../../../compat/RouterCompat';

class ExtensionTypeLayerComponent extends Component<ExtensionTypeLayerProps, { open: number }> {
	render() {
		return (
			<div>
				<InfoGroupAccordion
					groups={this.props.extensionTypes.map((extensionType, key) => {
						return {
							title: t(extensionType.key),
							content: (
								<GroupLayer
									key={extensionType.key}
									groups={extensionType.groups}
									types={this.props.types}
									dragging={this.props.dragging}
									moveGroupFunction={this.props.moveGroupFunction(extensionType.key)}
									moveFDToGroup={this.props.moveFDToGroup(extensionType.key)}
									moveFDToNewGroup={this.props.moveFDToNewGroup(extensionType.key)}
									moveFieldDefinitionFunction={this.props.moveFieldDefinitionFunction(extensionType.key)}
									moveNewFDToNewGroup={this.props.moveNewFDToNewGroup(extensionType.key)}
									onItemClick={this.props.onItemClick(extensionType.key)}
									onFDDrop={this.props.onFDDrop(extensionType.key)}
									onDeleteButtonClick={this.props.onDeleteButtonClick(extensionType.key)}
									match={this.props.match}
									location={this.props.location}
								/>
							),
						};
					})}
				/>
			</div>
		);
	}
}

export const ExtensionTypeLayer = withRouter(ExtensionTypeLayerComponent);
