import { ReactElement, createElement } from 'react';
import { Settings } from '../../../globals/settings';
import { PrioritizedCompanyViewCollectionViewModel } from '../../../viewmodels/Collections/PrioritizedCompanyViewCollectionViewModel';
import { PrioritizedCompanyView } from '../../../model/Views/PioritizedCompanyView';
import { CompanyType } from '../../../model/_Enums';
import { IconCustomType } from '../../IconCustom/IconCustom.definitions';
import { dateHelper } from '../../../utilities/dateHelper';
import { getAddressStringFromPrioritizedCompanyView, getRepresentativeNameFromPrioritizedCompanyView } from '../../../utilities/entityHelpers';
import { colorHelper } from '../../../utilities/colorHelper';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { DashboardLane } from '../DashboardLane';
import DashboardListItem, { IDashboardListItemProps } from '../DashboardListItem';
import { t } from 'i18next';
import { newCompanyDisabled } from '../../../utilities/authProvider';

export function DashboardLaneCompanies() {
	const cvm = PrioritizedCompanyViewCollectionViewModel('dashboard_companies', false, Settings.PAGINATION_DASHBOARD_TAKE);

	const generateElement = (entity: any, clickHandler: any): ReactElement => {
		const item = entity as PrioritizedCompanyView;
		const isAccount = item.typeOfEntity === CompanyType[CompanyType.Account];
		const icon = isAccount ? IconCustomType.DASH_ACCOUNT : IconCustomType.DASH_LEAD;
		const lastVisitDate = item.lastestDate ? dateHelper.DATE_FORMATTER_SHORT.format(new Date(item.lastestDate)) : '-';
		const representative = getRepresentativeNameFromPrioritizedCompanyView(entity);
		const iconInfo = colorHelper.getCompanyOverdueInfo(item.weeksDue);
		const iconTitle = iconInfo.color;
		const iconValue = iconInfo.value;

		let address = getAddressStringFromPrioritizedCompanyView(entity);
		if (!address) address = '-';

		const properties: IDashboardListItemProps = {
			icon: icon,
			key: item.entity_Id,
			entity: item,
			id: 0,

			iconTitle: iconValue,
			iconColor: iconTitle,
			//TODO: Decide if we want to use a color for company icon?

			//LEFT
			titleTopLeft: item.entity_Name,
			titleMidLeft: address,
			titleBotLeft: representative,

			//RIGHT
			titleTopRight: item.entity_CompanyGroup_Code?.toString(),
			titleBotRight: lastVisitDate,

			onListItemClick: clickHandler,
		};

		const block = createElement(DashboardListItem, properties, null);
		return block;
	};

	return (
		<DashboardLane
			icon={IconCustomType.GEN_COMPANY}
			title={t('companies')}
			cvm={cvm}
			elementGeneratorFunc={generateElement}
			popupEntityType={EntityType.Company}
			disableAddButton={newCompanyDisabled()}
		/>
	);
}
