import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';

import styles from './inputFields.module.scss';
import { InputAction, IInputActionObject } from './InputFieldComponent';

export enum IDateTimeType {
	date = 'date',
	time = 'time',
	'datetime-local' = 'datetime-local',
}

interface IDateTimeInputProps extends React.InputHTMLAttributes<IDateTimeType> {
	type: IDateTimeType;
	defaultValue?: string;
	InputActionObject?: IInputActionObject;
}

export function DateTimeInput(props: IDateTimeInputProps) {
	const defaultValue = props.defaultValue !== undefined ? convertDefaultValue(props.type, props.defaultValue) : '';
	const [value, setValue] = useState(defaultValue);

	const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	}, []);

	return (
		<Fragment>
			<input
				className={styles.dateTimeInput}
				name={props.name}
				required={props.required}
				type={props.type}
				value={value}
				defaultValue={defaultValue}
				onChange={changeHandler}
				readOnly={props.readOnly}
			/>
			<InputAction InputActionObject={props.InputActionObject} />
		</Fragment>
	);
}

//FIXME: Change localeStrings so we no longer use a "arbitrary" locale to split date and time
function convertDefaultValue(type: 'date' | 'time' | 'datetime-local', defaultValue: string): string {
	try {
		const dateObj = new Date(defaultValue);
		if (dateObj.toString() === 'Invalid Date') return defaultValue;

		const localeStrings = dateObj
			.toLocaleString('sv-SE', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				hourCycle: 'h23',
			})
			.split(' ');
		/*toLocaleString() will produce a string like: '2022-12-15, 06:40'. 
		Only the locales: en-CA, fr-CA, & sv-SE write the dates in YYYY-MM-DD, 
		other locales don't use hyphens (-) and/or write the year last.*/
		const dateTime = {
			date: localeStrings[0],
			time: localeStrings[1],
		};

		switch (type) {
			case 'date':
				return dateTime.date;
			case 'time':
				return dateTime.time;
			case 'datetime-local':
				return localeStrings.join('T');
		}
	} catch (e) {
		console.error(e, 'dateTime type:', type, 'defaultValue:', defaultValue);
		return defaultValue;
	}
}
