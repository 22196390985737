import logo from '../../images/mydesk_blue.svg';
import styles from './errorPageLayout.module.scss';

export const ErrorPageLayout = (props) => {
	return (
		<div className={styles.errorPageLayout}>
			<div>
				<img
					src={logo}
					alt='MyDesk logo'
					className={styles.logo}
				/>
			</div>
			{props.children}
		</div>
	);
};
