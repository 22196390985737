import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { IViewModel } from './_viewModel.interfaces';
import { QuickAppointmentAnnotation } from '../models/BusinessObjects_Model/QuickAppointmentAnnotation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getObjPropz, isNilOrEmpty, isNotNilOrEmpty } from '../utilities/utils';
import { useAppSelector } from '../store/hooks';
import { openAlert } from '../components/Dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { globals } from '../globals/environment';

const propertyNames = getObjPropz<QuickAppointmentAnnotation>();

// const nonDefaultChangeHandlers = {
// 	[propertyNames.appointmentTypeId]: (value: string, model: QuickAppointmentAnnotation) => {},
// };

const validationRules = {
	[propertyNames.representativeId]: ['required'],
	[propertyNames.companyId]: ['required'],
	[propertyNames.annotationText]: ['required'],
	[propertyNames.startTime]: ['required'],
	[propertyNames.endTime]: ['required'],
	[propertyNames.appointmentTypeId]: [],
	[propertyNames.appointmentSubject]: [],
	[propertyNames.isFollowUp]: ['required'],
};

function emptyQuickAppointmentAnnotation(representativeId: string, companyId: string, organizationSettings?: any): QuickAppointmentAnnotation {
	const now = new Date();
	const future = new Date();
	future.setMinutes(now.getMinutes() + 15);

	const emptyQuickAppointmentAnnotation = new QuickAppointmentAnnotation();

	emptyQuickAppointmentAnnotation.representativeId = representativeId;
	emptyQuickAppointmentAnnotation.companyId = companyId;
	emptyQuickAppointmentAnnotation.startTime = now;
	emptyQuickAppointmentAnnotation.endTime = future;
	emptyQuickAppointmentAnnotation.isFollowUp = false;

	if (organizationSettings !== undefined) {
		if (isNotNilOrEmpty(organizationSettings.quickAnnotation_DefaultAppointmentTypeId)) {
			emptyQuickAppointmentAnnotation.appointmentTypeId = organizationSettings.quickAnnotation_DefaultAppointmentTypeId;
		}

		emptyQuickAppointmentAnnotation.appointmentSubject = organizationSettings.quickAnnotation_DefaultAppointmentSubject;
		emptyQuickAppointmentAnnotation.isFollowUp = false;
	}

	return emptyQuickAppointmentAnnotation;
}

function throwException(functionName: string): any {
	const message = `don't use this function: quickAnnotationViewModel.${functionName}`;
	console.error(message);

	if (globals.environment.isDev()) throw new Error(message);
}

export default function useQuickAnnotationViewModel(representativeId: string, companyId: string): IViewModel<QuickAppointmentAnnotation> {
	const dispatch = useDispatch();
	const organizationSettings = useAppSelector((state) => state.globals.organizationSettings);

	const emptyModel = useMemo(
		() => emptyQuickAppointmentAnnotation(representativeId, companyId, organizationSettings),
		[companyId, organizationSettings, representativeId]
	);
	const [model, setModel] = useState(emptyModel);
	const [isDirty, setIsDirty] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const update = useCallback(async () => {
		if (isDirty && isValid) {
			const res = await api.quickAnnotation.updateAsync(model);
			if (res !== undefined) {
				const asyncFuncs = [setIsDirty(false), setHasSavedChanges(true), setModel(emptyModel)];
				await Promise.all(asyncFuncs);

				return true;
			} else {
				dispatch(
					openAlert({
						title: 'anErrorOccurred',
					})
				);

				return false;
			}
		} else return false;
	}, [dispatch, emptyModel, isDirty, isValid, model]);

	const getProperty = useCallback(
		(propName: string) => {
			return model[propName];
		},
		[model]
	);

	const setProperty = useCallback(
		(propName: string, value: any) => {
			const newModel = { ...model };

			newModel[propName] = value;

			setModel(newModel);
			setIsDirty(true);
		},
		[model]
	);

	const getPropertyErrorMessage = useCallback(
		(propName: string): string[] => {
			const brokenRules: string[] = [];
			const propertyValidationRules: string[] = validationRules[propName];

			if (propertyValidationRules && propertyValidationRules.length > 0) {
				const property = model[propName];
				const rules = propertyValidationRules;
				if (rules !== undefined)
					rules.forEach((rule) => {
						switch (rule) {
							case 'required': {
								if (isNilOrEmpty(property)) {
									brokenRules.push('requiredFieldEmpty');
								}
								break;
							}
						}
					});
			}
			return brokenRules;
		},
		[model]
	);

	const isPropertyValid = useCallback(
		(propName: string): boolean => {
			const errorMessage = getPropertyErrorMessage(propName);

			if (errorMessage) return false;
			else return true;
		},
		[getPropertyErrorMessage]
	);

	const getErrorMessages = useCallback((): string[] => {
		const errorMessages: string[] = [];

		for (const propertyName of Object.values(propertyNames)) {
			const errorMessage = getPropertyErrorMessage(propertyName);

			if (errorMessage) {
				errorMessages.push(...errorMessage);
			}
		}

		return errorMessages;
	}, [getPropertyErrorMessage]);

	useEffect(() => {
		let isValidTemp = true;
		for (const propertyName of Object.keys(validationRules)) {
			if (getPropertyErrorMessage(propertyName).length > 0) {
				isValidTemp = false;
				break;
			}
		}

		setIsValid(isValidTemp);
	}, [model, getPropertyErrorMessage]);

	const entityType = EntityType.quickAnnotation;

	return {
		entityType,
		model,
		isValid,
		isDirty,
		hasSavedChanges,
		instanceName: 'quickappointmentannotation',
		fieldDefinitions: undefined,
		create: () => false,
		read: async () => throwException('read'),
		update,
		del: async () => throwException('del'),
		setProperty,
		getProperty,
		isPropertyValid,
		isPropertyReadOnly: throwException,
		getPropertyErrors: getPropertyErrorMessage,
		getProperties: getObjPropz<QuickAppointmentAnnotation>,
		getId: () => companyId,
		getType: () => throwException('getType'),
		getTypeCased: () => throwException('getTypeCased'),
	};
}
