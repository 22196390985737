import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFormHeaderProps } from '../../entityDetailPopup.interfaces';
import styles from '../formDetail.module.scss';

export const FormHeaderBase: React.FC<IFormHeaderProps> = (props) => {
	return (
		<div className={styles.header}>
			<div>
				<span className={styles.label}>
					<FontAwesomeIcon
						icon={props.iconName}
						// size='xl'
						className={styles.icon}
					/>
					<span className={styles.name}>{props.headerText}</span>
				</span>
			</div>
			<div className={styles.formButtons}>{props.children}</div>
		</div>
	);
};
