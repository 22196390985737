import { AuthorizedFetchRequest } from './apiBase';
import { IQueryOLD } from './_Query';
import { UserManager } from '../utilities/UserManager';

const baseUrl = `api/Company`;
const leadUrl = 'api/Lead';
const accountUrl = `api/Account`;
const prioUrl = 'api/PrioritizedCompany';
const userManager = new UserManager();

export const PrioritizedCompanyViewQuery:IQueryOLD = ({
	MustBeActive: true,
	QueryAll: false,
	Representative_Id: userManager.getCurrentUserId(),
});

export function GetCompanies(queries?: object): Promise<Response> {
	let url = { base: prioUrl, path: '/list' };

	return AuthorizedFetchRequest(url, 'POST', { ...queries });
}

export const GetPrioritizedCompanyViews = (queries?: object):Promise<Response> => {
	let url = { base: prioUrl, path: '/view' };
	return AuthorizedFetchRequest(url, 'POST', { ...queries });
};

export function GetCompany(id, queries?: object): Promise<Response> {
	let url = { base: baseUrl + `/${id}`, queries: queries};
	
	return AuthorizedFetchRequest(url, 'GET');
}



export function CreateCompany(company, queries?: object): Promise<Response> {
	let url = { base: baseUrl, queries: queries };

	return AuthorizedFetchRequest(url, 'POST', company);
}

export function EditLead(company, queries?: object): Promise<Response> {
	let url = { base: leadUrl + `/${company.id}`, queries: queries };
	company.$type = "lead";
	return AuthorizedFetchRequest(url, 'PUT', company);
}

export function EditAccount(company, queries?: object): Promise<Response> {
	let url = { base: accountUrl + `/${company.id}`, queries: queries };
	company.$type = "account";
	return AuthorizedFetchRequest(url, 'PUT', company);
}

export function DeactivateCompanyById(id, queries?: object): Promise<Response> {
	let url = { base: baseUrl + `/${id}/deactivate`, queries: queries };

	return AuthorizedFetchRequest(url, 'PUT');
}

const companyApi = {
	get: GetCompanies,
	create: CreateCompany,
	editLead: EditLead,
	editAccount: EditAccount,
	deactivate: DeactivateCompanyById,
};

export default companyApi;