import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';

import styles from './inputFields.module.scss';
import { InputAction, IGeneralProps } from './InputFieldComponent';

export interface IDropdownComponentProps extends IGeneralProps {
	type: ISelectType;
	options: IOption[];
}

export interface IMultiDropdownComponentProps extends Omit<IDropdownComponentProps, 'defaultValue' | 'type'> {
	type: ISelectType.multiSelect;
	defaultValue: string;
}

export interface IOption {
	value: any;
	label: string;
}

export enum ISelectType {
	select = 'select',
	multiSelect = 'multiSelect',
}

const nothingSelected = t('nothingSelected');

function DropdownComponent(props: IDropdownComponentProps) {
	if (props.type === 'multiSelect') {
		const multiDropdownProps = props as IMultiDropdownComponentProps;
		return <MultiDropdownComponent {...multiDropdownProps} />;
	}

	return (
		<Fragment>
			<select
				name={props.name}
				className={styles.input}
				required={props.required}
				onChange={props.onChange}
				disabled={props.readOnly}
			>
				<option
					label={nothingSelected}
					hidden
				/>
				{props.options.map((option) => (
					<option
						selected={props.defaultValue === option.value}
						{...option}
					/>
				))}
			</select>
			<InputAction InputActionObject={props.InputActionObject} />
		</Fragment>
	);
}

function MultiDropdownComponent(props: IMultiDropdownComponentProps) {
	const [multiValues, setMultiValues] = useState(props.defaultValue ? props.defaultValue : '');
	useEffect(() => {
		setMultiValues(props.defaultValue ? props.defaultValue : '');
	}, [props.defaultValue]);

	const [open, setOpen] = useState(false);
	const [dropdownWidth, setWidth] = useState('auto');

	const inputClick = useCallback(() => {
		if (!props.readOnly) setOpen(!open);
		if (textInputRef.current) setWidth(`${textInputRef.current.offsetWidth - 1}px`);
	}, [props.readOnly, open]);

	const optionClick = useCallback(
		(index: number) => () => {
			const currentValues = multiValues ? multiValues.split(',') : [];
			const clickedValue = props.options[index].value;

			// eslint-disable-next-line eqeqeq
			const foundValueIndex = currentValues.findIndex((value) => value == clickedValue);

			if (foundValueIndex >= 0) currentValues.splice(foundValueIndex, 1);
			else {
				currentValues.push(clickedValue);
			}

			setMultiValues(currentValues.toString());
		},
		[props.options, multiValues]
	);

	// eslint-disable-next-line eqeqeq
	const valueLabels = multiValues.split(',').map((value) => props.options.find((option) => option.value == value)?.label);
	const textInputRef = useRef<any>();

	return (
		<Fragment>
			<input
				ref={textInputRef}
				className={styles.input}
				type='text'
				value={valueLabels.join(', ')}
				onClick={inputClick}
				readOnly={props.readOnly}
			/>

			<input
				name={props.name}
				type='hidden'
				value={multiValues.toString()}
				defaultValue={props.defaultValue}
			/>

			<select
				className={styles.multiSelect}
				style={{ display: open && !props.readOnly ? 'block' : 'none', width: dropdownWidth }}
				multiple
				required={props.required}
				value={multiValues.toString()}
			>
				{props.options.map((option, i) => (
					<option
						{...option}
						onClick={optionClick(i)}
					/>
				))}
			</select>
			<InputAction InputActionObject={props.InputActionObject} />
		</Fragment>
	);
}

export default DropdownComponent;
