export const table = {
	title: 'annotations',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'text',
			property: {
				name: 'entity_Text',
			},
			type: 'textarea',
		},
		{
			headTitle: 'createdDate',
			property: {
				name: 'entity_CreatedDate',
			},
			type: 'datetime-local',
		},
	],
	apiUrl: '/CompanyAnnotation',
};
