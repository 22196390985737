import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from '../../compat/RouterCompat';
import styles from '../test.module.scss';
import { PanelMain } from '../../components';
import { IReactRouterProps } from '../../globals/types';
import { connect } from 'react-redux';
import { UserManager } from '../../utilities/UserManager';
import { TestDropDownControl } from '../../components/Test/TestDropdownControl';
import { PrioritizedCompanyViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedCompanyViewCollectionViewModel';
import { Button } from '@progress/kendo-react-buttons';
import EventBus from '../../utilities/EventBus';
import { CrudType } from '../../globals/enums';
import { CompanyFormDetail } from '../../components/EntityDetailPopup/FormDetail/CompanyFormDetail';
import CompanyRelatedViewModel from '../../viewmodels/Related/CompanyRelatedViewModel';
import { EditMethod } from '../../components/EntityDetailPopup/entityDetailPopup.interfaces';

const userManager = new UserManager();
const vmInstanceName = 'page5_company';

export const TestPage5Component: React.FC<IReactRouterProps> = (props) => {
	const cmCompanies = PrioritizedCompanyViewCollectionViewModel('page5_companies');
	const vmCompanyGroup = CompanyRelatedViewModel('page5_company');
	const [njaa, setNjaa] = useState<boolean>(false);

	const dropdownListRef = useRef<typeof TestDropDownControl>(null);
	const [companyId, setCompanyId] = useState<string>('10428abd-6975-ed11-a816-002248816633');

	async function handle_btn_onClick() {
		if (dropdownListRef.current === undefined || dropdownListRef.current === null) return;
		var value = dropdownListRef.current['selectedValueRef']['current'];
		setCompanyId(value);
		setNjaa(!njaa);
	}

	function handle_BoundListView_onRowClick(tableItem: any) {
		// vmTask.read(tableItem.task_Id);
	}

	async function test() {}
	async function refresh() {}

	async function handle_btnNew_onClick() {
		if (companyId === '') {
			alert('Please select a company first');
			return;
		}
		vmCompanyGroup.create();
	}

	async function handle_viewModel_event(args) {
		if (args.detail.crudType === CrudType.Update || args.detail.crudType === CrudType.Delete) {
			console.log('ev');
		}
	}

	function handle_collection_event(args) {
		//uncomment this for logging info
		//console.log('woohoo, collection updated: ' + args.detail.message);
	}

	useEffect(() => {
		cmCompanies.readAll(0).catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		vmCompanyGroup.read(companyId);
		EventBus.instance().on(vmInstanceName, handle_viewModel_event);
	}, [companyId, njaa]);

	return (
		<PanelMain>
			<div className={styles.container}>
				<div className={styles.group}>
					<div>Choose company:</div>
					<TestDropDownControl
						ref={dropdownListRef}
						options={cmCompanies.items}
						property={cmCompanies.getProperties().entity_Id}
						optionText={cmCompanies.getProperties().entity_Name}
						optionValue={cmCompanies.getProperties().entity_Id}
					/>
					<button onClick={handle_btn_onClick}>Choose</button>
					<div>{companyId}</div>
				</div>
				<div style={{ height: 580 }}></div>
				<div className={styles.box}>
					&nbsp; IsValid: <b>{vmCompanyGroup.isValid.toString()}</b> | IsDirty: {vmCompanyGroup.isDirty.toString()}
					<Button
						onClick={async () => await handle_btnNew_onClick()}
						className={styles.floatR}
					>
						Add new
					</Button>
				</div>
				<div>&nbsp;</div>
				<button onClick={async () => await refresh()}>Refresh</button>
				<button onClick={async () => await test()}>Test</button>
			</div>
		</PanelMain>
	);
};

export const TestPage5 = connect(() => {})(withRouter(TestPage5Component));
