import styles from './inputFields.module.scss';
import { DateTimeType, IDateTimeInputProps, IWeekInputProps } from './inputField.types';
import { ChangeEvent, Fragment, useCallback, useMemo, useRef } from 'react';
import { dateHelper } from '../../utilities/dateHelper';
import { Trans } from 'react-i18next';

declare const Modernizr: any;

//FIXME: Change localeStrings so we no longer use a "arbitrary" locale to split date and time
function convertDefaultValue(type: DateTimeType, defaultValue: string): string {
	try {
		if (defaultValue === null || defaultValue === undefined) return '';

		const dateObj = new Date(defaultValue);
		if (dateObj.toString() === 'Invalid Date' || !defaultValue) return '';

		const localeStrings = dateObj
			.toLocaleString('sv-SE', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				hourCycle: 'h23',
			})
			.split(' ');
		/*toLocaleString() will produce a string like: '2022-12-15, 06:40'. 
		Only the locales: en-CA, fr-CA, & sv-SE write the dates in YYYY-MM-DD, 
		other locales don't use hyphens (-) and/or write the year last.*/
		const dateTime = {
			date: localeStrings[0],
			time: localeStrings[1],
		};

		switch (type) {
			case 'date':
				return checkDateString(dateTime.date);
			case 'time':
				return dateTime.time;
			case 'datetime-local':
				return localeStrings.join('T');
			default:
				return '';
		}
	} catch (e) {
		console.error(e, 'dateTime type:', type, 'defaultValue:', defaultValue);
		return defaultValue;
	}
}

function checkDateString(dateString): string {
	let [year, month, day] = dateString.split('-');
	const yearLength = year.length;

	for (let i = 4; i > yearLength; i--) {
		year = '0' + year;
	}

	const newDateString = `${year}-${month}-${day}`;

	return newDateString;
}

export function DateTimeInput(props: IDateTimeInputProps) {
	const value = convertDefaultValue(props.type, props.value);

	if (value === '' && props.readOnly) {
		return (
			<input
				className={styles.dateTimeInput}
				readOnly
				value="-"
			/>
		);
	} else if (props.type === DateTimeType.week) return <WeekInput {...props} />;
	else
		return (
			<Fragment>
				<input
					className={`${styles.input} ${styles.dateTimeInput} ${props.hasError && styles.invalid}`}
					required={props.required}
					type={props.type}
					value={convertDefaultValue(props.type, props.value)}
					onChange={(e) => props.onChange(e.target.value)}
					readOnly={props.readOnly}
				/>
			</Fragment>
		);
}

export function WeekInput(props: IWeekInputProps) {
	const [valueWeek, valueYear] = useMemo(() => {
		const date = new Date(props.value);
		return dateHelper.getWeekNumber(date);
	}, [props.value]);
	const dateInputRef = useRef<HTMLInputElement>(null);

	const propsOnChange = props.onChange;
	const weekInputChangeHandler = useCallback(
		(value: string) => {
			//ex. value: 2024-W38
			const [year, week] = value.split('-W');

			const date = dateHelper.createDateFromWeek(Number(week), Number(year));

			propsOnChange(date);
		},
		[propsOnChange]
	);

	const dateInputChangeHandler = useCallback(
		(value: string) => {
			const currentDate = new Date(value);
			propsOnChange(currentDate);
		},
		[propsOnChange]
	);

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target!.value;
			if (Modernizr.inputtypes.week) {
				weekInputChangeHandler(value as string);
			} else {
				dateInputChangeHandler(value as string);
			}
		},
		[weekInputChangeHandler, dateInputChangeHandler]
	);

	const dateInputClickHandler = useCallback(() => {
		if (dateInputRef.current) dateInputRef.current.showPicker();
	}, []);

	if (Modernizr.inputtypes.week)
		return (
			<input
				className={`${styles.input} ${styles.dateTimeInput} ${props.hasError && styles.invalid}`}
				ref={dateInputRef}
				type="week"
				value={`${valueYear}-W${valueWeek.toString().padStart(2, '0')}`}
				onClick={dateInputClickHandler}
				onChange={changeHandler}
			/>
		);
	else
		return (
			<div className={styles.weekInput}>
				<input
					className={`${styles.input} ${props.hasError && styles.invalid}`}
					ref={dateInputRef}
					type="date"
					value={convertDefaultValue(DateTimeType.date, props.value)}
					onClick={dateInputClickHandler}
					onChange={changeHandler}
				/>
				<div className={styles.weekText}>
					<Trans i18nKey="week" />: {!isNaN(valueWeek) ? valueWeek : null}
				</div>
			</div>
		);
}
