import styles from './authentication.module.scss';
import React, { Component } from 'react';

import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import PasswordStrengthBar from 'react-password-strength-bar';

import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { IPasswordCheck, IResetPasswordState } from '../../globals/types';
import { passwordChecks } from '../../utilities/passwordUtility';
import { Button } from '../../components';
import Alert from '../../components/LoginPanel/Alert';

import IconInput from '../../components/LoginPanel/IconInput';
import { LoginLayout } from '../../components/LoginPanel/LoginLayout';
import { withRouter } from '../../compat/RouterCompat';

class ResetPasswordComponent extends Component<
	{ location: any; history: any; match: any; navigate: any },
	IResetPasswordState
> {
	constructor(props) {
		super(props);
		this.state = {
			input: {
				Password: '',
				ConfirmedPassword: '',
			},
			errors: {
				httpError: '',
				Password: '',
				ConfirmedPassword: '',
			},
			UserName: '',
			passwordIncorrect: false,
			confirmedPasswordIncorrect: false,
			isLoaded: false,
			loading: false,
			tokenValid: true,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this._onBlur = this._onBlur.bind(this);
	}

	onKeyDown(e) {
		if (e.charCode === 13) {
			this.handleSubmit(e);
		}
	}

	_onBlur() {
		this.validate(passwordChecks, false);
	}

	handleSubmit(e) {
		this.setState({
			loading: true,
		});

		let xRecoveryToken;
		const token = queryString.parse(this.props.location.search).token;
		if (!token) xRecoveryToken = undefined;
		else xRecoveryToken = token.toString();

		let password = this.state.input.Password;

		if (this.validate(passwordChecks)) {
			try {
				const url = `api/RecoveryClient/FinishRecovery/`;
				fetch(url, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						'X-Recovery-Token': xRecoveryToken,
					},
					body: JSON.stringify(password),
				}).then((result) => {
					this.setState({
						loading: false,
					});
					if (result.ok) {
						this.props.navigate(
							`/Authentication/SuccessfulPasswordReset`
						);
					} else if (result.status === 500) {
						this.setState({
							tokenValid: false,
							errors: {
								...this.state.errors,
								httpError: t('internalServerError'),
							},
						});
					}
				});
			} catch (e) {
				console.error(e);
				this.setState({
					loading: false,
				});
			}
		} else {
			this.setState({
				loading: false,
			});
		}

		e.preventDefault();
	}

	validate(checks: IPasswordCheck[], verifyBoth = true): boolean {
		let input = this.state.input;
		let isValid = true;
		let errors = {
			httpError: this.state.errors.httpError,
			Password: this.state.errors.Password,
			ConfirmedPassword: this.state.errors.ConfirmedPassword,
		};
		let isPasswordCorrect = !this.state.passwordIncorrect;
		let isConfirmedPasswordCorrect = !this.state.confirmedPasswordIncorrect;

		for (let check of checks) {
			const test = check.regex.test(input.Password);

			if (!test) {
				isValid = false;
				errors.Password = t(check.errorMessage);
				isPasswordCorrect = false;
				break;
			}
		}

		if (verifyBoth && isValid) {
			if (!input.ConfirmedPassword) {
				isValid = false;
				errors.ConfirmedPassword = t('fillInRepeatedPassword');
				isConfirmedPasswordCorrect = false;
			}

			if (
				input.Password &&
				input.ConfirmedPassword &&
				input.Password !== input.ConfirmedPassword
			) {
				isValid = false;
				errors.ConfirmedPassword = t('passwordsDoNotMatch');
				isConfirmedPasswordCorrect = false;
			}
		}

		this.setState({
			errors: errors,
			passwordIncorrect: !isPasswordCorrect,
			confirmedPasswordIncorrect: !isConfirmedPasswordCorrect,
		});

		return isValid;
	}

	changeHandler(event) {
		let input = this.state.input;
		input[event.target.name] = event.target.value;

		this.setState({
			input,
		});
	}
	componentDidMount() {
		let xRecoveryToken;
		const token = queryString.parse(this.props.location.search).token;
		if (!token) xRecoveryToken = undefined;
		else xRecoveryToken = token.toString();
		const url = `api/RecoveryClient/GetRecovery/`;

		fetch(url, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-Recovery-Token': xRecoveryToken!,
			},
		})
			.then((result) => {
				if (result.ok) {
					result.json().then((json) => {
						this.setState({ UserName: json });
					});
				} else if (result.status === 400) {
					this.setState({
						tokenValid: false,
						errors: {
							...this.state.errors,
							httpError: t('recoveryTokenInvalid'),
						},
					});
				} else if (result.status === 500) {
					this.setState({
						tokenValid: false,
						errors: {
							...this.state.errors,
							httpError: t('internalServerError'),
						},
					});
				}
			})
			.then(() => {
				this.setState({
					isLoaded: true,
				});
			});
	}

	render() {
		return (
			<LoginLayout>
				{' '}
				{/* TODO: Handle unable to retrieve user info gracefully*/}
				<form onSubmit={this.handleSubmit}>
					{this.state.errors.httpError && (
						<Alert severity='error'>
							{this.state.errors.httpError}
						</Alert>
					)}
					<h5 className={styles.label}>
						<Trans i18nKey='enterNewPassword' />
					</h5>

					<label className={styles.label}>
						<Trans i18nKey='resetPasswordMainText' />
						<div>
							<FontAwesomeIcon
								style={{ zoom: 1.5 }}
								icon={faUserCircle}
							/>
							<span
								style={{
									verticalAlign: 'top',
									paddingLeft: 5,
								}}
							>
								{this.state.UserName}
							</span>
						</div>
					</label>

					<input
						type='hidden'
						value={this.state.UserName ?? ''}
						autoComplete='username'
					/>

					<IconInput
						icon={faLock}
						type='password'
						placeholder={t('password') as string}
						onChange={this.changeHandler}
						onKeyPress={this.onKeyDown}
						value={this.state.input.Password}
						name='Password'
						onBlur={this._onBlur}
						error={this.state.passwordIncorrect}
						autoComplete='new-password'
					/>
					<div className={styles.textDanger}>
						{this.state.errors.Password}
					</div>
					<PasswordStrengthBar password={this.state.input.Password} />

					<IconInput
						icon={faLock}
						type='password'
						placeholder={t('repeatPassword') as string}
						onChange={this.changeHandler}
						onKeyPress={this.onKeyDown}
						value={this.state.input.ConfirmedPassword}
						name='ConfirmedPassword'
						error={this.state.confirmedPasswordIncorrect}
						autoComplete='new-password'
					/>
					<div className={styles.textDanger}>
						{this.state.errors.ConfirmedPassword}
					</div>

					<Button
						onClick={this.handleSubmit}
						theme='confirm'
						loading={this.state.loading}
						i18nKey='savePassword'
					/>
				</form>
			</LoginLayout>
		);
	}
}

export const ResetPassword = withRouter(ResetPasswordComponent);
