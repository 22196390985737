import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { Task } from '../models/Model/Task';
import authProvider from '../utilities/authProvider';
import { BaseViewModel } from './_BaseViewModel';
import { IViewModel } from './_viewModel.interfaces';

export interface ITaskViewModel extends IViewModel<Task> {
	toggleTaskCompleteCommand(): Promise<boolean>;
}

export enum taskDropDownPropertyNames {
	company_Id = 'company_Id',
	createdBy_Id = 'createdBy_Id',

	assignedTo_Id = 'assignedTo_Id',
}

export default function TaskViewModel(instanceName: string): ITaskViewModel {
	const entityType = EntityType.task;
	const functionRead = api.task.getByIdAsync;
	const functionUpdate = api.task.updateAsync;
	const functionDelete = api.task.deleteByIdAsync;

	const vm = BaseViewModel<Task>(instanceName, entityType, functionRead, functionUpdate, functionDelete);

	function generateModelInstance(id?: string, representativeId?: string): Task {
		const m = new Task();

		m.createdBy_Id = authProvider.getUserId() ?? null;
		m.assignedTo_Id = vm.getRepresentativeId();

		if (representativeId) {
			m.createdBy_Id = representativeId;
			m.assignedTo_Id = representativeId;
		}

		if (id) {
			m.company_Id = id;
		}

		return m;
	}

	function create(id?: string, representativeId?: string, params?: any) {
		return vm.doCreate(generateModelInstance(id, representativeId));
	}

	async function read(id) {
		return await vm.doRead(id);
	}

	async function update() {
		return await vm.doUpdate();
	}

	async function del(clearModel: boolean = true) {
		return await vm.doDelete(clearModel);
	}

	return {
		entityType,
		model: vm.model,
		fieldDefinitions: vm.fieldDefinitions,
		isValid: vm.isValid,
		isDirty: vm.isDirty,
		hasSavedChanges: vm.hasSavedChanges,
		instanceName: instanceName,
		create,
		read,
		update,
		del,
		setProperty: vm.setProperty,
		getProperty: vm.getProperty,
		isPropertyValid: vm.isPropertyValid,
		isPropertyReadOnly: vm.isPropertyReadOnly,
		getPropertyErrors: vm.getPropertyErrors,
		getProperties: vm.getProperties,
		getId: vm.getId,
		getType: vm.getType,
		getTypeCased: vm.getTypeCased,

		toggleTaskCompleteCommand,
	};

	//#region COMMANDS
	async function toggleTaskCompleteCommand(): Promise<boolean> {
		if (vm.model === undefined) return false;
		if (vm.isValid === false) return false;

		const shouldComplete = !vm.model.isCompleted;

		if (shouldComplete) {
			vm.model.completeDate = new Date();
		}

		vm.model.isCompleted = shouldComplete;

		return await vm.doUpdate();
	}
	//#endregion COMMANDS
}
