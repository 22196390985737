/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IRepresentative } from "./Representative";
import { ICompany } from "./Company";

export interface ITask extends IModelBase {
	createdBy: IRepresentative | null;
    createdBy_Id: string | null;
    assignedTo: IRepresentative | null;
    assignedTo_Id: string | null;
    company: ICompany | null;
    company_Id: string | null;
    dueDate: Date | null;
    subject: string | null;
    description: string | null;
    notifyOnComplete: boolean;
    isEmailed: boolean;
    emailedDate: Date | null;
    isCompleted: boolean;
    completedBy: IRepresentative | null;
    completedBy_Id: string | null;
    completeDate: Date | null;
}

export class Task extends ModelBase implements ITask {
	public createdBy: IRepresentative | null = null;
    public createdBy_Id: string | null = null;
    public assignedTo: IRepresentative | null = null;
    public assignedTo_Id: string | null = null;
    public company: ICompany | null = null;
    public company_Id: string | null = null;
    public dueDate: Date | null = null;
    public subject: string | null = null;
    public description: string | null = null;
    public notifyOnComplete: boolean = false;
    public isEmailed: boolean = false;
    public emailedDate: Date | null = null;
    public isCompleted: boolean = false;
    public completedBy: IRepresentative | null = null;
    public completedBy_Id: string | null = null;
    public completeDate: Date | null = null;
}
