import * as React from 'react';
import { withRouter } from '../../../compat/RouterCompat';

import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { dateHelper } from "../../../utilities/dateHelper";
import { ExternalConnectionsProps, ExternalConnectionState } from '../types';
import { ExternalConnectionStatus } from '../enums';
import { InfoGroup, InfoRow } from '../../../components/InfoBlocks';

export class ExternalConnection<
	Props extends ExternalConnectionsProps,
	State extends ExternalConnectionState
> extends React.Component<Props, State> {
	protected getStatus = (status: ExternalConnectionStatus) => {
		switch (status) {
			case ExternalConnectionStatus.ReadyToRun:
			case ExternalConnectionStatus.Active:
			case ExternalConnectionStatus.Successful:
			case ExternalConnectionStatus.NoUpdates:
				return (
					<div className='text-capitalize'>
						<div className='status_indicator green'></div>
						<Trans i18nKey='externalConnectionStatus.ok' />
					</div>
				);
			case ExternalConnectionStatus.AuthorizationExpired:
			case ExternalConnectionStatus.AuthorizationFailed:
			case ExternalConnectionStatus.AuthorizationMissing:
				return (
					<div className='text-capitalize'>
						<div className='status_indicator orange'></div>
						<Trans i18nKey='externalConnectionStatus.authRequired' />
					</div>
				);
			case ExternalConnectionStatus.PartialActive:
			case ExternalConnectionStatus.PartialSuccessful:
				return (
					<div className='text-capitalize'>
						<div className='status_indicator blue'></div>
						<Trans i18nKey='externalConnectionStatus.ok' />
					</div>
				);
			case ExternalConnectionStatus.Inactive:
				return (
					<div className='text-capitalize'>
						<div className='status_indicator yellow'></div>
						<Trans i18nKey='externalConnectionStatus.inactive' />
					</div>
				);
			case ExternalConnectionStatus.IncorrectSetup:
			case ExternalConnectionStatus.NetworkError:
			case ExternalConnectionStatus.NoSuccess:
			case ExternalConnectionStatus.UnknownError:
			case ExternalConnectionStatus.MultipleErrors:
				return (
					<div className='text-capitalize'>
						<div className='status_indicator red'></div>
						<Trans i18nKey='externalConnectionStatus.error' />
					</div>
				);
			default:
				return (
					<div className='text-capitalize'>
						<div className='status_indicator grey'></div>
						<Trans i18nKey='externalConnectionStatus.unknown' />
					</div>
				);
		}
	};

	/**
	 * Override this function to add additional InfoRow JSX Elements to the Group
	 */
	renderAdditionalInfo = () => {
		return <React.Fragment />;
	};

	render() {
		return (
			<InfoGroup
				title={this.props.connector.name}
				open={true}
				visible={true}
				padding={true}
			>
				<InfoRow label={t('externalConnection.status')}>
					{this.getStatus(this.props.connector.status)}
				</InfoRow>
				{/*{this.props.message &&*/}
				{/*	<InfoRow label={t('externalConnection.message')}>*/}
				{/*		{this.props.message}*/}
				{/*	</InfoRow>*/}
				{/*}*/}
				{this.props.connector.lastRunDate &&
					<InfoRow label={t('externalConnection.lastRunDate')}>
						{dateHelper.DATE_FORMATTER_SHORT.format(new Date(this.props.connector.lastRunDate))}
					</InfoRow>
				}
				{this.renderAdditionalInfo()}
			</InfoGroup>
		);
	}
}

export const DefaultConnection = withRouter(ExternalConnection);
