import styles from './annotationEditor.module.scss';
import { ChangeEvent, DetailedHTMLProps, TextareaHTMLAttributes, createRef, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faNoteSticky } from '@fortawesome/pro-solid-svg-icons';

export enum whenToUseChangeHandler {
	focusLose,
	change,
}

export interface annotationEditorProps {
	defaultContent: string | null;
	labels: {
		left: string;
		middle: string;
	};
	functions: {
		changeHandler: (content: string) => any;
		deleteHandler?: Function;
	};
	errorMessage?: string;
	whenToUseChangeHandler?: whenToUseChangeHandler;
	editing?: boolean;
}

function AnnotationEditor(props: annotationEditorProps) {
	const textareaRef = createRef<HTMLTextAreaElement>();
	const [content, setContent] = useState(props.defaultContent ?? '');

	const changeHandler = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			props.functions.changeHandler(e.target.value);
		},
		[props.functions]
	);
	const deleteHandler = props.functions.deleteHandler;

	useEffect(() => {
		setContent(props.defaultContent ?? '');
	}, [props.defaultContent]);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = '0px';
			const scrollHeight = textareaRef.current.scrollHeight + 16;
			textareaRef.current.style.height = `${scrollHeight}px`;
		}
	}, [props.defaultContent, content, textareaRef]);

	const textareaProps: DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> = {
		value: content,
		ref: textareaRef,
		required: true,
	};

	if (props.whenToUseChangeHandler === whenToUseChangeHandler.change) {
		textareaProps.onChange = (e) => {
			changeHandler(e);
			setContent(e.target.value);
		};
	} else if (props.whenToUseChangeHandler === whenToUseChangeHandler.focusLose || props.whenToUseChangeHandler === undefined) {
		textareaProps.onChange = (e) => setContent(e.target.value);
		textareaProps.onBlur = changeHandler;
	}

	return (
		<div className={styles.annotationEditor}>
			<div className={styles.header}>
				<div className={styles.left}>
					<FontAwesomeIcon
						icon={faNoteSticky}
						size="xl"
						className={styles.icon}
					/>
					<span className={styles.labels}>{props.labels.left}</span>
				</div>
				<div className={styles.middle}>{props.labels.middle}</div>
				<div className={styles.right}>
					{deleteHandler !== undefined && (
						<FontAwesomeIcon
							icon={faTrashCan}
							size="xl"
							onClick={() => {
								deleteHandler();
							}}
							className={`${styles.icon} ${styles.action}`}
						/>
					)}
				</div>
			</div>
			<div className={`${styles.contentWrapper} ${props.errorMessage && styles.error}`}>
				<textarea {...textareaProps} />
			</div>
		</div>
	);
}

export default AnnotationEditor;
