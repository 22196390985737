import { BaseQuery } from "./_BaseQuery";

export class ActivityQuery extends BaseQuery {
	//RangeFilter
	//ExchangeIdFilter

	public representative_Id?:string;
	public weekDate?:Date;
	public returnWholeWeek?:boolean;
	public company_Id?:string;
	public future?:boolean;
	public cultureString?:string;
	public noAppointments?:boolean;
}
