import { t } from 'i18next';

/**
 * Helper to produce an array of enum descriptors.
 * @param enumeration Enumeration object.
 */
export function enumToSelectionArray<T>(enumeration: T, translationSet): any[][] {
	return (Object.keys(enumeration as object) as Array<keyof T>)
		.filter((key) => isNaN(Number(key)))
		.filter((key) => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
		.map((key) => [enumeration[key], t(`${translationSet}.${String(key).toLowerCase()}`)]);
}
