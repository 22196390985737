import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashCan, faDownload } from '@fortawesome/pro-light-svg-icons';
import { t } from 'i18next';
import { GetRepresentative } from '../../../../api/RepresentativesAPI';
import { openConfirm, openAlert } from '../../../Dialog/dialogSlice';
import { dateHelper } from '../../../../utilities/dateHelper';
import { bytesConversion } from '../../../../utilities/bytesConversion';
import { IFileViewCollectionViewModel } from '../../../../viewmodels/Collections/FileViewCollectionViewModel';
import { IFileView } from '../../../../models/BusinessObjects_View/FileView';
import styles from '../fileListView.module.scss';
import getErrorMessage from '../getErrorMessage';

interface IFileViewProps {
	primaryRepresentativeId: string;
	cvm: IFileViewCollectionViewModel<any, any>;
	item: IFileView;
	index: number;
}
export const FileView = (props: IFileViewProps) => {
	const dispatch = useDispatch();

	const [repInfo, setRepInfo] = useState<{ fullName: string | undefined }>({ fullName: '' });

	const getRepresentativeInfo = useCallback(async () => {
		const response = await GetRepresentative(props?.primaryRepresentativeId);
		setRepInfo(await response.json());
	}, [props?.primaryRepresentativeId]);

	useEffect(() => {
		if (props?.primaryRepresentativeId) {
			getRepresentativeInfo();
		}
	}, [props?.primaryRepresentativeId, getRepresentativeInfo]);

	const deleteFile = useCallback(
		(index: number) => {
			dispatch(
				openConfirm({
					title: 'delete',
					text: 'areYouSureDelete',
					confirmButtons: [
						{
							confirmText: 'delete',
							onClick: async () => await props?.cvm?.deleteFile(index),
						},
					],
				})
			);
		},
		[props?.cvm, dispatch]
	);

	const downloadFile = useCallback(
		async (index: number) => {
			const downloadResponse = await props.cvm.downloadFile(index);
			if (!downloadResponse.errorName) {
				window.open(downloadResponse, '_blank');
			} else {
				dispatch(
					openAlert({
						text: getErrorMessage(downloadResponse?.errorCode, downloadResponse?.errorName),
						title: 'anErrorOccurred',
						dismissButtonPosition: 'bottom',
						dismissText: 'ok',
						icon: 'warning',
					})
				);
			}
		},
		[props, dispatch]
	);

	return (
		<>
			<div className={styles.fileIcon}>
				<FontAwesomeIcon icon={faFile} size="2x" />
			</div>
			<div className={styles.fileInfo}>
				<div className={styles.top}>
					<p className={styles.fileName}>{props.item?.name}</p>
					<div>
						<span className={styles.fileSize}>{bytesConversion(props.item?.size)}</span>
					</div>
				</div>
				{repInfo ? <span className={styles.repName}>{repInfo?.fullName}</span> : ''}
				<div className={styles.bottom}>
					<div className={styles.uploadInfo}>
						<span>{t('uploaded')}:</span>
						<span>
							{dateHelper.getDateFormatted(props.item?.createdDate)},
							{dateHelper.getTimeFormatted(props.item?.createdDate)}
						</span>
					</div>
					<div className={styles.actions}>
						<FontAwesomeIcon
							className={`${styles.download}`}
							icon={faDownload}
							size="xl"
							onClick={() => downloadFile(props?.index)}
						/>

						<FontAwesomeIcon
							className={`${styles.delete}`}
							icon={faTrashCan}
							size="xl"
							onClick={() => deleteFile(props?.index)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
