import styles from './iconCustom.module.scss';
import React, { ReactElement } from 'react';
import { Component } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconCustomDefinitions } from './IconCustom.definitions';
import { IIconCustomProps, IIconCustomState } from './IconCustom.types';
import { CssBuilder } from '../../utilities/parseUtility';
import { colorHelper } from '../../utilities/colorHelper';

export class IconCustom extends Component<IIconCustomProps, IIconCustomState> {
	isBusy: boolean = false;

	cssBuilder: CssBuilder = new CssBuilder();
	refContainer!: React.RefObject<HTMLDivElement>;
	refAwesome!: React.RefObject<HTMLDivElement>;

	constructor(props: IIconCustomProps) {
		super(props);

		this.state = {
			definition: IconCustomDefinitions[props.kind],
		};

		this.refContainer = React.createRef<HTMLDivElement>();
		this.refAwesome = React.createRef<HTMLDivElement>();
	}

	get def() {
		return this.state.definition;
	}

	private getAwesomeIconProp = (): IconProp => {
		const getThicNess = () => {
			switch (this.def.thickNess) {
				case 1:
					return 'far';
				case 2:
					return 'fas';
				default:
					return 'fal';
			}
		};

		const arr = new Array<string>(1);
		arr[0] = getThicNess();
		arr[1] = this.def.graphic ?? '';

		return arr as unknown as IconProp;
	};

	//#region FOREGROUND
	getForegroundColor = () => {
		return this.def.foregroundColor;
	};

	getForegroundFontSize = () => {
		if (this.def.foregroundPxSize) return this.def.foregroundPxSize;
		return undefined;
	};

	private getForegroundClassName = (): string | undefined => {
		let className = '';

		if (this.def.foregroundClassName) {
			className = styles[this.def.foregroundClassName];
		}
		if (this.def.flipHorizontal && this.def.flipHorizontal === true) {
			className = `${className} fa-flip-horizontal`;
		}

		return className;
	};

	private getForegroundInlineStyle = (): React.CSSProperties | undefined => {
		let props: React.CSSProperties = {};

		if (this.def.foregroundCorrectionY) {
			Object.assign(props, { marginTop: `${this.def.foregroundCorrectionY}px` });
		}
		if (this.def.foregroundCorrectionX) {
			Object.assign(props, { marginLeft: `${this.def.foregroundCorrectionX}px` });
		}

		if (Object.keys(props).length === 0) return undefined;

		return props;
	};
	//#endregion FOREGROUND

	//#region BACKGROUND
	private getBackgroundInlineStyle = (): React.CSSProperties | undefined => {
		let props: React.CSSProperties = {};

		if (this.def.backgroundPxSize) {
			Object.assign(props, {
				width: `${this.def.backgroundPxSize}px`,
				height: `${this.def.backgroundPxSize}px`,
			});
		}

		if (this.props.onClick) {
			Object.assign(props, { cursor: 'pointer' });
		}

		if (this.props.style) {
			props = { ...props, ...this.props.style };
		}

		return props;
	};

	private getBackgroundClassName = (): string | undefined => {
		let className = '';

		if (this.def.backgroundClassName) {
			className = styles[this.def.backgroundClassName];
		}

		if (this.props.className) {
			className = `${className} ${this.props.className}`;
		}

		if (this.props.backColor) {
			className = `${className} clr_b_${this.props.backColor}`;
		}

		return className;
	};
	//#endregion BACKGROUND

	darkenIcon = () => {
		if (!this.def.autoHover) return;
		if (!this.props.onClick) return;

		const icon = this.refContainer?.current?.firstChild as HTMLElement;
		if (!icon) return;

		const svg = this.refContainer?.current?.firstChild as SVGAElement;
		if (!svg) return;

		const colorOrg = svg.attributes['color'].value;
		if (!colorOrg) return;

		const colorNew = colorHelper.changeLightness(colorOrg, -90);
		icon.style.color = colorNew;
	};

	lightenIcon = () => {
		if (!this.def.autoHover) return;
		if (!this.props.onClick) return;

		const icon = this.refContainer?.current?.firstChild as HTMLElement;
		if (!icon) return;

		icon.style.color = '';
	};

	handle_mouseOver = () => {
		if (!this.def.autoHover) return;
		this.darkenIcon();
	};

	handle_mouseOut = () => {
		if (!this.def.autoHover) return;
		if (this.isBusy) return;
		this.lightenIcon();
	};

	handle_onClick = async () => {
		if (!this.props.onClick) return;
		if (!this.refContainer.current) return;

		//TODO: Find out how to remove react event handlers,
		//instead of using "isBusy" E.G. (icon.onMouseOut = null)

		try {
			this.isBusy = true;
			this.darkenIcon();
			await this.props.onClick();
		} catch (ex) {
		} finally {
			this.isBusy = false;
			this.lightenIcon();
		}
	};

	renderSquare = (icon): ReactElement => {
		const div = <div className={this.getBackgroundClassName()}>{icon}</div>;

		return div;
	};

	renderIcon = () => {
		const fontAwesomeIcon = (
			<FontAwesomeIcon
				className={this.getForegroundClassName()}
				icon={this.getAwesomeIconProp()}
				fontSize={this.getForegroundFontSize()}
				color={this.getForegroundColor()}
				style={this.getForegroundInlineStyle()}
			/>
		);

		return fontAwesomeIcon;
	};

	render() {
		return (
			<div
				ref={this.refContainer}
				onMouseOver={this.handle_mouseOver}
				onMouseOut={this.handle_mouseOut}
				onClick={this.handle_onClick}
				className={this.getBackgroundClassName()}
				style={this.getBackgroundInlineStyle()}
			>
				{this.renderIcon()}
			</div>
		);
	}
}
