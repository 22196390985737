import { t } from 'i18next';

import styles from './inputFields.module.scss';
import { IBooleanInputComponentProps } from './inputField.types';
import { useCallback } from 'react';

function BooleanInputComponent(props: IBooleanInputComponentProps) {
	const [labelTrue, labelFalse] = props.booleanLabels ? props.booleanLabels : [t('true'), t('false')];

	const handleClick = useCallback(
		(bool: boolean) => {
			if (props.storeBooleanLabelsAsValue) {
				if (bool === true) props.onChange(labelTrue);
				if (bool === false) props.onChange(labelFalse);
			} else {
				props.onChange(bool);
			}
		},
		[labelFalse, labelTrue, props]
	);

	const getClassName = useCallback(
		(bool: boolean) => {
			let currentValueAsBoolean: boolean | undefined = undefined;

			if (props.value === null || props.value === undefined) return styles.selection;

			if (props.storeBooleanLabelsAsValue) {
				if (props.value === labelTrue) currentValueAsBoolean = true;
				else if (props.value === labelFalse) currentValueAsBoolean = false;
			} else {
				const stringValue = props.value.toString().toLowerCase();
				if (stringValue === 'true') currentValueAsBoolean = true;
				else if (stringValue === 'false') currentValueAsBoolean = false;
			}

			if (bool === currentValueAsBoolean) return `${styles.selection} ${styles.active}`;

			return styles.selection;
		},
		[labelFalse, labelTrue, props.storeBooleanLabelsAsValue, props.value]
	);

	return (
		<div
			className={`
				${styles.boolean}
				${props.readOnly && styles.notEditing}
				${props.hasError && styles.invalid}
			`}
		>
			{props.readOnly && (props.value === null || props.value === undefined) && <div className={`${styles.selection} ${styles.active}`}>-</div>}

			<div
				className={getClassName(true)}
				onClick={() => handleClick(true)}
			>
				{labelTrue}
			</div>
			<div
				className={getClassName(false)}
				onClick={() => handleClick(false)}
			>
				{labelFalse}
			</div>
		</div>
	);
}

export default BooleanInputComponent;
