import { createPortal } from 'react-dom';
import {
	IEntityDetailPopupProps,
	ITabListProps,
	ITabListTabProps,
	EditMethod,
	entityTabType,
	IEntityDetailPopupConfiguredProps,
} from './entityDetailPopup.interfaces';
import styles from './entityDetailPopup.module.scss';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { tabConfigurations } from './entityDetailPopup.tabConfigurations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openConfirm } from '../Dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { EntityType } from './entityDetailPopup.enums';
import { hasModule, moduleNames } from '../../utilities/authProvider';

export function EntityDetailPopupConfigured(props: IEntityDetailPopupConfiguredProps) {
	const viewModel = tabConfigurations[props.popupEntityType].viewModel(props.viewModelInstanceName);

	return (
		<EntityDetailPopup
			viewModel={viewModel}
			id={props.id}
			editMethod={props.editMethod}
			popupEntityType={props.popupEntityType}
			closeFunction={props.closeFunction}
			defaultEditingState={props.defaultEditingState}
		/>
	);
}

export function EntityDetailPopup(props: IEntityDetailPopupProps) {
	const viewModel = props.viewModel;

	const tabs = useMemo(() => {
		const copyTabs = [...tabConfigurations[props.popupEntityType].tabs];

		//remove Opportunity Tab when opportunity module is not enabled
		if (props.popupEntityType === EntityType.Company) {
			const hasOpportunityModule = hasModule(moduleNames.opportunity);
			if (!hasOpportunityModule) {
				const index = copyTabs.findIndex((tab) => {
					if (tab.type !== entityTabType.subEntityList) return false;
					else return tab.entityType === EntityType.Opportunity;
				});

				if (index !== -1) copyTabs.splice(index, 1);
			}
		}

		//remove Files Tab when document module is not enabled
		const hasDocManagementModule = hasModule(moduleNames.documentManagement);
		if (!hasDocManagementModule) {
			const index = copyTabs.findIndex((tab) => {
				if (tab.type !== entityTabType.subEntityList) return false;
				else return tab.entityType === EntityType.File;
			});

			if (index !== -1) copyTabs.splice(index, 1);
		}

		return copyTabs;
	}, [props.popupEntityType]);

	const [currentTabIndex, setCurrentTabIndex] = useState<number>(
		tabs.findIndex((tab) => tab.type === entityTabType.form || tab.type === entityTabType.subEntityList)
	);

	const currentTab = currentTabIndex !== -1 ? tabs[currentTabIndex] : null;

	const [tabListProps] = useState<ITabListProps>({
		tabs: tabs.map((tab, index) => {
			const object: ITabListTabProps = {
				title: tab.title,
				icon: tab.icon,
			};

			if (tab.type === entityTabType.form || tab.type === entityTabType.subEntityList) object.onClick = () => setCurrentTabIndex(index);

			return object;
		}),
	});

	const [visible, setVisible] = useState(true);

	let defaultEditingState = props.defaultEditingState !== undefined ? props.defaultEditingState : false;
	if (props.editMethod === EditMethod.create) defaultEditingState = true;

	const [isEditing, setIsEditing] = useState(defaultEditingState);

	const dispatch = useDispatch();

	const propsCloseFunction = props.closeFunction;
	const closeFunction = useCallback(
		(ignoreIsDirty?: boolean) => {
			if (viewModel.isDirty && !ignoreIsDirty) {
				dispatch(
					openConfirm({
						title: 'confirmUnsavedChanges',
						dismissButtonPosition: 'bottom',
						confirmButtons: [
							{
								onClick: () => propsCloseFunction(props.viewModel.hasSavedChanges),
								confirmText: 'closeAnyway',
							},
						],
					})
				);
				return;
			}

			propsCloseFunction(props.viewModel.hasSavedChanges);
		},
		[propsCloseFunction, viewModel.isDirty, dispatch, props.viewModel.hasSavedChanges]
	);

	const getCurrentViewElement = useCallback(() => {
		if (!currentTab) return null;

		const elementProps = {
			id: viewModel.getId(),
			editMethod: props.editMethod,
			isEditing: isEditing,
			setIsEditing: setIsEditing,
			vm: viewModel,
			closeDetailPopup: closeFunction,
			defaultEditingState: props.defaultEditingState,
		};

		if (currentTab.type === entityTabType.form) {
			return React.createElement(currentTab.view, elementProps);
		}

		if (currentTab?.type === entityTabType.subEntityList) {
			return React.createElement(currentTab.view, {
				...elementProps,
				tableAttributes: currentTab.tableAttributes,
				collectionVMPropName: currentTab.collectionVMPropName,
				entityType: currentTab.entityType,
				// idPropName: currentTab.idPropName,
			});
		}
	}, [closeFunction, currentTab, isEditing, props.defaultEditingState, props.editMethod, viewModel]);

	useEffect(() => {
		setVisible(true);

		if (props.editMethod === EditMethod.create) {
			viewModel.create(props.id);
			return;
		}

		viewModel.read(props.id);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, props.editMethod]);

	if (visible)
		return createPortal(
			<div
				className={styles.modal}
				onClick={() => closeFunction()}
			>
				<div
					className={styles.popup}
					onClick={(event) => event.stopPropagation()}
				>
					<TabList
						{...tabListProps}
						currentTabIndex={currentTabIndex}
						disabled={isEditing}
					/>

					<div className={styles.entityView}>{getCurrentViewElement()}</div>
					<FontAwesomeIcon
						icon={'circle-xmark'}
						size="2x"
						className={styles.closeButton}
						onClick={() => closeFunction()}
					/>
				</div>
			</div>,
			document.body
		);
	else return null;
}

function TabList(props: ITabListProps) {
	const generateTabProps = useCallback(
		(tab: ITabListTabProps, index: number) => {
			let className = styles.tab;
			let onClick: (() => void) | undefined = undefined;
			if (tab.onClick) {
				className += ` ${styles.clickable}`;
				onClick = tab.onClick;
			} else {
				className += ` ${styles.tabHeader}`;
			}

			if (props.currentTabIndex === index) {
				className += ` ${styles.selected}`;
			}

			if (props.disabled) {
				className += ` ${styles.disabled}`;
				onClick = undefined;
			}

			const tabProps = {
				key: index,
				className: className,
				onClick: onClick,
			};

			return tabProps;
		},
		[props.currentTabIndex, props.disabled]
	);

	return (
		<div className={styles.tabList}>
			{props.tabs.map((tab, index) => (
				<div {...generateTabProps(tab, index)}>
					{tab.icon && (
						<div className={styles.tabIcon}>
							<FontAwesomeIcon
								size="lg"
								icon={tab.icon}
							/>
						</div>
					)}
					{t(tab.title)}
				</div>
			))}
		</div>
	);
}
