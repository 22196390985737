import React, { Fragment } from 'react';
import { t } from 'i18next';
import { ExternalConnectionsProps, ExternalConnector } from '../types';

import { getConnectors } from '../../../api/ExternalConnectionAPI';
import { ExactConnection } from './ExactConnection';
import { ZapierConnection } from './ZapierConnection';
import { ExchangeConnection } from './ExchangeConnection';
import { DefaultConnection } from './ExternalConnection';
import { GridComponents } from '../../../components';
import { fireLoadBegin, fireLoadEnd } from '../../../utilities/EventService';

export class ExternalConnections extends React.Component<
    ExternalConnectionsProps,
    { connectors: ExternalConnector[] }
> {
	constructor(props) {
		super(props);

		this.state = {
			connectors: [],
		};
	}

	componentDidMount() {
		this.getConnectors();
	}

	async getConnectors() {
		fireLoadBegin()
		var response = await getConnectors();
		if (response.ok) {
			var connectors = await response.json();
			this.setState({
				connectors: connectors,
			});
		}
		fireLoadEnd();
	}

	renderConnectors(connectors: ExternalConnector[]): JSX.Element[] {
		return connectors.map((connector, key) => {
			switch (connector.name) {
				case 'Exact':
					return (
						<ExactConnection
							key={key}
							{...this.props}
							connector={connector}
						/>
					);
				case 'Exchange':
					return (
						<ExchangeConnection
							key={key}
							{...this.props}
							connector={connector}
						/>
					);
				case 'Unit4':
				case 'Nocore':
				default:
					return (
						<DefaultConnection
							key={key}
							{...this.props}
							connector={connector}
						/>
					);
			}
		});
	}

	render() {
		return (
			<Fragment>
				<GridComponents.Column size='threeFourths'>
					<GridComponents.Row.Header
						content={
							<GridComponents.Row.Breadcrumb
								match={this.props.match}
							/>
						}
					/>
					<GridComponents.Row.SubHeader>
						<GridComponents.Row.SubHeaderDetail
							icon='wifi'
							name={t('externalConnections')}
						/>
					</GridComponents.Row.SubHeader>
					<GridComponents.Row.Scroll>
						{this.state.connectors &&
							this.renderConnectors(this.state.connectors)}
						<ZapierConnection
							key='zapier'
							{...this.props}
						/>
					</GridComponents.Row.Scroll>
				</GridComponents.Column>
				{/*    <GridComponents.Column size="fourth"/>*/}
			</Fragment>
		);
	}
}
