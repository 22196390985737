import { useCallback, SetStateAction, Dispatch } from 'react';
import { api } from '../../api/_Executor';
import { FileQuery } from '../../business/Query/FileQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { ContactTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { FileView } from '../../models/BusinessObjects_View/FileView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

export interface IFileViewCollectionViewModel<T extends FileView, Q extends BaseQuery = FileQuery>
	extends ICollectionViewModel<T, Q> {
	deleteFile(index: number, refreshAll?: boolean): Promise<boolean>;
	downloadFile(index: number): Promise<any>;

	startUploadFile(
		file: File | any,
		entityType: string,
		setUploadProgress: Dispatch<SetStateAction<number>>
	): Promise<any>;
	cancelUpload(): Promise<void>;
}
export const useFileViewCollectionViewModel = <T extends FileView, Q extends BaseQuery = FileQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean,
	parentId?: string
): IFileViewCollectionViewModel<T, Q> => {
	const apiReader = api.file.listAsync;
	const query = new FileQuery() as Q;
	query['parentId'] = parentId;

	const table = ContactTableDefinition;
	const canSearch: boolean = false;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<T, Q>(instanceName, apiReader, ignoreRepresentative_id, query, undefined);

	const downloadFile = useCallback(
		async (index: number) => {
			if (cvm.items.length === 0) return ''; //No error
			const item = cvm.items[index];
			if (item === undefined) return ''; //Can't find
			const response = await api.file.downloadByIdAsync(item?.id);
			if (response.status !== 500 && response.status !== 400) {
				return response;
			} else {
				return await response.json();
			}
		},
		[cvm.items]
	);

	const deleteFile = useCallback(
		async (index: number, refreshAll: boolean = true) => {
			if (cvm.items.length === 0) return true; //No error
			const item = cvm.items[index];
			if (item === undefined) return false; //Can't find

			const success = await api.file.deleteByIdAsync(item?.id);
			if (success === undefined) return false;

			//Refresh the whole list?
			if (refreshAll) {
				return await cvm.doQuery();
			}

			return true;
		},
		[cvm]
	);

	const startUploadFile = useCallback(
		async (
			file: File | any,
			entityType: string,
			setUploadProgress: Dispatch<SetStateAction<number>>,
			refreshAll: boolean = true
		) => {
			const fileInfo = {
				fileName: file?.name,
				expectedSize: file?.size,
				parentId: parentId,
				entityType: entityType,
			};
			const response = await api.file.startUploadFileAsync(parentId, fileInfo);
			if (response.status !== 500 && response.status !== 400) {
				await api.file.uploadFileAsync(response, file, setUploadProgress).then(async (res) => {
					if (res === 201) {
						const completed = await api.file.completeUploadAsync(encodeURIComponent(response), true);
						//Refresh the whole list?
						if (refreshAll) {
							return await cvm.doQuery();
						}
						return completed;
					} else {
						await api.file.completeUploadAsync(encodeURIComponent(response), false);
					}
				});
				return response;
			} else {
				return await response.json();
			}
		},
		[cvm, parentId]
	);

	const cancelUpload = async () => {
		await api.file.cancelUploadAsync();
	};

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,
		deleteFile,
		downloadFile,
		startUploadFile,
		cancelUpload,
	};
};
