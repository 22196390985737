import styles from '../../formDetail.module.scss';
import { Fragment, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSave, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { IButtonsProps } from '../../../entityDetailPopup.interfaces';
import { openAlert, openConfirm } from '../../../../Dialog/dialogSlice';
import { useDispatch } from 'react-redux';

export function ButtonsGeneral(props: IButtonsProps) {
	const [editing, setEditing] = [props.editing, props.setEditing];
	const dispatch = useDispatch();

	const deleteItem = useCallback(async () => {
		await props.vm.del();
		props.closeDetailPopup();
	}, [props.vm.del, props.closeDetailPopup]);

	const toggleEditing = useCallback(() => {
		setEditing(!editing);
	}, [editing, setEditing]);

	const confirmDelete = useCallback(() => {
		dispatch(
			openConfirm({
				title: 'delete',
				text: 'areYouSureDelete',
				confirmButtons: [
					{
						confirmText: 'delete',
						onClick: async () => await deleteItem(),
					},
				],
			})
		);
	}, [deleteItem, dispatch]);

	const updateItem = useCallback(async () => {
		if (!props.vm.isValid || !props.vm.isDirty) return;

		const success = await props.vm.update();

		if (!success) {
			dispatch(
				openAlert({
					text: 'anErrorOccurred',
				})
			);
		} else setEditing(false);
	}, [props.vm.isValid, props.vm.isDirty, props.vm.update, setEditing]);

	const saveButtonClassName = `${styles.formButton} ${!props.vm.isValid && styles.disabled}`;

	return (
		<Fragment>
			{props.children}
			{props.editing ? (
				<FontAwesomeIcon
					className={saveButtonClassName}
					icon={faSave}
					onClick={props.vm.isValid ? (props.vm.isDirty ? async () => await updateItem() : toggleEditing) : undefined}
					size="xl"
				/>
			) : (
				<FontAwesomeIcon
					className={styles.formButton}
					icon={faPenToSquare}
					onClick={toggleEditing}
					size="xl"
				/>
			)}

			{!props.excludeDelete ? (
				<FontAwesomeIcon
					className={styles.formButton}
					icon={faTrashCan}
					size="xl"
					onClick={confirmDelete}
				/>
			) : null}
		</Fragment>
	);
}
