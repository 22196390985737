import { basename } from "path";
import { FilterField } from "./_enums";
import { convertDateToNetIsoString } from "../../utilities/utils";

export interface IFilterBase { 
	$type:string;
}

abstract class FilterBase implements IFilterBase {
	public $type: string;

	public constructor(type:string) {
		this.$type = type;
	}
}

export class FilterBaseOf<T> implements IFilterBase {
	public $type: string;
	public value:T;

	constructor(type:string, value:T) {
		this.$type = type;
		this.value = value;
	}
}

export class IdFilter extends FilterBaseOf<string> {
	constructor(type:string, id:string) {
		super(type, id);
	}
}

export class CompanyFilter extends IdFilter {
	constructor(type:string, id:string) {
		super(type, id);
	}
}

export class BoundsFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public latitudeNorth?:number;
	public latitudeSouth?:number;
	public longitudeEast?:number;
	public longitudeWest?:number;
}

export class SegmentationFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public segmentsNorthSouth?:number;
	public segmentsWestEast?:number;
}

export class SearchTermFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public filterFields?:FilterField; //0,1,2 only
	public searchTerm?:string;
}

export class RepresentativeIdFilter extends FilterBase {
	constructor(type:string) { super(type) }
	id?:string
}

export class SortStartsWithFilter extends FilterBase {
	constructor(type:string) { super(type) }
}

export class PriorityFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public minimumWeeksDue?:number;
	public priorityOnly?:boolean; //not in use in PrioritizedCompanyQyery
}

export class NearFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public longitude?:number;
	public latitude?:number;
	public radius?:number;
}

export class RangeFilter extends FilterBase {
	public from:string;
	public to: string;

	constructor(type:string, fromDate:Date, toDate:Date) { 
		super(type);
		
		this.from = convertDateToNetIsoString(fromDate);
		this.to = convertDateToNetIsoString(toDate); 
	}
}

export class ExchangeIdFilter extends FilterBaseOf<string> {
	constructor (type:string,exchangeId:string) {
		super(type, exchangeId);
	}
}

export class CloseAppointmentFieldsFilter extends FilterBase {
	constructor(type:string) { super(type) }
}

export class EntityFilter extends FilterBase {
	constructor(type:string) { super(type) }
}

export class EntityFilterString<T = string> extends FilterBaseOf<T> {
	constructor(type:string, value:T) {
		super(type, value);
	}
}

export class EntityTypeFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public id?:string;
	public entityType?:string;
}

export class VisitingFrequencyIdFilter extends FilterBase {
	constructor(type:string) { super(type) }

	public id?:string;
}

//PROBABLY NOT IN USE
export class HasOldValue extends FilterBaseOf<string> { }
export class HasNewValue extends FilterBaseOf<string> { }
export class OfEntityType extends FilterBase {
	public entiteitNaam?:string;
	public metOvererving?:boolean;
}
export class ReportTaskFilter extends FilterBaseOf<string> {
	constructor(type:string, id:string) {
		super(type, id);
	}
}
export class ActiveFilter extends FilterBaseOf<Date> {
	constructor(type:string, date:Date) {
		super(type, date);
	}
}