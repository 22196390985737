import { useEffect, useState } from 'react';
import { GridComponents, PanelMain } from '../../components';
import { BoundListView } from '../../components/EntityListDetail/BoundListView/BoundListView';
import { AgendaItemViewCollectionViewModel } from '../../viewmodels/Collections/AgendaItemViewCollectionViewModel';
import { AppointmentFormDetail } from '../../components/EntityDetailPopup/FormDetail/AppointmentFormDetail';
import AppointmentViewModel from '../../viewmodels/AppointmentViewModel';
import { EntityType } from '../../components/EntityDetailPopup/entityDetailPopup.enums';
import { EditMethod } from '../../components/EntityDetailPopup/entityDetailPopup.interfaces';

export const TestPage2 = (props) => {
	const cvmAgendaItems = AgendaItemViewCollectionViewModel('agenda');
	const vmAppointment = AppointmentViewModel('appointment');
	const [id, setId] = useState<string>('');

	const date1 = new Date('2022-01-25T05:00:00');
	const date2 = new Date('2022-02-16T00:00:00');
	const noa = '184F8EB7-6975-ED11-A816-002248816633';

	useEffect(() => {
		cvmAgendaItems.fetch(date1, date2, noa);
	}, []);

	function handle_BoundListViewMain_onRowClick(tableItem: any) {
		const entityId = tableItem['entity_Id'];
		vmAppointment.read(entityId);
		setId(entityId);
	}

	return (
		<PanelMain>
			<GridComponents.Grid>
				<GridComponents.Column size='half'>
					{/* <BoundListView
						cvm={cvmAgendaItems}
						entityType={EntityType.Appointment}
						// idPropName='entity_Id'
						VMInstanceName='test2Appointment'
						headerText='bogos'
					/> */}
				</GridComponents.Column>
				<GridComponents.Column size='half'></GridComponents.Column>
			</GridComponents.Grid>
		</PanelMain>
	);
};
