import React, { Fragment, useCallback, useState, useEffect } from 'react';

import styles from './buttonLogout.module.scss';

import authProvider from '../../utilities/authProvider';

import { Trans } from 'react-i18next';
import { ButtonDropDown } from '../ButtonDropDown/ButtonDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetCurrentRepresentative, selectCurrentRepresentative, setCurrentRepresentative } from '../../store/reducers/CurrentRepresentativeSlice';
import { IRepresentativeDto } from '../../model/EntityDtos/RepresentativeDto';
import { clearGlobals, selectActiveRepresentatives } from '../../store/reducers/GlobalsSlice';
import { closeDialog, openAlert, openConfirm } from '../Dialog/dialogSlice';
import { Button } from '../Button/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function ButtonLogout() {
	const [userName, setUserName] = useState<string>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const userName = authProvider.getUserName();

		setUserName(userName);
	}, []);

	const logOff = useCallback(() => {
		authProvider.clearTokenCookie();
		dispatch(clearGlobals());
		dispatch(resetCurrentRepresentative());

		navigate(`/login`);
	}, [dispatch, navigate]);

	const openLogoutConfirm = useCallback(() => {
		dispatch(
			openConfirm({
				title: 'confirmLogout',
				dismissButtonPosition: 'bottom',
				confirmButtons: [
					{
						onClick: logOff,
						confirmText: 'logout',
					},
				],
			})
		);
	}, [dispatch, logOff]);

	const closeDialogClickFunction = useCallback(() => {
		dispatch(closeDialog());
	}, [dispatch]);

	const openRepresentativeDialog = useCallback(
		(event) => {
			dispatch(
				openAlert({
					title: 'switchRepresentativeText',
					content: <RepresentativeList onClose={closeDialogClickFunction} />,
				})
			);
		},
		[closeDialogClickFunction, dispatch]
	);

	// const getInitialsFromUserName = useCallback(() => {
	// 	if (userName)
	// 		return userName
	// 			?.split(' ')
	// 			.map((i) => i.charAt(0))
	// 			.join('');
	// 	return '?';
	// }, [userName]);

	const UserIconButton = () => (
		<Fragment>
			<ButtonDropDown
				key='userinitials'
				className={styles.logoutButtonContainer}
				items={[
					{
						key: 'logout',
						content: (
							<React.Fragment>
								<Trans i18nKey='logout' />
							</React.Fragment>
						),
						onClick: openLogoutConfirm,
					},
					{
						key: 'switchRepresentative',
						content: (
							<React.Fragment>
								<Trans i18nKey='switchRepresentativeButton' />
							</React.Fragment>
						),
						onClick: openRepresentativeDialog,
					},
				]}
			>
				<div className={styles.logoutButton}>
					{/* {getInitialsFromUserName()} */}
					<FontAwesomeIcon icon={['fas', 'user-tie']} />
				</div>
			</ButtonDropDown>
		</Fragment>
	);

	return (
		<React.Fragment>
			<UserIconButton />
		</React.Fragment>
	);
}

function RepresentativeList(props) {
	const dispatch = useAppDispatch();
	const representatives: IRepresentativeDto[] = useSelector(selectActiveRepresentatives);

	const currentRepresentative = useAppSelector(selectCurrentRepresentative);
	const currentRepIndex = representatives.findIndex((representative) => representative.id === currentRepresentative?.id);

	const [selectedRep, setSelectedRep] = useState<number | undefined>(currentRepIndex > -1 ? currentRepIndex : undefined);

	const onClose = props.onClose;
	const setActiveRep = useCallback(
		(representative) => {
			dispatch(setCurrentRepresentative(representative));
			onClose();
		},
		[dispatch, onClose]
	);

	const resetActiveRep = useCallback(() => {
		dispatch(resetCurrentRepresentative());
		onClose();
	}, [dispatch, onClose]);

	const setRepButtonClick = useCallback(() => {
		if (selectedRep !== undefined) setActiveRep(representatives[selectedRep]);
		else resetActiveRep();
	}, [selectedRep, setActiveRep, resetActiveRep, representatives]);

	const selectRepClick = useCallback(
		(index: number) => () => {
			if (index === selectedRep) setSelectedRep(undefined);
			else setSelectedRep(index);
		},
		[selectedRep]
	);

	const renderRepList = () => {
		return (
			<React.Fragment>
				{representatives &&
					representatives.map((rep, i) => {
						let icon = 'fa-regular fa-user-tie';
						if (rep.id === authProvider.getUserId()) icon = 'fa-solid fa-user-tie';

						return (
							<tr
								key={i}
								onClick={selectRepClick(i)}
								className={`${selectedRep === i && styles.selected}`}
							>
								<td>{rep.fullName}</td>
								<td className={styles.codeCell}>{rep.code}</td>
								<td className={styles.iconCell}>
									<FontAwesomeIcon icon={icon as IconProp} />
								</td>
							</tr>
						);
					})}
			</React.Fragment>
		);
	};

	let setRepButtonText = `${t('setRepresentativeButton')} ${t('myself')}`;
	if (selectedRep !== undefined) {
		if (representatives[selectedRep].id !== authProvider.getUserId())
			setRepButtonText = `${t('setRepresentativeButton')} ${representatives[selectedRep].code}`;
	}

	return (
		<React.Fragment>
			<table className={styles.repList}>
				<thead>
					<tr>
						<th>
							<Trans i18nKey='name' />
						</th>
						<th className={styles.codeCell}>
							<Trans i18nKey='abbreviationHeaderText' />
						</th>
						<th className={styles.iconCell} />
					</tr>
				</thead>
				<tbody>{renderRepList()}</tbody>
			</table>
			<div className={styles.repListActions}>
				<Button
					i18nKey='cancel'
					onClick={() => dispatch(closeDialog())}
				/>
				<Button
					i18nKey='resetRepresentativeButton'
					onClick={setRepButtonClick}
					theme='confirm'
					width={200}
					textAlign='left'
				>
					{setRepButtonText}
				</Button>
			</div>
		</React.Fragment>
	);
}
