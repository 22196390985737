import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { Appointment } from '../../../models/Model/Appointment';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { updateCompanies } from '../../../store/reducers/CompanySlice';
import { stringJoin } from './_utility';
import { DateTimeType } from '../../InputFields/inputField.types';
import { FormDetail } from './_FormDetail';
import { InfoGroup } from '../../InfoBlocks';
import { EntityType as GlobalEntityType } from '../../../globals/enums';
import { extendedType } from '../../../viewmodels/AppointmentViewModel';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { FormHeaderAppointment } from './Headers/FormHeaderAppointment';
import { AppointmentFinishPopup } from '../../popups/AppointmentFinishPopup/AppointmentFinishPopup';

export interface IAppointFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<Appointment>;
	isAgenda: boolean;
}

export const AppointmentFormDetail = (props: IAppointFormDetailProps) => {
	const [isAppointment, setIsAppointment] = useState(false);
	const [appointmentFinishId, setAppointmentFinishId] = useState<string>();
	//TODO: Baseform?
	const p = props.vm.getProperties();
	const e = (p: string) => stringJoin(props.vm.getPropertyErrors(p));

	const dispatch = useAppDispatch();

	const titleDetails = useCallback(() => {
		if (isAppointment === false) return 'activityDetails';
		return 'appointmentDetails';
	}, [isAppointment]);

	const hasAppointmentDescriptionModule = hasModule(moduleNames.appointmentDescription);

	const isCompanyDropdownRowReadOnly = useCallback(() => {
		//From company (popup)
		if (props.isAgenda === false) return props.id !== '';

		//Direct Agenda (popup)
		if (props.isEditing) return false;
		return true;
	}, [props.id, props.isAgenda, props.isEditing]);

	const appointmentFinishClickHandler = useCallback(() => {
		setAppointmentFinishId(props.id);
	}, [props.id]);

	const appointmentFinishCloseHandler = useCallback(() => {
		setAppointmentFinishId(undefined);

		props.vm.read(props.id);
	}, [props.id, props.vm]);

	const vmModel = props.vm.model;
	const setIsEditing = props.setIsEditing;

	useEffect(() => {
		if (vmModel === undefined) return;

		if (vmModel[extendedType] === GlobalEntityType.activity.toString()) {
			setIsAppointment(false);
			setIsEditing(true);
			return;
		}

		setIsAppointment(true);
	}, [vmModel, setIsEditing]);

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<Fragment>
			<FormDetail
				vm={props.vm}
				headerText={props.vm.getProperty(p.subject)}
				editing={props.isEditing}
				setEditing={props.setIsEditing}
				closeDetailPopup={props.closeDetailPopup}
				iconName="calendar-days"
				header={
					isAppointment ? (
						<FormHeaderAppointment
							vm={props.vm}
							editing={props.isEditing}
							setEditing={props.setIsEditing}
							closeDetailPopup={props.closeDetailPopup}
							appointmentFinishClickHandler={appointmentFinishClickHandler}
							editMethod={props.editMethod}
						/>
					) : undefined
				}
			>
				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('connection')}
				>
					<BoundInputRows.PresetDropDownRow
						label={t('company')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.company_Id}
						DDEntityType={DropdownEntityType.companies}
						readonly={isCompanyDropdownRowReadOnly()}
						errorMessage={e(p.company_Id)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('appointmentAttributes.type')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.appointmentType_Id}
						DDEntityType={DropdownEntityType.appointmentTypes}
						readonly={!props.isEditing}
						errorMessage={e(p.appointmentType_Id)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('appointmentAttributes.contactWith')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.contactWith_Id}
						DDEntityType={DropdownEntityType.contact}
						readId={props.vm.getProperty('company_Id')}
						readonly={!props.isEditing}
						errorMessage={e(p.contactWith_Id)}
					/>
				</InfoGroup>

				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t(titleDetails())}
				>
					{/* todo: DATE, STARTTIME & ENDTIME structure instead of startdatetime & enddatetime */}
					<BoundInputRows.DateTimeRow
						label={t('appointmentAttributes.startDateTime')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.startDateTime}
						type={DateTimeType['datetime-local']}
						readonly={!props.isEditing}
						errorMessage={e(p.startDateTime)}
					/>
					<BoundInputRows.DateTimeRow
						label={t('appointmentAttributes.endDateTime')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.endDateTime}
						type={DateTimeType['datetime-local']}
						readonly={!props.isEditing}
						errorMessage={e(p.endDateTime)}
					/>

					<BoundInputRows.BooleanRow
						label={t('appointmentAttributes.isClosed')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.isClosed}
						readonly
						errorMessage={e(p.isClosed)}
						booleanLabels={[t('yes'), t('no')]}
						noDeleteButton
					/>

					<BoundInputRows.BooleanRow
						label={t('appointmentAttributes.isFollowUp')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.isFollowUp}
						readonly={!props.isEditing}
						errorMessage={e(p.isFollowUp)}
						booleanLabels={[t('yes'), t('no')]}
						noDeleteButton
					/>
					<BoundInputRows.TextRow
						label={t('appointmentAttributes.location')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.location}
						readonly={!props.isEditing}
						errorMessage={e(p.location)}
					/>
					<BoundInputRows.TextRow
						label={t('appointmentAttributes.subject')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.subject}
						readonly={!props.isEditing}
						errorMessage={e(p.subject)}
					/>
					{hasAppointmentDescriptionModule && (
						<BoundInputRows.TextareaRow
							label={t('appointmentAttributes.description')}
							vm={props.vm}
							model={props.vm.model}
							modelValuePropertyName={p.description}
							readonly={!props.isEditing}
							errorMessage={e(p.description)}
						/>
					)}
				</InfoGroup>

				<CustomFieldEditor
					vm={props.vm}
					editing={props.isEditing}
				/>
			</FormDetail>
			{appointmentFinishId !== undefined && vmModel?.company_Id !== null ? (
				<AppointmentFinishPopup
					id={appointmentFinishId}
					companyId={vmModel!.company_Id}
					closeFunction={appointmentFinishCloseHandler}
				/>
			) : null}
		</Fragment>
	);
};
