import { api } from '../../api/_Executor';
import { PrioritizedTaskQuery } from '../../business/Query/PrioritizedTaskQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { TaskTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { Settings } from '../../globals/settings';
import { PrioritizedTaskView } from '../../models/BusinessObjects_View/PrioritizedTaskView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

export enum PrioritizedTaskCollectionMode {
	open = 0,
	closed = 1,
	delegatedOpen = 2,
	delegatedClosed = 3,
}

export function PrioritizedTaskViewCollectionViewModel<T = PrioritizedTaskView, Q extends BaseQuery = PrioritizedTaskQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean,
	take: number = Settings.PAGINATION_DEFAULT_TAKE,
	defaultMode: number = 0
): ICollectionViewModel<T, Q> {
	function switchMode(modus: number, query: PrioritizedTaskQuery) {
		switch (modus) {
			case PrioritizedTaskCollectionMode.open: {
				//Open
				query.isComplete = false;
				query.queryDelegateTasks = false;
				break;
			}
			case PrioritizedTaskCollectionMode.closed: {
				query.isComplete = true;
				query.queryDelegateTasks = false;
				break;
			}
			case PrioritizedTaskCollectionMode.delegatedOpen: {
				query.isComplete = false;
				query.queryDelegateTasks = true;
				break;
			}
			case PrioritizedTaskCollectionMode.delegatedClosed: {
				query.isComplete = true;
				query.queryDelegateTasks = true;
				break;
			}
		}
	}

	const apiReader = api.prioritizedTask.viewAsync;
	const query = new PrioritizedTaskQuery() as Q;
	const table = TaskTableDefinition;
	const canSearch: boolean = true;
	const canSwitch: boolean = true;
	const collectionMode = PrioritizedTaskCollectionMode;

	const cvm = BaseViewCollectionViewModel<T, Q>(
		instanceName,
		apiReader,
		ignoreRepresentative_id,
		query,
		switchMode,
		'task_Id',
		'company_Id',
		false,
		take,
		defaultMode
	);

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,
	};
}

// import { api } from "../../api/_Executor";
// import { PrioritizedTaskQuery } from "../../business/Query/PrioritizedTaskQuery";
// import { TaskQuery } from "../../business/Query/TaskQuery";
// import { PrioritizedTaskView } from "../../models/BusinessObjects_View/PrioritizedTaskView";
// import { UserManager } from "../../utilities/UserManager";
// import { BaseViewCollectionViewModel } from "./_BaseViewCollectionViewModel";

// export enum PrioritizedTaskCollectionMode {
// 	Open = 0,
// 	Closed = 1,
// 	DelegatedOpen = 2,
// 	DelegatedClosed = 3,
// }

// export function PrioritizedTaskViewCollectionViewModel(instanceName:string) {
// 	function switchMode(modus:number, query:PrioritizedTaskQuery) {
// 		query.representative_Id = cm.getRepresentativeId();

// 		switch (modus) {
// 			case PrioritizedTaskCollectionMode.Open: {
// 				//Open
// 				query.isComplete = false;
// 				query.queryDelegateTasks = false;
// 				break;
// 			}
// 			case PrioritizedTaskCollectionMode.Closed: {
// 				query.isComplete = true;
// 				query.queryDelegateTasks = false;
// 				break;
// 			}
// 			case PrioritizedTaskCollectionMode.DelegatedOpen: {
// 				query.isComplete = false;
// 				query.queryDelegateTasks = true;
// 				break;
// 			}
// 			case PrioritizedTaskCollectionMode.DelegatedClosed: {
// 				query.isComplete = true;
// 				query.queryDelegateTasks = true;
// 				break;
// 			}
// 		}
// 	}

// 	const switchModeFunc = switchMode;
// 	const apiReader = api.prioritizedTask.viewAsync;
// 	const query = new PrioritizedTaskQuery();

// 	const cvm = BaseViewCollectionViewModel<PrioritizedTaskView, PrioritizedTaskQuery>(
// 		instanceName,
// 		apiReader,
// 		switchModeFunc,
// 		query,
// 	);

// 	return {
// 		switchMode: cm.doSwitchMode,
// 		read: cm.doRead,
// 		pageNext: cm.doPageNext,
// 		search: cm.doSearch,
// 		refresh: cm.doRefresh,

// 		items: cm.items,
// 		mode: cm.mode,
// 		searchTerm: cm.searchTerm,
// 		query: cm.query,

// 		instanceName
// 	}
// }
