import styles from './authentication.module.scss';
import React, { Component } from 'react';
import { faUser } from '@fortawesome/pro-regular-svg-icons';

import { t } from 'i18next';
import { Trans, withTranslation } from 'react-i18next';
import { ILoginResetState } from '../../globals/types';
import { Button } from '../../components';
import Alert from '../../components/LoginPanel/Alert';
import IconInput from '../../components/LoginPanel/IconInput';
import { LoginLayout } from '../../components/LoginPanel/LoginLayout';
import { Languages } from '../../localization/I18n';
import { withRouter } from '../../compat/RouterCompat';

class LoginResetComponent extends Component<any, ILoginResetState> {
	constructor(props) {
		super(props);
		this.state = {
			UserName: '',
			isLoaded: true,
			isLoading: false,
			errors: {
				httpError: '',
			},
		};
		this.startRecoveryClick = this.startRecoveryClick.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	changeHandler(event) {
		this.setState({
			UserName: event.target.value,
		});
	}

	onKeyDown(e) {
		if (e.charCode === 13) {
			this.startRecoveryClick(this.state.UserName);
		}
	}

	async startRecoveryClick(userName) {
		this.setState({
			isLoading: true,
		});
		try {
			let culture = 'nl-NL';
			const currentLanguage = Languages.find(
				(Language) => Language.fullLocale === this.props.i18n.language
			);
			if (currentLanguage) culture = currentLanguage.fullLocale;

			const url = `api/RecoveryClient/StartRecovery/${userName}?culture=${culture}`;

			fetch(url, {
				method: 'GET',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			}).then((result) => {
				this.setState({
					isLoading: false,
				});
				if (result.ok) {
					this.props.navigate(`/Authentication/SuccessfulRequest`);
				} else if (result.status === 500) {
					this.setState({
						isLoading: false,
						errors: {
							...this.state.errors,
							httpError: t('internalServerError'),
						},
					});
				}
			});
		} catch (e) {
			console.error(e);
			this.setState({
				isLoading: false,
				errors: {
					...this.state.errors,
					httpError: t('internalServerError'),
				},
			});
		}
	}

	render() {
		return (
			<LoginLayout>
				{this.state.errors.httpError && (
					<Alert severity='error'>
						{this.state.errors.httpError}
					</Alert>
				)}
				<h5 className={styles.label}>
					<Trans i18nKey='enterUsername' />
				</h5>
				<span className={styles.label}>
					<Trans i18nKey='loginResetMainText' />
				</span>
				<IconInput
					icon={faUser}
					type='text'
					placeholder={t('enterUsername') as string}
					onChange={this.changeHandler}
					onKeyPress={this.onKeyDown}
					value={this.state.UserName}
					autoComplete='username'
				/>
				<Button
					onClick={() => this.startRecoveryClick(this.state.UserName)}
					theme='confirm'
					loading={this.state.isLoading}
					i18nKey='sendRecoveryLink'
				/>
			</LoginLayout>
		);
	}
}

export default withRouter(withTranslation('loginReset')(LoginResetComponent));
