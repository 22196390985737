import { useCallback, useState } from 'react';

import { GridComponents, PanelMain } from '../../components';
import { DateTimeInput } from '../../components/InputFields/DateTimeInput';
import { DateTimeType } from '../../components/InputFields/inputField.types';

export function TestPage() {
	const [weekValue, setWeekValue] = useState('');

	const onChange = useCallback((newValue) => {
		console.log(newValue);

		setWeekValue(newValue);
	}, []);

	return (
		<PanelMain>
			<GridComponents.Column size="threeFourths">
				<DateTimeInput
					value={weekValue}
					onChange={onChange}
					type={DateTimeType.week}
				/>
			</GridComponents.Column>
		</PanelMain>
	);
}
