import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEntityFormHeaderProps } from '../../entityDetailPopup.interfaces';
import { ButtonsGeneral } from './Buttons/_ButtonsGeneral';
import styles from '../formDetail.module.scss';
import { FormHeaderBase } from './_FormHeaderBase';
import { faSquare, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { t } from 'i18next';

export function FormHeaderTask(props: IEntityFormHeaderProps) {
	const completeTaskIcon = props.vm.getProperty('isCompleted') ? faSquareCheck : faSquare;

	return (
		<FormHeaderBase
			{...props}
			iconName="list-check"
			headerText={props.vm.getProperty('subject')}
		>
			<ButtonsGeneral
				vm={props.vm}
				editing={props.editing}
				setEditing={props.setEditing}
				closeDetailPopup={props.closeDetailPopup}
			>
				<FontAwesomeIcon
					className={`${styles.formButton} ${styles.withDivider}`}
					icon={completeTaskIcon}
					color={!props.vm.isValid ? 'gray' : ''}
					onClick={props.vm['toggleTaskCompleteCommand']}
					size="xl"
					title={t('completeTask')!}
				/>
			</ButtonsGeneral>
		</FormHeaderBase>
	);
}
