/* UNMODIFIED */
import { EntityPropertyValidationAttribute, IEntityPropertyValidationAttribute } from "./EntityPropertyValidationAttribute";

export interface INumericRangeEntityPropertyValidationAttribute extends IEntityPropertyValidationAttribute {
	minimum: number | null;
    maximum: number | null;
}

export class NumericRangeEntityPropertyValidationAttribute extends EntityPropertyValidationAttribute implements INumericRangeEntityPropertyValidationAttribute {
	public minimum: number | null = null;
    public maximum: number | null = null;
}
