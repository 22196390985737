import styles from './progressBar.module.scss';

export interface IProgressBarProps {
	percentage: number;
	className?: string;
}

export function ProgressBar(props: IProgressBarProps) {
	return (
		<div className={`${styles.wrapper} ${props.className}`}>
			<div
				className={styles.progress}
				style={{ width: `${props.percentage}%` }}
			/>
		</div>
	);
}
