/* UNMODIFIED */
import { Annotation, IAnnotation } from "./Annotation";
import { ICompany } from "./Company";

export interface ICompanyAnnotation extends IAnnotation {
	company: ICompany | null;
    company_Id: string | null;
}

export class CompanyAnnotation extends Annotation implements ICompanyAnnotation {
	public company: ICompany | null = null;
    public company_Id: string | null = null;
}
