import React, { Component, Fragment } from 'react';

import styles from '../../settingsPage.module.scss';

import { FieldDefinitionLayerProps } from './types';
//import Enums from '../../enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DropZone extends Component<
	{
		dragging: boolean;
		dropHandler: (e: React.DragEvent) => void;
		children?: React.ReactNode;
	},
	{ dragOver: boolean }
> {
	constructor(props) {
		super(props);

		this.state = { dragOver: false };
	}

	componentDidUpdate(prevProps) {
		if (prevProps.dragging !== this.props.dragging)
			this.setState({
				dragOver: false,
			});
	}

	render() {
		return (
			<div
				className={`${styles.dropZone} 
					${this.props.dragging && styles.dragging} 
					${this.state.dragOver && styles.dragOver}`}
				onDrop={this.props.dropHandler}
				onDragOver={(event) => {
					event.preventDefault();
					if (!this.state.dragOver) this.setState({ dragOver: true });
				}}
				onDragExit={() => {
					if (this.state.dragOver) this.setState({ dragOver: false });
				}}
			>
				<div className={styles.indicator} />
				{this.props.children}
			</div>
		);
	}
}

export class FieldDefinitionLayer extends Component<
	FieldDefinitionLayerProps,
	{
		open: boolean;
		draggingIndex?: number;
	}
> {
	constructor(props) {
		super(props);

		this.state = {
			open: true,
		};
	}

	onTitleClick = () => {
		this.setState({ open: !this.state.open });
	};

	onDeleteButtonClick = (index: number) => (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		this.props.onDeleteButtonClick(index)();
	};

	onDragSectionDragStart = (fieldDefinitionIndex: number) => (e: React.DragEvent) => {
		e.stopPropagation();
		this.setState({ draggingIndex: fieldDefinitionIndex });
		this.props.onFDDragStart(fieldDefinitionIndex);
	};

	onDragSectionDragEnd = (e: React.DragEvent) => {
		e.stopPropagation();
		this.setState({ draggingIndex: undefined });
		this.props.onFDDragStop();
	};

	dropZoneDropHandler = (dropZoneIndex: number) => (e: React.DragEvent) => {
		e.stopPropagation();
		this.setState({ draggingIndex: undefined });
		this.props.onFDDrop(dropZoneIndex);
	};

	render() {
		return (
			<Fragment>
				{this.props.fieldDefinitions.map((fieldDefinition, key) => {
					if (fieldDefinition.method === 'delete') return null;
					else {
						const iconProp = this.props.types.find((type) => type.name === fieldDefinition.$type)?.icon;
						return (
							<DropZone
								key={key}
								dragging={this.props.dragging !== undefined || this.props.isDragging}
								dropHandler={this.dropZoneDropHandler(key)}
							>
								<div
									className={`${styles.component} ${fieldDefinition.invalid && styles.invalid} ${this.props.isDragging && styles.dragging}`}
									draggable
									onDragStart={this.onDragSectionDragStart(key)}
									onDragEnd={this.props.onFDDragStop}
									onClick={this.props.onItemClick(key)}
								>
									{iconProp && (
										<div className={styles.icon}>
											<FontAwesomeIcon icon={iconProp} />
										</div>
									)}
									<div className={styles.text}>{fieldDefinition.label}</div>
									<div onClick={this.onDeleteButtonClick(key)}>
										<FontAwesomeIcon icon='trash' />
									</div>
									<div className={styles.grip}>
										<FontAwesomeIcon icon='grip-vertical' />
									</div>
								</div>
							</DropZone>
						);
					}
				})}
				<DropZone
					dragging={this.props.dragging !== undefined || this.props.isDragging}
					dropHandler={this.dropZoneDropHandler(this.props.fieldDefinitions.length)}
				/>
			</Fragment>
		);
	}
}
