import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import currentRepresentative from './reducers/CurrentRepresentativeSlice';
import companies, { updateCompanies } from './reducers/CompanySlice';

import globals, {
	updateActiveRepresentatives,
	updateAddressTypes,
	updateAppointmentTypes,
	updateEntityValidation,
	updateCompanyGroups,
	updateCountries,
	updateFieldDefinitions,
	updateOpportunityStatusReasons,
	updateRepresentatives,
	updateVisitingFrequencies,
	updateOrganizationSettings,
} from './reducers/GlobalsSlice';

import dialog from '../components/Dialog/dialogSlice';

const reducers = combineReducers({
	currentRepresentative,
	companies,
	globals,
	dialog,
});

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['counter/openConfirm'],
			},
		}),
});

export const updateGlobals = async () => {
	const dispatches = [
		store.dispatch(updateCompanies()),
		store.dispatch(updateRepresentatives()),
		store.dispatch(updateActiveRepresentatives()),
		store.dispatch(updateVisitingFrequencies()),
		store.dispatch(updateAppointmentTypes()),
		store.dispatch(updateCountries()),
		store.dispatch(updateCompanyGroups()),
		store.dispatch(updateFieldDefinitions()),
		store.dispatch(updateAddressTypes()),
		store.dispatch(updateOpportunityStatusReasons()),
		store.dispatch(updateEntityValidation()),
		store.dispatch(updateVisitingFrequencies()),
		store.dispatch(updateOrganizationSettings()),
	];

	Promise.all(dispatches);
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
