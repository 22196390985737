import { Component, Fragment } from 'react';

import { FieldDefinitionDetailPanelProps, GroupDetailPanelProps } from './types';
import { InfoGroup } from '../../../components/InfoBlocks';

import { FieldDefinitionForm, GroupForm } from './DetailForms';
import { t } from 'i18next';

export class GroupDetails extends Component<GroupDetailPanelProps> {
	render() {
		return (
			<Fragment>
				<InfoGroup
					title={t('settings')}
					open={true}
					visible={true}
					theme='gray'
				>
					<GroupForm
						navigate={this.props.navigate}
						location={this.props.location}
						match={this.props.match}
						group={this.props.group}
						onChange={this.props.onChange}
					/>
				</InfoGroup>
			</Fragment>
		);
	}
}

export class FieldDefinitionDetails extends Component<FieldDefinitionDetailPanelProps> {
	render() {
		return (
			<Fragment>
				<InfoGroup
					title={t('settings')}
					open={true}
					visible={true}
					theme='gray'
				>
					<FieldDefinitionForm
						navigate={this.props.navigate}
						location={this.props.location}
						match={this.props.match}
						fieldDefinition={this.props.fieldDefinition}
						onChange={this.props.onChange}
						onBooleanChange={this.props.onBooleanChange}
						onListItemDeleteButtonClick={this.props.onListItemDeleteButtonClick}
						onListItemMoveClick={this.props.onListItemMoveClick}
					/>
				</InfoGroup>
			</Fragment>
		);
	}
}
