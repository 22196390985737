import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { Representative } from '../models/Model/Representative';
import { BaseViewModel } from './_BaseViewModel';
import { IViewModel } from './_viewModel.interfaces';

//GEDEELTELIJK GETEST
//Eventueel dit viewModel uitbreiden met:
//CurrentUser, LoggedOnUser en SwitchRepresentativeCommand (zie Mobile)
export default function RepresentativeViewModel<T = Representative>(instanceName: string): IViewModel<T> {
	const entityType = EntityType.opportunity;
	const functionRead = api.representative.getByIdAsync;
	const functionUpdate = api.representative.updateAsync;
	const functionDelete = api.representative.deleteByIdAsync;

	const vm = BaseViewModel<T>(instanceName, entityType, functionRead, functionUpdate, functionDelete);

	function generateModelInstance(id?: string, representativeId?: string): T {
		const m = new Representative();
		return m as T;
	}

	function create(id?: string, representativeId?: string, params?: any) {
		return vm.doCreate(generateModelInstance(id, representativeId));
	}

	async function read(id) {
		return await vm.doRead(id);
	}

	async function update() {
		return await vm.doUpdate();
	}

	async function del(clearModel: boolean = true) {
		return await vm.doDelete(clearModel);
	}

	return {
		entityType,
		model: vm.model,
		fieldDefinitions: vm.fieldDefinitions,
		isValid: vm.isValid,
		isDirty: vm.isDirty,
		hasSavedChanges: vm.hasSavedChanges,
		instanceName: instanceName,
		create,
		read,
		update,
		del,
		setProperty: vm.setProperty,
		getProperty: vm.getProperty,
		isPropertyValid: vm.isPropertyValid,
		isPropertyReadOnly: vm.isPropertyReadOnly,
		getPropertyErrors: vm.getPropertyErrors,
		getProperties: vm.getProperties,
		getId: vm.getId,
		getType: vm.getType,
		getTypeCased: vm.getTypeCased,
	};
}
