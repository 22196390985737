import React, { Component, MouseEvent } from 'react';
import { Navigate } from 'react-router-dom';

import styles from './localeMenu.module.scss';
import mainstyles from '../../css/pageElements.module.scss'; //TODO: move relevant styling

import { ILocaleMenuProps } from '../../globals/types';
import { useTranslation } from 'react-i18next';
import { ButtonDropDown } from '../ButtonDropDown/ButtonDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Languages } from '../../localization/I18n';

export class WrappedLocaleMenu extends Component<ILocaleMenuProps> {
	render() {
		return (
			<div className={styles.wrappedLocaleMenu}>
				<LocaleMenu />
			</div>
		);
	}
}

function LocaleMenu() {
	const { i18n } = useTranslation();

	const menuItems = Languages.map((Language, index) => {
		return {
			key: index.toString(), //FIXME: Allow both numbers and strings as key
			content: (
				<React.Fragment>
					<span className={styles.flag}>
						<Language.Flag title={Language.name} />
					</span>
					&nbsp;
					{Language.name}
				</React.Fragment>
			),
			onClick: () => i18n.changeLanguage(Language.locale),
		};
	});

	return (
		<React.Fragment>
			<ButtonDropDown
				key='Test'
				className={styles.localeMenu}
				items={menuItems}
			>
				<span className={mainstyles.notranslate}>
					<FontAwesomeIcon icon={['fal', 'globe']} />
					<div className={styles.languageSelector}>
						<div className={styles.language}>
							{
								Languages.find(
									(l) =>
										l.locale === i18n.language.slice(0, 2)
								)?.abbreviation
							}
						</div>

						<span className={styles.downIcon}>
							<FontAwesomeIcon icon={['fal', 'angle-down']} />
						</span>
					</div>
				</span>
			</ButtonDropDown>
		</React.Fragment>
	);
}

export default LocaleMenu;
