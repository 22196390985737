import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import styles from './infoBlocks.module.scss';

export class InfoHeader extends React.Component<
	{
		title: string;
		open: boolean;
		theme?: 'light' | 'gray';
		onTitleClick: (event) => void; //TODO: event type handling
	},
	{}
> {
	themeClasses = {
		light: 'bgLight',
		gray: 'bgGray',
	};

	render() {
		const themeClass = this.themeClasses[this.props.theme || 'bgLight'];

		return (
			<div
				className={`
                    ${styles.infoHeader} 
                    ${this.props.open && styles.up} 
                    ${styles[themeClass]}
                `}
				onClick={this.props.onTitleClick}
			>
				<div className={`${styles.title} text-capitalize`}>
					{this.props.title}
				</div>
				<div className={styles.button}>
					<i
						className={
							this.props.open
								? 'fal fa-arrow-alt-circle-up'
								: 'fal fa-arrow-alt-circle-down'
						}
					></i>
				</div>
				<div className={styles.button}>
					<FontAwesomeIcon icon={['fal',
						this.props.open ?
							'arrow-alt-circle-up' :
							'arrow-alt-circle-down'
					]} />
				</div>
			</div>
		);
	}
}
