/* GLOBAL */

export enum RequestMethods {
	GET = 'GET',
	POST = 'POST',
	DELETE = 'DELETE',
	PUT = 'PUT',
}

export enum LoadEventTypes {
	loadBegin = 'loadBegin',
	loadEnd = 'loadEnd',
	loadReset = 'loadReset',
}

export enum ThemeColorType {
	blisssBlue = 'blue',
	blisssDarkBlue = 'darkblue',
	blisssOrange = 'orange',
	blisssGreen = 'green',
	blisssRed = 'red',
	white = 'white',
	transparant = 'transparant',
	greyTODO = '#727272', //TODO, find a fitting name
}

export enum ValidationAttributeType {
	stringLengthEntityPropertyValidationAttribute = 'stringLengthEntityPropertyValidationAttribute',
	numericRangeEntityPropertyValidationAttribute = 'NumericRangeEntityPropertyValidationAttribute',
	requiredEntityPropertyValidationAttribute = 'requiredEntityPropertyValidationAttribute',
	requiredIdEntityPropertyValidationAttribute = 'requiredIdEntityPropertyValidationAttribute',
	regexEntityPropertyValidationAttribute = 'regexEntityPropertyValidationAttribute',
	dateTimeStringEntityPropertyValidationAttribute = 'DateTimeStringEntityPropertyValidationAttribute',
}

export enum FieldAttributeType {
	fieldDefinitionText = 'fieldDefinitionText',
	fieldDefinitionBoolean = 'fieldDefinitionBoolean',
	fieldDefinitionDateTime = 'fieldDefinitionDateTime',
	fieldDefinitionNumeric = 'fieldDefinitionNumeric',
	fieldDefinitionOption = 'fieldDefinitionOption',
	fieldDefinitionSingleOption = 'fieldDefinitionSingleOption',
	fieldDefinitionMultipleOption = 'fieldDefinitionMultipleOption',
}

export enum ValidationErrors {
	defaultRequired = 'SFWS_DefaultRequiredMessage',
	defaultRequiredId = 'SFWS_DefaultRequiredIdMessage',
	defaultRange = 'SFWS_DefaultRangeMessage',
	defaultRegex = 'SFWS_DefaultRegexMessage',
	defaultDateTime = 'SFWS_DefaultDateTimeStringMessage',
	defaultEmail = 'SFWS_DefaultEmailValidationMessage',
}

export enum ThemeColorValue {
	blisssBlue = '#27AAE1',
	blisssDarkBlue = '#1E3768',
	blisssOrange = '#F68B1F',
	blisssGreen = '#21A179',
	blisssRed = '#BB342F',
	white = 'white',
	transparant = 'transparant',
	greyTODO = '#727272', //TODO, find a fitting name
}

//TODO: Make utitlity class together with getAwesomePropName
export enum IconType {
	appointment = 'fal,calendar-days',
	lead = 'fal,building',
	prospect = 'fal,city',
	task = 'fal,list-check',
	opportunity = 'fal,dice-tree',
}

export enum EntityType {
	modelBase = 'modelBase',

	account = 'account',
	activity = 'activity',
	address = 'address',
	addressType = 'addressType',
	annotation = 'annotation',
	appointment = 'appointment',
	appointmentAnnotation = 'AppointmentAnnotation',
	appointmentType = 'appointmentType',
	article = 'article',
	artikelGroep = 'artikelGroep',
	closeAppointmentField = 'closeAppointmentField',
	company = 'company',
	companyAnnotation = 'CompanyAnnotation',
	companyGroup = 'companyGroup',
	companyRepresentative = 'companyRepresentative',
	contact = 'contact',
	country = 'country',
	day = 'day',
	entityAudit = 'entityAudit',
	entitySynchronizationSet = 'entitySynchronizationSet',
	extensionBase = 'extensionBase',
	fieldDefinition = 'fieldDefinition',
	fieldDefinitionBoolean = 'fieldDefinitionBoolean',
	fieldDefinitionDateTime = 'fieldDefinitionDateTime',
	fieldDefinitionMultipleOption = 'fieldDefinitionMultipleOption',
	fieldDefinitionNumeric = 'fieldDefinitionNumeric',
	fieldDefinitionSingleOption = 'fieldDefinitionSingleOption',
	fieldDefinitionText = 'fieldDefinitionText',
	fieldValue = 'fieldValue',
	file = 'file',
	geocode = 'geocode',
	gridView = 'gridView',
	gridViewColumn = 'gridViewColumn',
	lead = 'lead',
	opportunity = 'opportunity',
	opportunityLine = 'opportunityLine',
	opportunityStatusReason = 'opportunityStatusReason',
	organizationSetting = 'organizationSetting',
	postalCodeArea = 'postalCodeArea',
	postalCodePrefix = 'postalCodePrefix',
	reportTask = 'reportTask',
	reportTaskAudit = 'reportTaskAudit',
	reportTaskParameter = 'reportTaskParameter',
	representative = 'representative',
	representativeRole = 'representativeRole',
	synchronizationFallbackInfo = 'synchronizationFallbackInfo',
	synchronizationKeyExtension = 'synchronizationKeyExtension',
	synchronizationEntity = 'SynchronizationEntity',
	synchronizationState = 'synchronizationState',
	synchronizationTask = 'synchronizationTask',
	synchronizationTaskAudit = 'synchronizationTaskAudit',
	synchronizationTaskExtendedProperty = 'synchronizationTaskExtendedProperty',
	task = 'task',
	visitingFrequency = 'visitingFrequency',

	//BUSINESS OBJECTS
	baseQueryModel = 'baseQueryModel',

	accountArea = 'accountArea',
	bulkCreateModel = 'bulkCreateModel',
	companyArea = 'companyArea',
	companyGeocodeArea = 'companyGeocodeArea',
	companyMap = 'companyMap',
	leadArea = 'leadArea',
	prioritizedAccount = 'prioritizedAccount',
	prioritizedAccountInfo = 'prioritizedAccountInfo',
	prioritizedBase = 'prioritizedBase',
	prioritizedCompany = 'prioritizedCompany',
	prioritizedLead = 'prioritizedLead',
	prioritizedOpportunity = 'prioritizedOpportunity',
	prioritizedTask = 'prioritizedTask',
	prioritiziedAccountWeeks = 'prioritiziedAccountWeeks', //NO TYPO!
	quickAppointmentAnnotation = 'quickAppointmentAnnotation',
	reportName = 'reportName',

	quickAnnotation = 'quickAnnotation',
}

export enum CrudType {
	Create,
	Read,
	Update,
	Delete,
}

const enums = {
	IconType: IconType,
	BlisssColorType: ThemeColorType,
};

export default enums;
