import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICompanyDto } from '../../model/EntityDtos/CompanyDto';
import { GetPrioritizedCompanyViews } from '../../api/CompaniesAPI';
import { RootState } from '../store';

const initialState: { companies: ICompanyDto[] } = {
	companies: [],
};

export const updateCompanies = createAsyncThunk('companies/companies', async () => {
	try {
		const res = await GetPrioritizedCompanyViews({ skip: 0, take: 999 });
		if (res.ok) {
			const json = await res.json();

			return json;
		}
	} catch (err) {
		console.error(err);
	}
});

export const companySlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(updateCompanies.fulfilled, (state, action) => {
			state.companies = action.payload;
		});
	},
});

export function selectCompanies(state: RootState) {
	updateCompanies();

	return state.companies;
}

export default companySlice.reducer;
