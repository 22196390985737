/* UNMODIFIED */
import { ModelBase, IModelBase } from '../AbstractModel/ModelBase';
import { IRepresentative } from './Representative';
import { ICompany } from './Company';
import { IOpportunityLine } from './OpportunityLine';
import { OpportunityStatus } from './OpportunityStatus';
import { IOpportunityStatusReason } from './OpportunityStatusReason';

export interface IOpportunity extends IModelBase {
	createdBy: IRepresentative | null;
	createdBy_Id: string | null;
	company: ICompany | null;
	company_Id: string | null;
	subject: string | null;
	description: string | null;
	amount: number;
	estimatedSuccessRate: number;
	estimatedClosingDate: Date | null;
	closedBy: IRepresentative | null;
	closedBy_Id: string | null;
	closeDate: Date | null;
	lines: IOpportunityLine[] | null;
	statusClarification: string | null;
	opportunityStatus: OpportunityStatus | null;
	representative: IRepresentative | null;
	representative_Id: string | null;
	opportunityStatusReason: IOpportunityStatusReason | null;
	opportunityStatusReason_Id: string | null;
	opportunityStatusName: string | null;
}

export class Opportunity extends ModelBase implements IOpportunity {
	public createdBy: IRepresentative | null = null;
	public createdBy_Id: string | null = null;
	public company: ICompany | null = null;
	public company_Id: string | null = null;
	public subject: string | null = null;
	public description: string | null = null;
	public amount: number = 0;
	public estimatedSuccessRate: number = 0;
	public estimatedClosingDate: Date | null = null;
	public closedBy: IRepresentative | null = null;
	public closedBy_Id: string | null = null;
	public closeDate: Date | null = null;
	public lines: IOpportunityLine[] | null = null;
	public statusClarification: string | null = null;
	public opportunityStatus: OpportunityStatus | null = OpportunityStatus.open;
	public representative: IRepresentative | null = null;
	public representative_Id: string | null = null;
	public opportunityStatusReason: IOpportunityStatusReason | null = null;
	public opportunityStatusReason_Id: string | null = null;
	public opportunityStatusName: string | null = null;
}
