export const table = {
	type: 'table',
	title: 'addresses',
	entityName: 'Address',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'code',
			property: {
				name: 'addressType_Code',
			},
		},
		{
			headTitle: 'street',
			property: {
				name: 'street',
			},
		},
		{
			headTitle: 'postalCode',
			property: {
				name: 'postalCode',
			},
		},
		{
			headTitle: 'city',
			property: {
				name: 'city',
			},
		},
		{
			headTitle: 'country',
			property: {
				name: 'country_Name',
			},
		},
	],
	apiUrl: '/Address',
};
