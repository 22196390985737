import React from 'react';
import styles from './searchBar.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

export class SearchBar extends React.Component<
	{
		searchCallback: (searchString: string) => void;
		filterOptions?: any;
		border?: boolean;
		autoFocus?: boolean;
		className?: string;
		noIcon?: boolean;
		searchPlaceholder?: string;
	},
	{
		faIcon: IconProp;
		faStyle: string;
	}
> {
	constructor(props) {
		super(props);

		this.state = {
			faIcon: ['far', 'search'],
			faStyle: styles.nospin,
		};
	}

	clickHandler(e) {
		e.target.focus();
		e.target.select();
	}

	changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.searchCallback(e.target.value);
	};

	spinStart = () => {
		//Maybe should be called via Props instead
		this.setState({ faIcon: ['far', 'spinner'] });
		this.setState({ faStyle: styles.spin });
	};

	spinStop = () => {
		//Maybe should be called via Props instead
		this.setState({ faIcon: ['far', 'search'] });
		this.setState({ faStyle: styles.nospin });
	};

	render() {
		let searchBarClassName = styles.search;
		if (this.props.border) searchBarClassName += ` ${styles.border}`;

		return (
			<div className={`${searchBarClassName} ${this.props.className ?? this.props.className}`}>
				{!this.props.noIcon && (
					<div className={styles.question}>
						<FontAwesomeIcon icon={this.state.faIcon} className={this.state.faStyle} />
					</div>
				)}
				<div className={styles.box}>
					<input
						type="text"
						title={t('search')!}
						onChange={this.changeHandler}
						onClick={this.clickHandler}
						autoFocus={this.props.autoFocus}
						placeholder={this.props.searchPlaceholder ? this.props.searchPlaceholder : ''}
					/>
				</div>
				{this.props.filterOptions !== undefined ? (
					<div className={styles.filter}>
						<FontAwesomeIcon icon={['far', 'filter']} />
					</div>
				) : null}
			</div>
		);
	}
}
