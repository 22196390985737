import { useCallback, useEffect, useState } from 'react';
import { Button, IButtonProps } from '../../../Button/Button';
import styles from './wizardFooter.module.scss';
import { Trans } from 'react-i18next';

export interface IWizardFooterProps {
	step: number;
	i18nKey?: string;
	notesMandatory?: boolean;
	leftButtonClick: () => void;
	rightButtonClick: () => void;
}

interface navButtonText {
	number?: string;
	mainTextI18nKey: string;
	arrow?: boolean;
}

const buttonI18nKeys = ['cancel', 'opportunities', 'annotations', 'tasks', 'appointment', 'finish'];

export function WizardFooter(props: IWizardFooterProps) {
	const [navButtonTextLeft, setButtonTextLeft] = useState<navButtonText>({ mainTextI18nKey: '' });
	const [navButtonTextRight, setButtonTextRight] = useState<navButtonText>({ mainTextI18nKey: '' });

	const generateNavButtonText = useCallback((step: number): navButtonText => {
		const navButtonText: navButtonText = { mainTextI18nKey: buttonI18nKeys[step] };
		const onFirstOrLastStep = step === 0 || step === buttonI18nKeys.length - 1;

		navButtonText.number = !onFirstOrLastStep ? `${step}.` : undefined;
		navButtonText.arrow = !onFirstOrLastStep;

		return navButtonText;
	}, []);

	useEffect(() => {
		const leftStep = props.step;
		const rightStep = props.step + 2;

		setButtonTextLeft(generateNavButtonText(leftStep));
		setButtonTextRight(generateNavButtonText(rightStep));
	}, [generateNavButtonText, props.step]);

	return (
		<div className={styles.container}>
			<div className={styles.footerText}>
				<Trans i18nKey={props.i18nKey} />
			</div>
			<div className={styles.buttons}>
				<Button
					width={200}
					i18nKey={navButtonTextLeft.mainTextI18nKey}
					onClick={props.leftButtonClick}
				>
					{navButtonTextLeft.arrow ? '« ' : null}
					{navButtonTextLeft.number} <Trans i18nKey={navButtonTextLeft.mainTextI18nKey} />
				</Button>
				<Button
					width={200}
					theme="confirm"
					i18nKey={navButtonTextRight.mainTextI18nKey}
					onClick={props.rightButtonClick}
				>
					{navButtonTextRight.number} <Trans i18nKey={navButtonTextRight.mainTextI18nKey} />
					{navButtonTextRight.arrow ? ' »' : null}
				</Button>
			</div>
		</div>
	);
}
