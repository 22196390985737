export const DropDownControl = (props) => {
	return (
		<label>
			{props.label}
			<select value={props.defaultValue} onChange={props.onChange}>
				{props.options?.map((option) => (
					<option value={option.value} key={option.value}>{option.text}</option>
				))}
			</select>
	  </label>
	)
}