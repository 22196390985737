import { Fragment, useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';

import styles from './inputFields.module.scss';
import { InputAction, IGeneralProps } from './InputFieldComponent';

export interface IBooleanInputComponentProps extends IGeneralProps {
	booleanLabels?: [string, string];
}

function BooleanInputComponent(props: IBooleanInputComponentProps) {
	const [booleanValue, setBooleanValue] = useState(props.defaultValue ? props.defaultValue : false);
	useEffect(() => {
		setBooleanValue(props.defaultValue ? props.defaultValue : false);
	}, [props.defaultValue]);
	const booleanClick = useCallback(
		(value: boolean) => () => {
			if (!props.readOnly) setBooleanValue(value);
		},
		[props.readOnly]
	);

	const [labelTrue, labelFalse] = props.booleanLabels ? props.booleanLabels : [t('true'), t('false')];

	return (
		<Fragment>
			<div className={`${styles.boolean} ${props.readOnly && styles.notEditing}`}>
				<div
					className={`${styles.selection} ${booleanValue && styles.active}`}
					onClick={booleanClick(true)}
				>
					{labelTrue}
				</div>
				<div
					className={`${styles.selection} ${!booleanValue && styles.active}`}
					onClick={booleanClick(false)}
				>
					{labelFalse}
				</div>
				<input
					name={props.name}
					required={props.required}
					type='hidden'
					value={booleanValue.toString()}
					defaultValue={props.defaultValue}
					onChange={props.onChange}
				/>
			</div>
			<InputAction InputActionObject={props.InputActionObject} />
		</Fragment>
	);
}

export default BooleanInputComponent;
