import styles from './agenda.module.scss';
import colors from '../../../css/definition.colors.scss';

import { useState, useEffect, useCallback, Fragment } from 'react';
import {
	MonthView,
	Scheduler,
	SchedulerDateChangeEvent,
	SchedulerItem,
	SchedulerItemContent,
	SchedulerItemProps,
	SchedulerSlot,
	SchedulerSlotProps,
	SchedulerViewChangeEvent,
	WeekView,
	WorkWeekView,
} from '@progress/kendo-react-scheduler';
import { GridComponents, PanelMain } from '../../../components';
import { SchedulerHeader } from './CustomComponents';
import { AgendaItemViewCollectionViewModel } from '../../../viewmodels/Collections/AgendaItemViewCollectionViewModel';
import { CalendarItemView } from '../../../models/BusinessObjects_View/CalendarItemView';
import { EntityDetailPopupConfigured } from '../../../components/EntityDetailPopup/EntityDetailPopup';
import { EntityType } from '../../../components/EntityDetailPopup/entityDetailPopup.enums';
import { openAlert } from '../../../components/Dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { EditMethod } from '../../../components/EntityDetailPopup/entityDetailPopup.interfaces';
import { useAppSelector } from '../../../store/hooks';
import { IntlProvider, load } from '@progress/kendo-react-intl';
import { useTranslation } from 'react-i18next';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import nlNumbers from 'cldr-numbers-full/main/nl/numbers.json';
import nlLocalCurrency from 'cldr-numbers-full/main/nl/currencies.json';
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json';
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json';
import nlTimeZoneNames from 'cldr-dates-full/main/nl/timeZoneNames.json';
import { AgendaItemView } from '../../../models/BusinessObjects_View/AgendaItemView';

enum CalendarItemEntityTypes {
	'Appointment',
	'Activity',
}

interface ISchedulerData {
	id: string | null;
	start: Date | null;
	startTimezone?: string | null;
	end: Date | null;
	endTimezone?: string | null;
	isAllDay?: boolean | null;
	content: string[];
	recurrenceRule?: string | null;
	recurrenceId?: number | null;
	recurrenceExceptions?: string | null;
	roomId?: number | null;
	ownerID?: number | null;
	personId?: number | null;
	typeOfEntity: string | null;
	backgroundColor: string;
	isRecurring: boolean;
}

function generateTimeRangeString(start: Date, end: Date) {
	const startString = `${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')}`;
	const endString = `${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}`;

	return `${startString} - ${endString}`;
}

function ConvertAgendaItemViewsToSchedulerData(agendaItemViews: AgendaItemView[]): ISchedulerData[] {
	const schedulerData: ISchedulerData[] = agendaItemViews.map((agendaItem) => {
		const start = new Date(agendaItem.startDateTime ? agendaItem.startDateTime : '');
		const end = new Date(agendaItem.endDateTime ? agendaItem.endDateTime : '');
		if (start.valueOf() === end.valueOf()) {
			end.setSeconds(end.getSeconds() + 1);
		}

		const content = [agendaItem.company_Name, agendaItem.appointmentType_Name, agendaItem.subject, agendaItem.location];
		const filteredContent = content.filter((element) => element !== null) as string[];

		let backgroundColor;
		if (agendaItem.typeOfEntity === 'Appointment')
			if (agendaItem.isClosed) backgroundColor = '#21A179';
			else backgroundColor = '#27AAE1';
		else if (agendaItem.typeOfEntity === 'Activity') backgroundColor = '#616161';

		const data: ISchedulerData = {
			id: agendaItem.entity_Id,
			start,
			end,
			content: filteredContent,
			backgroundColor,
			typeOfEntity: CalendarItemEntityTypes[agendaItem.typeOfEntity as string],
			isRecurring: agendaItem.isRecurring ? true : false,
		};

		return data;
	});

	return schedulerData;
}

function getRangeFromDate(date: Date, rangeType: 'month' | 'week' | 'work-week') {
	switch (rangeType) {
		case 'month':
			const start = new Date(date.getFullYear(), date.getMonth(), -6);
			const end = new Date(date.getFullYear(), date.getMonth() + 1, 6);
			return [start, end];

		case 'work-week':
		case 'week':
			const dateCopyWeek = new Date(date);
			//get the current date and subtract the current number of days into the week from it
			const weekStartOffset = rangeType === 'work-week' ? 1 : 0;
			const weekStartDate = dateCopyWeek.getDate() - dateCopyWeek.getDay() + weekStartOffset;
			dateCopyWeek.setDate(weekStartDate);

			dateCopyWeek.setHours(0);
			dateCopyWeek.setMinutes(0);
			dateCopyWeek.setSeconds(0);
			dateCopyWeek.setMilliseconds(0);
			//now dateCopyWeek will be 00:00:00 midnight on Monday/Sunday
			const firstDay = new Date(dateCopyWeek);

			const weekOffset = rangeType === 'work-week' ? 7 : 8;
			dateCopyWeek.setDate(dateCopyWeek.getDate() - dateCopyWeek.getDay() + weekOffset);
			//with this offset, dateCopyWeek will either be 00:00:00 midnight of Saturday or of Sunday next week
			const lastDay = new Date(dateCopyWeek);

			return [firstDay, lastDay];
	}
}

export function AgendaPage() {
	const [view, setView] = useState<'month' | 'week' | 'work-week'>('work-week');
	const [date, setDate] = useState(new Date());
	const [currentEvent, setCurrentEvent] = useState<{ id: string; entityType: CalendarItemEntityTypes }>();
	const [editMode, setEditMode] = useState<EditMethod>(EditMethod.create);

	const translation = useTranslation();

	const currentRepresentative = useAppSelector((store) => store.currentRepresentative.currentRepresentative);

	const agendaVM = AgendaItemViewCollectionViewModel(EntityType[EntityType.Agenda]);

	const handleViewChange = useCallback((newView: SchedulerViewChangeEvent) => {
		setView(newView.value as typeof view);
	}, []);

	const handleDateChange = useCallback((newDate: SchedulerDateChangeEvent) => {
		setDate(newDate.value);
	}, []);

	const popupCloseFunction = useCallback(() => setCurrentEvent(undefined), []);

	const refreshAgenda = useCallback(() => {
		const [start, end] = getRangeFromDate(date, view);

		agendaVM.fetch(start, end);
	}, [agendaVM, date, view]);

	const switchRepresentative = useCallback(() => {
		agendaVM.switchRepresentative();
	}, [agendaVM]);

	useEffect(() => {
		if (currentEvent === undefined) refreshAgenda();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentEvent, date, view]);

	useEffect(switchRepresentative, [currentRepresentative]);

	const dispatch = useDispatch();

	const CustomItem = useCallback(
		(props: SchedulerItemProps) => {
			const contentString = `${props.dataItem.content.join('\n')}\n\n${generateTimeRangeString(props.zonedStart, props.zonedEnd)}`;

			return (
				<SchedulerItem
					{...props}
					style={{ backgroundColor: props.dataItem.backgroundColor }}
					onClick={() => {
						if (props.dataItem.typeOfEntity === CalendarItemEntityTypes.Activity && props.dataItem.isRecurring) {
							dispatch(
								openAlert({
									text: 'activityIsRecurringWarning',
								})
							);

							return;
						}

						setEditMode(EditMethod.update);
						setCurrentEvent({ id: props.uid as string, entityType: props.dataItem.typeOfEntity });
					}}
				>
					{props.dataItem.content.map((contentItem) => (
						<SchedulerItemContent title={contentString}>
							<div className={styles.contentItem}>{contentItem}</div>
						</SchedulerItemContent>
					))}
					<SchedulerItemContent title={contentString}>{generateTimeRangeString(props.zonedStart, props.zonedEnd)}</SchedulerItemContent>
				</SchedulerItem>
			);
		},
		[dispatch]
	);

	const CustomSlot = useCallback(
		(props: SchedulerSlotProps) => (
			<SchedulerSlot
				{...props}
				onClick={() => {
					setDate(props.range.start);
					setView('week');
				}}
			/>
		),
		[]
	);

	const onPlusClick = () => {
		setEditMode(EditMethod.create);
		setCurrentEvent({ id: '', entityType: CalendarItemEntityTypes.Appointment });
	};

	load(likelySubtags, currencyData, weekData, nlTimeZoneNames, nlNumbers, nlLocalCurrency, nlDateFields, nlCaGregorian, nlTimeZoneNames);
	console.log(agendaVM.items.find((item) => item.typeOfEntity !== 'Appointment'));

	return (
		<Fragment>
			<PanelMain>
				<IntlProvider locale={translation.i18n.language}>
					<GridComponents.Column size="whole">
						<div className={styles.agendaView}>
							<SchedulerHeader
								view={view}
								setView={setView}
								date={date}
								setDate={setDate}
								addIconOnClick={onPlusClick}
							/>
							<Scheduler
								// style={{ height: 'calc(100% - 60px)' }}
								className={styles.scheduler}
								data={ConvertAgendaItemViewsToSchedulerData(agendaVM.items)}
								date={date}
								view={view}
								onViewChange={handleViewChange}
								onDateChange={handleDateChange}
								defaultDate={new Date()}
								header={() => null}
								footer={(props) => {
									return <div>{props.children}</div>;
								}}
								item={CustomItem}
							>
								<WeekView />
								<WorkWeekView />
								<MonthView viewSlot={CustomSlot} />
							</Scheduler>
						</div>
					</GridComponents.Column>
				</IntlProvider>
			</PanelMain>

			{currentEvent !== undefined && (
				<EntityDetailPopupConfigured
					id={currentEvent.id}
					editMethod={editMode}
					popupEntityType={EntityType.Agenda}
					closeFunction={popupCloseFunction}
					viewModelInstanceName={EntityType[EntityType.Agenda]}
					defaultEditingState={currentEvent.entityType === CalendarItemEntityTypes.Activity}
				/>
			)}
		</Fragment>
	);
}
