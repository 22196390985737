import { useCallback } from 'react';
import styles from './agenda.module.scss';
import { Button } from '../../../components';
import { IconCustom } from '../../../components/IconCustom/IconCustom';
import { IconCustomType } from '../../../components/IconCustom/IconCustom.definitions';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { faChevronsLeft, faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { entityIconDictionary } from '../../../components/EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EntityType } from '../../../components/EntityDetailPopup/entityDetailPopup.enums';
import { SchedulerItemProps } from '@progress/kendo-react-scheduler';

const dateTimeFormatOptions = {
	weekday: 'short',
	year: 'numeric',
	month: 'short',
	day: '2-digit',
} as Intl.DateTimeFormatOptions;

function generateNewDate(increase: boolean, type: 'month' | 'week', prevDate: Date): Date {
	switch (type) {
		case 'month': {
			const newDate = new Date(prevDate);
			newDate.setMonth(increase ? prevDate.getMonth() + 1 : prevDate.getMonth() - 1);
			return newDate;
		}
		case 'week': {
			const newDate = new Date(prevDate);
			newDate.setDate(increase ? prevDate.getDate() + 7 : prevDate.getDate() - 7);
			return newDate;
		}
	}
}

function generateCalendarRange(type: 'month' | 'week' | 'work-week', currentDate: Date, locale: string): string {
	switch (type) {
		case 'month': {
			const start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			const end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

			return `${start.toLocaleDateString(locale, dateTimeFormatOptions)} - ${end.toLocaleDateString(locale, dateTimeFormatOptions)}`;
		}
		case 'week':
		case 'work-week': {
			const [start, end] = [new Date(currentDate), new Date(currentDate)];
			start.setDate(currentDate.getDate() - (currentDate.getDay() % 7) + (type === 'work-week' ? 1 : 0));
			end.setDate(currentDate.getDate() - (currentDate.getDay() % 7) + (type === 'work-week' ? 5 : 6));

			return `${start.toLocaleDateString(locale, dateTimeFormatOptions)} - ${end.toLocaleDateString(locale, dateTimeFormatOptions)}`;
		}
	}
}

export function SchedulerHeader(props: {
	view: 'month' | 'week' | 'work-week';
	setView: (view: 'month' | 'week' | 'work-week') => void;
	date: Date;
	setDate: (date: Date) => void;
	addIconOnClick: () => any;
}) {
	const { i18n } = useTranslation();

	const propsView = props.view;
	const propsDate = props.date;
	const propsSetDate = props.setDate;

	const changeDate = useCallback(
		(increase?: boolean) => {
			if (increase !== undefined) {
				let view = propsView;
				if (view === 'work-week') view = 'week';

				const newDate = generateNewDate(increase, view, propsDate);

				propsSetDate(newDate);
			} else {
				propsSetDate(new Date());
			}
		},
		[propsView, propsDate, propsSetDate]
	);

	return (
		<div className={styles.schedulerHeader}>
			<div className={styles.tabs}>
				<div
					className={`${styles.tab} ${props.view === 'month' && styles.active}`}
					onClick={() => props.setView('month')}
				>
					{t('month')}
				</div>
				<div
					className={`${styles.tab} ${props.view === 'week' && styles.active}`}
					onClick={() => props.setView('week')}
				>
					{t('week')}
				</div>
				<div
					className={`${styles.tab} ${props.view === 'work-week' && styles.active}`}
					onClick={() => props.setView('work-week')}
				>
					{t('work-week')}
				</div>
			</div>
			<div className={`${styles.icon} ${styles.right}`}>
				<IconCustom
					kind={IconCustomType.GEN_PLUS}
					onClick={props.addIconOnClick}
				/>
			</div>
			<div className={styles.rowSubHeader}>
				<div className={styles.title}>
					<div className={styles.listIcon}>
						<FontAwesomeIcon icon={entityIconDictionary[EntityType.Agenda]} />
					</div>
					<div className={styles.listTitle}>{t('agenda')}</div>
				</div>

				<div className={styles.dateRange}>{generateCalendarRange(props.view, props.date, i18n.language)}</div>
				<div className={styles.dateButtons}>
					<Button
						i18nKey="NA"
						theme="border"
						onClick={() => changeDate()}
					>
						{t('today')}: {new Date().toLocaleDateString(i18n.language, dateTimeFormatOptions)}
					</Button>
					<Button
						i18nKey="NA"
						theme="border"
						onClick={() => changeDate(false)}
					>
						<FontAwesomeIcon icon={faChevronsLeft} />
					</Button>
					<Button
						i18nKey="NA"
						theme="border"
						onClick={() => changeDate(true)}
					>
						<FontAwesomeIcon icon={faChevronsRight} />
					</Button>
				</div>
			</div>
		</div>
	);
}
