export enum ExtensionType {
	Appointment = 0,
	Company = 1,
	Contact = 2,
	Opportunity = 3,
	Task = 4,
	Address = 5,
	Annotation = 6,
}

export enum CompanyType {
	Company,
	Lead,
	Account,
}

export enum CompanyTypeName {
	Company = 'company',
	Lead = 'lead',
	Account = 'account',
}

export enum CompanyTypeNameCapitalized {
	Company = 'Company',
	Lead = 'Lead',
	Account = 'Account',
}

export enum ActivityTypeName {
	Appointment = 'appointment',
	Activity = 'activity',
}

export enum FieldType {
	fieldDefinitionBoolean = 0,
	fieldDefinitionDateTime = 1,
	fieldDefinitionMultipleOption = 2,
	fieldDefinitionNumeric = 3,
	fieldDefinitionSingleOption = 4,
	fieldDefinitionText = 5,
}

export enum DateType {
	DateTime = 0,
	Date = 1,
	Time = 2,
}

export enum Environment {
	None = 0,
	TheNetherlands,
	Belgium,
	Germany,
	UnitedKingdom,
	UnitedStatesOfAmerica,
	Spain,
	France,
}

export enum OpportunityStatus {
	closedUnknown = -1,
	open = 0,
	won = 1,
	lost = 2,
}

export enum ExchangeSynchronizationStatus {
	NotSynced = 0,
	SyncedToExchange = 1,
	SyncedToSalesflow = 2,
	Conflict = 3,
	MarkedForDeletion = 4,
}
