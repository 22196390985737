import styles from '../FormDetail/formDetail.module.scss';
import stylesInput from '../../InputFields/inputFields.module.scss';
import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { Fragment, ReactElement, createElement, useCallback, useEffect, useState } from 'react';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import {
	DateTimeType,
	IBoundBooleanRowProps,
	IBoundDateTimeRowProps,
	IBoundDropdownRowProps,
	IBoundInputRowProps,
	IBoundTextRowProps,
	SelectType,
} from '../../InputFields/inputField.types';
import { stringJoin } from '../FormDetail/_utility';
import { IFullFieldDefinition } from '../../../models/_Custom/FullFieldDefinition';
import { FieldAttributeType } from '../../../globals/enums';
import { validationUtility } from '../../../utilities/validationUtility';
import { InfoGroup } from '../../InfoBlocks';

export interface ICustomFieldEditorProps {
	vm: IViewModel<any>;
	editing: boolean;
}

export enum dateTimeTypeEnum {
	'datetime-local',
	date,
	time,
}

export function CustomFieldEditor(props: ICustomFieldEditorProps) {
	const [elements, setElements] = useState<ReactElement[]>([]);

	useEffect(() => {
		generateElements();
	}, [props.editing, props.vm.model]);

	const generateBoundInputRowProps = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			let isReadOnly = !props.editing;
			if (def.isReadOnly) isReadOnly = def.isReadOnly;

			const boundRowProps: IBoundInputRowProps = {
				label: def.label!,
				vm: props.vm,
				model: props.vm.model,
				modelValuePropertyName: `f_${def.id}`,
				readonly: isReadOnly,
				errorMessage: stringJoin(props.vm.getPropertyErrors(`f_${def.id}`)),
				key: `boundInputRow_${index}`,
			};

			return boundRowProps;
		},
		[props.editing, props.vm]
	);

	const generateElementText = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			const elProps = generateBoundInputRowProps(def, index) as IBoundTextRowProps;
			const el = <BoundInputRows.TextRow {...elProps} />;
			return el;
		},
		[generateBoundInputRowProps]
	);

	const generateElementBoolean = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			const elProps = generateBoundInputRowProps(def, index) as IBoundBooleanRowProps;
			const booleans = ['true', 'false'];

			if (def.valueTrue) booleans[0] = def.valueTrue;
			if (def.valueFalse) booleans[1] = def.valueFalse;

			elProps.booleanLabels = booleans as [string, string];
			elProps.storeBooleanLabelsAsValue = true;
			const el = createElement(BoundInputRows.BooleanRow, elProps, null);
			return el;
		},
		[generateBoundInputRowProps]
	);

	const generateElementDateTime = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			const elProps = generateBoundInputRowProps(def, index) as IBoundDateTimeRowProps;

			switch (def.type) {
				case dateTimeTypeEnum.date:
					elProps.type = DateTimeType.date;
					break;
				case dateTimeTypeEnum.time:
					elProps.type = DateTimeType.time;
					break;
				case dateTimeTypeEnum['datetime-local']:
					elProps.type = DateTimeType['datetime-local'];
					break;
			}

			const el = createElement(BoundInputRows.DateTimeRow, elProps, null);
			return el;
		},
		[generateBoundInputRowProps]
	);

	const generateElementSingleOption = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			const elProps = generateBoundInputRowProps(def, index) as IBoundDropdownRowProps;

			elProps.options = validationUtility.generateOptionsArrayFromOptions(def.options);
			elProps.type = SelectType.select;
			const el = createElement(BoundInputRows.DropdownRow, elProps, null);
			return el;
		},
		[generateBoundInputRowProps]
	);

	const generateElementMultiOption = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			const elProps = generateBoundInputRowProps(def, index) as IBoundDropdownRowProps;
			elProps.options = validationUtility.generateOptionsArrayFromOptions(def.options);
			elProps.type = SelectType.multiSelect;
			const el = createElement(BoundInputRows.DropdownRow, elProps, null);
			return el;
		},
		[generateBoundInputRowProps]
	);

	const generateElementNumeric = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			//TODO: Should be numeric element!
			console.warn('Please implement BoundInputRows.NumericRow');
			const elProps = generateBoundInputRowProps(def, index) as IBoundDropdownRowProps;
			elProps.options = validationUtility.generateOptionsArrayFromOptions(def.options);
			elProps.type = SelectType.multiSelect;
			const el = createElement(BoundInputRows.TextRow, elProps, null);
			return el;
		},
		[generateBoundInputRowProps]
	);

	const generateElement = useCallback(
		(def: IFullFieldDefinition, index: number) => {
			switch (def.$type) {
				case FieldAttributeType.fieldDefinitionText: {
					return generateElementText(def, index);
				}
				case FieldAttributeType.fieldDefinitionBoolean: {
					return generateElementBoolean(def, index);
				}
				case FieldAttributeType.fieldDefinitionDateTime: {
					return generateElementDateTime(def, index);
				}
				case FieldAttributeType.fieldDefinitionSingleOption: {
					return generateElementSingleOption(def, index);
				}
				case FieldAttributeType.fieldDefinitionMultipleOption: {
					return generateElementMultiOption(def, index);
				}
				case FieldAttributeType.fieldDefinitionNumeric: {
					return generateElementNumeric(def, index);
				}
			}

			return undefined;
		},
		[generateElementBoolean, generateElementDateTime, generateElementMultiOption, generateElementNumeric, generateElementSingleOption, generateElementText]
	);

	const generateElements = useCallback(() => {
		if (props.vm.model === undefined) return;
		if (props.vm.fieldDefinitions === undefined) return;

		// const notFound = (
		// 	<div className={stylesInput.wrapper}>
		// 		<label>
		// 			<i>{t('not available')}</i>
		// 		</label>
		// 	</div>
		// );
		const inputs: ReactElement[] = [];

		props.vm.fieldDefinitions.forEach((def, index) => {
			const el = generateElement(def, index);
			if (el) inputs.push(el);
		});

		// if (inputs.length === 0) {
		// 	inputs.push(notFound);
		// }

		setElements(inputs);
	}, [generateElement, props.vm.fieldDefinitions, props.vm.model]);

	if (elements.length === 0) return null;
	return (
		<InfoGroup
			padding
			title={t('customFields')}
			visible={true}
			open
		>
			{elements}
		</InfoGroup>
	);
}
