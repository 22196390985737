//TODO: Use model/_Enums where possible
//If possible; remove
//Do not edit model/_Enuns since it is autogenerated.

import { CompanyTypeNameCapitalized, DateType, ExtensionType, FieldType } from "../../model/_Enums"

// enum ExtensionType {
// 	appointment = 0,
// 	company = 1,
// 	contact = 2,
// 	opportunity = 3,
// 	task = 4,
// }

// enum CompanyType {
// 	company = 0,
// 	lead = 1,
// 	account = 2
// }

// enum FieldType {
// 	Boolean = 0,
// 	DateTime = 1,
// 	MultipleOptions = 2,
// 	Numeric = 3,
// 	SingleOption = 4,
// 	Text = 5,
// }

// enum DateType {
// 	dateTime = 0,
// 	date = 1,
// 	time = 2,
// }

export enum ExternalConnectionStatus {
	UnknownState = 0, // default state
	ReadyToRun = 1, // no state (SyncTaskAudit) found
	Inactive = 2, // connector (Task) not active
	Active = 3, // connector (Task) active, no further details
	PartialActive = 4, // not all Tasks active
	Successful = 5, // all items successfully synced
	PartialSuccessful = 6, // some items successfully synced
	NoSuccess = 7, // all items failed to sync
	NoUpdates = 8, // no items to sync, proccess exited successful
	UnknownError = 128, // other problems
	IncorrectSetup = 130, // settings failed to validate
	AuthorizationMissing = 131, // no login, access token, etc
	AuthorizationExpired = 132, // login, access token, etc expired
	AuthorizationFailed = 133, // authorization failed for reasons other then being expired
	NetworkError = 136, // any network/thirdparty errors
	MultipleErrors = 137 // list of errors
}

const enums = {
	ExtensionType: ExtensionType,
	FieldType: FieldType,
	DateType: DateType,
	CompanyType: CompanyTypeNameCapitalized
}

export default enums