import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import { store, updateGlobals } from './store/store';
import { isTokenValid } from './utilities/authProvider';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	 // dev code
} else {
	// production code

	//Only initialize sentry in production. In development, we do not need Sentry
	Sentry.init({
		dsn: "https://47ce67a7df334820b4220e04f9b40c0b@o4504451731619840.ingest.sentry.io/4504451947102208",
		tunnel: "/api/utility/sentry", // redirect through own domain, so adblobkers don't intervene
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}


const rootElement = document.getElementById('root');

//update globals on load of SPA, if we have a valid token available
if (isTokenValid())
	updateGlobals();

const root = createRoot(rootElement);

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

unregister(); //Don't use ServiceWorker until we have researched how to work with it