import styles from './inputFields.module.scss';
import { ITextInputProps } from './inputField.types';

function TextInput(props: ITextInputProps) {
	return (
		<input
			type='text'
			required={props.required}
			className={`${styles.input} ${props.hasError && styles.invalid}`}
			value={props.value || ''} //to prevent 'input' should not be null
			onChange={(e) => props.onChange(e.target.value)}
			readOnly={props.readOnly}
		/>
	);
}

export default TextInput;
