import { BooleanToIconConverter } from '../Converters/ColumnConverters';

export const table = {
	title: 'appointments',
	entityName: 'Appointment',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'appointmentAttributes.isClosed',
			property: {
				name: 'isClosed',
			},
			type: 'boolean',
			converter: BooleanToIconConverter,
		},
		{
			headTitle: 'appointmentAttributes.isFollowUp',
			property: {
				name: 'isFollowUp',
			},
			type: 'boolean',
			converter: BooleanToIconConverter,
		},
		{
			headTitle: 'appointmentAttributes.startDate',
			property: {
				name: 'startDateTime',
			},
			type: 'datetime-local',
		},
		{
			headTitle: 'appointmentAttributes.endDate',
			property: {
				name: 'endDateTime',
			},
			type: 'datetime-local',
		},
		{
			headTitle: 'subject',
			property: {
				name: 'subject',
			},
		},
		{
			headTitle: 'appointmentAttributes.type',
			property: {
				name: 'appointmentType_Name',
			},
		},
		{
			headTitle: 'primaryRepresentative',
			property: {
				name: 'representative_FullName',
			},
		},
		{
			headTitle: 'appointmentAttributes.contact',
			property: {
				name: ['appointment_ContactWith_FirstName', 'appointment_ContactWith_SurnamePrefixes', 'appointment_ContactWith_Surname'],
			},
		},
		{
			headTitle: 'location',
			property: {
				name: 'location',
			},
		},
	],
	apiUrl: '/Appointment',
};
