import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components';
import { LoginLayout } from '../../components/LoginPanel/LoginLayout';
import styles from './oidc.module.scss';
import { faAt, faUser } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { ErrorPageLayout } from '../../components/ErrorPageLayout/ErrorPageLayout';
import { Trans } from 'react-i18next';
import { oidcGetRegisterUrl, oidcGetRepresentative } from '../../api/OidcApi';
import { isNotNilOrEmpty } from '../../utilities/utils';
import { t } from 'i18next';

export function SetupPage() {
	const [linkToken, setLinkToken] = useState<string>();
	const [personalInfo, setPersonalInfo] = useState<{ fullName: string; emailAddress: string }>();
	const [hasTokenError, setHasTokenError] = useState(false);

	const buttonClickHandler = useCallback(async () => {
		oidcGetRegisterUrl(linkToken!)
			.then((res) => {
				if (res.ok)
					res.json().then((url) => {
						window.location.href = url;
					});
			})
			.catch((err) => console.error(err));
	}, [linkToken]);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		if (searchParams.has('token')) {
			const token = searchParams.get('token')!;

			setLinkToken(token);
		}
	}, []);

	useEffect(() => {
		async function init() {
			if (isNotNilOrEmpty(linkToken)) {
				setHasTokenError(false);
				const res = await oidcGetRepresentative(linkToken!);
				if (res.ok) {
					const representative = await res.json();

					setPersonalInfo({ fullName: representative.fullName, emailAddress: representative.email });
				} else setHasTokenError(true);
			} else {
				setHasTokenError(true);
			}
		}

		init();
	}, [linkToken]);

	if (personalInfo) {
		return (
			<LoginLayout>
				<div className={styles.LinkEmailPage}>
					{personalInfo && (
						<div className={styles.personalInfo}>
							<div className={styles.fullName}>
								<FontAwesomeIcon
									icon={faUser}
									className={styles.icon}
								/>
								<span className={styles.text}>{personalInfo.fullName}</span>
							</div>
							<div className={styles.emailAddress}>
								<FontAwesomeIcon
									icon={faAt}
									className={styles.icon}
								/>
								<span className={styles.text}>{personalInfo.emailAddress}</span>
							</div>
						</div>
					)}
					<Button
						i18nKey='oidc.linkEmail'
						theme='confirm'
						onClick={buttonClickHandler}
					/>
				</div>
			</LoginLayout>
		);
	} else if (hasTokenError) {
		return (
			<LoginLayout>
				<h1>
					<Trans i18nKey='oidc.tokenErrorMessage' />
				</h1>
			</LoginLayout>
		);
	} else {
		return <LoginLayout>{t('oidc.loadingMessage')}</LoginLayout>;
	}
}
