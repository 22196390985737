export class AsyncLock {
    private isLocked: boolean = false;
    private queue: (() => void)[] = [];

    async acquire(timeout?: number): Promise<void> {
        if (!this.isLocked) {
            this.isLocked = true;
            return;
        }

        if (typeof timeout === 'number') {
            return new Promise<void>((resolve, reject) => {
                const timer = setTimeout(() => {
                    reject(new Error('Lock acquisition timed out'));
                }, timeout);

                this.queue.push(() => {
                    clearTimeout(timer);
                    resolve();
                });
            });
        }

        return new Promise<void>((resolve) => {
            this.queue.push(resolve);
        });
    }

    release(): void {
        if (this.queue.length > 0) {
            const resolve = this.queue.shift();
            if (resolve) {
                resolve();
            }
        } else {
            this.isLocked = false;
        }
    }

    isAcquired(): boolean {
        return this.isLocked;
    }
}
