/** ONLY FOR TESTING AGENDA! */
export const table = {
	title: 'appointments',
	entityName: 'Appointment',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'typeOfEntity',
			property: { name: 'typeOfEntity' },
		},
		{
			headTitle: 'entity_Id',
			property: { name: 'entity_Id' },
		},
		// {
		// 	headTitle: 'isClosed',
		// 	property: { name: 'isClosed' },
		// },
		// {
		// 	headTitle: 'isFollowUp',
		// 	property: { name: 'isFollowUp' },
		// },
		// {
		// 	headTitle: 'isRecurring',
		// 	property: { name: 'isRecurring' },
		// },
		{
			headTitle: 'company_Id',
			property: { name: 'company_Id' },
		},
		{
			headTitle: 'description',
			property: { name: 'description' },
		},
		{
			headTitle: 'startDateTime',
			property: { name: 'startDateTime' },
			type: 'datetime-local',
		},		
	],
	apiUrl: '/Appointment',
};
