import * as React from 'react';
import styles from './infoBlocks.module.scss';
import { InfoHeader } from '.';
import { useState } from 'react';

export interface IInfoGroupProps {
	open: boolean;
	title: string;
	visible: boolean;
	theme?: 'light' | 'gray';
	padding?: boolean;
	children?: React.ReactNode;
}

export function InfoGroup(props: IInfoGroupProps) {
	const [open, setOpen] = useState(props.open);
	const [visible, setVisible] = useState(props.visible);

	const onTitleClick = () => {
		setOpen(!open);
	};

	React.useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	React.useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	if (visible === false) {
		return null;
	}

	return (
		<div className={styles.infoGroup}>
			<InfoHeader
				title={props.title}
				onTitleClick={onTitleClick}
				open={open}
				theme={props.theme}
			/>
			{open && <div className={`${styles.content} ${props.padding && styles.padded}`}>{props.children}</div>}
		</div>
	);
}

// Converted this class to a functional component.
// Remove below code if no problems arise (25 oct 2023)

// export class InfoGroup extends React.Component<
// 	{
// 		open: boolean;
// 		title: string;
// 		theme?: 'light' | 'gray';
// 		padding?: boolean;
// 		children?: React.ReactNode;
// 	},
// 	{
// 		open: boolean;
// 	}
// > {
// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			open: this.props.open ?? true,
// 		};
// 	}

// 	onTitleClick = () => {
// 		this.setState({ open: !this.state.open });
// 	};

// 	render() {
// 		return (
// 			<div className={styles.infoGroup}>
// 				<InfoHeader
// 					title={this.props.title}
// 					onTitleClick={this.onTitleClick}
// 					open={this.state.open}
// 					theme={this.props.theme}
// 				/>
// 				{this.state.open && (
// 					<div
// 						className={`${styles.content} ${
// 							this.props.padding && styles.padded
// 						}`}
// 					>
// 						{this.props.children}
// 					</div>
// 				)}
// 			</div>
// 		);
// 	}
// }
