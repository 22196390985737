import { FilterBase } from '../Filters/_FilterBase';

export class BaseQuery {
	public take?: number;
	public skip?: number;
	public order: string[] = [];
	public includes: string[] = [];
	public filters?: FilterBase[] | any = [];

	private findFilterIndexByType(type: string): number {
		return this.filters.findIndex((filter) => filter.$type === type);
	}

	public addFilter(f: FilterBase) {
		this.filters.push(f);
	}

	public removeFilter(f: FilterBase) {
		const index = this.filters.indexOf(f);

		if (index !== -1) {
			this.filters.splice(index, 1);
		}
	}

	public removeFilterByType(f: FilterBase) {
		const index = this.findFilterIndexByType(f.$type);

		if (index !== -1) {
			this.filters.splice(index, 1);
		}
	}

	public addOrReplaceFilter(f: FilterBase) {
		const existingIndex = this.filters.findIndex((filter) => filter.$type === f.$type);

		if (existingIndex !== -1) {
			this.filters[existingIndex] = f;
		} else {
			this.filters.push(f);
		}
	}

	public include(s: string) {
		this.includes = this.includes.concat(s);
		return this;
	}

	public orderBy(s: string) {
		this.order = this.order.concat(`ASC ${s}`);
		return this;
	}

	public orderByDescending(s: string) {
		this.order = this.order.concat(`DESC ${s}`);
		return this;
	}

	public toString() {
		return JSON.stringify(this.export());
	}

	public export() {
		const obj: Record<string, any> = {};

		for (const key in this) {
			const value = this[key];

			if (key === 'constructor') continue;
			if (value === undefined) continue;
			if (value === '') continue;
			if (value instanceof Function) continue;
			if (value instanceof Array && value.length === 0) continue;

			obj[key] = value;
		}

		return obj;
	}
}
