import { Fragment, useCallback, useEffect, useState } from 'react';
import { IOption } from '../inputField.types';
import { generateOptionProp, generateOptionsProp } from '../BoundInputRows/BoundInputRows';

import { ICVMBoundDropDownInputProps } from './types';

import styles from '../inputFields.module.scss';
import { EntityDetailPopupConfigured } from '../../EntityDetailPopup/EntityDetailPopup';
import { EditMethod } from '../../EntityDetailPopup/entityDetailPopup.interfaces';
import DropdownComponent from '../DropdownInput';
import { isNotNilOrEmpty } from '../../../utilities/utils';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';

export function CVMBoundDropDownInput(props: ICVMBoundDropDownInputProps) {
	const cvm = props.cvm(`${props.DDEntityType}CVMBoundDropdownInput`, true);

	const [missingOption, setMissingOption] = useState<IOption>();
	const [detailPopupId, setDetailPopupId] = useState();

	const closeFunction = useCallback(() => setDetailPopupId(undefined), []);

	const options = generateOptionsProp(cvm.items, props.valuePropertyName, props.labelPropertyName, props.labelString);
	if (missingOption !== undefined) options.push(missingOption);

	useEffect(() => {
		if (cvm.isSwitchable && props.cvmCollectionMode !== undefined) {
			cvm.switchMode(props.cvmCollectionMode);
		}

		cvm.read(props.readId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.DDEntityType, props.readId, props.cvmCollectionMode]);

	useEffect(() => {
		async function getMissingOption(id) {
			const missingItem = await props.getEntityFunction(id);

			const missingOptionProp: IOption =
				props.getEntityOptionPropNames !== undefined
					? generateOptionProp(
							missingItem,
							props.getEntityOptionPropNames.valuePropertyName,
							props.getEntityOptionPropNames.labelPropertyName,
							props.getEntityOptionPropNames.labelString
					  )
					: generateOptionProp(missingItem, props.valuePropertyName, props.labelPropertyName, props.labelString);

			console.log(missingItem, missingOptionProp, props.getEntityOptionPropNames);

			setMissingOption(missingOptionProp);
		}

		if (isNotNilOrEmpty(props.value)) {
			const foundOption = options.filter((option) => option.value === props.value);

			if (foundOption.length > 1) {
				setMissingOption(undefined);
			} else if (foundOption.length === 0) {
				getMissingOption(props.value);
			}
		}
	}, [cvm.items, props.value, missingOption, props.getEntityOptionPropNames, props, options]);

	let inputField;
	if (props.readOnly && props.entityDetailPopupType !== undefined) {
		const selectedOption = options.find((option) => option.value === props.value);

		inputField = (
			<div className={styles.hyperlinkWrapper}>
				<div
					className={styles.entityLink}
					onClick={() => setDetailPopupId(selectedOption?.value)}
				>
					{selectedOption?.label}
				</div>
			</div>
		);
	} else {
		inputField = (
			<DropdownComponent
				value={props.value}
				options={options}
				onChange={props.onChange}
				searchCallback={cvm.search}
				readOnly={props.readOnly}
				hasError={props.hasError}
			/>
		);
	}

	return (
		<Fragment>
			{inputField}
			{detailPopupId !== undefined && props.entityDetailPopupType !== undefined && (
				<EntityDetailPopupConfigured
					id={detailPopupId}
					editMethod={EditMethod.update}
					popupEntityType={props.entityDetailPopupType}
					closeFunction={closeFunction}
					viewModelInstanceName={EntityType[props.entityDetailPopupType]}
				/>
			)}
		</Fragment>
	);
}
