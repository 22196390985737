import { ChangeEventHandler, Fragment, ReactNode } from 'react';

import styles from './inputFields.module.scss';
import TextareaComponent from './TextareaComponent';
import DropdownComponent, { IDropdownComponentProps } from './DropdownInput';
import { DateTimeInput, IDateTimeType } from './DateTimeInput';
import BooleanInputComponent, { IBooleanInputComponentProps } from './BooleanInput';
import { HyperlinkInputComponent, LinkType } from './HyperlinkTextFields';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum inputType {
	text = 'text',
	number = 'number',
	textarea = 'textarea',
	tel = 'tel',
	url = 'url',
	email = 'email',
	boolean = 'boolean',
	date = 'date',
	time = 'time',
	'datetime-local' = 'datetime-local',
	hidden = 'hidden',
}

export interface IGeneralProps {
	name: string;
	required?: boolean;
	defaultValue?: any;
	onChange?: ChangeEventHandler;
	InputActionObject?: IInputActionObject;
	readOnly?: boolean;
}

export interface GeneralFieldProps extends IGeneralProps {
	label: string;
}

export interface IInputFieldProps extends GeneralFieldProps {
	type: inputType;
}

export interface IBooleanFieldProps extends GeneralFieldProps, IBooleanInputComponentProps {
	type: inputType.boolean;
}

export interface IInputActionObject {
	icon: IconProp;
	onClick: () => any;
}

export interface ISelectionFieldProps extends GeneralFieldProps, IDropdownComponentProps {}

export interface ITextareaProps extends GeneralFieldProps {
	type: inputType.textarea;
}

function InputFieldComponent(props: IInputFieldProps | ISelectionFieldProps | IBooleanFieldProps | ITextareaProps) {
	//TODO: massively overhaul the popupPanel so that more of it can be moved to other files (this is why the wrapper isn't being used)
	switch (props.type) {
		case 'textarea':
			return (
				// <InputFieldComponentWrapper label={props.label}>
				<TextareaComponent
					name={props.name}
					required={props.required}
					onChange={props.onChange}
					defaultValue={props.defaultValue}
					readOnly={props.readOnly}
				/>
				// </InputFieldComponentWrapper>
			);
		case 'select':
		case 'multiSelect': {
			const selectionProps = props as ISelectionFieldProps;

			return (
				// <InputFieldComponentWrapper label={props.label}>
				<DropdownComponent
					type={props.type}
					name={props.name}
					options={selectionProps.options}
					required={props.required}
					onChange={props.onChange}
					defaultValue={props.defaultValue}
					readOnly={props.readOnly}
				/>
				// </InputFieldComponentWrapper>
			);
		}
		case 'boolean': {
			const booleanProps = props as IBooleanFieldProps;

			return (
				// <InputFieldComponentWrapper label={props.label}>
				<BooleanInputComponent
					name={props.name}
					required={props.required}
					booleanLabels={booleanProps.booleanLabels}
					onChange={props.onChange}
					defaultValue={props.defaultValue}
					readOnly={props.readOnly}
				/>
				// </InputFieldComponentWrapper>
			);
		}
		case 'date':
		case 'time':
		case 'datetime-local':
			return (
				// <InputFieldComponentWrapper label={props.label}>
				<DateTimeInput
					name={props.name}
					type={IDateTimeType[props.type]}
					required={props.required}
					defaultValue={props.defaultValue}
					readOnly={props.readOnly}
				/>
				// </InputFieldComponentWrapper>
			);

		case 'tel':
		case 'url':
		case 'email':
			return (
				// <InputFieldComponentWrapper label={props.label}>
				<HyperlinkInputComponent
					type={LinkType[props.type]}
					name={props.name}
					required={props.required}
					onChange={props.onChange}
					defaultValue={props.defaultValue}
					readOnly={props.readOnly}
				/>
				// </InputFieldComponentWrapper>
			);
		case 'text':
		case 'number':
		case 'hidden':
		default:
			const inputProps = props as IInputFieldProps;
			return (
				// <InputFieldComponentWrapper label={props.label}>
				<Fragment>
					<input
						name={props.name}
						className={styles.input}
						type={props.type}
						required={props.required}
						onChange={props.onChange}
						defaultValue={props.defaultValue}
						readOnly={props.readOnly}
					/>
					<InputAction InputActionObject={inputProps.InputActionObject} />
				</Fragment>
				// </InputFieldComponentWrapper>
			);
	}
}

export function InputFieldComponentWrapper(props: { label: string; children: ReactNode }) {
	return (
		<div className={styles.wrapper}>
			<label>{props.label}</label>
			<div className={styles.inputField}>{props.children}</div>
		</div>
	);
}

export function InputAction(props: { InputActionObject?: IInputActionObject }) {
	const [onClick, icon] = props.InputActionObject ? [props.InputActionObject.onClick, props.InputActionObject.icon] : [undefined, undefined];

	return (
		<div
			className={styles.InputActionObject}
			onClick={onClick}
		>
			{icon && <FontAwesomeIcon icon={icon} />}
		</div>
	);
}

export default InputFieldComponent;
