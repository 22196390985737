import styles from './formDetail.module.scss';
import { IFormDetailProps } from '../entityDetailPopup.interfaces';
import { FormHeaderGeneral } from './Headers/_FormHeaderGeneral';
import { useCallback } from 'react';

export const FormDetail = (props: IFormDetailProps) => {
	const getHeader = useCallback(() => {
		if (props.header) return props.header;
		return <FormHeaderGeneral {...props} />;
	}, [props]);

	// const header =
	// 	props.header !== undefined ? (
	// 		props.header
	// 	) : (
	// 		<div className={styles.header}>
	// 			<b>{props.headerText}</b>
	// 			<div className={styles.formButtons}>
	// 				<FormHeaderButtons
	// 					vm={props.vm}
	// 					editing={props.editing}
	// 					setEditing={props.setEditing}
	// 					closeDetailPopup={props.closeDetailPopup}
	// 				/>
	// 			</div>
	// 		</div>
	// 	);

	return (
		<div className={styles.container}>
			{getHeader()}
			<div className={styles.form}>{props.children}</div>
		</div>
	);
};
