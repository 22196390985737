import { useState, useCallback, useMemo } from 'react';
import { TreeView, TreeViewExpandChangeEvent, TreeViewCheckChangeEvent, processTreeViewItems, handleTreeViewCheckChange } from '@progress/kendo-react-treeview';
import { t } from 'i18next';
import { Button } from '../../../components/Button/Button';
import styles from './export.module.scss';
import { TreeViewDataItem } from './types';

export const EntityTreeView = ({ checkedItems, setCheckedItems, entityDefinition, exportFiles, setFilesToExport, loading }) => {
	const items: TreeViewDataItem[] = [];
	const level = { items };

	const [expand, setExpand] = useState({
		ids: ['Item2'],
		idField: 'text',
	});

	const onExpandChange = (event: TreeViewExpandChangeEvent) => {
		const ids = expand.ids.slice();
		const index = ids.indexOf(event.item.text);

		index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
		setExpand({ ids, idField: 'text' });
	};

	const updateCheckedItems = useCallback(
		(check: [], treeItems: TreeViewDataItem[]) => {
			const checked = check.map((index: string) => findItemByIndex(treeItems, index)).filter((item) => item !== null);

			setFilesToExport(checked);
		},
		[setFilesToExport]
	);

	const onCheckChange = useCallback(
		(event: TreeViewCheckChangeEvent) => {
			const settings = {
				singleMode: false,
				checkChildren: true,
				checkParents: false,
			};
			const newCheck = handleTreeViewCheckChange(event, checkedItems, items, settings) as any;
			setCheckedItems(newCheck);
			updateCheckedItems(newCheck, items);
		},
		[checkedItems, items, setCheckedItems, updateCheckedItems]
	);

	const findItemByIndex = (data: TreeViewDataItem[] | { items: [] }, index: string) => {
		const indexes = index?.split('_')?.map(Number);
		let item = { items: [] };
		let items = data;

		for (const i of indexes) {
			item = items[i];
			if (item?.items) {
				items = item?.items;
			}
		}

		return item;
	};

	// Get data in shape that TreeView component expects
	entityDefinition.Properties.forEach((property) => {
		property.DisplayName.split('> ').reduce((tree: { items: TreeViewDataItem | any }, text: string, i: number, array) => {
			if (!tree[text]) {
				tree[text] = { items: [] };
				tree.items?.push({
					text,
					items: tree[text].items,
					RelationPath: property.RelationPath,
					$type: property.$type,
					DisplayName: property.DisplayName,
					PropertyName: property.PropertyName,
				});
			} else if (i === array.length - 1) {
				tree.items.push({ text, items: [] });
			}
			return tree[text];
		}, level);
	});

	return (
		<>
			{entityDefinition?.Properties.length > 0 ? (
				<div>
					<TreeView
						className={styles.treeView}
						checkboxes={true}
						data={processTreeViewItems(items, {
							check: checkedItems,
							expand: expand,
						})}
						onCheckChange={onCheckChange}
						onExpandChange={onExpandChange}
						expandIcons={true}
					/>

					<div className={styles.action}>
						<Button
							i18nKey={'export'}
							onClick={() => exportFiles()}
							theme="confirm"
							width={200}
							disabled={checkedItems.length <= 0}
							loading={loading}
						/>
					</div>
				</div>
			) : (
				<div className={styles.noDataAvailable}>{t('chooseType')}</div>
			)}
		</>
	);
};
