/* UNMODIFIED */
import { EntityPropertyValidationAttribute, IEntityPropertyValidationAttribute } from "./EntityPropertyValidationAttribute";

export interface IRequiredEntityPropertyValidationAttribute extends IEntityPropertyValidationAttribute {
	allowEmptyStrings: boolean;
}

export class RequiredEntityPropertyValidationAttribute extends EntityPropertyValidationAttribute implements IRequiredEntityPropertyValidationAttribute {
	public allowEmptyStrings: boolean = false;
}
