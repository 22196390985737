/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IRepresentativeRole } from "./RepresentativeRole";
import { ICompanyRepresentative } from "./CompanyRepresentative";

export interface IRepresentative extends IModelBase {
	databaseUser: string | null;
    code: string | null;
    fullName: string | null;
    emailAddress: string | null;
    representativeRole: IRepresentativeRole | null;
    representativeRole_Id: string | null;
    includeInReport: boolean;
    isActive: boolean;
    companyRepresentatives: ICompanyRepresentative[] | null;
}

export class Representative extends ModelBase implements IRepresentative {
	public databaseUser: string | null = null;
    public code: string | null = null;
    public fullName: string | null = null;
    public emailAddress: string | null = null;
    public representativeRole: IRepresentativeRole | null = null;
    public representativeRole_Id: string | null = null;
    public includeInReport: boolean = false;
    public isActive: boolean = false;
    public companyRepresentatives: ICompanyRepresentative[] | null = null;
}
