import BoundInputRows, { generateOptionsProp } from '../../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Contact } from '../../../../models/Model/Contact';
import { IViewModel } from '../../../../viewmodels/_viewModel.interfaces';
import { EditMethod, IEntityFormDetailProps } from '../../entityDetailPopup.interfaces';
import { FormDetail } from '../_FormDetail';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateCompanies } from '../../../../store/reducers/CompanySlice';
import { LinkType } from '../../../InputFields/inputField.types';
import { stringJoin } from '../_utility';
import { EntityType } from '../../entityDetailPopup.enums';
import { InfoGroup } from '../../../InfoBlocks';
import { getContactFullName } from '../../../../utilities/entityHelpers';
import { CustomFieldEditor } from '../../CustomField/CustomFieldEditor';

interface IContactFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<Contact>;
}

export const ContactFormDetail = (props: IContactFormDetailProps) => {
	//MAYBE: Should use ContactViewViewModel (must be created), not ContactViewModel
	const companies = useAppSelector((state) => state.companies.companies);

	const dispatch = useAppDispatch();

	//TODO: Baseform?
	const p = props.vm.getProperties();
	const e = (p: string) => stringJoin(props.vm.getPropertyErrors(p));

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={props.vm}
			headerText={getContactFullName(props.vm.model)}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName='address-book'
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.EntityDropdownRow
					label={t('company')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.company_Id}
					errorMessage={e(p.company_Id)}
					entityType={EntityType.Company}
					options={generateOptionsProp(companies, 'entity_Id', 'entity_Name')}
					readonly
				/>
			</InfoGroup>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('name')}
			>
				<BoundInputRows.TextRow
					label={t('contactAttributes.salutation')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.salutation}
					errorMessage={e(p.salutation)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.firstName')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.firstName}
					errorMessage={e(p.firstName)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.surnamePrefixes')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.surnamePrefixes}
					errorMessage={e(p.surnamePrefixes)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.surname')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.surname}
					errorMessage={e(p.surname)}
					readonly={!props.isEditing}
				/>
			</InfoGroup>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('contactInfo')}
			>
				<BoundInputRows.HyperlinkRow
					label={t('contactAttributes.emailAddress')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.emailAddress}
					errorMessage={e(p.emailAddress)}
					readonly={!props.isEditing}
					type={LinkType.email}
				/>
				<BoundInputRows.HyperlinkRow
					label={t('contactAttributes.mobileNumber')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.mobileNumber}
					errorMessage={e(p.mobileNumber)}
					readonly={!props.isEditing}
					type={LinkType.tel}
				/>
				<BoundInputRows.HyperlinkRow
					label={t('contactAttributes.phoneNumber')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.phoneNumber}
					errorMessage={e(p.phoneNumber)}
					readonly={!props.isEditing}
					type={LinkType.tel}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.function')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.function}
					errorMessage={e(p.function)}
					readonly={!props.isEditing}
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={props.vm}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
};
