import styles from '../test.module.scss';
import EventBus from '../../utilities/EventBus';
import CompanyGroupViewModel from '../../viewmodels/CompanyGroupViewModel';
import OpportunityStatusReasonViewModel from '../../viewmodels/OpportunityStatusReasonViewModel';
import RepresentativeViewModel from '../../viewmodels/RepresentativeViewModel';
import VisitingFrequencyViewModel from '../../viewmodels/VisitingFrequencyViewModel';
import React, { useEffect, useState } from 'react';
import { withRouter } from '../../compat/RouterCompat';
import { PanelMain } from '../../components';
import { IReactRouterProps } from '../../globals/types';
import { connect } from 'react-redux';
import { UserManager } from '../../utilities/UserManager';
import { TestDropDownControl } from '../../components/Test/TestDropdownControl';
import { RepresentativeViewCollectionViewModel } from '../../viewmodels/Collections/RepresentativeViewCollectionViewModel';
import { GeneralFormDetail } from './_GeneralFormDetail';
import { CompanyGroupViewCollectionViewModel } from '../../viewmodels/Collections/CompanyGroupCollectionViewModel';
import { VisitingFrequencyViewCollectionViewModel } from '../../viewmodels/Collections/VisitingFrequencyCollectionViewModel';
import { OpportunityStatusReasonCollectionViewModel } from '../../viewmodels/Collections/OpportunityStatusReasonCollectionViewModel';
import { EditMethod } from '../../components/EntityDetailPopup/entityDetailPopup.interfaces';

const userManager = new UserManager();

export const TestPage4Component: React.FC<IReactRouterProps> = (props) => {
	const cms = [
		RepresentativeViewCollectionViewModel('cm_representatives'),
		CompanyGroupViewCollectionViewModel('cm_companygroup'),
		VisitingFrequencyViewCollectionViewModel('cm_visitingfrequency'),
		OpportunityStatusReasonCollectionViewModel('cm_opportunityStatusReason'),
	];

	const vms = [
		RepresentativeViewModel('vm_representatives'),
		CompanyGroupViewModel('vm_companygroup'),
		VisitingFrequencyViewModel('vm_visitingfrequency'),
		OpportunityStatusReasonViewModel('vm_opportunityStatusReason'),
	];

	const [defIndex, setDefindex] = useState<number>(0);
	const [id, setId] = useState<string>('');

	async function handle_dropdown_onChange(e) {
		var name = e.target.value;
		var index = cms.findIndex((x) => x.instanceName === name);
		setDefindex(index);
	}

	function handle_BoundListView_onRowClick(tableItem: any) {
		var id = tableItem['id'];
		vms[defIndex].read(id);
		setId(tableItem['id']);
	}

	async function handle_viewModel_event(args) {
		// if (args.detail.crudType === CrudType.Update || args.detail.crudType === CrudType.Delete) {
		// 	console.log('ev');
		// 	await cmTasks.refresh().catch((e) => console.warn(e));
		// }
	}

	function handle_collection_event(args) {
		//uncomment this for logging info
		//console.log('woohoo, collection updated: ' + args.detail.message);
	}

	useEffect(() => {
		cms.forEach((def) => {
			var name = def.instanceName;
			EventBus.instance().on(name, handle_viewModel_event);
		});

		// vms.forEach((def) => {
		// 	var name = def.instanceName;
		// 	EventBus.instance().on(name, handle_viewModel_event);
		// });
	}, []);

	useEffect(() => {
		cms[defIndex].read();
	}, [defIndex]);

	async function test() {}
	async function refresh() {}

	return (
		<PanelMain>
			<div className={styles.container}>
				<div className={styles.formBox}>
					<h3>Additional ViewModel tests</h3>
				</div>
				<div className={styles.formBox}>
					CollectionType:
					<TestDropDownControl
						options={cms}
						property={'cms'}
						optionText={'instanceName'}
						optionValue={'instanceName'}
						onChange={handle_dropdown_onChange}
					/>
				</div>
				<div
					className={styles.box}
					style={{ height: 32 }}
				>
					&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;IsValid: {vms[defIndex].isValid.toString()} | isDirty: {vms[defIndex].isDirty.toString()}
				</div>
				<div>&nbsp;</div>
				<button onClick={async () => await refresh()}>Refresh</button>
				<button onClick={async () => await test()}>Test</button>
			</div>
		</PanelMain>
	);
};

export const TestPage4 = connect(() => {})(withRouter(TestPage4Component));
