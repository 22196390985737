import * as React from 'react';
import styles from './buttonDropDown.module.scss';

interface IButtonDropDownProps {
	className: string,
	items: IButtonDropDownMenuItem[];
	children?: React.ReactNode;
}

interface IButtonDropDownState {
	menuOpen: boolean
}

export class ButtonDropDown
	extends React.Component<IButtonDropDownProps, IButtonDropDownState> {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false
		}
	}

	handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	onCloseMenu = () => {
		this.setState({
			menuOpen: false
		});
	}

	render() {
		return (
			/*TODO: Remove additional div if possible*/
			<div className={styles.dropdown}>
				<button className={this.props.className} onClick={this.handleClick}>
					{this.props.children}
				</button>
				{this.state.menuOpen &&
					<ButtonDropDownMenu
						onClose={this.onCloseMenu}
						items={this.props.items}
					/>
				}
			</div>
		);
	}
}

interface IButtonDropDownMenuProps {
	id?: string,
	onClose: () => void,
	items: IButtonDropDownMenuItem[]
}

interface IButtonDropDownMenuState {

}

export interface IButtonDropDownMenuItem {
	onClick?: (event) => void,
	content: JSX.Element | string,
	key: string
}

export class ButtonDropDownMenu
	extends React.Component<IButtonDropDownMenuProps, IButtonDropDownMenuState> {
	container: React.RefObject<HTMLUListElement>;

	constructor(props) {
		super(props);
		this.container = React.createRef<HTMLUListElement>();
	}

	handleClickOutside = (event) => {
		if (this.container.current && !this.container.current.contains(event.target))
			this.props.onClose();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		//HACK: Can we make this prettier? Global event listerers are ugly at best
	}
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	render() {
		return (
			<ul className={styles.dropdownContent}
				id={this.props.id}
				ref={this.container}
			>
				{this.props.items && this.props.items.map((item) => {
					return <li key={item.key}
						onClick={item.onClick}
					>
						{item.content}
					</li>
				})}
			</ul>
		);
	}
}

interface IButtonDropDownMenuItemProps { }

interface IButtonDropDownMenuItemState { }

export class ButtonDropDownMenuItem
	extends React.Component<
		IButtonDropDownMenuItemProps,
		IButtonDropDownMenuItemState
	> { }
