import { Fragment, useCallback, useState } from 'react';

import styles from './inputFields.module.scss';
import { InputAction, IGeneralProps } from './InputFieldComponent';
import { faCircleDown, faCircleUp } from '@fortawesome/pro-light-svg-icons';

function TextareaComponent(props: IGeneralProps) {
	const [expanded, setExpanded] = useState(false);
	const rows = expanded ? 20 : 3;
	const expandButtonClick = useCallback(() => setExpanded(!expanded), [expanded]);

	return (
		<Fragment>
			<textarea
				name={props.name}
				required={props.required}
				className={styles.textarea}
				rows={rows}
				cols={32}
				defaultValue={props.defaultValue}
				onChange={props.onChange}
				readOnly={props.readOnly}
			/>

			<InputAction
				InputActionObject={{
					onClick: expandButtonClick,
					icon: expanded ? faCircleUp : faCircleDown,
				}}
			/>
		</Fragment>
	);
}

export default TextareaComponent;
