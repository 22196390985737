import BoundInputRows, { generateOptionsProp } from '../../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { useEffect } from 'react';
import { Address } from '../../../../models/Model/Address';
import { IViewModel } from '../../../../viewmodels/_viewModel.interfaces';
import { IEntityFormDetailProps } from '../../entityDetailPopup.interfaces';
import { FormDetail } from '../_FormDetail';
import { stringJoin } from '../_utility';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { InfoGroup } from '../../../InfoBlocks';
import { EntityType } from '../../entityDetailPopup.enums';
import { updateCompanies } from '../../../../store/reducers/CompanySlice';
import { CustomFieldEditor } from '../../CustomField/CustomFieldEditor';
import { DropdownEntityType } from '../../../InputFields/BoundDropDown/types';

interface IAddressFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<Address>;
}

export const AddressFormDetail = (props: IAddressFormDetailProps) => {
	//TODO: Baseform?
	const p = props.vm.getProperties();
	const e = (p: string) => stringJoin(props.vm.getPropertyErrors(p));

	const companies = useAppSelector((state) => state.companies.companies);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={props.vm}
			headerText={props.vm.getProperty(p.street)}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="address-book"
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.EntityDropdownRow
					label={t('company')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.company_Id}
					errorMessage={e(p.company_Id)}
					entityType={EntityType.Company}
					options={generateOptionsProp(companies, 'entity_Id', 'entity_Name')}
					readonly={props.id !== ''}
				/>
			</InfoGroup>

			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('address')}
			>
				<BoundInputRows.PresetDropDownRow
					DDEntityType={DropdownEntityType.addressTypes}
					label={t('addressAttributes.addressType')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.addressType_Id}
					errorMessage={e(p.addressType_Id)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('addressAttributes.street')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.street}
					errorMessage={e(p.street)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('addressAttributes.postalCode')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.postalCode}
					errorMessage={e(p.postalCode)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('addressAttributes.city')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.city}
					errorMessage={e(p.city)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('addressAttributes.country')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.country_Id}
					errorMessage={e(p.country_Id)}
					DDEntityType={DropdownEntityType.countries}
					readonly={!props.isEditing}
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={props.vm}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
};
