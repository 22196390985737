import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './wizardTab.module.scss';
import { Trans } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { faFlagCheckered } from '@fortawesome/pro-regular-svg-icons';
import { ProgressBar } from '../../../ProgressBar/ProgressBar';

export interface IWizardTab {
	isActive?: boolean;
	isDone?: boolean;
	isMandatory?: boolean;
	number: number;
	i18nKey: string;
}

export function WizardTab(props: IWizardTab) {
	const renderTab = useCallback(() => {
		if (props.number === 0) {
			return (
				<div className={styles.tabFinish}>
					<FontAwesomeIcon icon={faFlagCheckered} />
				</div>
			);
		}

		if (props.isDone) {
			return (
				<div className={styles.tabDone}>
					<FontAwesomeIcon icon={'circle-check'} />
				</div>
			);
		}

		let style = styles.tabRegular;

		if (props.isActive) {
			style = `${style} ${styles.tabActive}`;
		}

		return <div className={style}>{props.number}</div>;
	}, [props.isActive, props.isDone, props.number]);

	const renderMandatory = useCallback(() => {
		if (props.isMandatory) {
			return (
				<div className={styles.mandatory}>
					<FontAwesomeIcon icon={'asterisk'} />
				</div>
			);
		}
	}, [props.isMandatory]);

	return (
		<div className={styles.tab}>
			{/* <div className={styles.tabNumber}>
				{props.number}
			</div> */}
			{renderMandatory()}
			{renderTab()}
			<div className={styles.tabText}>
				<Trans i18nKey={props.i18nKey} />
			</div>
		</div>
	);
}
