import { IFormInputValues } from '../globals/types';
import { AuthorizedFetchRequest } from './apiBase';

const baseUrl = 'api/fielddefinition';

export function AddFieldDefinition(formInputValues: IFormInputValues): Promise<Response> {
	const url = { base: baseUrl };

	return AuthorizedFetchRequest(url, 'POST', formInputValues).then((res) => {
		return res;
	});
}

export function GetFieldDefinitions(): Promise<Response> {
	let url = { base: baseUrl, path: '/list' };

	return AuthorizedFetchRequest(url, 'POST', {}).then((res) => {
		return res;
	});
}

export function GetFieldDefinitionByEntity(entity): Promise<Response> {
	const url = { base: `${baseUrl}/entity/${entity}` };

	return AuthorizedFetchRequest(url, 'GET').then((res) => {
		return res;
	});
}

export function GetFieldDefinitionTypes(): Promise<Response> {
	const url = { base: `${baseUrl}/types` };

	return AuthorizedFetchRequest(url, 'GET').then((res) => {
		return res;
	});
}

export function GetFieldDefinitionAreas(): Promise<Response> {
	const url = { base: `${baseUrl}/areas` };

	return AuthorizedFetchRequest(url, 'GET').then((res) => {
		return res;
	});
}

export function UpdateFieldDefinition(fieldDefinitionId: string, formInputValues: IFormInputValues): Promise<Response> {
	const url = { base: `${baseUrl}/${fieldDefinitionId}` };

	const body = fieldDefinitionId ? { id: fieldDefinitionId, ...formInputValues } : formInputValues;

	return AuthorizedFetchRequest(url, 'PUT', body).then((res) => {
		return res;
	});
}

export function DeleteFieldDefinition(fieldDefinitionId: string, deleteFieldValues = true): Promise<Response> {
	const url = { base: `${baseUrl}/delete/${fieldDefinitionId}` };

	if (deleteFieldValues) url.base += '?deleteFieldValues=true';

	return AuthorizedFetchRequest(url, 'DELETE').then((res) => {
		return res;
	});
}

const fieldDefinitionsAPI = {
	Create: AddFieldDefinition,
	Read: GetFieldDefinitions,
	Update: UpdateFieldDefinition,
	Delete: DeleteFieldDefinition,
};

export default fieldDefinitionsAPI;
