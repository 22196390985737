import _ from 'lodash';
import { EntityType as string, FieldAttributeType, ValidationAttributeType, ValidationErrors, EntityType } from '../globals/enums';
import { DateTimeStringEntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/DateTimeStringEntityPropertyValidationAttribute';
import { EntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/EntityPropertyValidationAttribute';
import { NumericRangeEntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/NumericRangeEntityPropertyValidationAttribute';
import { RegexEntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/RegexEntityPropertyValidationAttribute';
import { RequiredEntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/RequiredEntityPropertyValidationAttribute';
import { RequiredIdEntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/RequiredIdEntityPropertyValidationAttribute';
import { StringLengthEntityPropertyValidationAttribute } from '../models/Validation_EntityValidation/StringLengthEntityPropertyValidationAttribute';
import { GuidFactory } from './GuidFactory';
import { isDate, isNilOrEmpty, isNothing } from './utils';
import { IFullFieldDefinition } from '../models/_Custom/FullFieldDefinition';
import { IPropertyValidation, PropertyValidation } from '../models/Validation_EntityValidation/PropertyValidation';
import { IOption } from '../components/InputFields/inputField.types';
import { IEntityValidation } from '../models/Validation_EntityValidation/EntityValidation';
import { getEntityTypeParents } from '../globals/entityTree';

//TODO: NOT THOROUGHLY TESTED!
export module validationUtility {
	/** Filters supplied fieldDefinitions by entityType (including parents) */
	export function filterEnityFieldDefinitions(fieldDefinitions: IFullFieldDefinition[], entityType: EntityType) {
		const entityTypes = getEntityTypeParents(entityType);
		let filtered: IFullFieldDefinition[] = [];

		entityTypes.forEach((et) => {
			const currentEntityTypes = getFieldDefinitionsByType(fieldDefinitions, et);
			filtered = filtered.concat(currentEntityTypes);
		});

		return filtered;
	}

	function getFieldDefinitionsByType(fieldDefinitions: IFullFieldDefinition[], entityType: EntityType) {
		const entityTypeName = _.startCase(entityType);

		return fieldDefinitions.filter((x) => {
			return x.entityType_Name === entityTypeName;
		});
	}

	/** Filters supplied entityValidations by entityType and returns the inner propertyValidations (including parents) */
	export function filterEntityValidationsToPropertyValidations(entityValidations: IEntityValidation[], entityType: EntityType) {
		const entityTypes = getEntityTypeParents(entityType);
		let propertyValidations: IPropertyValidation[] = [];

		entityTypes.forEach((et) => {
			const currentPropertyValidations = getPropertyValidationsByType(entityValidations, et);
			propertyValidations = propertyValidations.concat(currentPropertyValidations);
		});

		return propertyValidations;
	}

	function getPropertyValidationsByType(entityValidations: IEntityValidation[], entityType: EntityType) {
		let propertyValidations: IPropertyValidation[] = [];

		const filtered = entityValidations.filter((x) => {
			return x.entityType === entityType;
		});

		filtered.forEach((entityValidation) => {
			const currentPropertyValidations = entityValidation.propertyValidations!;
			propertyValidations = propertyValidations.concat(currentPropertyValidations);
		});

		return propertyValidations;
	}

	/** For IFieldDefinition.Options */
	export function generateOptionsArrayFromOptions(value: any): IOption[] {
		const arr = generateStringArrayFromOptions(value);

		return arr.map((s) => {
			return { value: s, label: s };
		});
	}

	/** For IFieldDefinition.Options */
	export function generateStringArrayFromOptions(value: any): string[] {
		if (value === undefined || value === null) return [];

		if (Array.isArray(value)) {
			return value as unknown as string[];
		}

		return [];
	}

	function getAttrRequired() {
		const attr = new RequiredEntityPropertyValidationAttribute();
		attr.$type = ValidationAttributeType.requiredEntityPropertyValidationAttribute;
		attr.errorMessageResourceKey = ValidationErrors.defaultRequired;
		return attr;
	}

	function getAttrRegex() {
		const attr = new RegexEntityPropertyValidationAttribute();
		attr.$type = ValidationAttributeType.regexEntityPropertyValidationAttribute;
		attr.errorMessageResourceKey = ValidationErrors.defaultRegex;
		return attr;
	}

	function getAttrDateTime() {
		const attr = new DateTimeStringEntityPropertyValidationAttribute();
		attr.$type = ValidationAttributeType.dateTimeStringEntityPropertyValidationAttribute;
		attr.errorMessageResourceKey = ValidationErrors.defaultDateTime;
		return attr;
	}

	function getAttrStringLength() {
		const attr = new StringLengthEntityPropertyValidationAttribute();
		attr.$type = ValidationAttributeType.stringLengthEntityPropertyValidationAttribute;
		attr.errorMessageResourceKey = ValidationErrors.defaultRange; //TODO?
		return attr;
	}

	function getAttrNumeric() {
		const attr = new NumericRangeEntityPropertyValidationAttribute();
		attr.$type = ValidationAttributeType.numericRangeEntityPropertyValidationAttribute;
		attr.errorMessageResourceKey = ValidationErrors.defaultRange; //TODO?
		return attr;
	}

	export function convertFieldDefinitionToPropertyValidation(def: IFullFieldDefinition) {
		if (def === undefined) return undefined;

		const a: EntityPropertyValidationAttribute[] = [];

		if (def.isRequired) {
			const attrRequired = getAttrRequired();
			attrRequired.allowEmptyStrings = false;
			a.push(attrRequired);
		}

		switch (def.$type) {
			case FieldAttributeType.fieldDefinitionDateTime: {
				const attrDateTime = getAttrDateTime();
				attrDateTime.format = 'o';
				a.push(attrDateTime);
				break;
			}
			case FieldAttributeType.fieldDefinitionText: {
				if (def.length !== null) {
					const attrTextLength = getAttrStringLength();
					attrTextLength.minimumLength = 0;
					attrTextLength.maximumLength = def.length;
					a.push(attrTextLength);
				}
				if (def.regexValidation !== null && def.regexValidation.length > 0) {
					const attrTextReg = getAttrRegex();
					attrTextReg.regexPattern = def.regexValidation;
					a.push(attrTextReg);
				}
				break;
			}
			case FieldAttributeType.fieldDefinitionNumeric: {
				if (def.decimals !== 0) {
					const attrNumericReg = getAttrRegex();
					attrNumericReg.regexPattern = `^\d{1,6}(\.\d{0,${def.decimals}})?$`; //Trevi Regex Handling @ Joas?!
					a.push(attrNumericReg);
				}
				if (def.minimum !== null || def.maximum !== null) {
					const attrNumericRange = getAttrNumeric();
					attrNumericRange.minimum = def.minimum;
					attrNumericRange.maximum = def.maximum;
					a.push(attrNumericRange);
				}
				break;
			}
		}

		if (a.length === 0) return undefined;

		const propertyValidation: IPropertyValidation = {
			$type: 'propertyValidation',
			propertyName: `f_${def.id}`,
			entityPropertyValidationAttributes: a,
		};

		return propertyValidation;
	}

	export const validate = (attributes: EntityPropertyValidationAttribute[], value: any) => {
		let valid = true;
		const errorMessages: string[] = [];

		attributes.forEach((a) => {
			switch (a.$type) {
				case ValidationAttributeType.stringLengthEntityPropertyValidationAttribute: {
					valid &&= validateStringLength(a as StringLengthEntityPropertyValidationAttribute, value);
					break;
				}
				case ValidationAttributeType.numericRangeEntityPropertyValidationAttribute: {
					valid &&= validateNumericRange(a as NumericRangeEntityPropertyValidationAttribute, value);
					break;
				}
				case ValidationAttributeType.requiredEntityPropertyValidationAttribute: {
					valid &&= validateRequiredEntity(a as RequiredEntityPropertyValidationAttribute, value);
					break;
				}
				case ValidationAttributeType.requiredIdEntityPropertyValidationAttribute: {
					valid &&= validateRequiredIdEntity(a as RequiredIdEntityPropertyValidationAttribute, value);
					break;
				}
				case ValidationAttributeType.regexEntityPropertyValidationAttribute: {
					valid &&= validateRegex(a as RegexEntityPropertyValidationAttribute, value);
					break;
				}
				case ValidationAttributeType.dateTimeStringEntityPropertyValidationAttribute: {
					valid &&= validateDateTime(a as DateTimeStringEntityPropertyValidationAttribute, value);
					break;
				}
				default:
					throw new Error(a.$type?.toString() + ' not supported');
			}

			if (!valid && a.errorMessageResourceKey !== null) {
				errorMessages.push(a.errorMessageResourceKey);
			}
		});

		return {
			valid,
			errorMessageKeys: errorMessages,
		};
	};

	function validateStringLength(x: StringLengthEntityPropertyValidationAttribute, value: any): boolean {
		if (value === null) value = '';
		if (_.isString(value)) {
			return value.length >= x.minimumLength && value.length <= x.maximumLength;
		}

		return x.maximumLength === 0;
	}

	function validateNumericRange(x: NumericRangeEntityPropertyValidationAttribute, value: any): boolean {
		if (value! instanceof Number) return false;

		const number = value as number;
		if (Number.isNaN(number)) return false;

		let valid = true;

		if (x.minimum !== null) {
			valid = valid && number >= x.minimum;
		}
		if (x.maximum !== null) {
			valid = valid && number <= x.maximum;
		}

		return valid;
	}

	function validateRequiredEntity(x: RequiredEntityPropertyValidationAttribute, value: any) {
		if (x.allowEmptyStrings === false && isNilOrEmpty(value)) return false;
		return true;
	}

	function validateRequiredIdEntity(x: RequiredIdEntityPropertyValidationAttribute, value: any) {
		if (isNilOrEmpty(value)) return false;
		return value !== GuidFactory.Empty;
	}

	function validateRegex(x: RegexEntityPropertyValidationAttribute, value: any) {
		if (isNothing(x.regexPattern)) return true;
		if (isNothing(value)) return true;
		const reg = new RegExp(x.regexPattern);

		const isValid = reg.test(value.toString());

		return isValid;
	}

	function validateDateTime(x: DateTimeStringEntityPropertyValidationAttribute, value: any) {
		return isDate(value);
	}
}
