import { AuthorizedFetchRequest } from '../../../api/apiBase';
import { GetFieldDefinitionByEntity } from '../../../api/FieldDefinitionAPI';
import { ITableAttribute } from '../../../components/DynamicTable/dynamicTable.index';
import { IOption, ISelectType } from '../../../components/InputFields_OLD/DropdownInput';
import { inputType } from '../../../components/InputFields_OLD/InputFieldComponent';
import { IPanelApiTable, IBlock, IPanelBlockList, IData, IPanelGroup, ISelectionData } from '../../../components/PanelPopup/types';
import { DateType } from '../../../model/_Enums';
// import enums from "../Settings/enums";
import {
	FieldDefinitionBase,
	FieldDefinitionBoolean,
	FieldDefinitionDateTime,
	FieldDefinitionMultipleOption,
	FieldDefinitionSingleOption,
} from '../../Settings/FieldDefinitions/types';
import { ISelectionProperties } from '../Companies/types';

export enum popupEntityType {
	Task = 'Task',
	Company = 'Company',
	Opportunity = 'Opportunity',
	Contact = 'Contact',
	Address = 'Address',
	Note = 'Note',
}

export enum selectionType {
	addresses = 'addresses',
	addressTypes = 'addressTypes',
	appointmentTypes = 'appointmentTypes',
	companies = 'companies',
	contacts = 'contacts',
	countries = 'countries',
	representatives = 'representatives',
	visitingFrequencies = 'visitingFrequencies',
	activeRepresentatives = 'activeRepresentatives',
}

export async function AuthorizedFetchRequestJson(
	url: { base: string; queries?: object },
	method: 'POST' | 'GET' | 'PUT' | 'DELETE',
	inputValues?: any
): Promise<any> {
	const res = await AuthorizedFetchRequest(url, method, inputValues);
	const json = await res.json();

	return json;
}

export async function SearchAndAddCustomFieldBlock(
	entityName: 'Task' | 'Company' | 'Opportunity' | 'Contact' | 'Address' | 'Note',
	blockArray: IBlock[]
): Promise<IBlock[]> {
	const CFBlock = GenerateCustomFieldBlock(popupEntityType[entityName]);

	const CFBlockIndex = blockArray.findIndex((block) => {
		if (block.data.length > 0) return block.data[0].property.name === 'fieldDefinitionValues';
		else return -1;
	});

	if (CFBlockIndex >= 0) {
		blockArray[CFBlockIndex] = await CFBlock;
	} else {
		blockArray.push(await CFBlock);
	}

	return blockArray;
}

export async function initPopupBlockListPanels(popupPanels: IPanelGroup[], entityType: popupEntityType): Promise<IPanelGroup[]> {
	if (!popupPanels[0] || !popupPanels[0].panels[0]) return [];

	let blockListPanel = popupPanels[0].panels[0] as IPanelBlockList;

	const FDBlockIndex = blockListPanel.blocks.findIndex((block) => {
		if (block.data.length === 0) return -1;
		return block.data[0].property.name === 'fieldDefinitionValues';
	});

	const customFieldBlock = await GenerateCustomFieldBlock(entityType);

	if (FDBlockIndex >= 0) {
		blockListPanel.blocks[FDBlockIndex] = customFieldBlock;
	} else {
		blockListPanel.blocks.push(customFieldBlock);
	}

	return popupPanels;
}

export function getDefaultSelectionProperties(type: popupEntityType): ISelectionProperties {
	const result: ISelectionProperties = {} as ISelectionProperties;

	switch (type) {
		default:
	}

	return result;
}

const selectionObjTypesMap = {
	[popupEntityType.Company]: [
		selectionType.companies,
		selectionType.visitingFrequencies,
		selectionType.representatives,
		selectionType.countries,
		selectionType.addressTypes,
		selectionType.appointmentTypes,
	],
	[popupEntityType.Task]: [selectionType.companies, selectionType.activeRepresentatives, selectionType.representatives],
	[popupEntityType.Opportunity]: [selectionType.companies, selectionType.representatives],
	[popupEntityType.Contact]: [selectionType.companies, selectionType.addresses],
};

export function getSelectionPropertiesFromGlobals(globals: Object, type: popupEntityType) {
	const selectionTypesArray: selectionType[] = selectionObjTypesMap[type];
	let selections = {};

	selectionTypesArray.forEach((selType) => {
		selections[selType] = generateSelectionObjectWithArray(globals[selType], selType);
	});

	return selections;
}

export async function getSelectionProperties(type: popupEntityType): Promise<ISelectionProperties> {
	const result: ISelectionProperties = getDefaultSelectionProperties(type);

	const selectionTypesArray = selectionObjTypesMap[type];
	//EG: for type: Task; this would be: [companies, representatives];

	/*	create an array promises; this will soon be an array of selectionObjects, 
		but getSelectionObject is async, so... */
	const promises = new Array<object>(selectionTypesArray.length);
	selectionTypesArray.forEach((selectionType, i) => {
		promises[i] = getSelectionObject(selectionType);
	});

	/*	...here we await array of promises, 
		ensuring we have an array of selectionObjects instead*/
	const resultArray = await Promise.all(promises);

	//TODO: Error checking?
	//Finally, map results to selectionProperties
	for (let i = 0; i < (await resultArray).length; i++) {
		const name = selectionTypesArray[i];
		const value = resultArray[i];

		result[name] = value;
	}

	return result;
}

export async function GenerateCustomFieldBlock(entityType: popupEntityType): Promise<IBlock> {
	const fieldDefinitionBlock: IBlock = {
		headTitle: 'Custom fields',
		data: [],
	};

	return GetFieldDefinitionByEntity(entityType)
		.then((fds) => {
			if (fds) {
				return fds.json().then((fdsJson) => {
					fieldDefinitionBlock.data[0] = {
						property: {
							name: 'fieldDefinitionValues',
							children: fdsJson.map((FD, index) => {
								return {
									property: {
										name: index.toString(),
										children: [
											generateCustomField(FD),
											{
												type: 'hidden',
												property: {
													name: 'fieldDefinition_Id',
												},
												defaultValue: FD.id,
											},
											{
												type: 'hidden',
												property: {
													name: 'fieldDefinition_Type',
												},
												defaultValue: FD.type,
											},
										],
									},
									type: 'parent',
								};
							}),
						},
						type: inputType.hidden,
					};

					return fieldDefinitionBlock;
				});
			} else {
				return fieldDefinitionBlock;
			}
		})
		.catch((err) => {
			console.error(err);

			return fieldDefinitionBlock;
		});
}

function generateCustomField(FD: FieldDefinitionBase): IData | ISelectionData {
	let type: inputType | ISelectType;
	let selections: IOption[] | undefined = undefined;

	switch (FD.$type) {
		case 'fieldDefinitionBoolean':
			type = inputType.boolean;
			const booleanFD = FD as FieldDefinitionBoolean;

			selections = [
				{
					label: booleanFD.valueTrue,
					value: booleanFD.valueTrue,
				},
				{
					label: booleanFD.valueFalse,
					value: booleanFD.valueFalse,
				},
			];

			break;
		case 'fieldDefinitionDateTime':
			const dateTimeFD = FD as FieldDefinitionDateTime;
			if (dateTimeFD['type'] === 1) type = inputType.date;
			else if (dateTimeFD['type'] === 2) type = inputType.time;
			else type = inputType['datetime-local'];
			break;
		case 'fieldDefinitionMultipleOption':
			const multiOptionFD = FD as FieldDefinitionMultipleOption;
			type = ISelectType.multiSelect;
			selections = multiOptionFD.options.map((option) => {
				return { label: option, value: option };
			});
			break;
		case 'fieldDefinitionNumeric':
			type = inputType.number;
			break;
		case 'fieldDefinitionSingleOption':
			const singleOptionFD = FD as FieldDefinitionSingleOption;
			type = ISelectType.select;
			selections = singleOptionFD.options.map((option) => {
				return { label: option, value: option };
			});
			break;
		default:
			type = inputType.text;
			break;
	}

	let customField: IData = {
		property: {
			name: `value`,
		},
		headTitle: FD.label,
		type: type,
		defaultValue: FD.defaultValue,
	};

	if (selections !== undefined) {
		const selectionCustomField: ISelectionData = {
			...(customField as ISelectionData),
			selections: selections,
			selectionsPropertyName: '',
		};

		return selectionCustomField;
	} else return customField;
}

interface getSelectionPropertyFromAPIParams {
	url: string;
	propertyForSelectionName: string;
	propertiesForSelectionLabel: string[];
	joinString?: string;
	queries?: any;
}

function selectionPropertiesFromSelectionType(type: selectionType): getSelectionPropertyFromAPIParams {
	let params: getSelectionPropertyFromAPIParams = {
		url: '',
		propertyForSelectionName: 'id',
		propertiesForSelectionLabel: ['name'],
	};

	switch (type) {
		case 'addresses':
			params.url = 'api/Address';
			params.propertiesForSelectionLabel = ['street', 'city'];
			params.joinString = ', ';
			break;
		case 'addressTypes':
			params.url = 'api/AddressType';
			params.propertiesForSelectionLabel = ['code', 'description'];
			params.joinString = ': ';
			break;
		case 'appointmentTypes':
			params.url = 'api/appointmentType';
			break;
		case 'companies':
			params.url = 'api/Company';
			break;
		case 'contacts':
			params.url = 'api/Contact';
			params.propertiesForSelectionLabel = ['firstName', 'surname'];
			break;
		case 'countries':
			params.url = 'api/Country';
			break;
		case 'representatives':
			params.url = 'api/Representative';
			params.propertiesForSelectionLabel = ['fullName'];
			break;
		case 'visitingFrequencies':
			params.url = 'api/VisitingFrequency';
			params.propertiesForSelectionLabel = ['description'];
			break;
		case 'activeRepresentatives':
			//FIXME: this one should always be retrieved from the globals redux store, so should not have a url specified
			params.url = 'api/Representative';
			params.propertiesForSelectionLabel = ['fullName'];
			params.queries = {
				IsActive: true,
			};
			break;
	}

	return params;
}

export function generateSelectionObjectWithArray(selections: object[], type: selectionType): Object {
	const selectionProps = selectionPropertiesFromSelectionType(type);
	let obj = {};

	try {
		selections.forEach((selection) => {
			let label: string[] = [];
			selectionProps.propertiesForSelectionLabel.forEach((propertyName) => {
				if (selection[propertyName] !== null) label.push(selection[propertyName]);
			});
			obj[selection[selectionProps.propertyForSelectionName]] = label.join(selectionProps.joinString !== undefined ? selectionProps.joinString : ' ');
		});
	} catch (e) {
		console.error(e, selections);
	}

	return obj;
}

export async function getSelectionObject(selectionType: selectionType, extendUrl?: object): Promise<Object> {
	const params: getSelectionPropertyFromAPIParams = selectionPropertiesFromSelectionType(selectionType);
	const url = {
		base: params.url,
		path: '/list',
	};
	//FIXME: either always pass along queries, or move definition of those queries to one place
	const addedQueries = params.queries ? params.queries : extendUrl;
	const entities = await AuthorizedFetchRequestJson(url, 'POST', {
		...addedQueries,
	});

	return generateSelectionObjectWithArray(entities, selectionType);
}

export function filterPropsFromEntityTableAttributes(tableAttributes: ITableAttribute[], propertiesToSkip: string[]): ITableAttribute[] {
	const filteredTableAttributes = tableAttributes.filter((tableAttribute) => {
		for (let i = 0; i < propertiesToSkip.length; i++) {
			const property = propertiesToSkip[i];
			if (tableAttribute.property.name === property) return false;
		}

		return tableAttribute;
	});

	return filteredTableAttributes;
}
