const getErrorMessage = (errorCode: string, errorName: string) => {
	if (errorCode !== undefined) {
		switch (errorCode) {
			case '0':
				return errorName === 'StorageQuotaFault'
					? 'files.errorMessages.notEnoughSpace'
					: 'files.errorMessages.fileNotFound';
			case '1':
				return 'files.errorMessages.maximumLimit';
			case '2':
				return 'files.errorMessages.contentNotFound';
			default:
				return 'files.errorMessages.errorOccurred';
		}
	} else {
		return 'files.errorMessages.errorOccurred';
	}
};
export default getErrorMessage;
