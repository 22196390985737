import _ from "lodash";
import React, { useImperativeHandle, useEffect, useRef, useState } from "react";
import { DropDownControl } from "../InputControls/DropDownControl";
import { TextControl } from "../InputControls/TextControl"

export interface IBoundDropDownControlProps {
  vm?: any;
  options: any[];
  optionText?: string;
  optionValue?: string;
  property?: string;
  onChange?: (value: any) => void;
}

export const TestDropDownControl = React.forwardRef((props: IBoundDropDownControlProps, ref) => {
  const [options, setOptions] = useState<object[]>([]);
  const [id, setId] = useState<string>();
  const selectedValueRef = useRef<string>();

  useImperativeHandle(ref, () => ({
    selectedValueRef
  }));

  const init = () => {
    if (!props.options) return;
    if (!props.property) return;
    if (!props.optionText || !props.optionValue) return;

    const optionArray: object[] = [];

    _.forOwn(props.options, (value, key) => {
      const option: any = {};
      const optionText = _.get(value, props.optionText!);
      const optionValue = _.get(value, props.optionValue!);

      option['text'] = optionText;
      option['value'] = optionValue;

      optionArray.push(option);
    })

    setOptions(optionArray);

    if (optionArray.length > 0) {
      const first = optionArray[0];
      selectedValueRef.current = first['value'];
    }

    if (props.vm && props.vm.model) {
      const idValue = _.get(props.vm.model, props.property);
      setId(idValue);
    }
  }

  function handle_onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    selectedValueRef.current = event.target.value;

    if (props.vm && props.vm.model) {
      const value = event.target.value;
      _.set(props.vm.model, props.property!, value);
    }

    if (props.onChange) {
      props.onChange(event);
    }
  }

  useEffect(() => {
    init();
  }, [props.options])

  return (
    <DropDownControl
      options={options}
      onChange={handle_onChange}
      defaultValue={id}
    />
  )
});
