import * as React from 'react';
import { withRouter } from '../../../compat/RouterCompat';

import { Trans } from 'react-i18next';
import { ExternalConnection } from './ExternalConnection';
import { ExternalConnectionsProps, ExchangeExternalConnectionState } from '../types';
import { getExchangeTasks } from '../../../api/ExternalConnectionAPI';

export class ExchangeExternalConnection extends ExternalConnection<
    ExternalConnectionsProps,
    ExchangeExternalConnectionState
> {
	constructor(props) {
		super(props);

		this.state = {
			tasks: []
		};
	}

	componentDidMount() {
		this.getExchangeTasks();
	}

	async getExchangeTasks() {
		this.props.toggleLoading(true);
		let response = await getExchangeTasks();
		if (response.ok) {
			let tasks = await response.json();
			this.setState({
				tasks: tasks,
			});
		}
		this.props.toggleLoading(false);
	}

	renderTasks(tasks): JSX.Element[] {
		return tasks.map((task, key) => {
			return (
				<tr key={key}>
					<td style={{ paddingRight: 10 }} >
						{this.getStatusIcon(task)} {task.extendedProperties.filter(p => p.name === "Email")[0].value}
					</td>
					<td>{task.representative.fullName} ({task.representative.code})</td>
				</tr>)
		});
	}

	getStatusIcon(task): JSX.Element {
		if (task.active)
			return <div className="status_indicator green"></div>

		return <div className="status_indicator red"></div>
	}

	renderAdditionalInfo = () => {
		return (
			<table style={{
				visibility: this.state.tasks.length > 0 ? "visible" : "collapse",
				margin: "auto",
				paddingTop: 20
			}}>
				<thead>
					<tr>
						<th><Trans i18nKey='externalConnectionExchangeEmail' /></th>
						<th><Trans i18nKey='externalConnectionExchangeRepresentative' /></th>
					</tr>
				</thead>
				<tbody>
					{this.renderTasks(this.state.tasks)}
				</tbody>
			</table>
		);
	}
}

export const ExchangeConnection = withRouter(ExchangeExternalConnection);