import styles from '../../formDetail.module.scss';
import { Fragment, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole, faPenToSquare, faSave } from '@fortawesome/pro-light-svg-icons';
import { IButtonsProps } from '../../../entityDetailPopup.interfaces';
import { openAlert, openConfirm } from '../../../../Dialog/dialogSlice';
import { useDispatch } from 'react-redux';

export function ButtonsDisable(props: IButtonsProps) {
	const [editing, setEditing] = [props.editing, props.setEditing];
	const dispatch = useDispatch();

	async function deleteItem() {
		await props.vm.del();
		props.closeDetailPopup();
	}

	const toggleEditing = useCallback(() => {
		setEditing(!editing);
	}, [editing, setEditing]);

	function confirmDelete() {
		dispatch(
			openConfirm({
				title: 'Verwijderen',
				text: 'Weet u zeker dat u wilt deactiveren?',
				confirmButtons: [
					{
						confirmText: 'Deactiveren',
						onClick: async () => await deleteItem(),
					},
				],
			})
		);
	}

	const saveButtonClassName = `${styles.formButton} ${!props.vm.isValid && styles.disabled}`;

	return (
		<Fragment>
			{props.children}

			{props.editing ? (
				<FontAwesomeIcon
					className={saveButtonClassName}
					icon={faSave}
					onClick={async () => {
						if (!props.vm.isDirty) toggleEditing();
						else if (props.vm.isValid) {
							const success = await props.vm.update();

							if (!success) {
								dispatch(
									openAlert({
										text: 'anErrorOccurred',
									})
								);
							} else setEditing(false);
						}
					}}
					size="xl"
				/>
			) : (
				<FontAwesomeIcon
					className={styles.formButton}
					icon={faPenToSquare}
					onClick={toggleEditing}
					size="xl"
				/>
			)}

			<FontAwesomeIcon
				className={styles.formButton}
				icon={faLockKeyhole}
				size="xl"
				onClick={confirmDelete}
			/>
		</Fragment>
	);
}
