import { useCallback, useEffect, useState } from 'react';
import AppointmentViewModel from '../AppointmentViewModel';
import CompanyRelatedViewModel, { ICompanyRelatedModel } from './CompanyRelatedViewModel';
import { api } from '../../api/_Executor';
import {
	IAppointmentAnnotationViewCollectionViewModel,
	useAppointmentAnnotationViewCollectionViewModel,
} from '../Collections/AppointmentAnnotationViewCollectionViewModel';
import { CompanyAnnotationView } from '../../models/BusinessObjects_View/CompanyAnnotationView';
import { CompanyAnnotationQuery } from '../../business/Query/CompanyAnnotationQuery';
import { ICompanyAnnotation } from '../../models/Model/CompanyAnnotation';
import { AppointmentAnnotationQuery } from '../../business/Query/AppointmentAnnotationQuery';
import { isNotNilOrEmpty } from '../../utilities/utils';

export interface IAppointmentFinishModel {
	returnWeek: Date | null;
}

export interface IAppointmentFinishViewModel {
	finish: (id) => Promise<boolean>;
	model: IAppointmentFinishModel;
	hasReturnWeek: boolean;
	defaultReturnWeek?: Date;

	getProperty: (propName: string) => any;
	setProperty: (propName: string, value: any) => void;

	//Related
	companyVM: ICompanyRelatedModel;
	appointmentAnnotationCVM: IAppointmentAnnotationViewCollectionViewModel<ICompanyAnnotation, CompanyAnnotationQuery>;

	getAppointmentAnnotationViews: () => Promise<CompanyAnnotationView[] | undefined>;

	// tasks:ICollectionViewModel<PrioritizedTaskView, PrioritizedTaskQuery>;
	// annotations:ICollectionViewModel<CompanyAnnotationView, CompanyAnnotationQuery>;

	//Commands
}

async function defaultAppointmentFinishModel(companyId: string, representativeId: string): Promise<IAppointmentFinishModel> {
	const priorityWeek = await api.account.getPriorityWeekAsync(companyId, representativeId);

	return { returnWeek: priorityWeek };
}

export function useAppointmentFinishViewModel(appointmentId: string, companyId: string): IAppointmentFinishViewModel {
	const [model, setModel] = useState<IAppointmentFinishModel>({ returnWeek: null });
	const [hasReturnWeek, setHasReturnWeek] = useState<boolean>(false);
	const [priorityWeek, setPriorityWeek] = useState<Date>();

	const mainViewModel = AppointmentViewModel('AppointmentViewModel');
	const companyRelatedVM = CompanyRelatedViewModel('appointmentFinishCompany');
	const appointmentAnnotationCVM = useAppointmentAnnotationViewCollectionViewModel(appointmentId, companyId);

	const finish = useCallback(
		async (id) => {
			const returnWeek = model.returnWeek !== null ? model.returnWeek : undefined;

			return await api.appointment.closeByIdAsync(id, returnWeek);
		},
		[model.returnWeek]
	);

	const getProperty = useCallback(
		(propName: string) => {
			return model[propName];
		},
		[model]
	);

	const setProperty = useCallback(
		(propName: string, value: any) => {
			const newModel = { ...model };

			newModel[propName] = value;

			setModel(newModel);
		},
		[model]
	);

	const checkIfHasReturnWeek = useCallback(async (): Promise<boolean> => {
		const isRecurring = mainViewModel.model?.isFollowUp !== undefined ? mainViewModel.model?.isFollowUp : false;
		const representativeList = await api.representative.listAsync({
			company_Id: mainViewModel.model?.company_Id,
			representative_Id: mainViewModel.model?.assignedTo_Id,
		});
		const hasRepresentativeList = representativeList !== undefined && representativeList?.length > 0;

		const hasReturnWeek = isRecurring && hasRepresentativeList;

		return hasReturnWeek;
	}, [mainViewModel.model?.assignedTo_Id, mainViewModel.model?.company_Id, mainViewModel.model?.isFollowUp]);

	const getAppointmentAnnotationViews = useCallback(async () => {
		const query = new AppointmentAnnotationQuery();
		query.appointment_Id = appointmentId;
		// query.company_Id = companyId;

		return api.appointmentAnnotation.viewAsync(query);
	}, [appointmentId]);

	useEffect(() => {
		if (isNotNilOrEmpty(mainViewModel.model?.assignedTo_Id))
			defaultAppointmentFinishModel(companyId, mainViewModel.model?.assignedTo_Id!).then((defaultModel) => {
				setModel(defaultModel);
				if (defaultModel.returnWeek !== null) setPriorityWeek(defaultModel.returnWeek);
			});
	}, [companyId, mainViewModel.model?.assignedTo_Id]);

	useEffect(() => {
		checkIfHasReturnWeek().then((bool) => setHasReturnWeek(bool));
	}, [checkIfHasReturnWeek]);

	useEffect(() => {
		mainViewModel.read(appointmentId);
	}, [appointmentId]);

	useEffect(() => {
		if (mainViewModel.model === null || mainViewModel.model === undefined) return;
		if (mainViewModel.model.company_Id === null || mainViewModel.model.company_Id === undefined) return;

		companyRelatedVM.read(mainViewModel.model.company_Id);
	}, [mainViewModel.model]);

	return {
		finish,
		model: model,
		hasReturnWeek,
		defaultReturnWeek: priorityWeek,
		setProperty,
		getProperty,
		getAppointmentAnnotationViews,

		companyVM: companyRelatedVM,
		appointmentAnnotationCVM,
	};
}
