import { BooleanToIconConverter } from '../Converters/ColumnConverters';

export const table = {
	title: 'contacts',
	entityName: 'Contact',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'contactAttributes.salutation',
			property: {
				name: 'salutation',
			},
		},
		{
			headTitle: 'contactAttributes.firstName',
			property: {
				name: 'firstName',
			},
		},
		{
			headTitle: 'contactAttributes.surnamePrefixes',
			property: {
				name: 'surnamePrefixes',
			},
		},
		{
			headTitle: 'contactAttributes.surname',
			property: {
				name: 'surname',
			},
		},
		{
			headTitle: 'contactAttributes.function',
			property: {
				name: 'function',
			},
		},
		{
			headTitle: 'contactAttributes.emailAddress',
			property: {
				name: 'emailAddress',
			},
			type: 'email',
		},
		{
			headTitle: 'contactAttributes.phoneNumber',
			property: {
				name: 'phoneNumber',
			},
			type: 'tel',
		},
		{
			headTitle: 'contactAttributes.mobileNumber',
			property: {
				name: 'mobileNumber',
			},
			type: 'tel',
		},
		{
			headTitle: 'contactAttributes.isActive',
			property: {
				name: 'isActive',
			},
			type: 'boolean',
			converter: BooleanToIconConverter,
		},
	],
	apiUrl: '/Contact',
};
