import { IEntityFormDetailProps } from "../entityDetailPopup.interfaces"
import { AppointmentFormDetail } from "./AppointmentFormDetail"

export const AppointmentFormDetailAgenda = (props:IEntityFormDetailProps) => {
	return (
		<AppointmentFormDetail 
			{...props}
			isAgenda={true}
		/>
	)
}