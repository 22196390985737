import { Fragment, useCallback, useMemo, useState } from 'react';
import BooleanInputComponent from '../BooleanInput';
import { DateTimeInput } from '../DateTimeInput';
import DropdownComponent from '../DropdownInput';
import { HyperlinkInputComponent } from '../HyperlinkTextFields';
import NumberInput from '../NumberInputField';
import TextInput from '../TextInputField';
import TextareaComponent from '../TextareaComponent';
import {
	IBoundBooleanRowProps,
	IBoundDateTimeRowProps,
	IBoundDropdownRowProps,
	IBoundEntityDropdownRowProps,
	IBoundHyperlinkRowProps,
	IBoundInputRowProps,
	IBoundNumberRowProps,
	IBoundTextRowProps,
	IBoundTextareaRowProps,
	IDictionaryBoundDropDownRowProps,
	IOption,
	SelectType,
} from '../inputField.types';
import styles from '../inputFields.module.scss';
import { BoundInputRowWrapper } from './BoundInputRowWrapper';
import { EntityDetailPopupConfigured } from '../../EntityDetailPopup/EntityDetailPopup';
import { InputAction } from '../InputAction';
import { faCircleDown, faCircleUp, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { EditMethod } from '../../EntityDetailPopup/entityDetailPopup.interfaces';
import { DictionaryBoundDropDownInput } from '../BoundDropDown/DictionaryBoundDropdownInputRow';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';

function generalInputFieldProps(props: IBoundInputRowProps): {
	onChange: (value: any) => void;
	value: any;
	hasError?: boolean;
	readOnly?: boolean;
} {
	let value = null;

	value = props.vm.getProperty(props.modelValuePropertyName);

	const onChange = (value: any) => {
		props.vm.setProperty(props.modelValuePropertyName, value);
	};

	return {
		onChange,
		value,
		hasError: props.errorMessage !== undefined,
		readOnly: props.readonly,
	};
}

function multiDropdownFieldProps(props: IBoundInputRowProps): {
	onChange: (value: any[]) => void;
	value: any[];
	hasError?: boolean;
	readOnly?: boolean;
} {
	const generalProps = generalInputFieldProps(props);

	let values: any[];
	if (!Array.isArray(generalProps.value)) {
		if (generalProps.value === '' || generalProps.value === null || generalProps.value === undefined) values = [];
		else values = generalProps.value.split(',');
	} else values = generalProps.value;

	const onChange = (selectedValue: any) => {
		const valuesToChange = values;
		const iFound = valuesToChange.findIndex((value) => value === selectedValue);

		if (iFound < 0) valuesToChange.push(selectedValue);
		else valuesToChange.splice(iFound, 1);

		props.vm.setProperty(props.modelValuePropertyName, valuesToChange.join(','));
	};

	return {
		onChange,
		value: values,
		hasError: generalProps.hasError,
		readOnly: generalProps.readOnly,
	};
}

export function BoundTextRow(props: IBoundTextRowProps) {
	return (
		<BoundInputRowWrapper
			{...props}
			InputField={<TextInput {...generalInputFieldProps(props)} />}
		/>
	);
}

export function BoundNumberRow(props: IBoundNumberRowProps) {
	return (
		<BoundInputRowWrapper
			{...props}
			InputField={
				<NumberInput
					{...generalInputFieldProps(props)}
					allowDecimals={props.allowDecimals}
					min={props.min}
					max={props.max}
				/>
			}
		/>
	);
}

export function BoundHyperlinkRow(props: IBoundHyperlinkRowProps) {
	return (
		<BoundInputRowWrapper
			{...props}
			InputField={
				<HyperlinkInputComponent
					{...generalInputFieldProps(props)}
					type={props.type}
				/>
			}
		/>
	);
}

export function BoundTextareaRow(props: IBoundTextareaRowProps) {
	const localStorageItemName = useMemo(
		() => `${props.vm.instanceName}.${props.modelValuePropertyName}.textareaExpandedStatus`,
		[props.vm.instanceName, props.modelValuePropertyName]
	);
	const initialExpandedState = localStorage.getItem(localStorageItemName) === 'true';

	const [expanded, setExpanded] = useState(initialExpandedState);

	const expandButtonClick = useCallback(() => {
		const newExpandedState = !expanded;
		localStorage.setItem(localStorageItemName, newExpandedState.toString());
		setExpanded(newExpandedState);
	}, [expanded, localStorageItemName]);

	return (
		<BoundInputRowWrapper
			{...props}
			InputField={
				<TextareaComponent
					{...generalInputFieldProps(props)}
					expanded={expanded}
				/>
			}
			inputActionAlwaysVisible
			InputAction={
				<InputAction
					onClick={expandButtonClick}
					icon={expanded ? faCircleUp : faCircleDown}
				/>
			}
		/>
	);
}

export function BoundBooleanRow(props: IBoundBooleanRowProps) {
	const inputFieldProps = generalInputFieldProps(props);
	const deleteButton = !props.noDeleteButton ? (
		<InputAction
			onClick={() => inputFieldProps.onChange(null)}
			icon={faTrashAlt}
		/>
	) : undefined;

	return (
		<BoundInputRowWrapper
			{...props}
			InputField={
				<BooleanInputComponent
					{...inputFieldProps}
					storeBooleanLabelsAsValue={props.storeBooleanLabelsAsValue}
					booleanLabels={props.booleanLabels}
				/>
			}
			InputAction={deleteButton}
		/>
	);
}

export function BoundDateTimeRow(props: IBoundDateTimeRowProps) {
	const inputFieldProps = generalInputFieldProps(props);

	return (
		<BoundInputRowWrapper
			{...props}
			InputField={
				<DateTimeInput
					{...inputFieldProps}
					type={props.type}
				/>
			}
			InputAction={
				<InputAction
					onClick={() => inputFieldProps.onChange(null)}
					icon={faTrashAlt}
				/>
			}
		/>
	);
}

export function DictionaryBoundDropdownRow(props: IDictionaryBoundDropDownRowProps) {
	return (
		<BoundInputRowWrapper
			InputField={
				<DictionaryBoundDropDownInput
					DDEntityType={props.DDEntityType}
					readId={props.readId}
					{...generalInputFieldProps(props)}
				/>
			}
			label={props.label}
		/>
	);
}

export function BoundDropdownRow(props: IBoundDropdownRowProps) {
	let inputField: JSX.Element;

	if (props.type === SelectType.multiSelect) {
		const inputFieldProps = {
			...multiDropdownFieldProps(props),
			options: props.options,
		};

		inputField = <DropdownComponent {...inputFieldProps} />;
	} else {
		const inputFieldProps = {
			...generalInputFieldProps(props),
			options: props.options,
		};

		inputField = <DropdownComponent {...inputFieldProps} />;
	}

	return (
		<BoundInputRowWrapper
			{...props}
			InputField={inputField}
		/>
	);
}

export function BoundEntityDropdownRow(props: IBoundEntityDropdownRowProps) {
	const [currentId, setCurrentId] = useState<string>();

	const closeFunction = useCallback(() => setCurrentId(undefined), []);

	let inputField: JSX.Element;
	if (props.readonly) {
		const value = props.vm.getProperty(props.modelValuePropertyName);
		const selectedOption = props.options?.find((option) => option.value === value);

		inputField = (
			<div className={styles.hyperlinkWrapper}>
				<div
					className={styles.entityLink}
					onClick={() => setCurrentId(selectedOption?.value)}
				>
					{selectedOption?.label}
				</div>
			</div>
		);
	} else {
		const inputFieldProps = {
			...generalInputFieldProps(props),
			options: props.options,
			type: SelectType.select,
		};

		inputField = <DropdownComponent {...inputFieldProps} />;
	}
	return (
		<Fragment>
			<BoundInputRowWrapper
				{...props}
				InputField={inputField}
			/>
			{currentId !== undefined && (
				<EntityDetailPopupConfigured
					id={currentId}
					editMethod={EditMethod.update}
					popupEntityType={props.entityType}
					closeFunction={closeFunction}
					viewModelInstanceName={EntityType[props.entityType]}
				/>
			)}
		</Fragment>
	);
}

export function generateOptionsProp(objects: Object[], valuePropName: string, labelPropName: string | string[], labelString?: string): IOption[] {
	return objects.map((object) => {
		return generateOptionProp(object, valuePropName, labelPropName, labelString);
	});
}

export function generateOptionProp(object: Object, valuePropName: string, labelPropName: string | string[], labelString?: string): IOption {
	const option: IOption = { value: object[valuePropName], label: '' };
	if (Array.isArray(labelPropName)) {
		const labels: string[] = [];
		labelPropName.forEach((propName) => {
			if (object[propName] !== undefined) {
				labels.push(object[propName]);
			}
		});
		if (labelString !== undefined) {
			option.label = labelString.replace(/%\w+%/g, (subStr) => {
				const index = subStr.slice(1, -1);

				return labels[index];
			});
		} else option.label = labels.join(' ');
	} else if (typeof labelPropName === 'string') option.label = object[labelPropName];
	return option;
}

const BoundInputRows = {
	TextRow: BoundTextRow,
	NumberRow: BoundNumberRow,
	HyperlinkRow: BoundHyperlinkRow,
	TextareaRow: BoundTextareaRow,
	BooleanRow: BoundBooleanRow,
	DateTimeRow: BoundDateTimeRow,
	PresetDropDownRow: DictionaryBoundDropdownRow,
	DropdownRow: BoundDropdownRow,
	EntityDropdownRow: BoundEntityDropdownRow,
};

export default BoundInputRows;
