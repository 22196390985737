const millisecondsInADay = 86400000;
const millisecondsInAWeek = millisecondsInADay * 7;

export namespace dateHelper {
	export interface IDateRemainder {
		d: number;
		w: number;
		m: number;
		y: number;
	}

	export const getDateNow = () => {
		//With current timezone offset
		const date = new Date();
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	};

	//00-00-0000
	//TODO: Do proper localisation. Maybe no const?
	export const DATE_FORMATTER_SHORT = new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
	export const MS_PER_DAY: number = 1000 * 60 * 60 * 24;

	export const getDaysDiff = (date1: Date, date2: Date): number => {
		const diffInMs = date1.getTime() - date2.getTime();
		const diffInDays = Math.round(diffInMs / MS_PER_DAY);
		return diffInDays;
	};

	export const getDateRemainder = (date1: Date, date2: Date): IDateRemainder => {
		const date1_time_stamp = date1.getTime();
		const date2_time_stamp = date2.getTime();

		let diff: Date;

		//Check which timestamp is greater
		if (date1_time_stamp > date2_time_stamp) {
			diff = new Date(date1_time_stamp - date2_time_stamp);
		} else {
			diff = new Date(date2_time_stamp - date1_time_stamp);
		}

		//Retrieve the date, month and year
		const diffFormatTmp = diff.getDate() + '-' + (diff.getMonth() + 1) + '-' + diff.getFullYear();
		const calcFormat = diffFormatTmp.split('-');

		const temp_days = calcFormat[0] as unknown as number;
		const temp_months = calcFormat[1] as unknown as number;
		const temp_years = calcFormat[2] as unknown as number;

		const days_passed = Number(Math.abs(temp_days) - 1);
		const months_passed = Number(Math.abs(temp_months) - 1);
		const years_passed = Number(Math.abs(temp_years) - 1970);

		const total_days = years_passed * 365 + months_passed * 30.417 + days_passed;
		const total_weeks = total_days >= 7 ? total_days / 7 : 0;

		const dateRemainder: IDateRemainder = {
			d: Math.round(total_days),
			w: Math.round(total_weeks),
			m: Math.round(months_passed),
			y: Math.round(years_passed),
		};

		return dateRemainder;
	};

	export const formatRemainderAsString = (remainder: IDateRemainder, asPositive: boolean): string => {
		//TODO: Do proper localisation
		const isPositive = asPositive;
		const isNegative = !asPositive;
		const r = remainder;

		if (r.d === 0) {
			return 'today';
		}
		if (r.y > 0) {
			if (isPositive) return `${r.y}Y`;
			if (isNegative) return `-${r.y}Y`;
		}
		if (r.m > 0) {
			if (isPositive) return `${r.m}M`;
			if (isNegative) return `-${r.m}M`;
		}
		if (r.w > 0) {
			if (isPositive) return `${r.w}W`;
			if (isNegative) return `-${r.w}W`;
		}
		if (isPositive) return `${r.d}D`;
		if (isNegative) return `-${r.d}D`;

		console.warn('woopiedoepsie, what the heck?');
		return '';
	};
	// Displayed date dd/mm/yyyy
	export const getDateFormatted = (date: string) => {
		return new Date(date).toLocaleDateString();
	};

	// hh:mm
	export const getTimeFormatted = (date: string) => {
		const d = new Date(date);
		const timeComponents = [d.getHours(), d.getMinutes()];
		return timeComponents
			.map((component) => {
				const createTwoDigits = component < 10 ? '0' : '';
				return createTwoDigits + component;
			})
			.join(':');
	};

	export function getWeekNumber(date: Date): [week: number, year: number] {
		const yearStart = +new Date(date.getFullYear(), 0, 1);
		const dateCopy = +new Date(date.getFullYear(), date.getMonth(), date.getDate());
		const dayOfYear = (dateCopy - yearStart + 1) / millisecondsInADay;
		const weekNumber = Math.ceil(dayOfYear / 7);

		return [weekNumber, date.getFullYear()];
	}

	export function createDateFromWeek(week: number, year: number): Date {
		const yearStart = new Date(year, 0, 1);
		const dateOfWeek = new Date(+yearStart + week * millisecondsInAWeek);

		return dateOfWeek;
	}
}
