import { IPasswordCheck } from '../globals/types';

export const passwordChecks: IPasswordCheck[] = [
	{
		regex: /^.+$/m,
		errorMessage: 'enterNewPassword',
	},
	{
		regex: /^.{8,}$/m,
		errorMessage: 'minPasswordLength',
	},
	{
		regex: /^.{1,100}$/m,
		errorMessage: 'maxPasswordLength',
	},
];