import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../../../compat/RouterCompat';
import styles from '../settingsPage.module.scss';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { ExternalConnection } from './ExternalConnection';
import { initExactConnection } from '../../../api/ExternalConnectionAPI';
import { ExactConnectionProps, ExternalConnectionState } from '../types';
import { InfoRow } from '../../../components/InfoBlocks';
import { connect } from 'react-redux';
import { openAlert } from '../../../components/Dialog/dialogSlice';

class ExactConnectionComponent extends ExternalConnection<ExactConnectionProps, ExternalConnectionState> {
	//TODO: Configurable
	private clientId = 'b74249e1-51d1-467c-a6da-c7610aed7144';
	private redirectUri = `https://app.my-desk.nl/Settings/externalConnections/Exact/callback`;

	//TODO: get from back-end as this depends on the Exact environment of the end user
	private handleButtonClick = () => {
		window.open(
			`https://start.exactonline.nl/api/oauth2/auth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&force_login=1`,
			'_self'
		);
	};

	renderAdditionalInfo = () => {
		return (
			<React.Fragment>
				<InfoRow label={t('externalConnection.authorize')}>
					<button
						className={`${styles.button} text-capitalize`}
						onClick={this.handleButtonClick}
						type='button'
					>
						<Trans i18nKey='authorizeExact' />
					</button>
					<Routes>
						<Route
							path={`/Settings/ExternalConnections/Exact/callback`}
						>
							<ExactCallbackHandler {...this.props} />
						</Route>
					</Routes>
				</InfoRow>
			</React.Fragment>
		);
	};
}

class ExactCallbackHandler extends React.Component<ExactConnectionProps> {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		if (this.props.location.search) {
			const code = new URLSearchParams(this.props.location.search).get(
				'code'
			);

			let content: string = '';
			if (code) 
				await initExactConnection(code).then((res) => {
					content = res.ok ? t('exactConnectionSuccessful') : t('anErrorOccurred');
				});
			else
				content = t('anErrorOccurred');

			if (content)
				this.props.dispatch(
					openAlert({
						text: content,
					})
				);
		}
	}

	render() {
		return <React.Fragment />
	};
}

export const ExactConnection = withRouter(connect()(ExactConnectionComponent));
