import { IIconCustomDefinition } from "./IconCustom.index";

export class IconCustomDefinition implements IIconCustomDefinition {
	public isSquare = false;

	graphic?:string;
	parent?: IconCustomDefinition;
	thickNess?:number;
	foregroundClassName?:string;
	backgroundClassName?:string;
	foregroundColor?:string;
	backgroundColor?:string;
	foregroundPxSize?:number;
	backgroundPxSize?:number;
	foregroundCorrectionX?:number;
	foregroundCorrectionY?:number;
	autoHover?:boolean;
	flipHorizontal?:boolean;

	sAuto = (bool:boolean) => {
		this.autoHover = bool;
		return this;
	}

	sDaddy = (daddy:IconCustomDefinition) => {
		this.parent = daddy;
		return this;
	}

	sImage = (graphic:string) => {
		this.graphic = graphic;
		return this;
	}

	sThick = (thickNess:number)=> {
		this.thickNess = thickNess;
		return this;
	}

	sPX = (size:number) => {
		this.foregroundPxSize = size;
		return this;
	}

	sConPx = (size:number) => {
		this.backgroundPxSize = size;
		return this;
	}
	
	sColor = (color:string) => {
		this.foregroundColor = color;
		return this;
	}

	sConColor = (color:string) => {
		this.backgroundColor = color;
		return this;
	}

	sClass = (className:string) => {
		this.foregroundClassName = className;
		return this;
	}

	sConClass = (className:string) => {
		this.backgroundClassName = className;
		return this;
	}
	
	sCorX = (correction:number) => {
		this.foregroundCorrectionX = correction;
		return this;
	}

	sCorY = (correction:number) => {
		this.foregroundCorrectionY = correction;
		return this;
	}

	sCor = (x:number, y:number) => {
		this.foregroundCorrectionX = x;
		this.foregroundCorrectionY = y;
		return this;
	}
	
	sFlip = (flip:boolean) => {
		this.flipHorizontal = flip;
		return this;
	}

	constructor(
		parent?: IconCustomDefinition,
		graphic?:string,
		thickNess?:number,
		foregroundClassName?:string,
		backgroundClassName?:string,
		foregroundColor?:string,
		backgroundColor?:string,
		foregroundPxSize?:number,
		backgroundPxSize?:number,
		foregroundCorrectionX?:number,
		foregroundCorrectionY?:number,
		autoHover?:boolean,
		flipHorizontal?:boolean,
	) 
	{
		// const parents:Array<IconCustomDefinition> = [];
		// let father = this.parentIconId;

		// while (father) {
		// 	parents.push({...father});
		// 	father = father.parentIconId;
		// }

		// for (let i = parents.length - 1; i>=0; i--) {
		// 	const daddy = parents[i];

		// 	this.graphic = daddy.graphic;
		// 	this.foreground = daddy.foreground;
		// 	this.background = daddy.background;
		// 	this.thickNess = daddy.thickNess;
		// 	this.className = daddy.className;
		// }
		if (parent) {
			const daddy = parent;

			this.graphic = daddy.graphic;
			this.thickNess = daddy.thickNess;
			this.foregroundColor = daddy.foregroundColor;
			this.backgroundColor = daddy.backgroundColor;
			this.foregroundClassName = daddy.foregroundClassName;
			this.backgroundClassName = daddy.backgroundClassName;
			this.foregroundPxSize = daddy.foregroundPxSize;
			this.backgroundPxSize = daddy.backgroundPxSize;
			this.foregroundCorrectionX = daddy.foregroundCorrectionX;
			this.foregroundCorrectionY = daddy.foregroundCorrectionY;
			this.autoHover = daddy.autoHover;
			this.flipHorizontal = daddy.flipHorizontal;
		}

		if (parent) this.parent = parent;
		if (graphic) this.graphic = graphic;
		if (thickNess) this.thickNess = thickNess;
		if (foregroundColor) this.foregroundColor = foregroundColor;
		if (backgroundColor) this.backgroundColor = backgroundColor;
		if (foregroundClassName) this.foregroundClassName = foregroundClassName;
		if (backgroundClassName) this.backgroundClassName = backgroundClassName;
		if (foregroundPxSize) this.foregroundPxSize = foregroundPxSize;
		if (backgroundPxSize) this.backgroundPxSize = backgroundPxSize;
		if (foregroundCorrectionX) this.foregroundCorrectionX = foregroundCorrectionX;
		if (foregroundCorrectionY) this.foregroundCorrectionY = foregroundCorrectionY;
		if (autoHover) this.autoHover = autoHover;
		if (flipHorizontal) this.flipHorizontal = flipHorizontal;

		if (!this.graphic) this.graphic = 'question';

		if (this.thickNess === undefined||null) {
			this.thickNess = 1;
		} 
		if (!this.autoHover) this.autoHover = true;

		// if (!this.foregroundClassName) this.foregroundClassName = 'icon';
		// if (!this.backgroundClassName) this.backgroundClassName = 'icon';

		if (!this.foregroundColor) {
			this.foregroundColor = '#333';
		}

		// if ((this.foregroundColor &&! this.backgroundColor)) {
		// 	this.foregroundColor = BlisssColorValue.white;
		// 	this.backgroundColor = BlisssColorValue.blisssBlue;
		// } else {
		// 	if (!this.foregroundColor) this.foregroundColor = BlisssColorValue.blisssBlue;
		// }
	}
}





// export type WhatEver = 
// 	'42-group' |
// 	'test'






// export type IconCustomDefinitionEnum = {
// 	[key in Option]: IIconCustomDefinition;
// }



// export const IconCustomDefinitions:WhatEver = {

// }


// export const IconCustomDefinitions: IconCustomDefinitionEnum = {
	
// 	// TEST : new IconCustomDefinition('test')


// 	// TEST1 : new IconCustomDefinition('test')
// }

// export const customIconDefinitions:Array<IconCustomDefinition> = [
// 	new IconCustomDefinition(
// 		'test'
// 	)
// ];


// export const customIconDefinitions = [
// 	{
// 		new IIconCustomDefinition();
// 	}
// ]

// export const customIconDefinitions = [
// 	{
// 		"iconname": "dashboard",
// 		"Foreground": "white",
// 		"Background": "blisss_blue",
// 		"Classname": "icon"
// 	},
// 	{
// 		"iconname": "dashboard_company",
// 		"Parent": "dashboard",
// 		"Image": "building",
// 		"Thickness": "0"
// 	}
// ]