import { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';

import styles from './inputFields.module.scss';
import { InputAction, IGeneralProps } from './InputFieldComponent';

export enum LinkType {
	url = 'url',
	email = 'email',
	tel = 'tel',
}

export interface IHyperlinkInputProps extends IGeneralProps {
	type: LinkType;
}

export function HyperlinkInputComponent(props: IHyperlinkInputProps) {
	const [value, setValue] = useState(props.defaultValue);
	useEffect(() => {
		setValue(props.defaultValue);
	}, [props.defaultValue]);

	const changeHandler = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setValue(e.target.value);
			if (props.onChange) props.onChange(e);
		},
		[props.onChange]
	);

	let href = value;
	switch (props.type) {
		case LinkType.email:
			href = `mailto:${value}`;
			break;
		case LinkType.tel:
			href = `${props.type}:${value}`;
			break;
	}

	if (props.readOnly)
		return (
			<div className={styles.hyperlinkWrapper}>
				<a href={href}>{value}</a>
			</div>
		);
	else
		return (
			<Fragment>
				<input
					name={props.name}
					className={styles.input}
					type={props.type}
					required={props.required}
					onChange={changeHandler}
					defaultValue={props.defaultValue}
				/>

				<InputAction InputActionObject={props.InputActionObject} />
			</Fragment>
		);
}
