import { ProgressBar } from '@progress/kendo-react-progressbars';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { t } from 'i18next';
import { Button } from '../../../Button/Button';
import styles from '../fileListView.module.scss';

interface IModalProps {
	toggleDialog: () => void;
	uploadProgress: number;
	file: File;
	cancelUpload: () => void;
}

const Modal = (props: IModalProps) => {
	const modalTitle = t(`files.uploading`);

	return (
		<Dialog
			title={modalTitle}
			closeIcon={false}
			onClose={() => {
				props.cancelUpload();
				props.toggleDialog();
			}}
		>
			<div>
				<p className={styles.fileNameIcon}>
					<FontAwesomeIcon icon={faFile} size="xl" />
					{props.file.name}
				</p>
				<ProgressBar value={props.uploadProgress} animation={true} progressStyle={{ background: '#27AAE1' }} />
			</div>
			<DialogActionsBar>
				<Button
					onClick={() => {
						props.cancelUpload();
						props.toggleDialog();
					}}
					i18nKey="cancel"
				/>
			</DialogActionsBar>
		</Dialog>
	);
};

export default Modal;
