import { EntityType } from "./enums"

export function getEntityTypeParents(entityType: EntityType): EntityType[] {
	return findEntityTreeParents(entityType, EntityTree); 
}

function findEntityTreeParents(
	entityType: EntityType,
	entityTree: IEntityNode,
	parents: EntityType[] = []
): EntityType[] {
	if (entityTree.name === entityType) {
		return [...parents, entityType];
	}

	if (entityTree.kids) {
		for (const kid of entityTree.kids) {
			const result = findEntityTreeParents(entityType, kid, [...parents, entityTree.name]);
			if (result.length !== 0) {
				return result;
			}
		}
	}

	return [];
}

export interface IEntityNode {
	name: EntityType,
	kids?: IEntityNode[]
}

export const EntityTree: IEntityNode = {
	name: EntityType.modelBase,
	kids: [
		{
			name: EntityType.activity,
			kids: [
				{
					name: EntityType.appointment
				}
			]
		},
		{ name: EntityType.address },
		{ name: EntityType.addressType },
		{
			name: EntityType.annotation,
			kids: [
				{
					name: EntityType.companyAnnotation,
					kids: [
						{
							name: EntityType.appointmentAnnotation,
						}
					]
				}
			]
		},
		{ name: EntityType.appointmentType },
		{ name: EntityType.article },
		{ name: EntityType.closeAppointmentField },
		{
			name: EntityType.company,
			kids: [
				{ name: EntityType.lead },
				{ name: EntityType.account },
			]
		},
		{ name: EntityType.companyGroup },
		{ name: EntityType.companyRepresentative },
		{ name: EntityType.contact },
		{ name: EntityType.country },
		{ name: EntityType.day },
		{ name: EntityType.entityAudit },
		{
			name: EntityType.extensionBase,
			kids: [
				{ name: EntityType.fieldValue },
				{ name: EntityType.file },
			]
		},
		{
			name: EntityType.fieldDefinition,
			kids: [
				{ name: EntityType.fieldDefinitionBoolean },
				{ name: EntityType.fieldDefinitionDateTime },
				{ name: EntityType.fieldDefinitionMultipleOption },
				{ name: EntityType.fieldDefinitionNumeric },
				{ name: EntityType.fieldDefinitionSingleOption },
				{ name: EntityType.fieldDefinitionText },
			]
		},
		{ name: EntityType.geocode },
		{ name: EntityType.gridView },
		{ name: EntityType.gridViewColumn },
		{ name: EntityType.opportunity },
		{ name: EntityType.opportunityLine },
		{ name: EntityType.opportunityStatusReason },
		{ name: EntityType.organizationSetting },
		{ name: EntityType.postalCodeArea },
		{ name: EntityType.postalCodePrefix },
		{ name: EntityType.reportTask },
		{ name: EntityType.reportTaskAudit },
		{ name: EntityType.reportTaskParameter },
		{ name: EntityType.representative },
		{ name: EntityType.representativeRole },
		{ name: EntityType.synchronizationFallbackInfo },
		{ name: EntityType.synchronizationState },
		{ name: EntityType.synchronizationTask },
		{ name: EntityType.synchronizationTaskAudit },
		{ name: EntityType.synchronizationTaskExtendedProperty },
		{ name: EntityType.task },
		{ name: EntityType.visitingFrequency },

		{
			name: EntityType.baseQueryModel,
			kids: [
				{ name: EntityType.accountArea },
				{ name: EntityType.artikelGroep },
				{ name: EntityType.bulkCreateModel },
				{ name: EntityType.companyArea },
				{ name: EntityType.entitySynchronizationSet },
				{ name: EntityType.leadArea },
				{
					name: EntityType.prioritizedBase,
					kids: [
						{ name: EntityType.companyMap },
						{ name: EntityType.prioritizedAccount },
						{ name: EntityType.prioritizedCompany },
						{ name: EntityType.prioritizedLead },
						{ name: EntityType.prioritizedOpportunity },
						{ name: EntityType.prioritizedTask },
						{ name: EntityType.prioritiziedAccountWeeks },
					]
				},
				{ name: EntityType.synchronizationEntity }
			]
		}
	]
}