import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../inputFields.module.scss';
import { t } from 'i18next';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { IBoundInputRowWrapperProps } from '../inputField.types';

export function BoundInputRowWrapper(props: IBoundInputRowWrapperProps) {
	let errorTitle: string | undefined;
	if (props.errorMessage) errorTitle = t(props.errorMessage) !== null ? (t(props.errorMessage) as string) : undefined;
	const errorIcon =
		props.errorMessage !== undefined ? (
			<FontAwesomeIcon
				className={styles.errorIcon}
				icon={faTriangleExclamation}
				title={errorTitle}
				size="xl"
			/>
		) : null;

	return (
		<div className={styles.wrapper}>
			{errorIcon}
			<label title={props.label}>{props.label}</label>
			{props.InputField}
			<div className={styles.inputAction}>{(props.inputActionAlwaysVisible || !props.readonly) && props.InputAction}</div>
		</div>
	);
}
