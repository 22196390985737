import * as React from 'react';
import { withRouter } from '../../../compat/RouterCompat';

import { ExternalConnectionsProps } from '../types';
import { InfoGroup } from '../../../components/InfoBlocks';

export class ZapierExternalConnection<Props extends ExternalConnectionsProps> extends React.Component<
    Props
    > {
	//TODO: Configurable
	private clientId = 'c5o8L8Z2fFv3RPDy7o2twXod0peB8y3heRCc3Bsc';

	componentDidMount() {
		const script = document.createElement("script");
		script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
		script.type = "module";
		script.async = true;
		document.body.appendChild(script);
	}

	render() {
		return (
			<InfoGroup title="Zapier" open={false} visible padding={true} >
				<link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css" />

				<zapier-full-experience
					client-id={this.clientId}
					theme="light"
					app-selection-display="hide"
					zap-limit="0"
					zap-create-from-scratch-display="hide"
					style={{ paddingLeft: 15, paddingRight: 15 }}
				/>
			</InfoGroup>
		);
	}
}

export const ZapierConnection = withRouter(ZapierExternalConnection);