import { ReactElement, useEffect, useState } from 'react';
import styles from './checkbox.module.scss';
interface CheckboxProps {
	className?: string;
	onChange?: Function;
	defaultChecked?: boolean;
	isDisabled?: boolean;
	label?: string | ReactElement | null;
}

const Checkbox = ({ className, onChange, defaultChecked = false, label }: CheckboxProps) => {
	const [isChecked, setIsChecked] = useState(defaultChecked);

	useEffect(() => {
		setIsChecked(defaultChecked);
	}, [defaultChecked]);

	const handleChange = (event) => {
		onChange && onChange(event);
		setIsChecked(event.target.checked);
	};

	return (
		<div className={styles.checkboxContainer}>
			<div
				className={styles.checkbox + `${className ?? ''} ${isChecked ? styles.checked : ''}`}
				onClick={(e) => onChange && onChange(e)}
			>
				<p>{label}</p>
				<div className={styles.checkboxWrapper}>
					<input type="checkbox" checked={isChecked} onChange={handleChange} />
					<span className={styles.check}></span>
				</div>
			</div>
		</div>
	);
};

export default Checkbox;
