import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRepresentativeDto } from '../../model/EntityDtos/RepresentativeDto';
import { RootState } from '../store';
import { UserManager } from '../../utilities/UserManager';

interface ICurrentRepresentativeSliceState {
    currentRepresentative: IRepresentativeDto | undefined
}

const initialState : ICurrentRepresentativeSliceState = {
	currentRepresentative: undefined
};

export const currentRepresentativeSlice = createSlice({
	name: 'currentRepresentative',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setCurrentRepresentative: (state, action: PayloadAction<IRepresentativeDto>) => {
			state.currentRepresentative = action.payload;
			UserManager.CurrentRepresentative = action.payload;
		},
		resetCurrentRepresentative: (state) => {
			state.currentRepresentative = undefined;
			UserManager.CurrentRepresentative = undefined;
		}
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => { }
});

export const { setCurrentRepresentative, resetCurrentRepresentative } = currentRepresentativeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentRepresentative = (state: RootState) => state.currentRepresentative.currentRepresentative;

export default currentRepresentativeSlice.reducer;