import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import styles from './loginPanel.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export default class Alert extends React.Component<any, any> {
	render() {
		return (<div className={styles.alert}>
			<div className={styles.icon}>
				<FontAwesomeIcon icon={faExclamationCircle} />
			</div>
			<div className={styles.message}>
				{this.props.children}
			</div>
		</div>)
	}
}