import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { iSortOrderObject, orderStringEnum } from '../viewmodels/Collections/_collectionViewModel.interfaces';

export function sortOrderToIcon(orderString?: orderStringEnum) {
	if (orderString === orderStringEnum.DESC) {
		return faSortDown;
	} else if (orderString === orderStringEnum.ASC) return faSortUp;
	else return faSort;
}

export function convertSortOrderItemToObject(sortOrderItem: string): iSortOrderObject {
	const sortOrderItemSplit = sortOrderItem.split(' ');

	if (sortOrderItemSplit.length < 2) {
		return {
			order: undefined,
			propName: sortOrderItem,
		};
	} else {
		return {
			order: sortOrderItemSplit[0] as orderStringEnum,
			propName: sortOrderItemSplit[1],
		};
	}
}

export function convertSortOrderItemArrayToObjectArray(sortOrderItemArray: string[]): iSortOrderObject[] {
	const sortOrderObjectArray = sortOrderItemArray.map((item) => convertSortOrderItemToObject(item));

	return sortOrderObjectArray;
}

export function findSortOrderObject(propertyName: string, apiQueryOrder: string[]): [iSortOrderObject | undefined, number] {
	const sortOrder = apiQueryOrder;
	const sortOrderObjects = sortOrder.map((item) => convertSortOrderItemToObject(item));
	const sortOrderObject = sortOrderObjects.find((item) => item.propName === propertyName);
	const sortOrderindex = sortOrderObjects.findIndex((item) => item.propName === propertyName);

	return [sortOrderObject, sortOrderindex];
}
