import styles from './loginPanel.module.scss';
import * as React from 'react';
import Container from './Container';
import { WrappedLocaleMenu } from '../LocaleMenu/LocaleMenu';
import { IReactRouterProps } from '../../globals/types';
import { Link } from 'react-router-dom';
import { withRouter } from '../../compat/RouterCompat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';

class LoginLayoutComponent extends React.Component<IReactRouterProps> {
	render() {
		return (
			<React.Fragment>
				<WrappedLocaleMenu
					navigate={this.props.navigate}
					location={this.props.location}
					match={this.props.match}
				/>
				<Container>{this.props.children}</Container>
				<Link
					className={styles.desktopDownloadButton}
					to={`/Download`}
				>
					<FontAwesomeIcon icon='download' />
					<Trans
						i18nKey='downloadMyDesk'
						className='text-capitalize'
					/>
				</Link>
			</React.Fragment>
		);
	}
}

export const LoginLayout = withRouter(LoginLayoutComponent);
