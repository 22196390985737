import { BaseQuery } from "./_BaseQuery";

export class AppointmentQuery extends BaseQuery {
	public representative_Id?:string;
	public weekDate?:Date;
	public returnWholeWeek?:boolean;
	public company_Id?:string;
	public future?:boolean;
	public cultureString?:string;
	public lastModifiedAfter?:Date;
	public appointmentDateAfter?:Date;
	public representativesGuidList?:string[];
	public exchange_Id?:string;
}
