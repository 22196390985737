import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import { ReactElement } from 'react';

type confirmOkClickHandler = () => any;

export interface IDialogState {
	title?: string;
	text?: string;
	content?: ReactElement;
	type: 'alert' | 'prompt' | 'confirm';
	icon?: string;
	dismissButtonPosition: 'top' | 'bottom' | 'both' | 'none';
	dismissText?: string;
	dismissCallBack?: () => void;
	promptValue?: string;
	confirmButtons?: {
		confirmText?: string;
		onClick: confirmOkClickHandler;
	}[];
}

// Define a type for the slice state
interface IOpenDialogParams {
	title?: string;
	text?: string;
	content?: ReactElement;
	icon?: string;
	dismissButtonPosition?: 'top' | 'bottom' | 'none';
	dismissText?: string;
	dismissCallBack?: () => void;
}

interface IOpenConfirmParams extends IOpenDialogParams {
	confirmButtons: {
		confirmText?: string;
		onClick: confirmOkClickHandler;
	}[];
}

// Define the initial state using that type
const initialState: IDialogState[] = [];

function openDialog(type: 'alert' | 'prompt' | 'confirm', paramsObj: IOpenDialogParams): IDialogState {
	let position = paramsObj.dismissButtonPosition;
	if (position === undefined) position = 'top';
	return {
		type: type,
		...paramsObj,
		dismissButtonPosition: position,
	};
}

export const dialogSlice = createSlice({
	name: 'counter',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		openAlert: (state, action: PayloadAction<IOpenDialogParams>) => {
			state.push(openDialog('alert', action.payload));
		},
		openPrompt: (state, action: PayloadAction<IOpenDialogParams>) => {
			state.push(openDialog('prompt', action.payload));
		},
		openConfirm: (state, action: PayloadAction<IOpenConfirmParams>) => {
			state.push(openDialog('confirm', action.payload));
		},
		closeDialog: (state) => {
			state.splice(0, 1);
		},
	},
});

export const { openAlert, openPrompt, openConfirm, closeDialog } = dialogSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDialog = (state: RootState) => state.dialog;

export default dialogSlice.reducer;
