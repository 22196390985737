import styles from './authentication.module.scss';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { LoginLayout } from '../../components/LoginPanel/LoginLayout';

export class SuccessfulPasswordReset extends Component<any> {
	static displayName = SuccessfulPasswordReset.name;

	render() {
		return (
			<LoginLayout>
				<h5 className={styles.label}>
					<Trans i18nKey='passwordSuccessfullyReset' />
				</h5>
				<label className={styles.label}>
					<Trans i18nKey='successfulPasswordResetMainText' />
				</label>
			</LoginLayout>
		);
	}
}
