import React from 'react';
import Logo from '../../images/mydesk.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export default class DownloadPage extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			showInstructions: true,
			browser: 'unknown',
		};
	}

	styles = {
		wrapper: {
			maxWidth: '100%',
			height: '100%',
			overflow: 'auto',
		},
		container: {
			maxWidth: 750,
			margin: '0 auto',
		},
		box: {
			backgroundColor: 'white',
			border: '1px solid lightgray',
			padding: 20,
			marginTop: 20,
		},
		bottomBox: {
			backgroundColor: 'white',
			border: '1px solid lightgray',
			padding: 20,
			marginTop: 20,
			marginBottom: 20,
		},
		logo: {
			display: 'block',
			maxWidth: '35%',
			height: 'auto',
			margin: '0 auto',
			marginBottom: 10,
		},
		h2: {
			margin: '0 0 15px',
		},
		h4: {
			padding: 0,
			margin: 0,
			marginTop: 10,
			textTransform: 'capitalize' as const,
		},
		mkButton: {
			backgroundColor: '#6EC4E9',
			padding: '15px 28px',
			fontSize: 20,
			lineHeight: '80%',
			borderRadius: 3,
			color: 'white',
			letterSpacing: 1,
			cssFloat: 'right',
			display: 'inline-block',
			textDecoration: 'none',
			':hover': {
				textDecoration: 'none',
			},
		},
		downloadContainer: {
			paddingTop: 10,
			width: '25%',
			display: 'inline-block',
			verticalAlign: 'top',
		},
		versionInfo: {
			width: '75%',
			display: 'inline-block',
		},
		instructionImage: {
			maxWidth: '100%',
		},
		hiddenXs: {
			backgroundImage: "linear-gradient(to right, #1B3A6D, #095495)",
			padding: "10px 0",
			boxSizing: "border-box" as const,
		}
	};

	componentDidMount() {
		let showInstructions: boolean = true;
		let browser = 'unknown';

		// Chrome 1+
		var isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

		if (isChrome) browser = 'chrome';

		var deviceAgent = navigator.userAgent.toLowerCase();
		var deviceIsIpad = deviceAgent.match(/(ipad)/);
		if (deviceIsIpad) {
			showInstructions = false;
			window.location.href =
				'https://itunes.apple.com/nl/app/salesflow/id1071240300?ls=1&mt=8';
			browser = 'mobile';
		}
		var deviceIsIphone = deviceAgent.match(/(iphone)/);
		var deviceIsIpod = deviceAgent.match(/(ipod)/);
		if (deviceIsIphone || deviceIsIpod) {
			showInstructions = false;
			window.location.href =
				'https://itunes.apple.com/nl/app/salesflow-mobile/id1451204205?ls=1&mt=8';
			browser = 'mobile';
		}
		var deviceIsAndroid = deviceAgent.match(/(android)/);
		if (deviceIsAndroid) {
			showInstructions = false;
			browser = 'mobile';
		}

		this.setState({
			showInstructions: showInstructions,
			browser: browser,
		});
	}

	render() {
		return (
			<div style={this.styles.wrapper}>
				<div
					className='container'
					style={this.styles.container}
				>
					<div style={this.styles.box}>
						<div style={this.styles.hiddenXs} className='col-sm-3 col-md-3'>
							<img
								className='img-responsive'
								src={Logo}
								style={this.styles.logo}
								alt='MyDesk'
							/>
						</div>
						{this.state.browser !== 'mobile' && (
							<div>
								<div style={{ marginBottom: '1rem' }}>
									<h2 style={{ margin: '0 0 -5px' }}>
										<Trans i18nKey='download.desktop' />
									</h2>
									<div style={{ fontSize: 'smaller' }}>
										<Trans i18nKey='download.desktopDescription' />
									</div>
								</div>
								<hr style={{ margin: 0 }} />
								<div style={this.styles.versionInfo}>
									<h3
										style={{
											fontSize: 24,
											marginTop: 10,
											marginBottom: 10,
										}}
									>
										<Trans i18nKey='download.version' />{' '}
										4.0.1
									</h3>
									<div style={{ marginBottom: 10 }}>
										<small>
											<FontAwesomeIcon icon='calendar-days' />{' '}
											30 Nov 2022
										</small>
									</div>
									<div>
										<small>
											<FontAwesomeIcon icon='file' /> 1,16
											MB
										</small>
									</div>
								</div>
								<div style={this.styles.downloadContainer}>
									<a
										id='download-button'
										style={this.styles.mkButton}
										href='https://desktopclient.my-desk.nl/client/MyDesk.exe'
										download='MyDesk.exe'
									>
										Download
									</a>
								</div>
							</div>
						)}
					</div>

					{this.state.showInstructions && (
						<div
							style={this.styles.box}
							id='instructions'
						>
							<h2 style={this.styles.h2}>
								<Trans i18nKey='download.installationInstructions' />
							</h2>
							<h4 style={this.styles.h4}>
								<Trans i18nKey='download.step' /> 1.
							</h4>
							<p>
								<Trans i18nKey='download.step1' />
							</p>
							{this.state.browser === 'chrome' && (
								<div>
									<h4 style={this.styles.h4}>
										<Trans i18nKey='download.step' /> 2.
									</h4>
									<p>
										<Trans i18nKey='download.step2chrome' />
									</p>
									<img
										style={this.styles.instructionImage}
										src={
											t('download.image2chrome') as string
										}
										alt='instructions'
									/>

									<h4 style={this.styles.h4}>
										<Trans i18nKey='download.step' /> 3.
									</h4>
									<p>
										<Trans i18nKey='download.step3chrome' />
									</p>
								</div>
							)}
							{this.state.browser === 'unknown' && (
								<div>
									<h4 style={this.styles.h4}>
										<Trans i18nKey='download.step' /> 2.
									</h4>
									<p>
										<Trans i18nKey='download.step2' />
									</p>

									<h4 style={this.styles.h4}>
										<Trans i18nKey='download.step' /> 3.
									</h4>
									<p>
										<Trans i18nKey='download.step3' />
									</p>
								</div>
							)}

							<h4 style={this.styles.h4}>
								<Trans i18nKey='download.step' /> 4.
							</h4>
							<p>
								<Trans i18nKey='download.step4' />
							</p>
							<img
								src={t('download.image4') as string}
								alt=''
							/>

							<h4 style={this.styles.h4}>
								<Trans i18nKey='download.step' /> 5.
							</h4>
							<p>
								<Trans i18nKey='download.step5' />
							</p>

							<h4 style={this.styles.h4}>
								<Trans i18nKey='download.step' /> 6.
							</h4>
							<p>
								<Trans i18nKey='download.step6' />
							</p>
						</div>
					)}
					<div style={this.styles.bottomBox}>
						<p style={{ textAlign: 'center' }}>
							<Trans i18nKey='download.mobile' />
						</p>
						<p style={{ textAlign: 'center' }}>
							<a
								id='appStoreLink'
								href='https://itunes.apple.com/nl/app/salesflow-mobile/id1451204205?ls=1&mt=8'
							>
								<img
									width='150px'
									src='./Download/Images/appstore.png'
									alt='App store'
								/>
							</a>
							<a
								id='playStoreLink'
								href='https://play.google.com/store/apps/details?id=nl.blisss.salesflowmobile'
							>
								<img
									width='150px'
									src='./Download/Images/playstore.png'
									alt='Play store'
								/>
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
