import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../components';
import { LoginLayout } from '../../components/LoginPanel/LoginLayout';
import { useNavigate } from 'react-router-dom';
import styles from './oidc.module.scss';
import { oidcAuthenticate } from '../../api/OidcApi';
import { setTokenCookie } from '../../utilities/authProvider';
import { updateGlobals } from '../../store/store';
import { t } from 'i18next';

export function FinishPage() {
	const [success, setSuccess] = useState(false);
	const [headerTextKey, setHeaderTextKey] = useState<string>();
	const [paraTextKey, setParaTextKey] = useState('oidc.loadingMessage');
	const [showHomeButton, setShowHomeButton] = useState(false);

	const navigate = useNavigate();

	const clickHandler = useCallback(() => {
		navigate('/');
	}, [navigate]);

	const failureMessage = useCallback(() => {
		setHeaderTextKey('failure');
		setParaTextKey('oidc.accountLinkFailureMessage');
		setShowHomeButton(true);
	}, []);

	const successMessage = useCallback(() => {
		setSuccess(true);
		setHeaderTextKey('success');
		setParaTextKey('oidc.accountLinkSuccessMessage');
		setShowHomeButton(true);
	}, []);

	useEffect(() => {
		if (!success) {
			const searchParams = new URLSearchParams(window.location.search);

			const hasCodeAndState = searchParams.has('code') && searchParams.has('state');
			const [code, state] = [searchParams.get('code'), searchParams.get('state')];
			const codeAndStateNotEmpty = (code !== '' || code !== null) && (state !== '' || state !== null);

			if (hasCodeAndState && codeAndStateNotEmpty) {
				oidcAuthenticate(code!, state!).then((res) => {
					if (res.status === 200) {
						res.json().then((json) => {
							setTokenCookie(json);
							updateGlobals().then(successMessage);
						});
					} else {
						failureMessage();
					}
				});
			} else {
				failureMessage();
			}
		}
	}, [failureMessage, successMessage]);

	return (
		<LoginLayout>
			<div className={styles.content}>
				<h1 className={styles.header}>{headerTextKey && t(headerTextKey)}</h1>
				<p>{t(paraTextKey)}</p>
			</div>
			{showHomeButton && (
				<Button
					onClick={clickHandler}
					i18nKey='oidc.proceed'
					theme='confirm'
				/>
			)}
		</LoginLayout>
	);
}
