import { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import i18n from '../../localization/I18n';
import DropdownComponent, { DropDownVariant } from '../InputFields/DropdownInput';

export interface FilterDropDownOption {
	value: string | null;
	label: string | null;
	type?: string;
}
export interface IFilterDropDownProps {
	defaultValue: FilterDropDownOption[] | undefined | string | any;
	isSearchable?: boolean;
	variant?: DropDownVariant;
	hasClearOptions?: boolean;
	options: FilterDropDownOption[];
	filterName?: string;
	filterType?: string;
	selectedValue: FilterDropDownOption[] | any;
	setSelectedValue: Dispatch<SetStateAction<Array<FilterDropDownOption> | any>>;
}
const FilterDropDown = ({
	variant,
	defaultValue,
	isSearchable,
	hasClearOptions,
	options,
	filterName,
	filterType,
	selectedValue,
	setSelectedValue,
}: IFilterDropDownProps) => {
	const [update, setUpdate] = useState('');
	const [activeFilter, setActiveFilter] = useState<string | undefined>('');
	const [numOfSelected, setNumOfSelected] = useState();

	useEffect(() => {
		if (defaultValue !== '') setSelectedValue(defaultValue?.map((dv) => dv.value));
	}, [defaultValue, setSelectedValue]);

	const onChange = useCallback(
		(e: string | any) => {
			const isSelected = selectedValue.some((item: FilterDropDownOption) => item.value === e.value);
			const newSelectedValues = isSelected ? selectedValue.filter((item: FilterDropDownOption) => item.value !== e.value) : [...selectedValue, e];
			if (variant !== DropDownVariant.text) {
				setSelectedValue(newSelectedValues);
			} else {
				setUpdate(e.target.value);
			}
		},
		[i18n, selectedValue, setSelectedValue, variant]
	);

	const handleUpdate = () => {
		setSelectedValue(update);
	};

	const handleSearch = () => {};

	const handleClearOptions = (filterType) => {
		const removeSelectedWithType = selectedValue.filter((selected) => selected.type !== filterType);
		setSelectedValue(removeSelectedWithType);
	};

	useEffect(() => {
		setActiveFilter('');
		if (selectedValue && selectedValue.length !== 0) {
			const selectedWithType = selectedValue.filter((selected) => selected?.type === filterType);
			if (selectedWithType.length !== 0) {
				setActiveFilter(selectedWithType[0]?.type);
				setNumOfSelected(selectedWithType.length);
			} else {
				setActiveFilter('');
				setNumOfSelected(undefined);
			}
		}
	}, [selectedValue]);

	/* TODO: use the presetDropdowns for this */

	return (
		<DropdownComponent
			options={variant === DropDownVariant.multiCheckboxes ? options : []}
			value={selectedValue ? selectedValue.map((selected) => selected.value) : []}
			onChange={(e) => onChange(e)}
			searchCallback={isSearchable ? handleSearch : undefined}
			onUpdate={handleUpdate}
			clearOptions={() => (hasClearOptions ? handleClearOptions(filterType) : undefined)}
			variant={variant}
			withFilters
			filterName={filterName}
			activeFilter={activeFilter === filterType ? true : false}
			numOfSelected={numOfSelected}
		/>
	);
};
export default FilterDropDown;
