import { AuthorizedFetchRequest } from './apiBase';

const baseUrl = `api/Export`;

export function getEntities(culture: string | undefined): Promise<Response> {
	const url = { base: `${baseUrl}/entity` };

	return AuthorizedFetchRequest(url, 'GET', null, culture).then((res) => {
		return res;
	});
}
export function getEntityDefinition(
	params: { entityType: string; depth: number },
	culture: string | undefined
): Promise<Response> {
	const url = { base: `${baseUrl}/entityDefinition?entityType=${params.entityType}&depth=${params.depth}` };

	return AuthorizedFetchRequest(url, 'GET', null, culture).then((res) => {
		return res;
	});
}
export function exportProperties(
	entityType: string,
	queries?: [{ displayName?: string; relationPath?: string }] | [],
	culture?: string | undefined
): Promise<Response> {
	const url = { base: `${baseUrl}?entityType=${entityType}` };

	return AuthorizedFetchRequest(url, 'POST', queries, culture).then((res) => {
		return res;
	});
}
