import { BaseQuery } from './_BaseQuery';
import { FilterField } from './_enums';

//WARNING: MAKE SURE TO SEND BOTH SKIP AND TAKE!!!
export class PrioritizedCompanyQuery extends BaseQuery {
	//BoundsFilter
	//PriorityFilter
	//SearchTermFilter
	//RepresentativeIdFilter
	//VisitingFrequencyIdFilter

	public filterFields?: FilterField;
	public id?: string;
	public searchTerm?: string;
	public representative_Id?: string;
	public sortIt?: boolean;
	public mustBeActive?: boolean;
	public dateTime?: Date;
	public queryAllAccounts?: boolean;
	public filters?: Array<{ $type: string; companyType: string }>;
}
