import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { Fragment, useEffect } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { DateTimeType } from '../../InputFields/inputField.types';
import { updateCompanies } from '../../../store/reducers/CompanySlice';
import { FormDetail } from './_FormDetail';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { Opportunity } from '../../../models/Model/Opportunity';
import { stringJoin } from './_utility';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { InfoGroup } from '../../InfoBlocks';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { isNotNilOrEmpty } from '../../../utilities/utils';

interface IOpportunityFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<Opportunity>;
}

export const OpportunityFormDetail = (props: IOpportunityFormDetailProps) => {
	//TODO: Baseform?
	const p = props.vm.getProperties();
	const e = (p: string) => stringJoin(props.vm.getPropertyErrors(p));
	const hasOpportunityStatusReasonModule = hasModule(moduleNames.opportunityStatusReason);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={props.vm}
			headerText={props.vm.getProperty(p.subject)}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="phone"
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.PresetDropDownRow
					label={t('company')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.company_Id}
					errorMessage={e(p.company_Id)}
					DDEntityType={DropdownEntityType.companies}
					readonly={!props.isEditing}
				/>
			</InfoGroup>

			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('opportunityData')}
			>
				<BoundInputRows.TextRow
					label={t('opportunityAttributes.subject')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.subject}
					errorMessage={e(p.subject)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.NumberRow
					allowDecimals
					label={t('opportunityAttributes.amount')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.amount}
					errorMessage={e(p.amount)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.NumberRow
					label={t('opportunityAttributes.estimatedSuccessRate')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.estimatedSuccessRate}
					errorMessage={e(p.estimatedSuccessRate)}
					readonly={!props.isEditing}
					min={0}
					max={100}
				/>
				<BoundInputRows.DateTimeRow
					label={t('opportunityAttributes.estimatedClosingDate')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.estimatedClosingDate}
					errorMessage={e(p.estimatedClosingDate)}
					type={DateTimeType.date}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextareaRow
					label={t('opportunityAttributes.description')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.description}
					errorMessage={e(p.description)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('opportunityAttributes.representative')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.representative_Id}
					errorMessage={e(p.representative_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('representativeAttributes.createdBy')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.createdBy_Id}
					errorMessage={e(p.createdBy_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('opportunityAttributes.status')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.opportunityStatus}
					errorMessage={e(p.opportunityStatus)}
					DDEntityType={DropdownEntityType.opportunityStatuses}
					readonly={!props.isEditing}
				/>
				{hasOpportunityStatusReasonModule && (
					<Fragment>
						<BoundInputRows.PresetDropDownRow
							label={t('opportunityAttributes.statusReason')}
							vm={props.vm}
							model={props.vm.model}
							modelValuePropertyName={p.opportunityStatusReason_Id}
							errorMessage={e(p.opportunityStatusReason_Id)}
							DDEntityType={DropdownEntityType.opportunityStatusReasons}
							readonly={!props.isEditing}
						/>
						<BoundInputRows.TextRow
							label={t('opportunityAttributes.statusClarification')}
							vm={props.vm}
							model={props.vm.model}
							modelValuePropertyName={p.statusClarification}
							errorMessage={e(p.statusClarification)}
							readonly={!props.isEditing}
						/>
					</Fragment>
				)}
			</InfoGroup>

			<CustomFieldEditor
				vm={props.vm}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
};
