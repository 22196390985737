import { Fragment } from 'react';
import styles from '../../css/pageElements.module.scss';
import pagedefinitions from '../../globals/pagedefinitions.json';
import { hasModule, moduleNames } from '../../utilities/authProvider';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

export function HeaderLinks(props: { activePath: string }) {
	const topmenuPages = [...pagedefinitions.groups.top.pages];

	const hasOpportunityModule = hasModule(moduleNames.opportunity);

	if (!hasOpportunityModule) {
		const i = topmenuPages.findIndex((page) => page.link === 'opportunities');
		if (i !== undefined && i > -1) topmenuPages.splice(i, 1);
	}

	const count = topmenuPages.length;

	const Links = topmenuPages.map((page, index) => {
		const isLast = index === count - 1;
		const link = `/${page.link}`;
		const css = page.link === props.activePath ? styles.headerMenuActive : '';

		return (
			<Fragment key={page.title}>
				<Link
					to={link}
					className={css}
				>
					<Trans i18nKey={page.title} />
				</Link>
				{!isLast && <div className={styles.sep}>|</div>}
			</Fragment>
		);
	});
	return <Fragment>{Links}</Fragment>;
}
