import { BaseQuery } from "./_BaseQuery";
import { SortingLevel } from "./_enums";

export class TaskQueryBase extends BaseQuery {
	public company_Id?:string;
	public noCompany?:boolean;
	public representative_Id?:string;
	public currentRepresentative?:boolean;
	public queryDelegateTasks?:boolean;
	public searchTerm?:string;
	public startWithSearchTerm?:string;
	public sorting?:SortingLevel;
	public isComplete?:boolean;
}

export class TaskQuery extends TaskQueryBase {
	//ExchangeIdFilter
	public onlyOpenTasks?:boolean;
	public exchange_Id?:string;
	public representativeGuidList?:string[];
	public lastModifiedAfter?:Date;
	public tasksDateAfter?:Date;
}
