import i18n, { Resource } from 'i18next';
import reactI18Next, { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import dutch from './dutch.json';
import english from './english.json';
import { NL, GB, FlagComponent } from 'country-flag-icons/react/3x2';
import { Translation } from './translation';
import { globals } from '../globals/environment';

const languages: {
	locale: string;
	fullLocale: string;
	name: string;
	translationObject: Translation;
	abbreviation: string;
	Flag: FlagComponent;
}[] = [
	{
		locale: 'nl',
		fullLocale: 'nl-NL',
		translationObject: dutch,
		name: 'Nederlands',
		abbreviation: 'NL',
		Flag: NL,
	},
	{
		locale: 'en',
		fullLocale: 'en-US',
		translationObject: english,
		name: 'English',
		abbreviation: 'EN',
		Flag: GB,
	},
];

const resources: Resource = {};

languages.forEach((language) => {
	resources[language.locale] = { translation: language.translationObject };
});

const supportedLngs = languages.map((language) => language.locale);

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'nl',
		debug: false,

		interpolation: {
			escapeValue: false,
		},
		parseMissingKeyHandler: (key: string, defaultValue: string | undefined) => {
			//TODO: !!!!!!!!!!!!!!!!!!!!!!!!!!!
			return `#${key}`;
			// if (globals.environment.isDev())
			// 	return `#${key}`;
			// else
			// 	return defaultValue;
		},
		load: 'languageOnly',

		resources: resources,
		supportedLngs: supportedLngs,
	});

export default i18n;

export { languages as Languages, supportedLngs as Locales };
