import { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { t } from 'i18next';
import { getAvailableStorage } from '../../../api/FileAPI';
import { getStorageTotalAmount } from '../../../api/OrganizationSettings';
import { bytesConversion } from '../../../utilities/bytesConversion';
import { InfoGroup } from '../../../components/InfoBlocks';
import { GridComponents } from '../../../components';
import styles from './fileStorage.module.scss';
export const FileStoragePage = () => {
	const location = useLocation();
	const match = useMatch(location.pathname);

	const [availableStorage, setAvailableStorage] = useState(0);
	const [totalStorage, setTotalStorage] = useState(0);

	const getAmountAvailableStorage = useCallback(async () => {
		const response = await getAvailableStorage();
		setAvailableStorage(await response.json());
	}, []);

	const getTotalStorage = useCallback(async () => {
		const response = await getStorageTotalAmount();
		const data = await response.json();
		setTotalStorage(data.fileStorageQuota);
	}, []);

	useEffect(() => {
		getAmountAvailableStorage();
		getTotalStorage();
	}, [getAmountAvailableStorage, getTotalStorage]);

	const storageInUse = useMemo(() => totalStorage - availableStorage, [totalStorage, availableStorage]);
	const percentageOfUsedStorage = useMemo(() => (storageInUse / totalStorage) * 100, [storageInUse, totalStorage]);

	return (
		<Fragment>
			<GridComponents.Column size="threeFourths">
				<GridComponents.Row.Header content={<GridComponents.Row.Breadcrumb match={match} />} />
				<GridComponents.Row.SubHeader>
					<GridComponents.Row.SubHeaderDetail icon="box-archive" name={t('fileStorage')} />
				</GridComponents.Row.SubHeader>
				<GridComponents.Row.Scroll>
					<InfoGroup
						title={t('fileUsage')}
						open
						visible
						children={
							<div className={styles.progressBarWrapper}>
								<ProgressBar value={percentageOfUsedStorage} />
								<div className={styles.progressBarLabel}>
									{bytesConversion(storageInUse)} {t('outOf')} {bytesConversion(totalStorage)}{' '}
									{t('isUsed')} {percentageOfUsedStorage.toFixed(2)}%
								</div>
							</div>
						}
					/>
				</GridComponents.Row.Scroll>
			</GridComponents.Column>
		</Fragment>
	);
};
