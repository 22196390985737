import { useCallback, useEffect } from 'react';
import { CompanyAnnotationQuery } from '../../business/Query/CompanyAnnotationQuery';
import authProvider from '../../utilities/authProvider';
import { EntityType } from '../../globals/enums';
import { AppointmentAnnotation } from '../../models/Model/AppointmentAnnotation';
import { api } from '../../api/_Executor';
import { AnnotationViewTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICompanyAnnotation } from '../../models/Model/CompanyAnnotation';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';
import { BaseQuery } from '../../business/Query/_BaseQuery';

export interface IAppointmentAnnotationViewCollectionViewModel<T extends ICompanyAnnotation, Q extends BaseQuery = CompanyAnnotationQuery>
	extends ICollectionViewModel<T, Q> {
	updateItem(index: number, text: string, refreshAll?: boolean): Promise<boolean>;
	deleteItem(index: number, refreshAll?: boolean): Promise<boolean>;
	insertItem(representativeId?: string, refreshAll?: boolean): Promise<boolean>;
}

export function useAppointmentAnnotationViewCollectionViewModel(
	appointmentId: string,
	companyId: string,
	ignoreRepresentative_id?: boolean
): IAppointmentAnnotationViewCollectionViewModel<ICompanyAnnotation, CompanyAnnotationQuery> {
	const apiReader = api.companyAnnotation.listAsync;
	const query = new CompanyAnnotationQuery();
	const table = AnnotationViewTableDefinition;
	const canSearch: boolean = false;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<ICompanyAnnotation, CompanyAnnotationQuery>(
		'companyAnnotations',
		apiReader,
		ignoreRepresentative_id,
		query,
		undefined,
		'entity_Id',
		'company_Id'
	);

	const generateAppointmentAnnotation = useCallback(
		(companyId, representativeId?: string) => {
			const annotation = new AppointmentAnnotation();
			annotation.$type = EntityType.appointmentAnnotation;
			annotation.company_Id = companyId;
			annotation.appointment_Id = appointmentId;

			if (representativeId === undefined) {
				representativeId = authProvider.getUserId();
			}

			annotation.createdBy_Id = representativeId ?? null;

			return annotation;
		},
		[appointmentId]
	);

	// const convertAppointmentAnnotationToView = useCallback((annotation: AppointmentAnnotation) => {
	// 	const view = new AppointmentAnnotationView();

	// 	view.entity_Id = annotation.id;
	// 	view.entity_Company_Id = annotation.company_Id;
	// 	view.entity_CreatedBy_Id = annotation.createdBy_Id;
	// 	view.entity_Text = annotation.text;

	// 	//Not sure if needed
	// 	view.entity_CreatedDate = annotation.createdDate;
	// 	view.entity_LasteModifiedDate = annotation.lastModifiedDate;

	// 	return view;
	// }, []);

	// const convertViewToAnnotation = useCallback((view: CompanyAnnotationView) => {
	// 	const annotation = new CompanyAnnotation();
	// 	annotation.id = view.entity_Id;
	// 	annotation.company_Id = view.entity_Company_Id;
	// 	annotation.createdBy_Id = view.entity_CreatedBy_Id;
	// 	annotation.text = view.entity_Text;

	// 	//Not sure if needed:
	// 	annotation.createdDate = view.entity_CreatedDate;
	// 	annotation.lastModifiedDate = view.entity_LasteModifiedDate;

	// 	return annotation;
	// }, []);

	const deleteItem = useCallback(
		async (index: number, refreshAll: boolean = false) => {
			if (cvm.items.length === 0) return true; //No error
			const item = cvm.items[index];
			if (item === undefined) return false; //Can't find

			const success = await api.companyAnnotation.deleteByIdAsync(item.id!);
			if (success === undefined) return false;

			//Refresh the whole list?
			if (refreshAll) {
				return await cvm.doQuery();
			}

			// Create a copy of the array without the deleted item
			const updatedItems = cvm.items.filter((_, i) => i !== index);

			// Update the state
			cvm.setItems(updatedItems);

			return true;
		},
		[cvm]
	);

	const insertItem = useCallback(
		async (representativeId?: string, refreshAll?: boolean) => {
			const generatedAnnotation = generateAppointmentAnnotation(representativeId);

			const annotation = await api.companyAnnotation.updateAsync(generatedAnnotation);
			if (annotation === undefined) return false;

			//Refresh the whole list?
			if (refreshAll) {
				return await cvm.doQuery();
			}

			//Insert at index 0
			cvm.items.splice(0, 0, annotation); //deliberately not using setItems
			cvm.setItems([...cvm.items]);

			return true;
		},
		[cvm, generateAppointmentAnnotation]
	);

	const updateItem = useCallback(
		async (index: number, text: string, refreshAll: boolean = false): Promise<boolean> => {
			if (cvm.items.length === 0) return true; //No error
			const annotation = cvm.items[index];
			if (annotation === undefined) return false; //Can't find

			//set new text
			annotation.text = text;

			const updatedAnnotation = await api.companyAnnotation.updateAsync(annotation);
			if (updatedAnnotation === undefined) return false;

			//Refresh the whole list?
			if (refreshAll) {
				return await cvm.doQuery();
			}

			//Only update the modified listItem
			annotation.text = updatedAnnotation.text;
			cvm.setItems([...cvm.items]); //unfortunately, needed to trigger a refresh

			return true;
		},
		[cvm]
	);

	useEffect(() => {
		cvm.doRead(companyId);
	}, [companyId]);

	return {
		instanceName: 'companyAnnotations',
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,

		//Additional
		updateItem,
		deleteItem,
		insertItem,
	};
}
