import {
	CompanyContactConverter,
	CompanyToIconConverter,
	EmailConverter,
	MainConverter,
	TelephoneConverter,
	WebsiteConverter,
} from '../Converters/ColumnConverters';

export const table = {
	title: 'companies',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'companyAttributes.type',
			width: 15,
			property: {
				name: 'typeOfEntity',
			},
			converter: CompanyToIconConverter,
		},
		{
			headTitle: 'companyAttributes.name',
			converter: MainConverter,
			property: {
				name: 'entity_Name',
			},
		},
		{
			headTitle: 'companyAttributes.city',
			property: {
				name: 'entity_PrimaryVisitingAddress_City',
			},
		},
		{
			headTitle: 'companyAttributes.primaryContact',
			converter: CompanyContactConverter,
			property: {
				name: 'entity_PrimaryContact_Surname',
			},
		},
		{
			headTitle: 'companyAttributes.group',
			property: {
				name: 'entity_CompanyGroup_Code',
			},
		},
		{
			headTitle: 'companyAttributes.visitingFrequency',
			property: {
				name: 'account_VisitingFrequency_Code',
			},
		},
		{
			headTitle: 'companyAttributes.latestContactDate',
			property: {
				name: 'lastestDate',
			},
			type: 'datetime-local',
		},
		{
			headTitle: 'companyAttributes.postalCode',
			property: {
				name: 'entity_PrimaryVisitingAddress_PostalCode',
			},
		},
		{
			headTitle: 'companyAttributes.streetName',
			property: {
				name: 'entity_PrimaryVisitingAddress_Street',
			},
		},
		{
			headTitle: 'primaryRepresentative',
			property: {
				name: 'entity_PrimaryRepresentative_Name',
			},
		},
		{
			headTitle: 'companyAttributes.weeksDue',
			property: {
				name: 'weeksDue',
			},
		},

		{
			headTitle: 'companyAttributes.weeksDuePercentage',
			property: {
				name: 'weeksDuePercentage',
			},
		},
		{
			headTitle: 'companyAttributes.phoneNumber',
			converter: TelephoneConverter,
			property: {
				name: 'entity_PhoneNumber',
			},
		},
		{
			headTitle: 'companyAttributes.countryName',
			property: {
				name: 'entity_PrimaryVisitingAddress_Country_Name',
			},
		},
		{
			headTitle: 'companyAttributes.website',
			converter: WebsiteConverter,
			property: {
				name: 'entity_Website',
			},
		},
		{
			headTitle: 'companyAttributes.emailAddress',
			converter: EmailConverter,
			property: {
				name: 'entity_EmailAddress',
			},
		},
		{
			headTitle: 'companyAttributes.number',
			property: {
				name: 'account_Number',
			},
		},
		{
			headTitle: 'companyAttributes.postalCodeArea',
			property: {
				name: 'postalCodeArea_Name',
			},
		},
	],
	apiUrl: '/Company',
};

export default table;
