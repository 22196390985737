/* UNMODIFIED */
import { CompanyAnnotation, ICompanyAnnotation } from "./CompanyAnnotation";
import { IAppointment } from "./Appointment";

export interface IAppointmentAnnotation extends ICompanyAnnotation {
	appointment: IAppointment | null;
    appointment_Id: string | null;
}

export class AppointmentAnnotation extends CompanyAnnotation implements IAppointmentAnnotation {
	public appointment: IAppointment | null = null;
    public appointment_Id: string | null = null;
}
