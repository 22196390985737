import { Component } from 'react';
import { GridComponents, PanelMain } from '../../components';
import { Route, Routes, Link, Navigate } from 'react-router-dom';

import { SettingsPageProps, SettingsPageState, navMenuItem } from './types';

import { Trans } from 'react-i18next';
import styles from './settingsPage.module.scss';

import { FieldDefinitionPage } from './FieldDefinitions/FieldDefinitionPage';
import { ExternalConnections } from './ExternalConnections/ExternalConnections';
import authProvider from '../../utilities/authProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasModule, moduleNames } from '../../utilities/authProvider';
import { IconCustomType } from '../../components/IconCustom/IconCustom.index';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { withRouter } from '../../compat/RouterCompat';
import { VisitingFrequenciesPage } from './VisitingFrequencies/VisitingFrequencyPage';
import { ExportPage } from './Export/ExportPage';
import { FileStoragePage } from './FileStorage/FileStoragePage';

class SettingsPageComponent extends Component<SettingsPageProps, SettingsPageState> {
	constructor(props) {
		super(props);

		this.state = {
			isLoadingPage: false,
			isSaving: false,
		};
	}

	/**
	 * Sets, or toggles, loading state item.
	 * @param value the value the loading state item will be set to.
	 * Loading state item will be set to its opposite (true -> false; false -> true) if this parameter is left empty.
	 */
	private toggleLoading = (value?: boolean) => {
		this.setState({
			isLoadingPage: value !== undefined ? value : !this.state.isLoadingPage,
		});
	};

	private handleAPIFunction = async (apiFunction: Promise<Response>, successMessage: string): Promise<string> => {
		return apiFunction
			.then((res) => {
				if (res.ok) {
					return successMessage;
				} else {
					return 'something went wrong';
				}
			})
			.catch((err) => {
				console.error(err);
				return 'something went wrong';
			});
	};

	/**
	 * Display a Dialog to notify the user of something.
	 * @param content name of I18n string to be displayed in Dialog.
	 */
	displayAlertDialog = (content: string) => {
		this.setState({
			dialog: {
				visible: true,
				type: 'alert',
				content: content,
				callback: this.onCloseDialog,
			},
		});
	};

	/**
	 * Display a Dialog that asks the user to select between a 'confirm' and 'cancel' option.
	 * @param content name of I18n string to be displayed in Dialog.
	 * @param confirmCallback Function to execute when the confirm option is selected by user.
	 */
	displayConfirmDialog = (content: string, confirmCallback: () => void) => {
		this.setState({
			dialog: {
				visible: true,
				type: 'confirm',
				content: content,
				callback: this.onCloseDialog,
				confirmCallback: confirmCallback,
			},
		});
	};

	/**
	 * Sets the Dialog state item to undefined. This function is passed to the Layout Component.
	 */
	onCloseDialog = () => {
		this.setState({
			dialog: undefined,
		});
	};

	navMenuItems: navMenuItem[] = [
		{
			iconName: 'toggle-on',
			labelText: 'fieldDefinition',
			destination: 'fieldDefinitions',
			pageComponent: FieldDefinitionPage,
			permission: 'ManageFields',
			visible: true,
		},
		{
			iconName: 'wifi',
			labelText: 'externalConnections',
			destination: 'externalConnections',
			pageComponent: ExternalConnections,
			permission: 'Organization',
			visible: true,
		},
		{
			iconName: 'wave-square',
			labelText: 'visitingFrequencies',
			destination: 'visitingFrequencies',
			pageComponent: VisitingFrequenciesPage,
			permission: 'Organization',
			visible: true,
		},
		{
			iconName: 'box-archive',
			labelText: 'fileStorage',
			destination: 'fileStorage',
			pageComponent: FileStoragePage,
			permission: 'Organization',
			visible: hasModule(moduleNames.documentManagement) ? true : false,
		},
		{
			iconName: 'city',
			labelText: 'export',
			destination: 'export',
			pageComponent: ExportPage,
			permission: 'Organization',
			visible: hasModule(moduleNames.export) ? true : false,
		},
	];

	render() {
		const validNavMenuItems = this.navMenuItems.filter((item) => {
			return (!item.permission || authProvider.getPermissions()?.includes(item.permission)) && item.visible;
		});

		return (
			<PanelMain dialog={this.state.dialog} loading={this.state.isLoadingPage}>
				<GridComponents.Column size="fourth">
					<GridComponents.Row.Header
						//TODO: Add cog icon
						// iconLeft={<FontAwesomeIcon icon={['fas', 'cog']} />}
						iconLeft={IconCustomType.GEN_COMPANY}
						content={<Trans i18nKey="settings" />}
					/>
					<GridComponents.Row.SubHeader>
						<SearchBar
							searchCallback={() => {
								console.log('no search yet');
							}}
						/>
					</GridComponents.Row.SubHeader>
					<GridComponents.Row.Scroll>
						<div className={styles.navMenu}>
							{validNavMenuItems.map((navMenuItem, key) => {
								const selected =
									this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] ===
									navMenuItem.destination;

								return (
									<Link
										key={key}
										className={`${styles.setting} ${selected && styles.selected} text-capitalize`}
										to={`/settings/${navMenuItem.destination}`}
									>
										<div className={`${styles.icon} clr_b_blue`}>
											<FontAwesomeIcon icon={navMenuItem.iconName} />
										</div>
										<div className={styles.title}>
											<Trans i18nKey={navMenuItem.labelText} />
										</div>
									</Link>
								);
							})}
						</div>
					</GridComponents.Row.Scroll>
				</GridComponents.Column>

				<Routes>
					<Route
						path={`fieldDefinitions`}
						element={
							<FieldDefinitionPage
								toggleLoading={this.toggleLoading}
								displayAlertDialog={this.displayAlertDialog}
								displayConfirmDialog={this.displayConfirmDialog}
								closeDialog={this.onCloseDialog}
							/>
						}
					/>
					{validNavMenuItems.map((route, key) => {
						return (
							<Route
								key={key}
								path={route.destination}
								element={
									<route.pageComponent
										toggleLoading={this.toggleLoading}
										displayAlertDialog={this.displayAlertDialog}
										displayConfirmDialog={this.displayConfirmDialog}
										closeDialog={this.onCloseDialog}
										/*	{...props}*/
									/>
								}
							/>
						);
					})}
					{validNavMenuItems.length > 0 && (
						<Route path="" element={<Navigate to={`/settings/${validNavMenuItems[0].destination}`} />} />
					)}
				</Routes>
			</PanelMain>
		);
	}
}

export const SettingsPage = withRouter(SettingsPageComponent);
