import { t } from 'i18next';
import { Component } from 'react';

import styles from './dialog.module.scss';
import { IDialogState, closeDialog } from './dialogSlice';
import { DispatchProp } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from '../Button/Button';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

interface IDialogProps extends DispatchProp {
	dialogState: IDialogState;
}

export class Dialog extends Component<IDialogProps, { loading: boolean }> {
	constructor(props: any) {
		super(props);

		this.state = { loading: false };
	}

	onConfirmClick = (onConfirmOk: () => any) => async () => {
		this.setState({ loading: true });
		await onConfirmOk();
		this.setState({ loading: false });

		this.props.dispatch(closeDialog());
	};

	closeDialog = (dismissCallBack?: () => any) => () => {
		this.props.dispatch(closeDialog());
		if (dismissCallBack) dismissCallBack();
	};

	onKeyPress = (dismissCallBack?: () => any) => (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			this.closeDialog(dismissCallBack)();
		}
	};

	renderIcon(): JSX.Element | null {
		const dialogState = this.props.dialogState;

		if (dialogState.icon) {
			let icon = faTriangleExclamation;
			let color = 'black';

			switch (dialogState.icon) {
				case 'warning':
					icon = faTriangleExclamation;
					color = 'red';
			}

			return (
				<div className={styles.iconRow}>
					<FontAwesomeIcon
						size='2xl'
						icon={icon}
						color={color}
					/>
				</div>
			);
		} else return null;
	}

	renderTopDismissButton(): JSX.Element | null {
		const dialogState = this.props.dialogState;
		const position = dialogState.dismissButtonPosition;

		if (this.state.loading) return <Spinner size={24} />;

		if (position === 'top' || position === 'both')
			return (
				<FontAwesomeIcon
					className={styles.dismissButton}
					icon={faCircleXmark}
					size='xl'
					onClick={this.closeDialog(dialogState.dismissCallBack)}
				/>
			);
		else return null;
	}

	renderBottomDismissButton(): JSX.Element | null {
		const dialogState = this.props.dialogState;
		const position = dialogState.dismissButtonPosition;

		if (position === 'bottom' || position === 'both')
			return (
				<Button
					i18nKey={dialogState.dismissText !== undefined ? dialogState.dismissText : 'cancel'}
					onClick={this.closeDialog(dialogState.dismissCallBack)}
					disabled={this.state.loading}
				/>
			);
		else return null;
	}

	renderActions(): JSX.Element | null {
		const dialogState = this.props.dialogState;
		const position = dialogState.dismissButtonPosition;

		if (dialogState.confirmButtons !== undefined || position === 'bottom' || position === 'both') {
			let confirmButtons: JSX.Element[] = [];
			if (dialogState.type === 'confirm' && dialogState.confirmButtons !== undefined) {
				confirmButtons = dialogState.confirmButtons.map((button) => (
					<Button
						i18nKey={button.confirmText !== undefined ? button.confirmText : 'confirm'}
						theme='confirm'
						onClick={this.onConfirmClick(button.onClick)}
						disabled={this.state.loading}
					/>
				));
			}

			return (
				<div className={styles.actions}>
					{this.renderBottomDismissButton()}
					{confirmButtons}
				</div>
			);
		} else return null;
	}

	renderText() {
		const dialogState = this.props.dialogState;

		if (dialogState.text)
			return (
				<div className={styles.content}>
					{this.renderIcon()}

					<div className={styles.textRow}>{dialogState.text && <div>{t(dialogState.text)}</div>}</div>
				</div>
			);
		else return null;
	}

	componentDidMount(): void {
		document.addEventListener('keydown', this.onKeyPress(this.props.dialogState.dismissCallBack));
	}

	componentWillUnmount(): void {
		document.removeEventListener('keydown', this.onKeyPress(this.props.dialogState.dismissCallBack));
	}

	render() {
		const dialogState: IDialogState = this.props.dialogState;
		return (
			<div className={styles.backgroundFadeContainer}>
				<div className={styles.dialog}>
					<div>
						<div className={`${styles.header} ${dialogState.content !== undefined && styles.underlined}`}>
							<div>{dialogState.title !== undefined && t(dialogState.title)}</div>

							{this.renderTopDismissButton()}
						</div>
						{this.renderText()}

						{dialogState.content}
					</div>

					{this.renderActions()}
				</div>
			</div>
		);
	}
}
