/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IAddressType } from "./AddressType";
import { ICountry } from "./Country";
import { ICompany } from "./Company";
import { IGeocode } from "./Geocode";

export interface IAddress extends IModelBase {
	addressType: IAddressType | null;
    addressType_Id: string | null;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    country: ICountry | null;
    country_Id: string | null;
    description: string | null;
    summary: string | null;
    company: ICompany | null;
    company_Id: string | null;
    geocode: IGeocode | null;
}

export class Address extends ModelBase implements IAddress {
	public addressType: IAddressType | null = null;
    public addressType_Id: string | null = null;
    public street: string | null = null;
    public postalCode: string | null = null;
    public city: string | null = null;
    public country: ICountry | null = null;
    public country_Id: string | null = null;
    public description: string | null = null;
    public summary: string | null = null;
    public company: ICompany | null = null;
    public company_Id: string | null = null;
    public geocode: IGeocode | null = null;
}
