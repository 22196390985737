import { AuthorizedFetchRequest, UnauthorizedFetchRequest } from './apiBase';

const baseUrl = 'api/user';

function LoginUser(
	userCredentials: {
		Username: string;
		Password: string;
	},
	queries?: object
): Promise<Response> {
	const url = { base: `${baseUrl}/authenticate`, queries: queries };
	return UnauthorizedFetchRequest(url, 'POST', userCredentials)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			return err;
		});
}

function RefreshToken() {
	const url = { base: `${baseUrl}/refreshToken` };
	return AuthorizedFetchRequest(url, 'POST')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			return err;
		});
}

export { LoginUser, RefreshToken };
