import { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';

import styles from './inputFields.module.scss';
import { InputAction } from './InputAction';
import { IHyperlinkInputProps, LinkType } from './inputField.types';

export function HyperlinkInputComponent(props: IHyperlinkInputProps) {
	let href = props.value;
	switch (props.type) {
		case LinkType.email:
			href = `mailto:${props.value}`;
			break;
		case LinkType.tel:
			href = `${props.type}:${props.value}`;
			break;
	}

	if (props.readOnly)
		return (
			<div className={styles.hyperlinkWrapper}>
				<a href={href}>{props.value}</a>
			</div>
		);
	else
		return (
			<input
				className={`${styles.input} ${props.hasError && styles.invalid}`}
				value={props.value}
				type={props.type}
				required={props.required}
				onChange={(e) => props.onChange(e.target.value)}
			/>
		);
}
