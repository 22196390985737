import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditMethod, IEntityFormHeaderProps } from '../../entityDetailPopup.interfaces';
import styles from '../formDetail.module.scss';
import { ButtonsGeneral } from './Buttons/_ButtonsGeneral';
import { FormHeaderBase } from './_FormHeaderBase';
import { t } from 'i18next';
import { faFlagCheckered } from '@fortawesome/pro-regular-svg-icons';
import { Fragment, useMemo } from 'react';
import { Trans } from 'react-i18next';

export interface IEntityFormHeaderAppointmentProps extends IEntityFormHeaderProps {
	appointmentFinishClickHandler: () => void;
	editMethod: EditMethod;
}

export function FormHeaderAppointment(props: IEntityFormHeaderAppointmentProps) {
	const showFinishAppointment = useMemo(() => {
		if (props.vm.model !== undefined) {
			if (props.vm.model.isClosed) return false;
			else if (props.editing) return false;
			else return true;
		} else return false;
	}, [props.editing, props.vm.model]);

	return (
		<FormHeaderBase
			{...props}
			iconName="calendar-days"
			headerText={props.vm.getProperty('subject')}
		>
			<ButtonsGeneral
				vm={props.vm}
				editing={props.editing}
				setEditing={props.setEditing}
				closeDetailPopup={props.closeDetailPopup}
			>
				{showFinishAppointment && (
					<Fragment>
						<span
							onClick={props.appointmentFinishClickHandler}
							className={styles.preButtonText}
						>
							<Trans i18nKey="finishAppointment.finishAppointment" />
						</span>

						<FontAwesomeIcon
							className={`${styles.formButton} ${styles.withDivider}`}
							icon={faFlagCheckered}
							onClick={props.appointmentFinishClickHandler}
							size="xl"
						/>
					</Fragment>
				)}
			</ButtonsGeneral>
		</FormHeaderBase>
	);
}
