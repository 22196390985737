import * as React from 'react';

import styles from './infoBlocks.module.scss'

export class InfoRow extends React.Component<
	{
		label: string;
		children?: React.ReactNode;
	},
	{}
> {

	render() {
		return (
			<div className={styles.infoRow}>
				<div className={`${styles.title} text-capitalize`}>{this.props.label}</div>
				<div className={styles.desc}>{this.props.children}</div>
			</div>
		);
	}
}