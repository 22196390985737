import { SetStateAction, Dispatch } from 'react';
import { IUserManager, UserManager } from '../utilities/UserManager';
import { GuidFactory } from '../utilities/GuidFactory';
import _ from 'lodash';
import { refreshToken } from '../utilities/authProvider';
import { handleError } from './apiBase';

const userManager: IUserManager = new UserManager();

export module server {
	export function removeNullValues(obj: Record<string, any>): Record<string, any> {
		const result: Record<string, any> = {};

		for (const key in obj) {
			if (obj.hasOwnProperty(key) && obj[key] !== null) {
				result[key] = obj[key];
			}
		}

		return result;
	}

	function append(url: string, value?: string) {
		if (_.isNil(value) || value === '') {
			if (url.endsWith('/')) {
				url = url.substring(0, url.length - 1);
			}

			return url;
		}

		if (url.endsWith('/') === false) {
			url = `${url}/`;
		}

		return `${url}${value}`;
	}

	function appendQueryString(url: string, value?: string) {
		if (_.isNil(value) || value === '') return url;

		if (value!.startsWith('?') || value!.startsWith('&')) {
			value = value!.substring(1);
		}
		if (url.indexOf('?') === -1) {
			url = `${url}?`;
		}
		if (url.endsWith('?') === false) {
			url = `${url}&`;
		}

		return `${url}${value}`;
	}

	function appendIncludes(url: string, value?: string[]) {
		if (_.isNil(value) || value!.length === 0) return url;
		const q = value!.map((item) => `&includes=${item}`).join('');
		return appendQueryString(url, q);
	}

	export async function viewAsync<T>(url: string, body?: any, action?: string): Promise<T | undefined> {
		url = append(url, action);

		if (!url.includes('/view')) {
			url = `${url}/view`;
		}

		if (body) {
			body = JSON.stringify(body);
		}

		return callAsync<T>('POST', url, body);
	}

	export async function listAsync<T>(url: string, body?: any, action?: string): Promise<T | undefined> {
		url = append(url, action);

		if (!url.endsWith('/list')) {
			url = `${url}/list`;
		}

		if (body) {
			body = JSON.stringify(body);
		}

		return callAsync<T>('POST', url, body);
	}

	export async function updateAsync<T>(url: string, model?: T, action?: string): Promise<T | undefined> {
		if (model === undefined) return undefined;
		if (model === null) return undefined;

		const id = model['id'];

		//WARNING; THIS MIGHT HOPEFULLY NOT LEAD TO UNEXPECTED RESULTS
		model = removeNullValues(model) as T; //we're lying btw: T ~= T

		if (id === undefined || id === null || id === GuidFactory.Empty) {
			//NEW.
			//We get a bad request if we send a model with an id field
			delete model['id'];
			return postAsync<T>(url, model, action);
		}

		//UPDATE
		return putAsync<T>(url, model, action);
	}

	//UPDATE EXISTING
	export async function putAsync<T>(
		url: string,
		model?: T,
		action?: string,
		mustDeleteId: boolean = false
	): Promise<T | undefined> {
		if (model === undefined) return undefined;
		if (model === null) return undefined;
		if (model['id'] === undefined) return undefined;
		if (model['id'] === null) return undefined;

		const id = model['id'];

		if (mustDeleteId) {
			//in some cases, like with annotations, we do not want to delete the id
			delete model['id'];
		}

		url = append(url, id);
		url = append(url, action);

		return callAsync<T>('PUT', url, JSON.stringify(model));
	}

	//CREATE NEW
	export async function postAsync<T>(url: string, model?: T, action?: string): Promise<T | undefined> {
		if (model === undefined) return undefined;
		url = append(url, action);

		return callAsync<T>('POST', url, JSON.stringify(model));
	}

	export async function getAsync<T = string>(url: string, action?: string): Promise<T | undefined> {
		url = append(url, action);
		return callAsync<T>('GET', url);
	}

	export async function putByIdAsync(url: string, id: string, action?: string): Promise<boolean> {
		url = append(url, id);
		url = append(url, action);

		const response = await call('PUT', url);

		if (response.ok === false) {
			console.warn(`putByIdAsync [${url} : ${response.statusText}`);
			return false;
		}

		return response.status === 200;
	}

	export async function deleteByIdAsync(url: string, id: string, action?: string): Promise<boolean> {
		url = append(url, id);
		url = append(url, action);

		const response = await call('DELETE', url);

		if (response.ok === false) {
			console.warn(`deleteByIdAsync [${url} : ${response.statusText}`);
			return false;
		}

		return response.status === 200;
	}

	export async function downloadByIdAsync(url: string, id?: string, action?: string): Promise<any> {
		url = append(url, action);

		if (!url.endsWith('/downloadLink')) {
			url = `${url}/downloadLink?fileId=${id}`;
		}

		const response = await call('GET', url);

		if (response.status === 500 || response.status === 400) {
			return response;
		} else return await response.json();
	}

	export async function startUploadFileAsync(
		url: string,
		id: string | undefined,
		fileInfo: { fileName: string; expectedSize: number; entityType: string }
	): Promise<any> {
		if (!url?.endsWith('/startUpload')) {
			url = `${url}/startUpload?fileName=${fileInfo.fileName}&expectedSize=${fileInfo.expectedSize}&parentId=${id}&entityType=${fileInfo.entityType}`;
		}
		const response = await call('POST', url);
		if (response.status === 500 || response.status === 400) {
			return response;
		} else {
			return await response.json();
		}
	}

	const req = new XMLHttpRequest();
	export async function uploadFileAsync(
		fileUrl: string,
		fileData: File | any,
		setUploadProgress: Dispatch<SetStateAction<number>>
	): Promise<any> {
		/* XHR PUT request */
		return new Promise((resolve, reject) => {
			req.open('PUT', fileUrl);

			req.upload.onprogress = function (event) {
				if (event.lengthComputable) {
					const percentComplete = (event.loaded / event.total) * 100;
					setUploadProgress(Math.round(percentComplete));
				}
			};

			req.setRequestHeader('x-ms-blob-type', 'BlockBlob');
			req.onload = async function () {
				if (req.status >= 200 && req.status < 300) {
					const responseData = req.status;
					resolve(responseData);
				} else {
					reject(new Error());
				}
			};

			req.send(fileData);
			req.onabort = () => completeUploadAsync('api/File', encodeURIComponent(fileUrl), false);
		});
	}
	export async function cancelUploadAsync(): Promise<any> {
		req.abort();
	}

	export async function completeUploadAsync(url: string, fileUrl: string, uploadSuccess: boolean): Promise<any> {
		if (!url.endsWith('/completeUpload')) {
			url = `${url}/completeUpload?url=${fileUrl}&uploadSuccess=${uploadSuccess}`;
		}
		const response = await call('PUT', url);

		if (response.ok === false) {
			console.warn(`completeUploadFileAsync [${url} : ${response.statusText}`);
			return {};
		}

		return response;
	}

	export async function getByIdAsync<T = string>(
		url: string,
		id: string,
		includes?: string[],
		action?: string
	): Promise<T | undefined> {
		url = append(url, id);
		url = append(url, action);
		url = appendIncludes(url, includes);

		// url = appendQueryString(url, queryString, true);

		//HACK: This might be a temporal hack.
		//We assume that we always want fieldValues
		//Remove or fix this when this seems to be not the case

		// if (params === undefined) {
		// 	params = "includes=fieldValues";
		// } else {
		// 	var q = 'includes=';

		// 	if (Array.isArray(params)) {

		// 	} else {
		// 		q += params;
		// 	}
		// }

		// url = appendParams(url, queryString);

		return callAsync<T>('GET', url);
	}

	export async function callAsync<T = string>(
		method: 'GET' | 'POST' | 'PUT' | 'DELETE',
		url: string,
		body?: any,
		header?: any,
		secure: boolean = true
	): Promise<T | undefined> {
		const response = await call(method, url, body, header, secure);
		const json = await response.json();

		if (response.ok) {
			return json as T;
		}

		console.warn(`callAsync [${method}] ${url} : ${response.statusText} | ${response.text}`);

		return handleError(json.ErrorName, () => callAsync(method, url, body, header, secure));
	}

	export function list(url: string, body?: any, action?: string) {
		url = append(url, action);

		if (!url.endsWith('/list')) {
			url = `${url}/list`;
		}

		if (body) {
			body = JSON.stringify(body);
		}

		return call('POST', url, undefined, body);
	}

	export function call(
		method: 'GET' | 'POST' | 'PUT' | 'DELETE',
		url: string,
		body?: any,
		header?: any,
		secure: boolean = true
	) {
		const headers = header === undefined ? {} : header;

		headers['Content-Type'] = 'application/json';
		headers['Accept'] = 'application/json';

		if (secure) {
			headers['Authorization'] = `bearer ${userManager.getToken()}`;
		}

		const init: RequestInit | any = {
			mode: 'cors',
			method: method,
			headers: headers,
		};

		if (body) {
			init.body = body;
		}

		return fetch(url, init);
	}

	//PLEASE, LEAVE COMMENTS IN TACT
	// const regQueryStringParams = /(\w*)=([\w,]*)/g;
	// function appendQueryString(url:string, value?:string, injectIncludes?:boolean) {
	// 	//E.G: includes=a,b,c,d,e&bla=&dinges=x,y,z

	// 	if (_.isNil(value) || value === '') return url;

	// 	let q = '';
	// 	let resultArray;

	// 	while ((resultArray = regQueryStringParams.exec(value)) !== null) {
	// 		//console.log(`Found ${resultArray[0]}. Next starts at ${regParams.lastIndex}.`);
	// 		var key = resultArray[1];
	// 		var values = resultArray[2];

	// 		q = `${q}&${key}=${values.split(',').join(`&${key}=`)}`;
	// 	}

	// 	url = appendParams(url, q);

	// 	if (injectIncludes) {
	// 		if (q.indexOf('includes=fieldValues') === -1) {
	// 			url = appendParams(url, '&includes=fieldValues');
	// 		}
	// 	}

	// 	return url;
	// }
}
