import styles from './dynamicTable.module.scss';

import { t } from 'i18next';
import { Fragment, MouseEventHandler, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DynamicTableHeadProps, IDynamicTableProps, ITableAttribute, header } from './DynamicTable.types';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortOrderToIcon } from '../../utilities/sortOrder';

function DynamicTableHead(props: DynamicTableHeadProps) {
	const thRef = useRef<HTMLTableCellElement>();

	const getOffsetLeft = useCallback(() => {
		let offsetLeft = 0;
		if (thRef.current) {
			let elem: any = thRef.current;
			do {
				if (!isNaN(elem.offsetLeft)) {
					offsetLeft += elem.offsetLeft;
				}
			} while ((elem = elem.offsetParent));
		}
		return offsetLeft;
	}, []);

	const propsTHClickHandler = props.thClickHandler;
	const propsName = props.name;
	const onTableHeadClick = useCallback(
		(e) => {
			if (propsTHClickHandler !== undefined) {
				propsTHClickHandler(propsName, e.shiftKey ? 'ADD' : 'REPLACE');
			}
		},
		[propsTHClickHandler, propsName]
	);

	let headerClassName = '';
	if (props.order !== undefined) headerClassName += styles.ordered + ' ';
	if (props.thClickHandler) headerClassName += styles.clickable;

	return (
		<Fragment>
			<th
				ref={thRef as any}
				id={props.name}
				style={{
					width: `${props.width}px`,
				}}
				className={headerClassName}
				onClick={onTableHeadClick}
			>
				{props.children}
				{props.thClickHandler && (
					<FontAwesomeIcon
						className={styles.sortIcon}
						icon={sortOrderToIcon(props.order)}
					/>
				)}
				<div
					className={styles.resizer}
					onMouseDown={props.onResizerClick(getOffsetLeft())}
				/>
			</th>
		</Fragment>
	);
}

function generateWidthsFromHeaders(tableAttributes: ITableAttribute[], minimalColumnWidth?: number): number[] {
	const widths: number[] = [];

	tableAttributes.forEach((header) => {
		let initialWidth = 0;
		if (header.width) initialWidth = header.width;
		if (minimalColumnWidth) Math.max(initialWidth, minimalColumnWidth);

		widths.push(initialWidth);
	});

	return widths;
}

export function DynamicTable(props: IDynamicTableProps) {
	const [widths, setWidths] = useState(generateWidthsFromHeaders(props.tableAttributes, props.minimalColumnWidth));
	const [dragging, setDragging] = useState<{ index: number; offsetLeft: number } | null>(null);
	const scrollRef = useRef<HTMLDivElement>();

	const getTotalWidth = useCallback(() => {
		let totalWidth = 0;

		widths.forEach((width) => (totalWidth += width));

		return totalWidth;
	}, [widths]);

	const setWidthFunction = useCallback(
		(index: number) => (width: number) => {
			const nextWidths = [...widths];

			nextWidths[index] = width;

			setWidths(nextWidths);
		},
		[widths]
	);

	const onMouseUp = useCallback(() => {
		setDragging(null);

		document.removeEventListener('mouseup', onMouseUp);
	}, []);

	const resizerClick = useCallback(
		(index: number) => (offsetLeft: number) => () => {
			setDragging({ index: index, offsetLeft: offsetLeft });

			document.addEventListener('mouseup', onMouseUp);
		},
		[onMouseUp]
	);

	const generateHeadersFromTableAttributes = useCallback(
		(tableAttributes: ITableAttribute[]): header[] => {
			const headers: header[] = [];

			tableAttributes.forEach((attr, index) => {
				if (attr.property && attr.property.children) {
					const newHeaders = generateHeadersFromTableAttributes(attr.property.children);
					headers.push(...newHeaders);
				} else {
					let content: ReactNode = null;
					if (attr.headIcon) content = <FontAwesomeIcon icon={attr.headIcon} />;
					else if (attr.headTitle) {
						if (attr.dontTranslateHeadTitle) content = attr.headTitle;
						else content = t(attr.headTitle);
					}

					const order = props.sortOrderObjectArray
						? props.sortOrderObjectArray.find((object) => object.propName === attr.property.name)?.order
						: undefined;

					const newHeader: header = {
						name: Array.isArray(attr.property.name) ? attr.property.name.join(' ') : attr.property.name,
						content: content,
						order: order,
						thClickHandler: !attr.dontUseSorting ? props.thClickHandler : undefined,
					};
					headers.push(newHeader);
				}
			}, []);

			return headers;
		},
		[props.sortOrderObjectArray, props.thClickHandler]
	);

	const generateTableHeads = useCallback(
		(tableAttributes: ITableAttribute[]): ReactNode => {
			const headers = generateHeadersFromTableAttributes(tableAttributes);

			const DynamicTableHeads = headers.map((header, index) => {
				return (
					<DynamicTableHead
						width={widths[index]}
						minimalWidth={props.minimalColumnWidth}
						onResizerClick={resizerClick(index)}
						setWidthFunction={setWidthFunction(index)}
						name={header.name}
						key={`th_${index}`}
						thClickHandler={header.thClickHandler}
						order={header.order}
					>
						{header.content}
					</DynamicTableHead>
				);
			});

			let remainingWidth = scrollRef.current?.clientWidth;

			if (remainingWidth !== undefined) {
				remainingWidth -= getTotalWidth();

				DynamicTableHeads.push(<th style={{ width: `${remainingWidth}px` }} />);
			}

			return DynamicTableHeads;
		},
		[widths, props.minimalColumnWidth, setWidthFunction, resizerClick, generateHeadersFromTableAttributes, getTotalWidth]
	);

	const onMouseMove: MouseEventHandler = useCallback(
		(e) => {
			if (dragging !== null) {
				if (scrollRef.current) {
					const currentMousePosition = e.clientX + scrollRef.current.scrollLeft;
					const precedingCellWidths = dragging.offsetLeft;

					const nextWidth = currentMousePosition - precedingCellWidths - 25;

					setWidthFunction(dragging.index)(nextWidth);
				}
			}
		},
		[dragging, setWidthFunction]
	);

	useEffect(() => {}, []);

	const hasData = props.tableItems && props.tableItems.length > 0;
	let scrollViewClasses = styles.scrollView;
	if (dragging !== null) scrollViewClasses += ` ${styles.dragging}`;

	const scrollViewWidth = scrollRef.current?.clientWidth !== undefined ? scrollRef.current.clientWidth : 0;

	return (
		<Fragment>
			<div
				className={scrollViewClasses}
				ref={scrollRef as any}
				onMouseMove={onMouseMove}
			>
				<table className={styles.table}>
					<tbody>
						<tr>{generateTableHeads(props.tableAttributes)}</tr>
						{hasData &&
							props.tableItems.map((tableItem, trKey) => {
								const finalVisibleCallback = trKey + 1 === props.tableItems.length ? props.finalItemVisibleCallback : undefined;

								return (
									<TableRow
										key={`tr_${trKey}`}
										index={trKey}
										tableAttributes={props.tableAttributes}
										listedItem={tableItem}
										columnWidths={widths}
										minimalColumnWidth={props.minimalColumnWidth}
										clickHandler={props.onRowClick}
										visibleCallback={finalVisibleCallback}
										checkEmphasisFunction={props.checkEmphasisFunction}
									/>
								);
							})}
						<tr>
							<td
								align="center"
								colSpan={props.tableAttributes.length + 1}
								style={{ width: Math.max(scrollViewWidth, getTotalWidth()) + 'px' }}
							>
								{!hasData && <div className={styles.noDataAvailable}>{t('noDataAvailable')}</div>}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Fragment>
	);
}

function useOnScreen(ref: RefObject<HTMLElement>) {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

	useEffect(() => {
		observer.observe(ref.current as Element);
		return () => observer.disconnect();
	}, [observer, ref]);

	return isIntersecting;
}

function TableRow(props: {
	index: number;
	tableAttributes: ITableAttribute[];
	listedItem: any;
	columnWidths: number[];
	minimalColumnWidth?: number;
	clickHandler?: (tableItem: any) => MouseEventHandler;
	visibleCallback?: () => void;
	checkEmphasisFunction?: (item) => boolean;
}) {
	const trRef = useRef<HTMLTableRowElement>(null);
	const isVisible = useOnScreen(trRef);

	const visibleCallback = props.visibleCallback;
	useEffect(() => {
		if (isVisible && visibleCallback) {
			visibleCallback();
		}
	}, [isVisible, visibleCallback]);

	const getData = useCallback((item: any, attr: ITableAttribute): any => {
		if (attr.converter !== undefined) {
			return attr.converter.convert(item, attr);
		}

		if (item) {
			if (Array.isArray(attr.property.name)) {
				return attr.property.name.map((propName) => item[propName]).join(' ');
			} else {
				return item[attr.property.name];
			}
		}

		return undefined;
	}, []);

	//WIP: Probably going to remove
	const replaceValueWithIcon = useCallback((value: any, iconReplace: { value: any; icon: IconName }[]): ReactNode => {
		let icon: ReactNode = value;
		iconReplace.forEach((option) => {
			if (value === option.value) icon = <FontAwesomeIcon icon={option.icon} />;
		});
		return icon;
	}, []);

	//WIP: Probably going to remove
	const replaceValueWithDateString = useCallback((value: string, dateType: 'date' | 'time' | 'datetime-local'): string => {
		const valueDate = new Date(value);

		switch (dateType) {
			case 'date':
				return valueDate.toLocaleDateString();
			case 'time':
				return valueDate.toLocaleTimeString();
			case 'datetime-local':
				return valueDate.toLocaleString();
		}
	}, []);

	const generateTableData = useCallback(
		(item: any, attr: ITableAttribute, index: number, tableItemEnum?): ReactNode => {
			if (attr.property.children !== undefined) {
				return attr.property.children.map((childTableAttributes) => {
					let tableDataItem = '';
					if (Array.isArray(attr.property.name)) {
						const propNames = attr.property.name;
						tableDataItem = propNames.map((propName) => item[propName]).join(' ');
					} else {
						tableDataItem = item[attr.property.name];
					}

					return generateTableData(tableDataItem, childTableAttributes, index, childTableAttributes.enum);
				});
			}

			const data = getData(item, attr);

			const valueIsEmpty = data === null || undefined || '';

			let displayedData;
			if (valueIsEmpty) displayedData = null;
			else if (attr.iconReplace) displayedData = replaceValueWithIcon(data, attr.iconReplace);
			else if (tableItemEnum) {
				displayedData = tableItemEnum[data];
			} else {
				const dateTimeRegex = /^(date)|(time)|(datetime-local)$/g;

				if (attr.type !== undefined && data !== undefined && dateTimeRegex.test(attr.type))
					displayedData = replaceValueWithDateString(data, attr.type as 'date' | 'time' | 'datetime-local');
				else displayedData = data;
			}

			const tdWidth = props.columnWidths[index] ? props.columnWidths[index] : undefined;

			return (
				<td key={`td_${index}`}>
					<div
						style={{
							width: `${tdWidth}px`,
							minWidth: `${props.minimalColumnWidth}px`,
							maxWidth: tdWidth !== undefined ? undefined : '500px',
						}}
						className={styles.cell}
					>
						{displayedData}
					</div>
				</td>
			);
		},
		[getData, props.columnWidths, props.minimalColumnWidth, replaceValueWithDateString, replaceValueWithIcon]
	);

	let className = styles.row;
	if (props.checkEmphasisFunction && props.checkEmphasisFunction(props.listedItem)) className += ` ${styles.emphasis}`;

	return (
		<tr
			key={`tr_${props.index}`} //TODO: Key should be unique (for pagination)
			className={className}
			onClick={props.clickHandler ? props.clickHandler(props.listedItem) : undefined}
			ref={trRef}
		>
			{props.tableAttributes.map((tableAttribute, index) => {
				return generateTableData(props.listedItem, tableAttribute, index, tableAttribute.enum);
			})}
		</tr>
	);
}
