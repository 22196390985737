import { Component, Fragment } from 'react';
import { GridComponents, PanelMain, PanelPopup, DynamicTable } from '..';
import { t } from 'i18next';
import { IEntityTableProps, IEntityTableState } from './types';
import { EntityListView } from '../EntityListDetail/EntityListView';
import { ListPageEntityType } from '../EntityListDetail/entityListView.interfaces';

export function PanelEntity(props: { entityType: ListPageEntityType; hidePlusButton?: boolean }) {
	return (
		<PanelMain>
			<EntityListView
				entityType={props.entityType}
				hidePlusButton={props.hidePlusButton}
			/>
		</PanelMain>
	);
}

export class EntityTable extends Component<IEntityTableProps, IEntityTableState> {
	constructor(props) {
		super(props);

		this.state = {};
	}

	viewEntity = (entity: any) => () => {
		this.setState({
			popupPanel: {
				entityToView: entity,
				method: 'PUT',
			},
		});
	};

	addEntity = () => {
		this.setState({
			popupPanel: {
				entityToView: {},
				method: 'POST',
				openInEdit: true,
			},
		});
	};

	closePopup = () => {
		this.setState({ popupPanel: undefined });
		this.props.onClose();
	};

	onDeleteClick = (id: any) => () => {
		this.props
			.onDelete(id)
			.then((res) => {
				this.closePopup();
			})
			.catch((err) => {
				alert(t('anErrorOccurred'));
				console.error(err);
				this.closePopup();
			});
	};

	componentDidUpdate(prevProps: Readonly<IEntityTableProps>) {
		if (prevProps.openAddForm !== this.props.openAddForm && this.props.openAddForm) {
			this.addEntity();
		}
	}

	render() {
		let defaultValuesObj: object | undefined = {};
		if (this.props.defaultValueObj) defaultValuesObj = this.props.defaultValueObj;
		else if (this.props.defaultValueObjAttributes !== undefined && this.state.popupPanel)
			defaultValuesObj[this.props.defaultValueObjAttributes.defaultValueName] =
				this.state.popupPanel.entityToView[this.props.defaultValueObjAttributes.entityPropName];

		if (this.state.popupPanel?.method === 'POST' && this.props.defaultValuesOnAdd) {
			defaultValuesObj = { ...defaultValuesObj, ...this.props.defaultValuesOnAdd };
		}

		const onRowClick = this.props.customEntityRowClick !== undefined ? this.props.customEntityRowClick(this.viewEntity) : this.viewEntity;

		//TODO: HACK
		let selector = 'id';
		if (this.props.entityName === 'Company') {
			selector = 'entity_Id';
		}

		return (
			<Fragment>
				<GridComponents.Row.Scroll>
					{this.props.errorOccurred ? (
						t('anErrorOccurred')
					) : (
						<DynamicTable
							tableAttributes={this.props.tableAttributes}
							tableItems={this.props.tableItems}
							minimalColumnWidth={50}
							onRowClick={onRowClick}
						/>
					)}
				</GridComponents.Row.Scroll>

				{this.state.popupPanel !== undefined && (
					<PanelPopup
						navigate={this.props.navigate}
						location={this.props.location}
						match={this.props.match}
						getEntityByIdFunction={this.props.getEntityByIdFunction}
						objectId={this.state.popupPanel.entityToView[selector]}
						selectionProperties={this.props.selectionProperties}
						panelGroups={this.props.popupPanels}
						closeHandler={this.closePopup}
						method={this.state.popupPanel.method}
						openInEdit={this.state.popupPanel.openInEdit}
						onDeleteClick={this.onDeleteClick}
						extraButtons={this.props.extraButtons}
						defaultValuesObj={{ ...defaultValuesObj }}
					/>
				)}
			</Fragment>
		);
	}
}
