import { OpportunityStatus } from '../../../model/_Enums';
import { enumToSelectionArray } from '../../../utilities/enumTranslator';

export const table = {
	title: 'opportunities',
	entityName: 'Opportunity',
	type: 'table',
	headerPropertyName: 'subject',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'opportunityAttributes.company',
			property: {
				name: 'opportunity_Company_Name',
			},
		},
		{
			headTitle: 'subject',
			property: {
				name: 'opportunity_Subject',
			},
		},
		{
			headTitle: 'opportunityAttributes.estimatedClosingDate',
			property: {
				name: 'opportunity_EstimatedClosingDate',
			},
			type: 'date',
		},
		{
			headTitle: 'opportunityAttributes.amount',
			property: {
				name: 'opportunity_Amount',
			},
			type: 'number',
		},
		{
			headTitle: 'opportunityAttributes.estimatedSuccessRate',
			property: {
				name: 'opportunity_EstimatedSuccessRate',
			},
			type: 'number',
		},
		{
			headTitle: 'status',
			property: {
				name: 'opportunity_OpportunityStatus',
			},
			type: 'selection',
			selections: enumToSelectionArray(OpportunityStatus, 'opportunityStatus'),
			enum: OpportunityStatus,
		},
		{
			headTitle: 'representativeAttributes.createdBy',
			property: {
				name: 'opportunity_CreatedBy_FullName',
			},
			type: 'selection',
		},
		{
			headTitle: 'opportunityAttributes.weeksDue',
			property: {
				name: 'weeksDue',
			},
			type: 'number',
		},
	],
	apiUrl: '/Opportunity',
};
