// import styles from './../PanelMain/layout.module.scss'; //XXX
import React, { Component, Fragment } from 'react';
import logo from '../../images/mydesk.webp';
import logo_spinner from '../../images/mydesk_spinner.webp';
import urlParser from '../../utilities/urlParser';
import styles from '../../css/pageElements.module.scss';
import pagedefinitions from '../../globals/pagedefinitions.json';
import authProvider, { hasModule, moduleNames } from '../../utilities/authProvider';
import EventBus from '../../utilities/EventBus';
import LocaleMenu from '../LocaleMenu/LocaleMenu';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentRepresentative } from '../../store/reducers/CurrentRepresentativeSlice';
import { LoadEventTypes } from '../../globals/enums';
import { ButtonLogout } from '../ButtonLogout/ButtonLogout';
import { IHeaderProps } from './headerMain.types';
import { DevMenu } from '../_DevMenu/DevMenu';
import { HeaderLinks } from './HeaderLinks';

export class HeaderMain extends Component<IHeaderProps> {
	logoMyDesk!: React.RefObject<HTMLImageElement>;
	logoMyDeskID!: string | undefined;

	logoMyDeskSpinner!: React.RefObject<HTMLImageElement>;
	logoMyDeskSpinnerID!: string | undefined;

	loadCount: number = 0;
	loadTimeoutID: any;
	loadTimeoutDelay: number = 10000;

	constructor(props) {
		super(props);

		EventBus.instance().on(LoadEventTypes.loadReset, (data: any) => {
			this.loadReset(data);
		});
		EventBus.instance().on(LoadEventTypes.loadBegin, (data: any) => {
			this.loadBegin(data);
		});
		EventBus.instance().on(LoadEventTypes.loadEnd, (data: any) => {
			this.loadEnd(data);
		});

		this.logoMyDesk = React.createRef<HTMLImageElement>();
		this.logoMyDeskSpinner = React.createRef<HTMLImageElement>();
		this.logoMyDeskID = this.logoMyDesk.current?.id;
		this.logoMyDeskSpinnerID = this.logoMyDeskSpinner.current?.id;
	}

	componentDidMount(): void {
		this.load('header');
	}

	componentWillUnmount(): void {
		//EventBus.instance().remove('loadReset', (data) => this.loadReset(data));
		this.removeTimeout();
		this.loadCount = 0;
	}

	componentDidUpdate(prevProps: Readonly<IHeaderProps>): void {
		if (this.props.loading !== prevProps.loading) {
			this.loadFromProps();
		}
	}

	tryGetLogo = (): HTMLImageElement | null => {
		/*
		Image ref might be null, because Render()
		has not been called yet in this instance.
		Therefore, we store a reference in the document (global)
		*/
		let logoMyDesk = this.logoMyDesk.current;

		if (logoMyDesk != null) {
			document['__logoMyDesk'] = logoMyDesk;
		}
		if (logoMyDesk == null) {
			logoMyDesk = document['__logoMyDesk'];
		}

		return logoMyDesk;
	};

	tryGetLogoSpinner = (): HTMLImageElement | null => {
		let logoMyDeskSpinner = this.logoMyDeskSpinner.current;

		if (logoMyDeskSpinner != null) {
			document['__logoMyDeskSpinner'] = logoMyDeskSpinner;
		}
		if (logoMyDeskSpinner == null) {
			logoMyDeskSpinner = document['__logoMyDeskSpinner'];
		}

		return logoMyDeskSpinner;
	};

	isShowingImage = (image: HTMLImageElement | null): Boolean => {
		if (!image) return false;

		const style = image.getAttribute('style');
		if (style === null) return true;
		if (style === '') return true;
		return false;
	};

	removeTimeout = () => {
		if (this.loadTimeoutID) {
			clearTimeout(this.loadTimeoutID);
		}
	};

	showSpinner = () => {
		const logoMyDesk = this.tryGetLogo();
		const logoMyDeskSpinner = this.tryGetLogoSpinner();

		if (this.isShowingImage(logoMyDeskSpinner)) return;

		if (logoMyDesk) {
			logoMyDesk.setAttribute('style', 'display: none');
		}
		if (logoMyDeskSpinner) {
			logoMyDeskSpinner.setAttribute('style', '');
		}
	};

	hideSpinner = () => {
		const logoMyDesk = this.tryGetLogo();
		const logoMyDeskSpinner = this.tryGetLogoSpinner();

		if (this.isShowingImage(logoMyDesk)) return;

		if (logoMyDesk) {
			logoMyDesk.setAttribute('style', '');
		}
		if (logoMyDeskSpinner) {
			logoMyDeskSpinner.setAttribute('style', 'display: none');
		}
	};

	load = (data: any) => {
		//log data to print arguments of event
		this.removeTimeout();

		if (this.loadCount < 0) {
			this.loadCount = 0;
		}

		if (this.loadCount === 0) {
			this.hideSpinner();
			return;
		}

		this.showSpinner();
		this.loadTimeoutID = setTimeout(this.loadReset, this.loadTimeoutDelay);
	};

	//@deprecated, please use EventBus instead
	loadFromProps = () => {
		console.warn('Deprecated: setting spinner from props');

		if (this.props.loading) {
			this.loadBegin('this.props.loading = ' + this.props.loading);
			return;
		}

		this.loadEnd('this.props.loading = ' + this.props.loading);
	};

	loadReset = (data: any) => {
		console.warn('LoadTimeout..!');
		this.loadCount = 0;
		this.load(data);
	};

	loadBegin = (data: any) => {
		this.loadCount++;
		this.load(data);
	};

	loadEnd = (data: any) => {
		this.loadCount--;
		this.load(data);
	};

	renderMenu(activePath: string) {
		const topmenu = pagedefinitions.groups.top;
		const hasOpportunityModule = hasModule(moduleNames.opportunity);
		if (!hasOpportunityModule) {
			const i = topmenu?.pages.findIndex((page) => page.link === 'opportunities');
			if (i !== undefined && i > -1) topmenu?.pages.splice(i, 1);
		}

		if (topmenu === undefined || topmenu.pages.length === 0) return null;
		const count = topmenu.pages.length;

		const elements = topmenu.pages.map((page, index) => {
			const isLast = index === count - 1;
			const link = `/${page.link}`;
			const css = page.link === activePath ? styles.headerMenuActive : '';

			return (
				<Fragment key={page.title}>
					<Link
						to={link}
						className={css}
					>
						<Trans i18nKey={page.title} />
					</Link>
					{!isLast && <div className={styles.sep}>|</div>}
				</Fragment>
			);
		});

		return elements;
	}

	renderToolLink(pagename: string) {
		const css = pagename === 'settings' ? styles.linkFillActive : styles.linkFill;
		const path = `/settings`;

		return (
			<Link
				to={path}
				className={css}
				key={pagename}
			>
				<FontAwesomeIcon
					className={styles.icon}
					icon={['fal', 'cog']}
				/>
			</Link>
		);
	}

	render() {
		const info = urlParser.getUrlPath();
		const activePath = info.path[0].toLowerCase();

		return (
			<header>
				<div className={styles.headerArea}>
					<nav className={styles.headerNavigation}>
						<Link
							to={`/`}
							className={styles.headerLogo}
						>
							<img
								src={logo}
								ref={this.logoMyDesk}
								title='MyDesk'
								alt='MyDesk'
							/>
							<img
								src={logo_spinner}
								ref={this.logoMyDeskSpinner}
								title='MyDesk (loading)'
								alt='MyDesk (loading)'
								style={{ display: 'none' }}
							/>
						</Link>

						<div className={styles.headerMenu}>
							<HeaderLinks activePath={activePath} />
						</div>

						<div className={styles.headerSettings}>
							<ActiveUser className={styles.impersonation} />

							{this.renderToolLink(activePath)}

							<LocaleMenu />
							<DevMenu />
							<ButtonLogout />
						</div>
					</nav>
				</div>
			</header>
		);
	}
}

function ActiveUser(props) {
	const currentRepresentative = useAppSelector(selectCurrentRepresentative);

	if (currentRepresentative && currentRepresentative.id !== authProvider.getUserId()) return <div {...props}>{currentRepresentative.fullName}</div>;

	return null;
}
