/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";

export interface IVisitingFrequency extends IModelBase {
	code: string | null;
    description: string | null;
    summary: string | null;
    weekSpan: number;
}

export class VisitingFrequency extends ModelBase implements IVisitingFrequency {
	public code: string | null = null;
    public description: string | null = null;
    public summary: string | null = null;
    public weekSpan: number = 0;
}
