import { AuthorizedFetchRequest } from './apiBase';

export function initExactConnection(
	code: string,
	queries?: object
): Promise<Response> {
	const url = { base: `api/ExternalConnections/Exact`, queries: queries };
	return AuthorizedFetchRequest(url, 'PUT', {
		code: code,
		active: true,
	}).then((res) => {
		return res;
	});
}

export function getConnectors(queries?: object): Promise<Response> {
	const url = { base: `api/ExternalConnections`, queries: queries };

	return AuthorizedFetchRequest(url, 'GET').then((res) => {
		return res;
	});
}

export function getExchangeTasks(queries?: object): Promise<Response> {
	const url = { base: `api/ExternalConnections/Exchange/tasks`, queries: queries };

	return AuthorizedFetchRequest(url, 'GET',).then((res) => {
		return res
	});
}