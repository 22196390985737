import styles from './inputFields.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IInputActionProps } from './inputField.types';

export function InputAction(props: IInputActionProps) {
	return (
		<div
			className={styles.InputActionObject}
			onClick={props.onClick}
		>
			<FontAwesomeIcon
				icon={props.icon}
				size='lg'
			/>
		</div>
	);
}
