import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { Opportunity } from '../models/Model/Opportunity';
import authProvider from '../utilities/authProvider';
import { BaseViewModel } from './_BaseViewModel';
import { IViewModel } from './_viewModel.interfaces';

export enum opportunityDropDownPropertyNames {
	company_Id = 'company_Id',
	createdBy_Id = 'createdBy_Id',

	opportunityStatus = 'opportunityStatus',
	opportunityStatusReason_Id = 'opportunityStatusReason_Id',
	representative_Id = 'representative_Id',
}

export default function OpportunityViewModel<T = Opportunity>(instanceName: string): IViewModel<T> {
	const entityType = EntityType.opportunity;
	const functionRead = api.opportunity.getByIdAsync;
	const functionUpdate = api.opportunity.updateAsync;
	const functionDelete = api.opportunity.deleteByIdAsync;

	const vm = BaseViewModel<T>(instanceName, entityType, functionRead, functionUpdate, functionDelete);

	function generateModelInstance(id?: string, representativeId?: string): T {
		const m = new Opportunity();

		m.createdBy_Id = authProvider.getUserId() ?? null;
		m.representative_Id = vm.getRepresentativeId();

		if (representativeId) {
			m.createdBy_Id = representativeId;
			m.representative_Id = representativeId;
		}

		if (id) {
			m.company_Id = id;
		}

		return m as T;
	}

	function create(id?: string, representativeId?: string, params?: any) {
		return vm.doCreate(generateModelInstance(id, representativeId));
	}

	async function read(id) {
		return await vm.doRead(id);
	}

	async function update() {
		return await vm.doUpdate();
	}

	async function del(clearModel: boolean = true) {
		return await vm.doDelete(clearModel);
	}

	return {
		entityType,
		model: vm.model,
		fieldDefinitions: vm.fieldDefinitions,
		isValid: vm.isValid,
		isDirty: vm.isDirty,
		hasSavedChanges: vm.hasSavedChanges,
		instanceName: instanceName,
		create,
		read,
		update,
		del,
		setProperty: vm.setProperty,
		getProperty: vm.getProperty,
		isPropertyValid: vm.isPropertyValid,
		isPropertyReadOnly: vm.isPropertyReadOnly,
		getPropertyErrors: vm.getPropertyErrors,
		getProperties: vm.getProperties,
		getId: vm.getId,
		getType: vm.getType,
		getTypeCased: vm.getTypeCased,
	};
}
