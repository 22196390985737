export const table = {
	type: 'table',
	entityName: 'visitingFrequency',
	tableAttributes: [
		{
			headTitle: 'code',
			property: {
				name: 'code',
			},
			type: 'text',
		},
		{
			headTitle: 'description',
			property: {
				name: 'description',
			},
			type: 'textarea',
		},
		{
			headTitle: 'weekSpan',
			property: {
				name: 'weekSpan',
			},
			type: 'number',
		},
	],
};
