import styles from './dashboard.module.scss';
import { useState, useEffect } from 'react';
import { IconCustom, IconCustomType } from '../IconCustom/IconCustom.index';
import { ThemeColorType } from '../../globals/enums';

export interface IDashboardListItemProps {
	onListItemClick: (entity: any) => void;

	key: any;
	entity: any;
	icon: IconCustomType;
	id: number;

	iconColor?: ThemeColorType;
	iconTitle?: string;

	titleTopLeft?: string | null;
	titleMidLeft?: string | null;
	titleBotLeft?: string | null;

	titleTopRight?: string | null;
	titleMidRight?: string | null;
	titleBotRight?: string | null;
}

export interface IDashboardListItemState {
	iconColor?: ThemeColorType;
	iconTitle?: string;

	titleTopLeft?: string | null;
	titleMidLeft?: string | null;
	titleBotLeft?: string | null;

	titleTopRight?: string | null;
	titleMidRight?: string | null;
	titleBotRight?: string | null;
}

const DashboardListItem = (props: IDashboardListItemProps) => {
	const getIconTitleClassName = (): string => {
		return `${styles.entity_rest_l} clr_f_${props.iconColor}`;
	};

	const handle_onClick = () => {
		props.onListItemClick(props.entity);
	};

	return (
		<div className={styles.entity} onClick={handle_onClick}>
			<IconCustom kind={props.icon} backColor={props.iconColor} />
			<div className={styles.entity_title}>
				<div className={styles.entity_title_l}>{props.titleTopLeft}</div>
				<div className={styles.entity_title_r}>{props.titleTopRight}</div>
			</div>
			<div className={styles.entity_desc}>
				<div className={styles.entity_desc_l}>{props.titleMidLeft}</div>
				<div className={styles.entity_desc_r}>{props.titleMidRight}</div>
			</div>
			<div className={styles.entity_rest}>
				<div className={getIconTitleClassName()}>{props.iconTitle}</div>
				<div className={styles.entity_rest_c}>{props.titleBotLeft}</div>
				<div className={styles.entity_rest_r}>{props.titleBotRight}</div>
			</div>
		</div>
	);
};

export default DashboardListItem;