import { BooleanToIconConverter } from '../Converters/ColumnConverters';

export const table = {
	title: 'tasks',
	entityName: 'Task',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'subject',
			property: {
				name: 'task_Subject',
			},
			type: 'text',
		},
		{
			headTitle: 'taskAttributes.company',
			property: {
				name: 'task_Company_Name',
			},
		},
		{
			headTitle: 'taskAttributes.dueDate',
			property: {
				name: 'task_DueDate',
			},
			type: 'date',
		},
		{
			headTitle: 'taskAttributes.weeksDue',
			property: {
				name: 'weeksDue',
			},
			type: 'number',
		},
		{
			headTitle: 'taskAttributes.isCompleted',
			property: {
				name: 'task_IsCompleted',
			},
			type: 'boolean',
			converter: BooleanToIconConverter,
		},
		{
			headTitle: 'representativeAttributes.assignedTo',
			property: {
				name: 'task_AssignedTo_FullName',
			},
		},
		{
			headTitle: 'representativeAttributes.createdBy',
			property: {
				name: 'task_CreatedBy_FullName',
			},
		},
	],
	apiUrl: '/Task',
};
