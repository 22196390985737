import { PanelEntity } from '../../../components/PanelEntity/PanelEntity';
import { EntityType } from '../../../components/EntityDetailPopup/entityDetailPopup.enums';
import { newCompanyDisabled } from '../../../utilities/authProvider';

export function CompaniesPage() {
	return (
		<PanelEntity
			entityType={EntityType.Company}
			hidePlusButton={newCompanyDisabled()}
		/>
	);
}
