/* UNMODIFIED */
import { Company, ICompany } from "./Company";
import { IVisitingFrequency } from "./VisitingFrequency";

export interface IAccount extends ICompany {
	number: string | null;
    visitingFrequency: IVisitingFrequency | null;
    visitingFrequency_Id: string | null;
}

export class Account extends Company implements IAccount {
	public number: string | null = null;
    public visitingFrequency: IVisitingFrequency | null = null;
    public visitingFrequency_Id: string | null = null;
}
