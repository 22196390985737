import { useState } from 'react';
import { VisitingFrequency } from '../../../models/Model/VisitingFrequency';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { FormDetail } from './_FormDetail';
import { InfoGroup } from '../../InfoBlocks';
import { t } from 'i18next';
import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { stringJoin } from './_utility';

interface IVisitingFrequencyFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<VisitingFrequency>;
}

export const VisitingFrequencyFormDetail = (props: IVisitingFrequencyFormDetailProps) => {
	const [editing, setEditing] = useState(!props.id);
	let headerText = `${props.vm.getProperty('code')}: ${props.vm.getProperty('description')}`;
	props.vm.getProperties();

	return (
		<FormDetail
			vm={props.vm}
			headerText={headerText}
			editing={editing}
			setEditing={setEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName='wave-square'
		>
			<InfoGroup
				padding
				open
				visible
				title={t('visitingFrequency')}
			>
				<BoundInputRows.TextRow
					label={t('code')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName='code'
					readonly={!editing}
					errorMessage={stringJoin(props.vm.getPropertyErrors('code'))}
				/>
				<BoundInputRows.TextRow
					label={t('description')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName='description'
					readonly={!editing}
					errorMessage={stringJoin(props.vm.getPropertyErrors('description'))}
				/>
				<BoundInputRows.NumberRow
					label={t('weekSpan')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName='weekSpan'
					readonly={!editing}
					errorMessage={stringJoin(props.vm.getPropertyErrors('weekSpan'))}
				/>
			</InfoGroup>
		</FormDetail>
	);
};
