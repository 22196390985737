import React, { PropsWithChildren, Fragment } from 'react';

import { HeaderMain, GridComponents } from '..';
import { IPanelProps } from '../../globals/types';
import styles from '../../css/pageElements.module.scss';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useRouterListener } from '../../App';

export function PanelMain(props: PropsWithChildren<IPanelProps>) {
	const location = useLocation();
	const navigate = useNavigate();
	const match = useMatch(location.pathname);
	useRouterListener();

	return (
		<Fragment>
			<HeaderMain
				location={location}
				match={match}
				navigate={navigate}
			/>
			<div className={styles.main}>
				<GridComponents.Grid>{props.children}</GridComponents.Grid>
			</div>

			<div className='footer'></div>
		</Fragment>
	);
}
