import { Component, FormEvent, Fragment, MouseEventHandler, ReactNode } from 'react';
import {
	IPanelApiTable,
	IBlock,
	IPanelBlockList,
	IData,
	IDateTimeData,
	IPanelBlockProps,
	IPanelBlockTableDataProps,
	IPanelGroup,
	IPanelProps,
	IPanelPopupProps,
	IProperty,
	IPanelRecursiveTable,
	IPanelRecursiveTableProps,
	ISelectionData,
	IPanelTable,
	ITabTypeProps,
} from './types';
import { InfoGroup } from '../InfoBlocks';
import { faFloppyDisk, faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import styles from './panelPopup.module.scss';
import GridComponents, { TableRow, ITabGroup } from '../Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DynamicTable } from '../DynamicTable/DynamicTable';
import { AuthorizedFetchRequest } from '../../api/apiBase';
import { t } from 'i18next';
import { EntityTable } from '../PanelEntity/PanelEntity';
import { getSelectionObject, SearchAndAddCustomFieldBlock } from '../../pages/EntityPages/utilities/utilities';
import { connect } from 'react-redux';
import { IconCustomType } from '../IconCustom/IconCustom.index';
import { fireLoadBegin, fireLoadEnd } from '../../utilities/EventService';
import { openConfirm } from '../Dialog/dialogSlice';
import InputFieldComponent, { IBooleanFieldProps, IInputFieldProps, ISelectionFieldProps, ITextareaProps } from '../InputFields_OLD/InputFieldComponent';
import { IOption } from '../InputFields_OLD/DropdownInput';
import { Portal } from '../Portal/Portal';

function getPropertyValueFromUrl(url: string, object: Object): string {
	const splitUrl = url.split(':');
	if (splitUrl.length > 1) {
		splitUrl[1] = object[splitUrl[1]];
	}
	const joinedUrl = splitUrl.join('');

	return joinedUrl;
}

export class PanelPopupComponent extends Component<
	IPanelPopupProps,
	{
		changed: boolean;
		activeTab: number;
		object?: Object;
	}
> {
	constructor(props) {
		super(props);

		this.state = {
			changed: false,
			activeTab: 0,
			object: {
				changed: false,
				id: props.objectId,
			},
		};
	}

	inputChangeHandler = (changedValueOverride?: boolean) => {
		let changed = true;

		if (changedValueOverride !== undefined) changed = changedValueOverride;

		this.setState({ changed: changed });
	};

	closeHandler = () => {
		if (this.state.changed)
			this.props.dispatch(
				openConfirm({
					title: 'confirmUnsavedChanges',
					dismissText: 'cancel',
					dismissButtonPosition: 'bottom',
					confirmButtons: [
						{
							confirmText: 'close',
							onClick: this.props.closeHandler,
						},
					],
				})
			);
		else this.props.closeHandler();
	};

	async componentDidMount() {
		if (this.props.objectId) {
			const objectResult = await this.props.getEntityByIdFunction(this.props.objectId);

			this.setState({
				object: objectResult,
			});
		}
	}

	getTabGroups = () => {
		let tabGroups: ITabGroup[] = this.props.panelGroups.map((group) => {
			const tabs = group.panels.map((panel) => {
				let panelProp: IPanelBlockList | IPanelTable;
				switch (panel.type) {
					case 'blockList':
						panelProp = panel as IPanelBlockList;
						break;
					case 'table':
						panelProp = {
							tableItems: [],
							...panel,
						} as IPanelTable;
				}

				return {
					title: panel.title,
					icon: panel.icon,
					element: (
						<PanelTab
							panel={panelProp}
							onInputChange={this.inputChangeHandler}
							{...this.props}
							object={this.state.object!}
						/>
					),
				};
			});

			return {
				title: group.title,
				tabs: tabs,
			};
		});

		if (this.props.method === 'POST')
			tabGroups = [
				{
					title: tabGroups[0].title,
					tabs: [tabGroups[0].tabs[0]],
				},
			];

		return tabGroups;
	};

	render() {
		return (
			<Portal>
				<div className={`customDialog ${styles.background}`}>
					<div className={styles.popupPanel}>
						<GridComponents.Grid>
							<GridComponents.Column size='whole'>
								<GridComponents.Row.VerticalTabs tabGroups={this.getTabGroups()} />
							</GridComponents.Column>
						</GridComponents.Grid>
						<FontAwesomeIcon
							icon={'circle-xmark'}
							size='2x'
							className={styles.closeButton}
							onClick={this.closeHandler}
						/>
					</div>
				</div>
			</Portal>
		);
	}
}

export const PanelPopup = connect()(PanelPopupComponent);

class PanelTab extends Component<
	IPanelProps,
	{
		editing: boolean;
		values: object;
		tableAddbuttonClicked: boolean;
		selectionProperties?: Object;
	}
> {
	constructor(props) {
		super(props);

		this.state = {
			editing: this.props.openInEdit ? this.props.openInEdit : false,
			tableAddbuttonClicked: false,
			values: this.props.object,
			selectionProperties: this.props.selectionProperties,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.object !== this.props.object)
			this.setState({
				values: this.props.object,
			});
	}

	editClickHandler: MouseEventHandler = () => {
		this.setState({ editing: true });
	};

	getAllInputNames = (datas: IData[], prefix?: string): string[] => {
		const inputNames: string[] = [];

		datas.forEach((data) => {
			if (data.property.children)
				inputNames.push(
					...this.getAllInputNames(data.property.children, prefix !== undefined ? `${prefix}-${data.property.name}` : data.property.name)
				);
			else inputNames.push(prefix !== undefined ? `${prefix}-${data.property.name}` : data.property.name);
		});

		return inputNames;
	};

	changeValueByInputName = (inputName: string, newValue: any) => {
		const values = this.props.object;
		let currentValue = values !== undefined ? values : {};

		const objectLayers = inputName.split('-');

		for (let i = 0; i < objectLayers.length; i++) {
			const objectLayer = objectLayers[i];
			if (i + 1 < objectLayers.length) {
				if (currentValue[objectLayer] === undefined) currentValue[objectLayer] = isNaN(+objectLayers[i + 1]) ? {} : [];

				currentValue = currentValue[objectLayer];
			} else {
				if (newValue !== this.props.object[inputName] && (newValue !== '' || this.props.object[inputName] !== undefined)) {
					currentValue[objectLayer] = newValue;
				}
			}
		}
	};

	formSubmitHandler = (method: 'POST' | 'PUT', url: string) => {
		return (e: FormEvent): boolean => {
			e.preventDefault();

			if (!this.state.editing) return false;

			const blockListPanel = this.props.panel as IPanelBlockList;
			let valid = true;

			blockListPanel.blocks.forEach((block) => {
				this.getAllInputNames(block.data).forEach((name) => {
					const input = e.target[name];

					if (input && input.validity)
						if (input.validity.valid) {
							if (input.type === 'select-multiple') {
								const options = input.options;
								let value: string[] = [];

								for (let i = 0; i < options.length; i++) {
									if (options[i].selected) value.push(options[i].value);
								}

								this.changeValueByInputName(name, value.toString());
							} else if (input.type === 'boolean') {
								this.changeValueByInputName(name, input.value === 'true' || input.value3 === true ? true : false);
							} else {
								const notANumber = isNaN(input.value) || input.value === '';

								this.changeValueByInputName(name, notANumber ? input.value : parseInt(input.value));
							}
						} else valid = false;
				});
			});

			if (!valid) return false;

			//TODO: Remove me
			//HACK: remove Dto and prioritized from $type field as we cannot post/put those values anyway
			let type = this.props.object['$type'];
			if (type) {
				if (type.endsWith('Dto')) type = type.substring(0, type.length - 3);

				if (type.startsWith('prioritized')) type = type.substring('prioritized'.length);

				this.props.object['$type'] = type;
			}

			AuthorizedFetchRequest({ base: url }, method, this.props.object);

			this.setState({ editing: false }, () => this.props.onInputChange(false));
			return true;
		};
	};

	urlByProperty = (apiUrlBasedOn: { property: string; urls: Object }): string | undefined => {
		let url = apiUrlBasedOn.urls[this.state.values[apiUrlBasedOn.property]];

		if (url) return getPropertyValueFromUrl(url, this.state.values);
		else {
			if (this.props.defaultValuesObj) url = apiUrlBasedOn.urls[this.props.defaultValuesObj[apiUrlBasedOn.property]];

			if (url) return getPropertyValueFromUrl(url, this.state.values);
		}

		return undefined;
	};

	formRef;

	async componentDidMount() {
		let panel = this.props.panel;
		const selectionProperties: Object = this.state.selectionProperties ? this.state.selectionProperties : {};

		if (panel.type === 'blockList') {
			panel = panel as IPanelBlockList;
			if (panel.getSelectionsFromApi !== undefined) {
				const params = panel.getSelectionsFromApi;
				let query = {};

				if (params.query !== undefined) {
					query = {
						//FIXME: fix name for property
						[params.query.query]: this.props.object[params.query.propertyForQuery],
					};
				}

				const newSelectionObject = await getSelectionObject(params.selectionType, query);

				selectionProperties[params.selectionType] = newSelectionObject;

				this.setState({ selectionProperties: selectionProperties });
			}
		}
	}

	render() {
		let panel = this.props.panel;
		let tablePanel = this.props.panel as IPanelTable;
		let url: string | undefined = undefined;
		let entityTypeName;

		if (panel.type === 'blockList') {
			panel = panel as IPanelBlockList;

			if (panel.entityTypeName) entityTypeName = panel.entityTypeName;
			else if (panel.getEntityTypeNameBasedOn) {
				const ETBasedOn = panel.getEntityTypeNameBasedOn;

				const propertyValue = this.props.object[ETBasedOn.property];

				entityTypeName = ETBasedOn.entityNames[propertyValue];
			}

			const apiUrl = this.props.method === 'POST' ? panel.apiUrl.split(':')[0] : panel.apiUrl;

			if (panel.apiUrlBasedOn !== undefined) {
				url = this.urlByProperty(panel.apiUrlBasedOn);
				if (url === undefined) url = getPropertyValueFromUrl(apiUrl, this.state.values);
			} else {
				url = getPropertyValueFromUrl(apiUrl, this.state.values);
			}
		}

		return (
			<form
				ref={(ref) => (this.formRef = ref)}
				onSubmit={url ? this.formSubmitHandler(this.props.method, url) : undefined}
				className={styles.popupForm}
			>
				<GridComponents.Row.SubHeader>
					<div className={styles.popupHeader}>
						<div className={styles.title}>
							{panel.icon !== undefined && (
								<FontAwesomeIcon
									size='lg'
									className={styles.icon}
									icon={panel.icon}
								/>
							)}
							<h3>{this.props.object[panel.headerPropertyName]}</h3>
						</div>

						{this.props.panel.type === 'table' && tablePanel.includePopupPanel && (
							<div className={styles.actions}>
								<FontAwesomeIcon
									onClick={() => {
										this.setState({
											tableAddbuttonClicked: true,
										});
									}}
									size='lg'
									icon={'plus'}
									className={`${styles.action}`}
								/>
							</div>
						)}

						{this.props.panel.type === 'blockList' && (
							<div className={styles.actions}>
								{this.props.extraButtons !== undefined &&
									this.props.extraButtons!.map((extraButton) => {
										return (
											<FontAwesomeIcon
												onClick={() => {
													extraButton
														.onClick(this.props.object)
														.then(() => {
															this.props.closeHandler();
														})
														.catch((err) => {
															console.error(err);
														});
												}}
												size='xl'
												icon={extraButton.getConditionalIcon ? extraButton.getConditionalIcon(this.props.object) : extraButton.icon}
												className={`
														${styles.extra}
														${styles.action} 
													`}
											/>
										);
									})}
								{this.props.onDeleteClick !== undefined && (
									<FontAwesomeIcon
										onClick={this.props.onDeleteClick(this.state.values['id'])}
										size='xl'
										icon={faTrashCan}
										className={`
											${styles.action} 
										`}
									/>
								)}
								{!this.state.editing ? (
									<FontAwesomeIcon
										size='xl'
										icon={faPenToSquare}
										className={`
                                        ${styles.action} 
                                    `}
										onClick={this.editClickHandler}
									/>
								) : (
									<FontAwesomeIcon
										size='xl'
										icon={faFloppyDisk}
										className={`
                                        ${styles.action}
                                    `}
										onClick={() => {
											this.formRef.dispatchEvent(
												new Event('submit', {
													bubbles: true,
													cancelable: true,
												})
											);
										}}
									/>
								)}
							</div>
						)}
					</div>
				</GridComponents.Row.SubHeader>
				<GridComponents.Row.Scroll>
					{
						<TabType
							editing={this.state.editing}
							adding={this.state.tableAddbuttonClicked}
							selectionProperties={this.state.selectionProperties}
							{...this.props}
							entityTypeName={entityTypeName}
						/>
					}
				</GridComponents.Row.Scroll>
			</form>
		);
	}
}

class TabType extends Component<ITabTypeProps, { tableItems: any[] }> {
	constructor(props) {
		super(props);

		this.state = {
			tableItems: [],
		};
	}

	refreshTable = () => {
		if (this.props.panel.type !== 'table') return;
		const tablePanel = this.props.panel as unknown as IPanelApiTable;

		const url = getPropertyValueFromUrl(tablePanel.apiUrl, this.props.object);

		fireLoadBegin();

		AuthorizedFetchRequest({ base: url }, 'GET')
			.then((res) => {
				if (res.ok)
					res.json().then((tableItems) => {
						this.setState({
							tableItems: tableItems,
						});

						fireLoadEnd();
					});
				else {
					console.error(res);

					fireLoadEnd();
				}
			})
			.catch((err) => {
				console.error(err);

				fireLoadEnd();
			});
	};

	componentDidMount() {
		this.refreshTable();
	}

	render() {
		switch (this.props.panel.type) {
			case 'blockList':
				const blockListPanel = this.props.panel as IPanelBlockList;

				return (
					<Fragment>
						{blockListPanel.blocks.map((block: IBlock, index) => {
							if (!(block.data.length > 0)) return null;
							let blockHasInputs = false;
							block.data.forEach((dataSingular) => {
								if (dataSingular.property.children) {
									if (dataSingular.property.children.length > 0) blockHasInputs = true;
								} else blockHasInputs = true;
							});

							if (
								(block.displayBasedOnAttribute === undefined ||
									this.props.object[block.displayBasedOnAttribute[0]] === block.displayBasedOnAttribute[1]) &&
								blockHasInputs
							) {
								return (
									<PanelBlockList
										key={index}
										block={block}
										{...this.props}
									/>
								);
							} else return null;
						})}
					</Fragment>
				);

			case 'table':
				const tablePanel = this.props.panel as IPanelTable;

				if (tablePanel.includePopupPanel) {
					const popupPanel = this.props.panel as IPanelRecursiveTable;

					return (
						<RecursiveTable
							{...this.props}
							tableItems={this.state.tableItems}
							refresh={this.refreshTable}
							panel={popupPanel}
						/>
					);
				} else
					return (
						<DynamicTable
							tableAttributes={tablePanel.tableAttributes}
							tableItems={this.state.tableItems}
						/>
					);
			default:
				return null;
		}
	}
}

class RecursiveTable extends Component<IPanelRecursiveTableProps, { popupPanels: IPanelGroup[] }> {
	constructor(props) {
		super(props);

		this.state = {
			popupPanels: props.panel.popupPanels,
		};
	}

	async componentDidMount(): Promise<void> {
		if (!this.state.popupPanels[0] || !this.state.popupPanels[0].panels[0]) return;

		const BLPanel = this.state.popupPanels[0].panels[0] as IPanelBlockList;

		BLPanel.blocks = await SearchAndAddCustomFieldBlock(this.props.panel.entityName, BLPanel.blocks);

		this.setState({
			popupPanels: this.state.popupPanels,
		});
	}

	render() {
		const deleteUrl = this.props.panel.apiUrl.split(':')[0];

		return (
			<EntityTable
				navigate={this.props.navigate}
				popupPanels={this.state.popupPanels}
				entityName={this.props.panel.entityName}
				tableAttributes={this.props.panel.tableAttributes}
				selectionProperties={this.props.selectionProperties}
				tableItems={this.props.tableItems}
				errorOccurred={false}
				defaultValueObj={this.props.defaultValuesObj}
				onClose={this.props.refresh}
				onDelete={(id: any) => AuthorizedFetchRequest({ base: `${deleteUrl}${id}` }, 'DELETE')}
				getEntityByIdFunction={this.props.panel.getEntityByIdFunction}
				location={this.props.location}
				match={this.props.match}
				openAddForm={this.props.adding}
				entityIcon={IconCustomType.GEN_COMPANY}
			/>
		);
	}
}

class PanelBlockList extends Component<IPanelBlockProps> {
	generateTableRow = (data: IData | ISelectionData, object: object, key?: any, parents?: string[]): ReactNode => {
		if (data.property.children) {
			return data.property.children.map((child, index) => {
				let nextParents: string[] = [];
				if (parents) {
					nextParents.push(...parents);
				}
				nextParents.push(data.property.name);

				return this.generateTableRow(child, object && object[data.property.name] ? object[data.property.name] : {}, index, nextParents);
			});
		}

		let value = object[data.property.name];

		const header = t(data.headTitle !== undefined ? data.headTitle : '');

		return (
			<div style={{ display: data.type === 'hidden' ? 'none' : 'block' }}>
				<TableRow
					key={key}
					header={header !== '' ? header : data.headTitle}
				>
					<PanelBlockListData
						type={data.type}
						value={value}
						data={data}
						parents={parents}
						defaultValue={data.defaultValue}
						{...this.props}
					/>
				</TableRow>
			</div>
		);
	};

	render() {
		return (
			<InfoGroup
				open={true}
				visible={true}
				title={t(this.props.block.headTitle)}
			>
				<table className={styles.popupPanelTable}>
					{this.props.block.data.map((data: IData | ISelectionData, index) => {
						if (
							(data.displayBasedOnAttribute === undefined ||
								this.props.object[data.displayBasedOnAttribute[0]] === data.displayBasedOnAttribute[1]) &&
							(data.displayBasedOnMethod === undefined || this.props.method === data.displayBasedOnMethod)
						) {
							return this.generateTableRow(data, this.props.object, index);
						} else return null;
					})}
				</table>
			</InfoGroup>
		);
	}
}

class PanelBlockListDataComponent extends Component<IPanelBlockTableDataProps, { valid: boolean }> {
	constructor(props) {
		super(props);

		this.state = {
			valid: true,
		};

		this.generateInputFieldProps.bind(this);
		this.generateDefaultValue.bind(this);
		this.getOptionsFromSelectionData.bind(this);
	}

	displayDateTime = (dateObject: Date, type: 'date' | 'time' | 'datetime-local'): string => {
		let datetime;

		switch (type) {
			case 'date':
				datetime = dateObject.toLocaleDateString();
				break;
			case 'time':
				const localeTimeArray = dateObject.toLocaleTimeString().split(':');
				datetime = `${localeTimeArray[0]}:${localeTimeArray[1]}`;
				break;
			case 'datetime-local':
				datetime = dateObject.toLocaleString();
				break;
		}

		return datetime;
	};

	generateInputName(dataPropertyName: string, parents?: string[]) {
		let name = dataPropertyName;
		if (parents === undefined) return name;

		name = `${parents.join('-')}-${dataPropertyName}`;
		return name;
	}

	generateDefaultValue(): any {
		let defaultValue = undefined;
		if (this.props.value) defaultValue = this.props.value;
		else if (this.props.defaultValue !== undefined) defaultValue = this.props.defaultValue;
		else if (this.props.data.defaultValuePropName !== undefined && this.props.defaultValuesObj !== undefined) {
			defaultValue = this.props.defaultValuesObj[this.props.data.defaultValuePropName];
		}
		return defaultValue;
	}

	getOptionsFromSelectionData(data: ISelectionData): any[] {
		let options: IOption[] = [];

		try {
			if (data.selections !== undefined) options = data.selections;
			else {
				const selectionsObject = this.props.selectionProperties![data.selectionsPropertyName];

				if (selectionsObject !== undefined)
					options = Object.entries(this.props.selectionProperties![data.selectionsPropertyName]).map((option) => {
						return { value: option[0], label: option[1] as string };
					});
			}
		} catch (e) {
			console.error('selectionData:', data, 'options:', options, 'error:', e);
		}

		return options;
	}

	generateInputFieldProps(data: IData | ISelectionData | IDateTimeData): IInputFieldProps | ISelectionFieldProps | IBooleanFieldProps | ITextareaProps {
		let props: any = {
			name: this.generateInputName(data.property.name, this.props.parents),
			type: this.props.type,
			required: data.required,
			defaultValue: this.generateDefaultValue(),
			onChange: () => this.props.onInputChange(),
			readOnly: data.readOnly || !this.props.editing,
		};

		switch (props.type) {
			case 'textarea':
				return props as ITextareaProps;
			case 'boolean':
				return props as IBooleanFieldProps;
			case 'select':
			case 'multiSelect':
				const selectionData = data as ISelectionData;
				props = { ...props, options: this.getOptionsFromSelectionData(selectionData) };
				return props as ISelectionFieldProps;
			default:
				return props as IInputFieldProps;
		}
	}

	render() {
		return <InputFieldComponent {...this.generateInputFieldProps(this.props.data)} />;
	}
}

function mapStateToProps(state) {
	return {
		validationRules: state.globals.entityValidation,
	};
}

const PanelBlockListData = connect(mapStateToProps)(PanelBlockListDataComponent);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PanelBlockCard extends Component<{
	title: string;
	icon?: IconProp;
	data: {
		title?: string;
		property: IProperty;
	}[];
}> {
	render() {
		return (
			<InfoGroup
				open
				visible
				title={this.props.title}
			>
				{this.props.data.map((data, index) => (
					<div
						key={index}
						className={styles.rightData}
					>
						{this.props.icon !== undefined && (
							<FontAwesomeIcon
								icon={this.props.icon}
								className={styles.icon}
							/>
						)}
						<div>
							<h4>{data.title}</h4>
							{data.property.name}
						</div>
					</div>
				))}
			</InfoGroup>
		);
	}
}
