import { LoadEventTypes } from '../globals/enums';
import EventBus from './EventBus';

const fire = (eventName: string, payload?: any) => {
	EventBus.instance().dispatch(eventName, payload);
};

//Declare your EventBus events here
const fireLoadBegin = (payload?: any) => fire(LoadEventTypes.loadBegin, payload);
const fireLoadEnd = (payload?: any) => fire(LoadEventTypes.loadEnd, payload);
const fireLoadReset = (payload?: any) => fire(LoadEventTypes.loadReset, payload);

const tryFireAsync = async (f: any, fArgs?: any, payload?: any): Promise<boolean> => {
	let success = true;
	fireLoadBegin(payload);

	try {
		success = await f(fArgs);
	} catch (e) {
		console.warn(e);
		success = false;
	} finally {
		fireLoadEnd(payload);
	}

	return success;
};

const tryFire = (f: any, fArgs?: any, payload?: any): boolean => {
	let success = true;
	fireLoadBegin(payload);

	try {
		f(fArgs);
	} catch (e) {
		console.warn(e);
		success = false;
	} finally {
		fireLoadEnd(payload);
	}

	return success;
};

export { tryFire, tryFireAsync, fireLoadBegin, fireLoadEnd, fireLoadReset };
