import { api } from '../api/_Executor';
import { AddressQuery } from '../business/Query/AddressQuery';
import { ContactQuery } from '../business/Query/ContactQuery';
import { EntityType } from '../globals/enums';
import { CompanyTypeName } from '../model/_Enums';
import { Account } from '../models/Model/Account';
import { BaseViewModel } from './_BaseViewModel';
import { IMappings, IViewModel } from './_viewModel.interfaces';
import { isNotDefined } from '../utilities/utils';
import { hasModule, moduleNames } from '../utilities/authProvider';

export interface ICompanyViewModel extends IViewModel<Account> {
	upgradeToAccountCommand(): void;
}

const mappings: IMappings = {
	ApiMappings: [
		{
			collectionName: 'contacts',
			apiFunc: api.contact.listAsync,
			apiFuncQuery: new ContactQuery(),
		},
		{
			collectionName: 'addresses',
			apiFunc: api.address.listAsync,
			apiFuncQuery: new AddressQuery(),
		},
	],
};

export enum companyDropDownPropertyNames {
	$type = '$type',
	primaryRepresentative_Id = 'primaryRepresentative_Id',
	VisitingFrequency_Id = 'visitingFrequency_Id',
	primaryVisitingAddress_Id = 'primaryVisitingAddress_Id',
	primaryShippingAddress_Id = 'primaryShippingAddress_Id',
	primaryContact_Id = 'primaryContact_Id',
}

export default function CompanyViewModel(instanceName: string): ICompanyViewModel {
	const entityType = EntityType.account;

	const functionRead = api.company.getByIdAsync;
	const functionUpdate = api.special.updateCompany;
	//const functionDelete = api.special.deleteCompanyByIdAsync;

	const functionDelete = async (id: string) => {
		if (vm.getType() === EntityType.account) {
			return await api.account.deleteByIdAsync(id);
		}
		return await api.lead.deleteByIdAsync(id);
	};

	const vm = BaseViewModel<Account>(instanceName, entityType, functionRead, functionUpdate, functionDelete, mappings);

	function generateModelInstance(id?: string, representativeId?: string): Account {
		const m = new Account();
		const hasHideNewAccountAndPromoteLeadModule = hasModule(moduleNames.hideNewAccountAndPromoteLead);

		m.$type = !hasHideNewAccountAndPromoteLeadModule ? CompanyTypeName.Account.toLowerCase() : CompanyTypeName.Lead.toLocaleLowerCase();
		m.isActive = true;
		m.primaryRepresentative_Id = vm.getRepresentativeId();

		if (representativeId) {
			m.primaryRepresentative_Id = representativeId;
		}

		return m;
	}

	function create(id?: string, representativeId?: string, params?: any) {
		return vm.doCreate(generateModelInstance(id, representativeId));
	}

	async function read(id) {
		return await vm.doRead(id);
	}

	async function update() {
		return await vm.doUpdate();
	}

	async function del(clearModel: boolean = true) {
		return await vm.doDelete(clearModel);
	}

	function setProperty(prop: any, value: any) {
		if (prop === '$type' && isNotDefined(vm.getId())) {
			//New company, and user wants to swich account/lead.
			toggleCompanyTypeCommand();
			return;
		}

		vm.setProperty(prop, value);
	}

	return {
		entityType,
		model: vm.model,
		fieldDefinitions: vm.fieldDefinitions,
		isValid: vm.isValid,
		isDirty: vm.isDirty,
		hasSavedChanges: vm.hasSavedChanges,
		instanceName: instanceName,
		create,
		read,
		update,
		del,
		setProperty: setProperty,
		getProperty: vm.getProperty,
		isPropertyValid: vm.isPropertyValid,
		isPropertyReadOnly: vm.isPropertyReadOnly,
		getPropertyErrors: vm.getPropertyErrors,
		getProperties: vm.getProperties,
		getId: vm.getId,
		getType: vm.getType,
		getTypeCased: vm.getTypeCased,
		upgradeToAccountCommand,
	};

	//#region COMMANDS
	function upgradeToAccountCommand() {
		if (vm.model === undefined) return;
		if (vm.model.$type === null) return;
		if (vm.model.$type === CompanyTypeName.Account) return;

		toggleCompanyTypeCommand();
	}

	function toggleCompanyTypeCommand() {
		if (vm.model === undefined) return;
		const model = { ...vm.model };

		model.fieldValues = [];

		for (const key in model) {
			if (key.startsWith('f_')) {
				delete model[key];
			}
		}

		if (model.$type === CompanyTypeName.Account) {
			model.$type = CompanyTypeName.Lead;
		} else {
			model.$type = CompanyTypeName.Account;
		}

		vm.setModel(undefined);
		vm.setTempModel(model);
	}
	//#endregion COMMANDS
}

// import { api } from "../api/_Executor";
// import { EntityType } from "../globals/enums";
// import { Account } from "../models/Model/Account";
// import { Company } from "../models/Model/Company";
// import { Opportunity } from "../models/Model/Opportunity";
// import { selectRepresentatives } from "../reducers/GlobalsSlice";
// import { BaseViewModel } from "./_BaseViewModel"
// import { IMappings, IViewModel } from "./_viewModel.interfaces";

// export interface ICompanyModel extends IViewModel<Account> {
// }

// // const mappings: IViewModelStateMapping[] = [
// // 	{
// // 	  columnName: 'primaryRepresentative',
// // 	  selector: selectRepresentatives
// // 	}
// // ];

// // const mappings: IMappings = {
// // 	ApiMappings: [
// // 		{
// // 			Columns: 'primaryContact',
// // 			ApiFunc: api.contact.getByIdAsync
// // 		}
// // 	]

// // }

// export default function CompanyViewModel(instanceName:string):IViewModel<Account> {
// 	let entityType = EntityType.company;
// 	const functionRead = api.company.getByIdAsync;
// 	const functionUpdate = api.company.updateAsync;
// 	const functionDelete = api.company.deleteByIdAsync;

// 	const vm = BaseViewModel<Account>
// 	(
// 		instanceName,
// 		entityType,
// 		functionRead,
// 		functionUpdate,
// 		functionDelete,
// 		// mappings,
// 	);

// 	function generateModelInstance(id?:string, representativeId?:string):Account {
// 		var m = new Account();
// 		return m;
// 	}

// 	function create(id?:string, representativeId?:string, params?:any) {
// 		return vm.doCreate (
// 			generateModelInstance(id, representativeId)
// 		)
// 	}

// 	async function read(id) {
// 		return await vm.doRead(id);
// 	}

// 	async function update() {
// 		return await vm.doUpdate();
// 	}

// 	async function del(clearModel:boolean = true) {
// 		return await vm.doDelete(clearModel);
// 	}

// 	return {
// 		entityType,
// 		model: vm.model,
// 		isValid: vm.isValid,
// 		isDirty: vm.isDirty,
// 		create,
// 		read,
// 		update,
// 		del,
// 		setProperty: vm.setProperty,
// 		getProperty: vm.getProperty,
// 		isPropertyValid: vm.isPropertyValid,
// 		getPropertyErrors: vm.getPropertyErrors,
// 		getProperties: vm.getProperties,
// 		getModelId: vm.getModelId,
// 	}
// }
