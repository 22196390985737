import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { AppointmentFormDetail } from './AppointmentFormDetail';

export const AppointmentFormDetailCompany = (props: IEntityFormDetailProps) => {
	return (
		<AppointmentFormDetail
			{...props}
			isAgenda={false}
		/>
	);
};
