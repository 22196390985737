import { EditMethod, IButtonsProps } from '../../../entityDetailPopup.interfaces';
import styles from '../../formDetail.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListDropdown } from '@fortawesome/pro-light-svg-icons';
import { CompanyTypeName } from '../../../../../model/_Enums';
import { ButtonsDisable } from './_ButtonsDisable';
import { Fragment, useCallback } from 'react';
import { t } from 'i18next';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons';
import { useAppSelector } from '../../../../../store/hooks';

export interface IButtonsCompanyProps extends IButtonsProps {
	quickAnnotationClickHandler: () => void;
	editMethod: EditMethod;
}

export function ButtonsCompany(props: IButtonsCompanyProps) {
	const organizationSettings = useAppSelector((state) => state.globals.organizationSettings);
	const powerBIReportLink = organizationSettings.powerBIReportLink;
	// UPGRADE button was disabled in [SF-4787]

	// const isUpgradable = props.vm.model?.$type === CompanyTypeName.Lead && props.vm.getId() !== null;

	// const upgradeToAccount = useCallback(() => {
	// 	props.vm['upgradeToAccountCommand']();
	// }, []);

	const powerBIButtonClick = useCallback((link: string) => {
		window.open(link);
	}, []);

	return (
		<ButtonsDisable
			vm={props.vm}
			editing={props.editing}
			setEditing={props.setEditing}
			closeDetailPopup={props.closeDetailPopup}
		>
			{/* {isUpgradable && (
				<FontAwesomeIcon
					className={`${styles.formButton} ${styles.withDivider}`}
					icon={faBuildings}
					color={!props.vm.isValid ? 'gray' : ''}
					onClick={upgradeToAccount}
					size='xl'
				/>
			)} */}
			{props.editMethod === EditMethod.update && (
				<Fragment>
					<span
						onClick={props.quickAnnotationClickHandler}
						className={styles.preButtonText}
					>
						{t('appointmentAnnotation')}
					</span>
					<FontAwesomeIcon
						className={`${styles.formButton} ${styles.withDivider}`}
						icon={faListDropdown}
						onClick={props.quickAnnotationClickHandler}
						size="xl"
					/>
				</Fragment>
			)}
			{props.vm.model?.$type === CompanyTypeName.Account && props.editMethod === EditMethod.update && powerBIReportLink && (
				<FontAwesomeIcon
					className={`${styles.formButton} ${styles.withDivider}`}
					icon={faChartSimple}
					onClick={() => powerBIButtonClick(powerBIReportLink)}
					size="xl"
					title={t('powerBIReportButtonText')!}
				/>
			)}
		</ButtonsDisable>
	);
}
