import { ThemeColorType } from "../globals/enums";
import { dateHelper } from "./dateHelper";

export interface IOverdueInfo {
	isSuccess: boolean;
	color: ThemeColorType;
	value: string;
}

export module colorHelper {

	export const getTaskOverdueInfo = (dateNow: Date, dateDue?: Date | null, isCompleted: boolean = false): IOverdueInfo => {
		const info: IOverdueInfo = {} as IOverdueInfo;

		info.isSuccess = false;
		info.value = '';
		info.color = ThemeColorType.blisssGreen;

		if (isCompleted) {
			info.isSuccess = true;
			return info;
		}

		dateDue = dateDue ? new Date(dateDue) : dateNow;

		if (!dateDue) {
			return info;
		}

		info.isSuccess = true;

		const daysDiff = Math.abs(dateHelper.getDaysDiff(dateNow, dateDue));
		const isPositive = dateDue >= dateNow;
		const remainder = dateHelper.getDateRemainder(dateNow, dateDue);
		const remainderFormatted = dateHelper.formatRemainderAsString(remainder, isPositive);

		info.value = remainderFormatted;

		if (isPositive) {
			if (daysDiff < 15) {
				info.color = ThemeColorType.blisssOrange;
			} else {
				info.color = ThemeColorType.blisssBlue;
			}
		} else {
			info.color = ThemeColorType.blisssRed;
		}

		return info;
	}

	//TODO: Decide if we want to use a color for company icon?
	export const getCompanyOverdueInfo = (numberOfWeeks:number|null) : IOverdueInfo => {
		const info: IOverdueInfo = {} as IOverdueInfo;

		info.isSuccess = false;
		info.value = '-';
		info.color = ThemeColorType.greyTODO;

		if (numberOfWeeks === null) return info;
		if (numberOfWeeks <= -1) return info;

		info.isSuccess = true;
		info.value = `-${numberOfWeeks.toString()}W`;

		if (numberOfWeeks > 52) {
			info.color = ThemeColorType.blisssRed;
		} else if (numberOfWeeks === 0) {
			info.color = ThemeColorType.blisssGreen;
		} else {
			info.color = ThemeColorType.blisssOrange;
		}

		return info;
	}


	export const convertStringToHex = (color: string): number => {
		return (parseInt(color.replace('#', ''), 16));
	}

	export const convertHexToString = (color: number): string => {
		//Might need improvement
		return `#${color.toString()}`;
	}

	export const changeLightness = (col, amt) => {
		col = col.replace(/^#/, '')
		if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

		let [r, g, b] = col.match(/.{2}/g);
		([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

		r = Math.max(Math.min(255, r), 0).toString(16)
		g = Math.max(Math.min(255, g), 0).toString(16)
		b = Math.max(Math.min(255, b), 0).toString(16)

		const rr = (r.length < 2 ? '0' : '') + r
		const gg = (g.length < 2 ? '0' : '') + g
		const bb = (b.length < 2 ? '0' : '') + b

		return `#${rr}${gg}${bb}`
	}

	export const changeLightness2 = (col, amt) => (+('0x' + col) + amt * 0x010101).toString(16).padStart(6);

	// static convertColorTypeToStringType(colorType:ColorType):BlisssColorType
	// {
	//     const indexOfS = Object.values(ColorType).indexOf(colorType as unknown as ColorType);
	//     return Object.keys(BlisssColorType)[indexOfS] as BlisssColorType;
	// }
}