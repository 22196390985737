import { EditMethod, IEntityFormHeaderProps } from '../../entityDetailPopup.interfaces';
import { ButtonsCompany } from './Buttons/ButtonsCompany';
import { FormHeaderBase } from './_FormHeaderBase';

export interface IEntityFormHeaderCompanyProps extends IEntityFormHeaderProps {
	quickAnnotationClickHandler: () => void;
	editMethod: EditMethod;
}

export function FormHeaderCompany(props: IEntityFormHeaderCompanyProps) {
	return (
		<FormHeaderBase
			{...props}
			iconName="city"
			headerText={props.vm.getProperty('name')}
		>
			<ButtonsCompany
				vm={props.vm}
				editing={props.editing}
				setEditing={props.setEditing}
				closeDetailPopup={props.closeDetailPopup}
				quickAnnotationClickHandler={props.quickAnnotationClickHandler}
				editMethod={props.editMethod}
			/>
		</FormHeaderBase>
	);
}
