import styles from './annotationEditor.module.scss';
import AnnotationEditor from './AnnotationEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import { ICompanyAnnotationViewCollectionViewModel } from '../../../viewmodels/Collections/CompanyAnnotationViewCollectionViewModel';
import { SearchBar } from '../../SearchBar/SearchBar';
import { entityIconDictionary } from '../../EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { openConfirm } from '../../Dialog/dialogSlice';
import { isNotNilOrEmpty, utcDateToLocaleDateString } from '../../../utilities/utils';
import { IAppointmentAnnotationViewCollectionViewModel } from '../../../viewmodels/Collections/AppointmentAnnotationViewCollectionViewModel';
import { CompanyAnnotationQuery } from '../../../business/Query/CompanyAnnotationQuery';
import { ICompanyAnnotation } from '../../../models/Model/CompanyAnnotation';

export interface IAnnotationEditorListProps {
	cvm:
		| IAppointmentAnnotationViewCollectionViewModel<ICompanyAnnotation, CompanyAnnotationQuery>
		| ICompanyAnnotationViewCollectionViewModel<ICompanyAnnotation, CompanyAnnotationQuery>;
	id: string;
	headerText: string;
	className?: string;
}

export function AnnotationEditorList(props: IAnnotationEditorListProps) {
	const dispatch = useDispatch();

	useEffect(() => {
		props.cvm.read(props.id);
	}, [props.id]);

	const confirmDelete = useCallback(
		(index: number) => {
			dispatch(
				openConfirm({
					title: 'delete',
					text: 'areYouSureDelete',
					confirmButtons: [
						{
							confirmText: 'delete',
							onClick: async () => await props.cvm.deleteItem(index),
						},
					],
				})
			);
		},
		[props.cvm, dispatch]
	);

	const annotationEditors = props.cvm.items.map((annotation, index) => {
		const formattedDate = utcDateToLocaleDateString(annotation.createdDate);
		const text = annotation.text;

		return (
			<AnnotationEditor
				key={index}
				defaultContent={text}
				labels={{
					left: formattedDate,
					middle: isNotNilOrEmpty(annotation.createdBy?.fullName) ? annotation.createdBy!.fullName! : '',
				}}
				functions={{
					changeHandler: (content) => props.cvm.updateItem(index, content),
					deleteHandler: () => confirmDelete(index),
				}}
			/>
		);
	});

	return (
		<div className={`${styles.annotationEditorList} ${props.className}`}>
			<div className={styles.listHeader}>
				<div className={styles.headerText}>
					<FontAwesomeIcon
						className={styles.headerIcon}
						icon={entityIconDictionary[EntityType.Annotation]}
					/>
					{props.headerText}
				</div>
				<div className={styles.searchBox}>
					{props.cvm.isSearchable && (
						<SearchBar
							searchCallback={props.cvm.search}
							border
						/>
					)}
				</div>
				<FontAwesomeIcon
					className={`${styles.icon} ${styles.action}`}
					icon={faPlusCircle}
					size="xl"
					onClick={() => props.cvm.insertItem(props.id)}
				/>
			</div>
			{annotationEditors}
		</div>
	);
}
