export enum SortingLevel {
	Default = 0,
	Custom,
	Id,
	Minimal,
	Full
}

export enum FilterField {
	None = 0,
	Name = 1,
	City = 2,
	PostalCode = 4,
	PostalCodeArea = 8,
	Representative = 16
}

export enum AuditType {
	Add,
	Modify,
	Delete,
	Unknown
}