import { createPortal } from 'react-dom';
import { IPopupProps } from './Popup.interfaces';
import styles from './Popup.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Popup: React.FC<IPopupProps> = (props) => {
	const handle_onClick_close = async () => {
		if (props.onCloseRequest) {
			await props.onCloseRequest();
		}
	};

	// useEffect(() => {
	// 	setIsShowing(props.show);
	// }, [props.show]);

	return createPortal(
		<div
			className={styles.modal}
			onClick={handle_onClick_close}
		>
			<div
				className={styles.popup}
				onClick={(event) => event.stopPropagation()}
			>
				<FontAwesomeIcon
					icon={'circle-xmark'}
					size="2x"
					className={styles.closeButton}
					onClick={props.onCloseRequest}
				/>
				{props.children}
			</div>
		</div>,
		document.body
	);
};
