import authProvider, { refreshToken } from '../utilities/authProvider';
import { IUserManager, UserManager } from '../utilities/UserManager';

const userManager: IUserManager = new UserManager();

async function AuthorizedFetchRequest(
	url: { base: string; path?: string; queries?: object },
	method: 'POST' | 'GET' | 'PUT' | 'DELETE',
	inputValues?: any,
	culture?: string
): Promise<Response> {
	const response = await FetchRequest(
		url,
		method,
		{
			Authorization: `bearer ${userManager.getToken()}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Culture: culture ? culture : null,
		},
		inputValues
	);

	if (!response.ok) {
		const json = await response.json();

		const errorName = json.ErrorName !== undefined ? json.ErrorName : json.errorName;

		if (errorName !== undefined) handleError(errorName, () => AuthorizedFetchRequest(url, method, inputValues));
	}

	return response;
}

function UnauthorizedFetchRequest(
	url: { base: string; queries?: object },
	method: 'POST' | 'GET' | 'PUT' | 'DELETE',
	inputValues?: any
): Promise<Response> {
	return FetchRequest(
		url,
		method,
		{
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		inputValues
	);
}

function FetchRequest(
	url: { base: string; path?: string; queries?: object },
	method: 'POST' | 'GET' | 'PUT' | 'DELETE',
	headers?: any,
	inputValues?: any
): Promise<Response> {
	let fullUrl = url.base;
	fullUrl = url.path ? `${fullUrl}${url.path}` : fullUrl;
	if (url.queries !== undefined) {
		fullUrl = AddQueriesToUrl(fullUrl, url.queries);
	}

	const init: RequestInit = {
		method: method,
		mode: 'cors',
		headers: headers,
	};

	if (inputValues) init.body = JSON.stringify(inputValues);

	return fetch(fullUrl, init)
		.then((res) => {
			return res;
		})

		.catch((e) => {
			console.error(e);
			return e;
		});
}

async function handleError(errorName: string, erroredRequest: () => Promise<any>) {
	switch (errorName) {
		case 'SecurityTokenExpiredException':
			await refreshToken();

			return erroredRequest();
		case 'SecurityTokenSignatureKeyNotFoundException':
		case 'AuthTokenExpired':
		case 'NoJwtException':
		case 'SecurityTokenInvalidLifetimeException':
			authProvider.clearTokenCookie();
			window.location.href = '/login';
			return undefined;
		default:
			return undefined;
	}
}

function AddQueriesToUrl(url: string, queries: Object): string {
	let newUrl = url;

	//SVEN ADDED FIX FOR NEW WAY OF SENDING PARAMETERS
	Object.entries(queries).forEach(([key, value], index) => {
		newUrl += `${index === 0 ? '?' : '&'}`;
		if (Array.isArray(value))
			value.forEach((v, i) => {
				newUrl += `${key}=${v}`;
				if (i < value.length - 1) newUrl += '&';
			});
		else newUrl += `${key}=${value}`;
	});

	return newUrl;
}

export { AuthorizedFetchRequest, UnauthorizedFetchRequest, handleError };
