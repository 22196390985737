import { PanelEntity } from '../../../components/PanelEntity/PanelEntity';
import { EntityType } from '../../../components/EntityDetailPopup/entityDetailPopup.enums';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { NotFoundPage } from '../../NotFoundPage';

export function OpportunityPage() {
	const hasOpportunityModule = hasModule(moduleNames.opportunity);

	return hasOpportunityModule ? <PanelEntity entityType={EntityType.Opportunity} /> : <NotFoundPage />; //TODO: missing module page
}
