import loginStyles from '../../pages/Authentication/authentication.module.scss';
import styles from './loginPanel.module.scss';
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export default class IconInput extends React.Component<{
	placeholder?: string,
	type: "text" | "number" | "password",
	name?: string,
	className?: string,
	id?: string,
	value?: string | number | readonly string[],
	error?: boolean
	icon: IconDefinition,
	onBlur?: React.FocusEventHandler<HTMLInputElement>,
	onChange?: React.ChangeEventHandler<HTMLInputElement>,
	onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>,
	autoComplete?: string
}, {}> {


	render() {
		return (
			<div className={`
                ${styles.inputIcons} 
                ${styles.inputRow} 
                ${styles.label} 
                ${this.props.error && styles.error}
            `}>
				<i className={`${styles.icon} ${this.props.error && styles.error}`}>
					<FontAwesomeIcon icon={this.props.icon} />
				</i>
				<input
					className={`${loginStyles.inputField} 
                        ${this.props.error && styles.error} 
                        ${this.props.className}`}
					name={this.props.name}
					id={this.props.id}
					type={this.props.type}
					placeholder={this.props.placeholder}
					onChange={this.props.onChange}
					onKeyPress={this.props.onKeyPress}
					value={this.props.value}
					onBlur={this.props.onBlur}
					autoComplete={this.props.autoComplete }
				/>
			</div>
		);
	}
}