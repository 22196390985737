import { api } from '../../api/_Executor';
import { AppointmentQuery } from '../../business/Query/AppointmentQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { AppointmentTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { AppointmentView } from '../../models/BusinessObjects_View/AppointmentView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

/** NOT FOR AGENDA. Mainly for popup appointments/activies */
export function AppointmentViewCollectionViewModel<T = AppointmentView, Q extends BaseQuery = AppointmentQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean
): ICollectionViewModel<T, Q> {
	const apiReader = api.appointment.viewAsync;
	const query = new AppointmentQuery() as Q;
	const table = AppointmentTableDefinition;
	const canSearch: boolean = false;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<T, Q>(instanceName, apiReader, ignoreRepresentative_id, query, undefined, 'id');

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,
	};
}
