import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { DateTimeType } from '../../InputFields/inputField.types';
import { updateCompanies } from '../../../store/reducers/CompanySlice';
import { FormDetail } from './_FormDetail';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { Task } from '../../../models/Model/Task';
import { stringJoin } from './_utility';
import { EditMethod, IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { ITaskViewModel } from '../../../viewmodels/TaskViewModel';
import { FormHeaderTask } from './Headers/FormHeaderTask';
import { InfoGroup } from '../../InfoBlocks';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';

interface ITaskFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<Task>;
}

export function TaskFormDetail(props: ITaskFormDetailProps) {
	//TODO: Baseform?
	const p = props.vm.getProperties();
	const e = (p: string) => stringJoin(props.vm.getPropertyErrors(p));

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={props.vm}
			headerText={p.subject}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName='list-check'
			header={
				<FormHeaderTask
					vm={props.vm as ITaskViewModel}
					editing={props.isEditing}
					setEditing={props.setIsEditing}
					closeDetailPopup={props.closeDetailPopup}
				/>
			}
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.PresetDropDownRow
					label={t('company')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.company_Id}
					errorMessage={e(p.company_Id)}
					DDEntityType={DropdownEntityType.companies}
					readonly={!props.isEditing}
				/>
			</InfoGroup>

			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('taskData')}
			>
				<BoundInputRows.TextRow
					label={t('taskAttributes.subject')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.subject}
					errorMessage={e(p.subject)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextareaRow
					label={t('taskAttributes.description')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.description}
					errorMessage={e(p.description)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.DateTimeRow
					label={t('taskAttributes.dueDate')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.dueDate}
					errorMessage={e(p.dueDate)}
					type={DateTimeType.date}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('representativeAttributes.assignedTo')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.assignedTo_Id}
					errorMessage={e(p.assignedTo_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('representativeAttributes.createdBy')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName={p.createdBy_Id}
					errorMessage={e(p.createdBy_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={props.vm}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
}
