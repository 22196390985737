import React from "react";
import { InfoHeader } from "./InfoHeader";

import styles from './infoBlocks.module.scss'

export class InfoGroupAccordion extends React.Component<
	{
		groups: { title: string, content?: React.ReactNode }[]
	},
	{
		openIndex: number;
	}
>{
	constructor(props) {
		super(props);

		this.state = {
			openIndex: 0
		}
	}

	onTitleClick = (index) => {
		this.setState({ openIndex: index });
	}

	render() {
		return (
			<React.Fragment>
				{
					this.props.groups.map((group, i) => {
						let open = this.state.openIndex === i;

						return (<div className={styles.infoGroup}>
							<InfoHeader title={group.title}
								onTitleClick={() => this.onTitleClick(i)}
								open={open}
							/>
							{open &&
								<div className={styles.content}>
									{group.content}
								</div>
							}
						</div>
						);
					})
				}
			</React.Fragment>
		);
	}
}