export enum EntityType {
	Company,
	Task,
	Opportunity,
	Appointment,
	Agenda,
	Address,
	Contact,
	Annotation,
	VisitingFrequency,
    File
}

export enum EditorConditionType {
	Normal,
	Modified,	
}

export enum PopupDisplayType {
	Create,
	Read,
}

export enum EditorType {
	Form = "Form",
	Table = "Table",
}

export enum TabType {
	Tab,
	Header,
}

//TODO: REMOVED
export enum CrudType {
	Create,
	Read,
	Update,
	Delete,
}

export enum ModeType {
	Create,
	Delete,
	Edit,
	Save,
}

