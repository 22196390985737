import { Fragment, useCallback, useEffect } from 'react';
import { GridComponents } from '../../../components';
import { BoundListView } from '../../../components/EntityListDetail/BoundListView/BoundListView';
import React from 'react';
import { VisitingFrequencyViewCollectionViewModel } from '../../../viewmodels/Collections/VisitingFrequencyCollectionViewModel';
import { CrudType, EntityType } from '../../../components/EntityDetailPopup/entityDetailPopup.enums';
import EventBus from '../../../utilities/EventBus';
import { VisitingFrequencyTableDefinition } from '../../../components/DynamicTable/dynamicTable.index';

export const VisitingFrequenciesPage = () => {
	// const location = useLocation();
	// const navigate = useNavigate();
	// const match = useMatch(location.pathname);

	const instanceName = 'settings.visitingFrequencyCollection';
	const vm = VisitingFrequencyViewCollectionViewModel(instanceName);

	const handleViewModelEvent = useCallback((args) => {
		const crudType = args.detail.crudType;

		if (crudType && crudType !== CrudType.Read) {
			//TODO, remember scrollposition?
			vm.refresh().catch((e) => console.warn(e));
		}
	}, []);

	const cvmRead = useCallback(() => {
		vm.read();
		EventBus.instance().on(instanceName, handleViewModelEvent);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<GridComponents.Column size='threeFourths'>
				{/* <GridComponents.Row.Header content={<GridComponents.Row.Breadcrumb match={match} />} /> */}
				<BoundListView
					cvm={vm}
					cvmRead={cvmRead}
					tableAttributes={VisitingFrequencyTableDefinition.tableAttributes}
					entityType={EntityType.VisitingFrequency}
					// idPropName='id'
					VMInstanceName='settings.vistingFrequency'
					headerText='visitingFrequencies'
				/>
			</GridComponents.Column>
		</Fragment>
	);
};
