/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";

export interface IOpportunityStatusReason extends IModelBase {
	code: string | null;
    description: string | null;
}

export class OpportunityStatusReason extends ModelBase implements IOpportunityStatusReason {
	public code: string | null = null;
    public description: string | null = null;
}
