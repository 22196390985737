import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from '../../../../compat/RouterCompat';

import styles from '../../settingsPage.module.scss';

import { FieldDefinitionLayer } from './FieldDefinitionLayer';
import { GroupDropZoneProps, groupPositionOnGroupLayer, GroupLayerProps, GroupLayerState, fieldDefinitionPositionOnGroupLayer } from './types';

class GroupDropZone extends Component<GroupDropZoneProps, { dragOver: boolean }> {
	constructor(props) {
		super(props);

		this.state = { dragOver: false };
	}

	private dropHandler = () => {
		if (this.state.dragOver) this.setState({ dragOver: false });

		if (this.props.dragging !== undefined) {
			if (this.props.isEmpty) this.props.onNewFDInEmptyGroupDrop(this.props.dragging);
		} else if (this.props.prevPosition)
			if (this.props.prevPosition.FD !== undefined) {
				const prevFDPosition: fieldDefinitionPositionOnGroupLayer = {
					group: this.props.prevPosition.group,
					FD: this.props.prevPosition.FD!,
				};
				if (this.props.isEmpty) {
					this.props.onFDInEmptyGroupDrop(prevFDPosition);
				} else {
					this.props.onFDInGroupDrop(prevFDPosition);
				}
			} else {
				this.props.onGroupInGroupDrop(this.props.prevPosition, this.props.currentPosition);
			}
	};

	componentDidUpdate(prevProps) {
		if (this.props.isDragging !== prevProps.isDragging) {
			this.setState({
				dragOver: false,
			});
		}
	}

	render() {
		return (
			<div
				className={`${styles.groupDropZoneWrapper} ${
					this.props.isDragging &&
					this.state.dragOver &&
					this.props.dragging === undefined &&
					this.props.prevPosition?.FD === undefined &&
					styles.borderTop
				}`}
			>
				<div
					className={`${styles.groupDropZone} ${this.props.isEmpty ? styles.empty : styles.filled} ${this.props.isDragging && styles.dragging} ${
						this.state.dragOver && styles.dragOver
					}`}
					onDrop={this.dropHandler}
					onDragOver={(event) => {
						event.preventDefault();
						if (!this.state.dragOver) this.setState({ dragOver: true });
					}}
					onDragExit={() => {
						if (this.state.dragOver) this.setState({ dragOver: false });
					}}
					draggable={this.props.dragFunctions && true}
					onDragStart={this.props.dragFunctions && this.props.dragFunctions.onDragStart}
					onDragEnd={this.props.dragFunctions && this.props.dragFunctions.onDragEnd}
				>
					{this.props.children}
				</div>
			</div>
		);
	}
}

class GroupLayerComponent extends Component<GroupLayerProps, GroupLayerState> {
	constructor(props) {
		super(props);

		this.state = {};
	}

	private groupDragHandler = (groupPosition: number) => () => {
		this.setState({
			positionOfGroupOrFieldDefinitionBeingDragged: {
				group: groupPosition,
			},
		});
	};

	private groupDropHandler = (prevGroupPosition: groupPositionOnGroupLayer, newGroupPosition: groupPositionOnGroupLayer) => {
		this.props.moveGroupFunction(prevGroupPosition, newGroupPosition);
	};

	private FDDragHandler = (groupPosition: number) => (FDPosition: number) => {
		this.setState({
			positionOfGroupOrFieldDefinitionBeingDragged: {
				group: groupPosition,
				FD: FDPosition,
			},
		});
	};

	private FDDropHandler = (groupName: string | null, groupPosition: number) => (FDPosition: number) => {
		if (this.state.positionOfGroupOrFieldDefinitionBeingDragged) {
			const prevPosition = this.state.positionOfGroupOrFieldDefinitionBeingDragged;
			if (prevPosition.FD !== undefined) {
				if (prevPosition.group === groupPosition) {
					let moveDifference = FDPosition - prevPosition.FD;
					if (moveDifference > 0) moveDifference--;
					this.props.moveFieldDefinitionFunction(groupPosition, groupName)(prevPosition.FD, moveDifference);
				} else {
					this.props.moveFDToGroup(prevPosition.group, groupPosition)(prevPosition.FD, FDPosition);
				}
			}
			this.setState({
				positionOfGroupOrFieldDefinitionBeingDragged: undefined,
			});
		} else this.props.onFDDrop(groupPosition, groupName)(FDPosition);
	};

	private FDDropInGroupHandler = (FDNewPosition: fieldDefinitionPositionOnGroupLayer) => (FDPrevPosition: fieldDefinitionPositionOnGroupLayer) => {
		this.props.moveFDToGroup(FDPrevPosition.group, FDNewPosition.group)(FDPrevPosition.FD, FDNewPosition.FD);
	};

	private FDDropInNewGroupHandler = (FDPrevPosition: fieldDefinitionPositionOnGroupLayer) => {
		this.props.moveFDToNewGroup(FDPrevPosition);
	};

	private newFDDropHandler = (FDTypeIndex: number) => {
		this.props.moveNewFDToNewGroup(FDTypeIndex);
	};

	private dragStopHandler = () => {
		this.setState({
			positionOfGroupOrFieldDefinitionBeingDragged: undefined,
		});
	};

	render() {
		const isDragging = this.props.dragging !== undefined || this.state.positionOfGroupOrFieldDefinitionBeingDragged !== undefined;

		const isDraggingFD = isDragging && this.state.positionOfGroupOrFieldDefinitionBeingDragged?.FD !== undefined;

		return (
			<Fragment>
				{this.props.groups.map((group, key) => {
					return (
						group.fieldDefinitions.length > 0 && (
							<GroupDropZone
								key={key}
								isEmpty={false}
								currentPosition={{ group: key }}
								prevPosition={this.state.positionOfGroupOrFieldDefinitionBeingDragged}
								dragging={this.props.dragging}
								isDragging={isDragging}
								onGroupInGroupDrop={this.groupDropHandler}
								onFDInGroupDrop={this.FDDropInGroupHandler({
									group: key,
									FD: group.fieldDefinitions.length,
								})}
								onFDInEmptyGroupDrop={this.FDDropInNewGroupHandler}
								onNewFDInEmptyGroupDrop={this.newFDDropHandler}
								dragFunctions={{
									onDragStart: this.groupDragHandler(key),
									onDragEnd: this.dragStopHandler,
								}}
							>
								<div
									className={styles.groupLabel}
									onClick={() => {
										this.props.onItemClick(key)()();
									}}
								>
									{group.label && <h5>{group.label}</h5>}
									<div className={styles.grip}>
										<FontAwesomeIcon icon='grip-vertical' />
									</div>
								</div>
								<FieldDefinitionLayer
									navigate={this.props.navigate}
									key={key}
									fieldDefinitions={group.fieldDefinitions}
									types={this.props.types}
									dragging={this.props.dragging}
									isDragging={isDraggingFD}
									moveFieldDefinitionFunction={this.props.moveFieldDefinitionFunction(key, group.label)}
									onItemClick={this.props.onItemClick(key)}
									onFDDragStart={this.FDDragHandler(key)}
									onFDDragStop={this.dragStopHandler}
									onFDDrop={this.FDDropHandler(group.label, key)}
									onDeleteButtonClick={this.props.onDeleteButtonClick(key)}
									match={this.props.match}
									location={this.props.location}
								/>
							</GroupDropZone>
						)
					);
				})}
				<GroupDropZone
					isEmpty={true}
					isDragging={isDragging}
					currentPosition={{ group: this.props.groups.length }}
					prevPosition={this.state.positionOfGroupOrFieldDefinitionBeingDragged}
					dragging={this.props.dragging}
					onGroupInGroupDrop={this.groupDropHandler}
					onFDInGroupDrop={this.FDDropInGroupHandler}
					onFDInEmptyGroupDrop={this.FDDropInNewGroupHandler}
					onNewFDInEmptyGroupDrop={this.newFDDropHandler}
				>
					<div className={styles.description}>
						<Trans i18nKey='dragAndDropDescription' />
					</div>
				</GroupDropZone>
			</Fragment>
		);
	}
}

export const GroupLayer = withRouter(GroupLayerComponent);
