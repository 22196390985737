import { useState } from 'react';

import { Annotation } from '../../../../models/Model/Annotation';
import { IViewModel } from '../../../../viewmodels/_viewModel.interfaces';
import { IEntityFormDetailProps } from '../../entityDetailPopup.interfaces';
import { FormDetail } from '../_FormDetail';
import { t } from 'i18next';
import BoundInputRows from '../../../InputFields/BoundInputRows/BoundInputRows';
import { stringJoin } from '../_utility';
import { InfoGroup } from '../../../InfoBlocks';
import { CustomFieldEditor } from '../../CustomField/CustomFieldEditor';

interface IAnnotationFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<Annotation>;
}

export const AnnotationFormDetail = (props: IAnnotationFormDetailProps) => {
	const [editing, setEditing] = useState(!props.id);

	return (
		<FormDetail
			vm={props.vm}
			headerText={new Date(props.vm.getProperty('createdDate')).toLocaleString()}
			editing={editing}
			setEditing={setEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName='pen'
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('annotation')}
			>
				<BoundInputRows.TextareaRow
					label={t('text')}
					vm={props.vm}
					model={props.vm.model}
					modelValuePropertyName='text'
					errorMessage={stringJoin(props.vm.getPropertyErrors('text'))}
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={props.vm}
				editing={editing}
			/>
		</FormDetail>
	);
};
