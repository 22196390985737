import { AuthorizedFetchRequest } from './apiBase';
import { IQueryOLD } from './_Query';
import { UserManager } from '../utilities/UserManager';

const baseUrl = `api/OrganizationSettings`;
const userManager = new UserManager();

export const AppointmentsQuery: IQueryOLD = {
	Representative_Id: userManager.getCurrentUserId(),
};
export function getStorageTotalAmount(): Promise<Response> {
	const url = { base: `${baseUrl}/Restricted/compact` };

	return AuthorizedFetchRequest(url, 'GET').then((res) => {
		return res;
	});
}