/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";

export interface ICompanyGroup extends IModelBase {
	code: string | null;
    description: string | null;
    summary: string | null;
}

export class CompanyGroup extends ModelBase implements ICompanyGroup {
	public code: string | null = null;
    public description: string | null = null;
    public summary: string | null = null;
}
