export const table = {
	title: 'tempName',
	type: 'table',
	headerPropertyName: 'name',
	icon: 'city',
	tableAttributes: [
		{
			headTitle: 'type',
			property: {
				name: '$type',
			},
		},		
		{
			headTitle: 'id',
			property: {
				name: 'id',
			},
		},
		{
			headTitle: 'name',
			property: {
				name: 'name',
			},
		},
		{
			headTitle: 'subject',
			property: {
				name: 'subject',
			},
		},
		{
			headTitle: 'text',
			property: {
				name: 'text',
			},
		},
		{
			headTitle: 'description',
			property: {
				name: 'description',
			},
		},
		{
			headTitle: 'rowVersion',
			property: {
				name: 'rowVersion',
			},
		},
		{
			headTitle: 'createdDate',
			property: {
				name: 'createdDate',
			},
			type: 'datetime-local',
		},
		{
			headTitle: 'modifiedDate',
			property: {
				name: 'modifiedDate',
			},
			type: 'datetime-local',
		},
	],
	apiUrl: '/CompanyAnnotation',
};
