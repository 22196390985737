import { ReactElement, createElement } from 'react';
import { Settings } from '../../../globals/settings';
import { IconCustomType } from '../../IconCustom/IconCustom.definitions';
import { dateHelper } from '../../../utilities/dateHelper';
import { colorHelper } from '../../../utilities/colorHelper';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { PrioritizedOpportunityViewCollectionViewModel } from '../../../viewmodels/Collections/PrioritizedOpportunityViewCollectionViewModel';
import { PrioritizedOpportunityView } from '../../../models/BusinessObjects_View/PrioritizedOpportunityView';
import { DashboardLane } from '../DashboardLane';
import DashboardListItem, { IDashboardListItemProps } from '../DashboardListItem';
import { t } from 'i18next';

export function DashboardLaneOpportunities() {
	const cvm = PrioritizedOpportunityViewCollectionViewModel('dashboard_opportunities', false, Settings.PAGINATION_DASHBOARD_TAKE);
	const dateNow = new Date();

	const generateElement = (entity: PrioritizedOpportunityView, clickHandler: any): ReactElement => {
		const getIcon = (chance: number): IconCustomType => {
			if (chance < 16.5) return IconCustomType.DASH_OPPORTUNITY_1;
			if (chance < 33) return IconCustomType.DASH_OPPORTUNITY_2;
			if (chance < 49.5) return IconCustomType.DASH_OPPORTUNITY_3;
			if (chance < 66) return IconCustomType.DASH_OPPORTUNITY_4;
			if (chance < 82.5) return IconCustomType.DASH_OPPORTUNITY_5;

			return IconCustomType.DASH_OPPORTUNITY_6;
		};

		//TODO: Get local currency
		let closingDate = entity.opportunity_EstimatedClosingDate;
		const money = Math.round(entity.opportunity_Amount!).toLocaleString();
		const titleBotRight = closingDate ? dateHelper.DATE_FORMATTER_SHORT.format(new Date(closingDate)) : '';
		const info = colorHelper.getTaskOverdueInfo(dateNow, closingDate, false);

		const properties: IDashboardListItemProps = {
			icon: getIcon(entity.opportunity_EstimatedSuccessRate!),
			iconColor: info.color,
			iconTitle: info.value,

			key: entity.opportunity_Id,
			entity: entity,
			id: 0,

			titleTopLeft: entity.opportunity_Subject,
			titleMidLeft: entity.opportunity_Company_Name ?? '[companyname]',
			titleBotLeft: entity.opportunity_Representative_FullName ?? '[representative]',

			titleTopRight: money,
			titleMidRight: `${entity.opportunity_EstimatedSuccessRate}%`,
			titleBotRight: titleBotRight,

			onListItemClick: clickHandler,
		};

		let block = createElement(DashboardListItem, properties, null);
		return block;
	};

	return (
		<DashboardLane
			icon={IconCustomType.GEN_OPPORTUNITY}
			title={t('opportunities')}
			cvm={cvm}
			elementGeneratorFunc={generateElement}
			popupEntityType={EntityType.Opportunity}
		/>
	);
}
