import { AuthorizedFetchRequest } from './apiBase';
import { IQueryOLD } from './_Query';
import { UserManager } from '../utilities/UserManager';

const baseUrl = `api/File`;
const userManager = new UserManager();

export const AppointmentsQuery: IQueryOLD = {
	Representative_Id: userManager.getCurrentUserId(),
};

export function getAvailableStorage(): Promise<Response> {
	const url = { base: `${baseUrl}/availableBytes` };

	return AuthorizedFetchRequest(url, 'GET').then((res) => {
		return res;
	});
}
