import React, { Component, Fragment } from 'react';

import { GroupFormProps, InputProperties, SelectionProperties, FieldDefinitionFormProps } from './types';
import styles from '../settingsPage.module.scss';

import inputs from '../DetailInputs.json';
import { InfoGroup } from '../../../components/InfoBlocks';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Enums from '../enums';

export class GroupForm extends Component<GroupFormProps> {
	private inputProperties: InputProperties[] = inputs.group;

	private generateInputElement = (input: InputProperties): JSX.Element => {
		return (
			<input
				type={input.type}
				id={input.name}
				name={input.name}
				value={this.props.group[input.name] ? this.props.group[input.name] : ''}
				placeholder={input.placeholder}
				required={input.required}
				onChange={this.props.onChange()}
				style={{ backgroundColor: 'hotpink' }}
			/>
		);
	};

	render() {
		return (
			<Fragment>
				{this.inputProperties.map((input, key) => {
					if (input.editable || this.props.group.method === 'post')
						return (
							<div
								key={key}
								className={styles.componentDetail}
							>
								<div className={styles.name}>
									<Trans i18nKey={input.label} />
								</div>
								<div className={styles.value}>{this.generateInputElement(input)}</div>
							</div>
						);
					else return null;
				})}
			</Fragment>
		);
	}
}

export class FieldDefinitionForm extends Component<FieldDefinitionFormProps> {
	private generateInputProperties = (type: string): InputProperties[] => {
		const fdInputProperties: InputProperties[] = [...inputs.base, ...inputs[type]];
		return fdInputProperties;
	};

	private generateInputElement = (input: InputProperties | SelectionProperties): JSX.Element => {
		if (input.type === 'boolean') {
			return (
				<select
					name={input.name}
					id={input.name}
					required={input.required}
					onChange={this.props.onBooleanChange()}
					value={this.props.fieldDefinition[input.name] !== undefined ? this.props.fieldDefinition[input.name] : ''}
				>
					{!input.required && <option></option>}
					<option value='true'>{t('true')}</option>
					<option value='false'>{t('false')}</option>
				</select>
			);
		} else if (input.type === 'selection') return this.generateSelectionElement(input);
		else if (input.type === 'selectionFromValue') {
			return this.generateSelectionElement({
				...input,
				selections: this.props.fieldDefinition[input.value!],
			});
		} else {
			let type = input.type;

			if (type === 'dateTimeTypeFromValue') {
				const dateTimeType = Enums.DateType[this.props.fieldDefinition[input.value!]];
				if (typeof dateTimeType === 'string') type = dateTimeType === 'dateTime' ? 'datetime-local' : dateTimeType.toLowerCase();
			}

			return (
				<input
					className={this.props.fieldDefinition.invalid && input.required && !this.props.fieldDefinition[input.name] ? styles.invalid : ''}
					type={type}
					id={input.name}
					name={input.name}
					value={this.props.fieldDefinition[input.name] ? this.props.fieldDefinition[input.name] : ''}
					placeholder={input.placeholder}
					required={input.required}
					onChange={this.props.onChange()}
				/>
			);
		}
	};

	private generateSelectionElement = (input: SelectionProperties): JSX.Element => {
		let selections: string[] = ['no options available'];
		if (input.selections !== undefined) {
			selections = input.selections;
		} else if (input.enum !== undefined) {
			selections = this.generateIndexArrayFromEnum(Enums[input.enum], input.required);
		}

		return (
			<select
				className={this.props.fieldDefinition.invalid && input.required && !this.props.fieldDefinition[input.name] ? styles.invalid : ''}
				name={input.name}
				id={input.name}
				required={input.required}
				onChange={this.props.onChange()}
				value={
					!(this.props.fieldDefinition[input.name] === undefined || this.props.fieldDefinition[input.name] === null)
						? this.props.fieldDefinition[input.name]
						: input.defaultValue
				}
			>
				{!input.required && <option value=''>{t('empty')}</option>}
				{selections.map((selection) => {
					return (
						<option
							key={selection}
							value={selection}
						>
							{input.enum ? Enums[input.enum][selection] : selection}
						</option>
					);
				})}
			</select>
		);
	};

	private generateListInputElements = (input: InputProperties) => {
		const listValues: string[] = this.props.fieldDefinition[input.name];
		const listInputs: JSX.Element[] = [];
		for (let i = 0; i <= listValues.length; i++) {
			listInputs.push(
				<div
					key={i}
					className={styles.listValue}
				>
					<input
						className={this.props.fieldDefinition.invalid && input.required && !this.props.fieldDefinition[input.name].length ? styles.invalid : ''}
						name={input.name}
						value={i !== listValues.length ? listValues[i] : ''}
						onChange={this.props.onChange(i)}
						placeholder={i === listValues.length ? (t('addNewItem') as string) : ''}
					/>

					{i !== listValues.length && (
						<div className={styles.actions}>
							<FontAwesomeIcon
								className={styles.icon}
								onClick={this.props.onListItemDeleteButtonClick(i, input.name)}
								icon='trash'
							/>
							<div className={styles.move}>
								{i !== 0 ? (
									<FontAwesomeIcon
										className={styles.icon}
										onClick={this.props.onListItemMoveClick(i, input.name, 'up')}
										icon='angle-up'
									/>
								) : null}
								{i !== listValues.length - 1 ? (
									<FontAwesomeIcon
										className={styles.icon}
										onClick={this.props.onListItemMoveClick(i, input.name, 'down')}
										icon='angle-down'
									/>
								) : null}
							</div>
						</div>
					)}
				</div>
			);
		}
		return <Fragment>{listInputs}</Fragment>;
	};

	private generateIndexArrayFromEnum = (Enum, required?: boolean): string[] => {
		const StringIsNotNumber = (value) => isNaN(Number(value)) === true;

		const array: string[] = [];
		if (!required) array.push('');

		return array.concat(
			Object.keys(Enum)
				.filter(StringIsNotNumber)
				.map((key): string => {
					return Enum[key];
				})
		);
	};

	render() {
		const Inputs = this.generateInputProperties(this.props.fieldDefinition.$type);
		const listInputs: InputProperties[] = [];
		Inputs.forEach((input) => {
			if (input.type === 'list') listInputs.push(input);
		});

		return (
			<Fragment>
				{Inputs.map((input, key) => {
					if (input.type === 'list') return <Fragment />;
					else if (
						(!input.entityTypes || input.entityTypes?.includes(this.props.fieldDefinition.entityType_Name)) &&
						(input.editable || this.props.fieldDefinition.method === 'post')
					)
						return (
							<div
								key={key}
								className={styles.componentDetail}
							>
								<div className={`${styles.name} text-capitalize`}>
									<Trans i18nKey={input.label} />
								</div>
								<div className={styles.value}>{this.generateInputElement(input)}</div>
							</div>
						);
					else return <Fragment key={key} />;
				})}
				{listInputs.map((listInput, key) => {
					return (
						<Fragment key={key}>
							<InfoGroup
								title={t(listInput.label)}
								open={true}
								visible={true}
								theme='gray'
							>
								{this.generateListInputElements(listInput)}
							</InfoGroup>
						</Fragment>
					);
				})}
			</Fragment>
		);
	}
}
