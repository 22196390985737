import { t } from 'i18next';
import { PrioritizedCompanyViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedCompanyViewCollectionViewModel';
import { PrioritizedOpportunityViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedOpportunityViewCollectionViewModel';
import { PrioritizedTaskViewCollectionViewModel } from '../../viewmodels/Collections/PrioritizedTaskViewCollectionViewModel';
import { EntityType } from '../EntityDetailPopup/entityDetailPopup.enums';
import { IEntityListViewConfigs, ListPageEntityType } from './entityListView.interfaces';
import { CompanyTableDefinition, OpportunityTableDefinition, TaskTableDefinition } from '../DynamicTable/dynamicTable.index';
import { PrioritizedCompanyView } from '../../model/Views/PioritizedCompanyView';
import { PrioritizedTaskView } from '../../models/BusinessObjects_View/PrioritizedTaskView';
import { PrioritizedOpportunityView } from '../../models/BusinessObjects_View/PrioritizedOpportunityView';
import { OpportunityStatus } from '../../model/_Enums';

export type IEntityListViewDictionary = Record<ListPageEntityType, IEntityListViewConfigs>;

const CompanyListView: IEntityListViewConfigs = {
	viewModel: PrioritizedCompanyViewCollectionViewModel,
	tableAttributes: CompanyTableDefinition.tableAttributes,
	idPropName: 'entity_Id',
	collectionVMInstanceName: 'company',
	headerText: t('companies'),
	checkEmphasisFunction: (item: PrioritizedCompanyView) => {
		if (item.weeksDue) return item.weeksDue >= 0;
		else return false;
	},
};

const TaskListView: IEntityListViewConfigs = {
	viewModel: PrioritizedTaskViewCollectionViewModel,
	tableAttributes: TaskTableDefinition.tableAttributes,
	idPropName: 'task_Id',
	collectionVMInstanceName: 'task',
	headerText: t('tasks'),
	checkEmphasisFunction: (item: PrioritizedTaskView) => {
		if (item.task_IsCompleted === false && item.task_DueDate !== null) {
			const now = new Date();
			const dueDate = new Date(item.task_DueDate);
			return now > dueDate;
		} else return false;
	},
};

const OpportunityListView: IEntityListViewConfigs = {
	viewModel: PrioritizedOpportunityViewCollectionViewModel,
	tableAttributes: OpportunityTableDefinition.tableAttributes,
	idPropName: 'opportunity_Id',
	collectionVMInstanceName: 'opportunity',
	headerText: t('opportunities'),
	checkEmphasisFunction: (item: PrioritizedOpportunityView) => {
		if (item.opportunity_OpportunityStatus === OpportunityStatus.open && item.opportunity_EstimatedClosingDate !== null) {
			const now = new Date();
			const dueDate = new Date(item.opportunity_EstimatedClosingDate);
			return now > dueDate;
		} else return false;
	},
};

export const listViewConfigurations: IEntityListViewDictionary = {
	[EntityType.Company]: CompanyListView,
	[EntityType.Task]: TaskListView,
	[EntityType.Opportunity]: OpportunityListView,
};
