import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { useAppSelector } from '../../../store/hooks';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import FilterDropDown, { FilterDropDownOption } from '../../FilterDropDown/FilterDropDown';
import { DropDownVariant } from '../../InputFields/DropdownInput';

const Filters = ({ props }) => {
	const companyGroups = useAppSelector((state) => state.globals.companyGroups);
	const [selectedValue, setSelectedValue] = useState<Array<FilterDropDownOption> | string | any>();

	const getFilterTypeData = (selected: FilterDropDownOption) => {
		switch (selected?.type) {
			case 'companyTypeFilter': {
				return { $type: 'prioritizedCompanyQuery.companyTypeFilter', companyType: selected.value };
			}
			case 'companyGroupIdFilter': {
				return { $type: 'prioritizedCompanyQuery.companyGroupIdFilter', id: selected.value };
			}
		}
	};

	const companyGroupOptions = companyGroups.map((group) => {
		return { value: group.id, label: `${group.description} (${group.code})`, type: 'companyGroupIdFilter' };
	});

	useEffect(() => {
		if (selectedValue) {
			const preparedFiltersForSending = selectedValue?.map((selected: FilterDropDownOption) => getFilterTypeData(selected));

			if (preparedFiltersForSending && props?.cvm?.filtering) {
				props?.cvm?.filtering(preparedFiltersForSending);
			}
		}
	}, [selectedValue]);

	switch (props.entityType) {
		case EntityType.Company:
			return (
				<>
					{hasModule(moduleNames.lead) && (
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={[
								{ value: 'account', label: t('account'), type: 'companyTypeFilter' },
								{ value: 'lead', label: t('lead'), type: 'companyTypeFilter' },
							]}
							hasClearOptions
							filterName={`${t('type')}`}
							filterType="companyTypeFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
					)}
					{hasModule(moduleNames.companyGroup) && (
						<FilterDropDown
							variant={DropDownVariant.multiCheckboxes}
							defaultValue={props.filters}
							options={companyGroupOptions}
							hasClearOptions
							filterName={`${t('companyAttributes.group')}`}
							filterType="companyGroupIdFilter"
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
						/>
					)}
				</>
			);
		case EntityType.Task:
			return <></>;
		default:
			return <></>;
	}
};
export default Filters;
