import { useCallback, useEffect } from 'react';
import { ISubEntityListDetailProps } from '../entityListView.interfaces';
import styles from '../entityListView.module.scss';
import { BoundListView } from '../BoundListView/BoundListView';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { Account } from '../../../models/Model/Account';
import { Lead } from '../../../models/Model/Lead';
import { AnnotationEditorList } from '../AnnotationEditor/AnnotationEditorList';

export interface ISubEntityAnnotationEditorListProps {
	className?: string;
	vm: IViewModel<Account | Lead>;
	collectionVMPropName: string;
	id: string;
}

export function SubEntityAnnotationEditorList(props: ISubEntityListDetailProps) {
	const cvm = props.vm[props.collectionVMPropName];

	return (
		<div className={styles.container}>
			<AnnotationEditorList
				className={props.className}
				id={props.id}
				cvm={cvm}
				headerText={props.vm.getProperty('name')}
			/>
		</div>
	);
}
