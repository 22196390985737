import { BaseQuery } from "./_BaseQuery";

export class RepresentativeQuery extends BaseQuery {
	//CompanyFilter
	
	public id?:string;
	public representative_Id?:string;
	public isActive?:boolean;
	public queryCurrentRepresentative?:boolean;
	public account_Id?:string;
	public inverse?:boolean;
	public systemPrivilige?:boolean;
	public searchTerm?:string;
}
