import styles from './inputFields.module.scss';
import { INumberInputProps } from './inputField.types';
import { useCallback } from 'react';

function NumberInput(props: INumberInputProps) {
	const handleFocus = useCallback((e) => {
		e.target.select();
	}, []);

	const [min, max] = [props.min, props.max];
	const onChange = props.onChange;
	const handleChange = useCallback(
		(e) => {
			let value = parseInt(e.target.value);

			if (min !== undefined && value < min) value = min;
			else if (max !== undefined && value > max) value = max;

			onChange(value);
		},
		[min, max, onChange]
	);

	return (
		<input
			type='number'
			required={props.required}
			className={`${styles.input} ${props.hasError && styles.invalid}`}
			value={props.value || 0}
			onFocus={handleFocus}
			onChange={handleChange}
			readOnly={props.readOnly}
			min={min}
			max={max}
		/>
	);
}

export default NumberInput;
