export interface IUrlInfo {
	path: string[];
}

export const getUrlPath = (): IUrlInfo => {
	//replace double slashes with single
	const regDoubleShlash = /\/&?/;
	let pathname = window.location.pathname.replace(regDoubleShlash, '/');

	//remove start slash if any
	if (pathname.startsWith('/')) {
		pathname = pathname.substring(1);
	}

	//remove end slash if any
	if (pathname.endsWith('/')) {
		pathname = pathname.substring(pathname.length - 1);
	}

	const array = pathname.split('/');

	//not sure about nullable types, yet
	let path: string[] = array;

	const info: IUrlInfo = {
		path: path,
	};

	return info;
};

const urlParser = {
	getUrlPath,
};

export default urlParser;
